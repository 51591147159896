import forEach from 'lodash/forEach';
import mergeWith from 'lodash/mergeWith';
import size from 'lodash/size';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';

import { Folder, Library, LibraryFolderLevel } from 'generated/graphql';

import libraryTemplates from 'components/Library/QuickStart/Steps/Carousel/templates';

const folderLevels = [LibraryFolderLevel.Root, LibraryFolderLevel.Season, LibraryFolderLevel.Collection, LibraryFolderLevel.Album];

export const getFolderLevel = (path: string): LibraryFolderLevel => {
	const folders = path.split('/');
	return folderLevels[folders.length - 1];
};

export const getNextFolderLevel = (currentLevel: LibraryFolderLevel) => {
	if (currentLevel === LibraryFolderLevel.Album) return null;
	const folderIndex = folderLevels.findIndex(folder => folder === currentLevel);
	return folderLevels[folderIndex + 1];
};

export const getFolderCounters = (folder: Folder) => {
	const counters = {
		[LibraryFolderLevel.Season]: 0,
		[LibraryFolderLevel.Collection]: 0,
		[LibraryFolderLevel.Album]: 0
	};
	const nextLevel = getNextFolderLevel(folder.level);
	// Album case
	if (!nextLevel) return counters;
	counters[nextLevel] = size(folder.folders);
	forEach(folder.folders, subfolder => {
		const nextLevelCounters = getFolderCounters(subfolder);
		mergeWith(counters, nextLevelCounters, (value, srcValue) => value + srcValue);
	});
	return counters;
};

export const getRootFolder = (libraryId: string, filetree: Library['filetree'], name = 'root'): Folder => ({
	path: '',
	libraryId,
	name,
	folders: filetree,
	level: LibraryFolderLevel.Root
});

// TODO: Complete this function when we have more library types
export const getDefaultAlbums = () => libraryTemplates[0].filetree.children[0].children.map(e => trim(e.name));

export const getAlbumNames = (folder: Folder, root: Boolean = true): string[] => {
	if (folder.level === LibraryFolderLevel.Album) return [trim(folder.name)];
	else {
		let names = [];
		forEach(folder.folders, subFolder => {
			names = [...names, ...getAlbumNames(subFolder, false)];
		});
		return root ? uniq(names) : names;
	}
};

export const getSubFolderNames = (folder: Folder, root: Boolean = true): string[] => {
	let names = [];
	forEach(folder.folders, subfolder => {
		const subFolderNames = getSubFolderNames(subfolder, false);
		names = [...names, subfolder.name, ...subFolderNames];
	});
	return root ? uniq(names) : names;
};

export const isInPath = (path: string, folderId: string) => {
	if (!path) return false;
	const pathFolders = path.split('/');
	for (const id of pathFolders) {
		if (folderId === id) return true;
	}
	return false;
};

export const isSubPath = (path: string, subPath: string) => {
	const pathFolders = path.split('/');
	const subpathFolders = subPath.split('/');
	let isSubPath = true;
	let i = 0;
	while (isSubPath && i < subpathFolders.length) {
		isSubPath = pathFolders[i] === subpathFolders[i];
		i++;
	}
	return isSubPath;
};
