import { gql } from '@apollo/client';

export const FEATURE_FLAGS_FRAGMENT = gql`
	fragment FeatureFlagsInfo on FeatureFlags {
		AITagging
		productDetails
		tagsEnabled
		smartCategory
		showDate
		metabase
		amplification
	}
`;

export const GET_FEATURE_FLAGS = gql`
	query getFeatureFlags {
		featureFlags {
			...FeatureFlagsInfo
		}
	}
	${FEATURE_FLAGS_FRAGMENT}
`;
