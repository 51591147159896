import { GetLibraryQueryResult, useGetCurrentLibraryIdQuery, useGetLibraryQuery } from 'generated/graphql';

type CurrentLibraryResult = [string, GetLibraryQueryResult];

export const useGetCurrentLibrary = (): CurrentLibraryResult => {
	const {
		data: {
			library: { currentLibraryId }
		}
	} = useGetCurrentLibraryIdQuery();
	const result = useGetLibraryQuery({ variables: { id: currentLibraryId }, skip: !currentLibraryId });
	return [currentLibraryId, result];
};
