import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
	mainContainer: {
		flexWrap: 'nowrap',
		paddingTop: 100
	},
	backHomebutton: {
		marginTop: 15
	}
}));
