import { LibraryResultsUi } from 'generated/graphql';

const defaultState: LibraryResultsUi = {
	selectedMedias: [],
	isUploadDrawerOpen: false,
	isRequestSpotlightDrawerOpen: false,
	uploadingQueue: [],
	shouldDisplayReloadButton: false,
	bigPreview: false
};

export default defaultState;
