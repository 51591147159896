import gql from 'graphql-tag';

export default gql`
	extend type Query {
		library: LibraryUI
		showroom: ShowroomUI
		spotlight: SpotlightUI
		showroomSamplesBasket(showroomId: String!): [Media]
		librarySelectedMedias: [Media]
		librarySelectionModeEnabled: Boolean
		folderById(id: String!): Folder
		librarySelectedFilterValues(filterType: String!): [MediaBucket]
		libraryBoxSelectedFilterValues(filterBox: String!): [MediaBucket]
		libraryAllSelectedFilterValues: [String]
		cachedMedia(mediaId: String): Media
		cachedMedias(mediaIds: [String]): [Media]
		mediaDetailContext(id: String): LibraryMediaDetailsContext
		showroomSection(id: String!): ShowroomSectionUI
		currentShowroomSection: ShowroomSectionUI
		currentShowroomSectionMedias: [SectionFileUI]
		showroomFilterValues(ids: [String]): [ShowroomFilterValue]
		collectionById(id: String!): Collection
	}

	extend type Folder {
		level: LibraryFolderLevel
		libraryId: String
	}

	type LibraryUI {
		currentLibraryId: String
		currentFolderId: String
		results: LibraryResultsUI
		search: LibrarySearchUI
		folderForm: LibraryFolderFormUI
		mediaDetailModal: LibraryMediaDetailModalUI
		deleteFolderDialog: LibraryDeleteFolderDialogUI
		lastCreatedFolders: [String]
	}

	type SelectedMediaUI {
		id: String
		type: MediaType
	}

	type LibraryResultsUI {
		selectedMedias: [SelectedMediaUI]
		isUploadDrawerOpen: Boolean
		isRequestSpotlightDrawerOpen: Boolean
		bigPreview: Boolean
		uploadingQueue: [LibraryUploads]
		shouldDisplayReloadButton: Boolean
	}

	enum LibraryUploadStatus {
		UPLOADING
		COMPLETED
		FETCHING
		FETCHED
	}

	type LibraryUploadedFile {
		publicId: String
		mediaId: String
		mediaType: String
	}

	type LibraryUploads {
		path: String
		processes: [LibraryUploadsProcess]
		status: LibraryUploadStatus
		uploadedFiles: [LibraryUploadedFile]
		libraryId: String
		pathIds: String
	}

	type LibraryUploadProcessTags {
		location: [String]
	}

	type LibraryUploadsProcess {
		images: Int
		videos: Int
		processedFiles: Int
		error: Boolean
		id: Int
		tags: LibraryUploadProcessTags
	}

	type LibrarySearchFilter {
		box: String!
		type: String!
		byValue: [String]
	}

	type LibrarySearchBox {
		key: String!
		name: String!
		toggleSelected: Boolean
	}

	type LibrarySearchUI {
		selectedBoxes: [LibrarySearchBox]
		selectedFilters: [LibrarySearchFilter]
		actionedFilter: LibrarySearchFilter
		filterLoading: String
	}

	type LibraryFolderFormUI {
		libraryId: String
		open: Boolean
		currentStep: LibraryFolderLevel
		params: LibraryFolderFormParams
		parentFolder: Folder
	}

	type LibraryFolderFormParams {
		season: String
		collections: [String]
		albums: [String]
	}

	type LibraryDeleteFolderDialogUI {
		open: Boolean
		libraryId: String
		path: String
	}

	enum MediaDetailDisplay {
		ONE_BY_ONE
		BULK
	}

	enum TagPanelDisplay {
		LIST
		FORM
		SAMPLE_INFO
	}

	type LibraryMediaDetailTagPanelUI {
		display: TagPanelDisplay
		initialCategory: String
		selectedTag: String
		isDescriptionTag: Boolean
		relationshipId: String
	}

	type MergedTagRelationshipUI {
		id: String
		relations: [Tag]
		target: Tag
		isSampleLinked: Boolean
	}

	type LibraryMergedMediaDetailsUI {
		id: String
		ids: [String]
		name: String
		mediaType: String
		isSample: Boolean
		tags: [Tag]
		rootTagsLeaves: [RootTagLeaves]
		tagRelationships: [MergedTagRelationshipUI]
		library: Library
		tenant: Tenant
		libraryFolders: LibraryFolders
		createdAt: Date
		height: Int
		width: Int
		orientation: String
		sampleInfo: SampleInfo
		seasonYear: Int
		showDate: Date
		globalDescription: String
		placements: [MediaPlacement]
		unlinkedSampleMedias: [String]
		aiTags: [MediaAITag]
	}

	type LibraryMediaDetailsContext {
		id: String
		sharedMedia: LibraryMergedMediaDetailsUI
		originalTags: [String]
		currentTags: [String]
		addedTags: [String]
		deletedTags: [String]
		addedRelationships: [String]
		deletedRelationships: [String]
		editingMedia: Boolean
	}

	type LibraryMediaDetailModalUI {
		open: Boolean
		display: MediaDetailDisplay
		mediaIds: [String]
		mediaDetailsContextIds: [String]
		tagPanel: LibraryMediaDetailTagPanelUI
	}

	type LibraryMediaTaggingModal {
		open: Boolean
		mediaIds: [String]
		tagPanel: LibraryMediaDetailTagPanelUI
	}

	enum LibraryFolderLevel {
		ROOT
		SEASON
		COLLECTION
		ALBUM
	}

	type ShowroomUI {
		search: ShowroomSearch
		editor: ShowroomEditor
		userView: ShowroomUserViewUI
	}

	enum Direction {
		asc
		desc
	}

	enum ShowroomListTab {
		current
		archive
	}

	type ShowroomSearch {
		query: String
		page: Int
		field: String
		direction: Direction
		tab: ShowroomListTab
		selectedIds: [String]
	}

	type ShowroomHeroParams {
		mediaType: String
		publicId: String
		publicUrl: String
	}

	type SectionFileUI {
		mediaId: String!
		mediaType: String!
		publicId: String!
		cloudinaryVersion: String!
		width: Int
		height: Int
		originalFilename: String!
		createdAt: Date!
		isSample: Boolean
	}

	type PdfFileUI {
		mediaType: String
		publicId: String
		publicUrl: String
		originalFilename: String
		createdAt: Date
	}

	type SectionRowUI {
		medias: [SectionFileUI]
	}

	type ShowroomSectionProductLayerSettingsUI {
		galleriesFields: [String]
		samplesFields: [String]
	}

	type ShowroomSectionGlobalSelectedFiltersUI {
		galleriesFields: [String]
		samplesFields: [String]
	}

	type ShowroomSectionOptionsUI {
		spacing: Float
		fullWidth: Boolean
		displayLabel: Boolean
		zoomLayer: Boolean
		productLayer: Boolean
		productLayerSettings: ShowroomSectionProductLayerSettingsUI
		globalSelectedFilters: ShowroomSectionGlobalSelectedFiltersUI
	}

	type ShowroomSectionUI {
		id: String
		name: String
		rows: [SectionRowUI]
		rowMediaIds: [ShowroomSectionRowMediaIds]
		options: ShowroomSectionOptionsUI
		loading: Boolean
		loaded: Boolean
	}

	type ShowroomPrContactUI {
		name: String
		jobTitle: String
		email: String
		phone: String
	}

	type ShowroomParams {
		name: String
		downloadEnabled: Boolean
		downloadPdfEnabled: Boolean
		requestSamplesEnabled: Boolean
		samplesDepartmentId: String
		language: String
		library: String
		librarySeason: String
		libraryCollection: String
		heroTitle: String
		heroContent: String
		heroFontColor: String
		heroBackgroundColor: String
		heroMedia: ShowroomHeroParams
		displayHeroContent: Boolean
		displayHeroMedia: Boolean
		pdfs: [PdfFileUI]
		sections: [ShowroomSectionUI]
		prContacts: [ShowroomPrContactUI]
	}

	type ShowroomEditor {
		isOpen: Boolean
		showroomId: String
		step: Int
		currentSection: Int
		displaySectionOptions: String
		showroomParams: ShowroomParams
	}

	enum ShowroomTab {
		SAMPLES
	}

	enum ShowroomMobileMenuType {
		SECTION
		SAMPLES_BASKET
	}

	type MediaMobileMenu {
		mediaId: String
		type: ShowroomMobileMenuType
	}

	type ShowroomUserViewUI {
		selectedPdfs: [ShowroomPdf]
		selectedMedias: [Media]
		share: ShowroomUserViewShareUI
		samples: ShowroomUserViewSamplesUi
		selectedTab: ShowroomTab
		activeMediaMobileMenu: MediaMobileMenu
	}

	type ShowroomUserViewShareUI {
		isDialogOpen: Boolean
		mediaQuality: QualityOptions
	}

	type ShowroomUserViewSamplesUi {
		isRequestSamplesFormOpen: Boolean
		isRequestSamplesFormValid: Boolean
		form: ShowroomUserViewSamplesFormUi
	}

	type ShowroomUserViewSamplesFormUi {
		deliveryDate: Date
		returnDate: Date
		streetAdress: String
		city: String
		zipCode: String
		country: String
		state: String
		firstName: String
		lastName: String
		jobTitle: String
		company: String
		email: String
		prefixNumber: String
		phoneNumber: String
		occasionType: OccasionType
		runThroughDate: Date
		publication: String
		storyTheme: String
		eventName: String
		celebrity: String
		additionalComments: String
		shootDateStart: Date
		shootDateEnd: Date
		shootLocation: String
		photographer: String
		modelName: String
	}

	type SpotlightUI {
		collection: SpotlightCollectionUI
		visualContentModal: VisualContentModalUI
		spotlightDrawer: SpotlightDrawerUI
	}

	enum VisitorTab {
		visitors
		magazines
	}

	type SpotlightCollectionUI {
		filters: SpotlightCollectionFiltersUI
		currentCollectionId: String
		visitorSelectTab: VisitorTab
	}

	type SpotlightCollectionFiltersUI {
		startDate: Date
		endDate: Date
	}

	type VisualContentModalUI {
		open: Boolean
		visualContent: VisualContent
	}

	type SpotlightDrawerParamsUI {
		id: String
	}

	type SpotlightDrawerUI {
		open: Boolean
		type: VisualContentPerformanceType
		params: SpotlightDrawerParamsUI
	}
`;
