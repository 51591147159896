import { ApolloProvider } from '@apollo/client';
import { LMThemeProvider } from '@launchmetrics/lm-ui';
import find from 'lodash/find';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import client from 'Apollo';

import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import AlertProvider from 'context/alert';

import ErrorBoundary from 'components/ErrorBoundary';

import { theme } from 'Styles/theme';

import App from './App';

const Main = () => {
	const notistackRef = React.createRef<any>();

	return (
		<ErrorBoundary>
			<LMThemeProvider>
				<ThemeProvider theme={theme}>
					<SnackbarProvider
						ref={notistackRef}
						maxSnack={3}
						preventDuplicate
						action={id => {
							const snack = find(notistackRef.current.state.snacks, { key: id });
							return snack && snack.persist ? (
								<IconButton color="primary" onClick={() => notistackRef.current.closeSnackbar(id)}>
									<CloseIcon />
								</IconButton>
							) : null;
						}}
					>
						<AlertProvider>
							<ApolloProvider client={client}>
								<App />
							</ApolloProvider>
						</AlertProvider>
					</SnackbarProvider>
				</ThemeProvider>
			</LMThemeProvider>
		</ErrorBoundary>
	);
};

export default Main;
