import React from 'react';
import Lottie from 'react-lottie';

import { useStyles } from './styles';
import animationData from './animation.json';

const animationOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

const LoadingAnimation = () => {
	const classes = useStyles();
	return (
		<div className={classes.loadingContainer}>
			<Lottie options={animationOptions}
				height={250}
				width={250}
			/>
		</div>
	);
};

export default React.memo(LoadingAnimation);
