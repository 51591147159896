import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Theme = useTheme;
export const useStyles = makeStyles(theme => ({
	notificationContentBody: {
		display: 'flex',
		alignItems: 'center',
		padding: '22px 0'
	},
	notificationIcon: {
		display: 'flex',
		marginLeft: 30,
		marginRight: 30
	},
	notificationDescription: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		flexWrap: 'wrap'
	},
	notificationTitle: {
		fontSize: 18,
		fontWeight: 600
	},
	notificationText: {
		fontSize: 14
	},
	textMargin: {
		marginTop: 5
	}
}));
