import { common, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	confirmRemoveContainer: {
		position: 'fixed',
		backgroundColor: common.white,
		top: 80,
		zIndex: theme.custom.alertZIndex,
		borderRadius: 8,
		boxShadow: theme.custom.defaultBoxShadow,
		left: '50%',
		marginLeft: -270,
		maxWidth: 540,
		maxHeight: 166,
		padding: 25
	},
	icon: {
		marginRight: 30
	},
	textContainer: {
		paddingRight: 50
	},
	confirmRemoveTitle: {
		marginBottom: 15
	},
	confirmRemoveText: {
		fontSize: 14,
		marginBottom: 15,
		fontStyle: 'normal',
		width: 350
	},
	confirmRemoveButtonText: {
		fontSize: 12
	},
	confirmRemoveButtonKeep: {
		marginRight: 5,
		borderRadius: 3
	},
	confirmRemoveButtonRemove: {
		marginRight: 5,
		backgroundColor: grey[300],
		'&:hover': {
			backgroundColor: grey[400]
		}
	},
	windowContainer: {
		backgroundColor: theme.custom.transparentBlackBackroundColor,
		zIndex: theme.custom.alertZIndex
	}
}));
