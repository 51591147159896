import { gql } from '@apollo/client';

import { FEATURE_FLAGS_FRAGMENT } from '../FeatureFlags/queries';

export const SESSION = gql`
	query getUserSession {
		getSession {
			isAuthenticated
			sessionData {
				tenant
				tenantName
				tenantEcosystemId
				EcosystemAccountID
				EcosystemAccountName
				salesforceAccountId
				salesforceAccountParentId
			}
			usePortal
			tenant {
				id
				logo {
					publicId
					publicUrl
				}
				isSamplesEnabled
				isTagsEnabled
				dev
				name
				featureFlags {
					...FeatureFlagsInfo
				}
				designers
			}
			user {
				id
				uuid
				email
				firstName
				lastName
				role
			}
		}
	}
	${FEATURE_FLAGS_FRAGMENT}
`;

export const GET_FEATURE_FLAGS_FROM_SESSION = gql`
	query getFeatureFlagsFromSession {
		getSession {
			tenant {
				featureFlags {
					...FeatureFlagsInfo
				}
			}
		}
	}
	${FEATURE_FLAGS_FRAGMENT}
`;

export const GET_TAGS_ENABLED = gql`
	query getTagsEnabled {
		getSession @client {
			tenant {
				isTagsEnabled
			}
		}
	}
`;

export const GET_SESSION_TENANT = gql`
	query getSessionTenant {
		getSession @client {
			sessionData {
				tenant
			}
		}
	}
`;

export const UPDATE_TENANT = gql`
	mutation updateTenant($logo: LogoParams) {
		updateTenant(logo: $logo) {
			id
			logo {
				publicId
				publicUrl
			}
		}
	}
`;

export const UPLOAD_TENANT_LOGO = gql`
	mutation uploadTenantLogo {
		uploadTenantLogo
	}
`;

export const SAMPLES_COLLECTIONS = gql`
	query getSamplesCollections {
		tenant {
			isSamplesEnabled
			samplesCollections {
				name
				season
				designer
			}
		}
	}
`;
