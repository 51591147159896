import clsx from 'clsx';
import { Locale } from 'dayjs/locale/*';
import merge from 'lodash/merge';
import { useSnackbar, OptionsObject, SnackbarOrigin } from 'notistack';
import React from 'react';

import { Notification as NotificationType } from 'Types/Notification';

import Notification from 'components/Common/Notification/';
import { useStyles } from 'components/Common/Notification/containerStyles';

export const useTimeout = () => {
	const timeoutId = React.useRef<number>();

	const clear = React.useCallback(() => {
		if (timeoutId.current) clearTimeout(timeoutId.current);
	}, []);

	const run = React.useCallback(
		(callback: Function, delay: number) => {
			clear();
			timeoutId.current = setTimeout(callback, delay);
		},
		[clear]
	);

	return { run, clear };
};

const defaultAnchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' };
const defaultAutoHideDuration = 5000;

export const useCustomNotifications = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const notificationClasses = useStyles();

	const enqueueNotification = React.useCallback(
		(notificationProps: NotificationType, overrideOptions: OptionsObject = {}) => {
			const defaultOptions: OptionsObject = {
				className: clsx(notificationClasses.notificationContainer, {
					[notificationClasses.notificationContainerNoIcon]: !notificationProps.icon
				}),
				anchorOrigin: notificationProps.anchor || defaultAnchorOrigin,
				autoHideDuration: notificationProps.duration || defaultAutoHideDuration
			};
			const notification = <Notification notification={notificationProps}></Notification>;
			return enqueueSnackbar(notification, merge(defaultOptions, overrideOptions));
		},
		[enqueueSnackbar, notificationClasses]
	);

	return { enqueueNotification, closeSnackbar };
};

export const useDateLocale = (language: string = 'en'): Locale => {
	const locale = require('dayjs/locale/' + language + '.js');
	return locale;
};
