import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { InMemoryCache, InMemoryCacheConfig, Reference } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import isEmpty from 'lodash/isEmpty';

import fragmentData from 'generated/fragmentTypes.json';

import { getFolderLevel } from 'lib/folder';

import { librarySelectedMediasRead, librarySelectionModeEnabledRead } from './Store/Library/Results/fieldPolicies';
import {
	mergeMedias,
	readMedias,
	readSelectedFilterValues,
	readAllSelectedFilterValues,
	readSelectedBoxFilterValues
} from './Store/Library/Search/fieldPolicies';
import { folderByIdRead } from './Store/Library/fieldPolicies';
import LibraryState from './Store/Library/state';
import { mediaPathRead, cachedMediasRead, mediaDetailContextRead, mergedMediaDetailRead, cachedMediaRead } from './Store/Media/fieldPolicies';
import { readCurrentShowroomSection, readCurrentShowroomSectionMedias, readShowroomSection } from './Store/Showroom/Editor/fieldPolicies';
import { getShowroomSamplesBasketMedias } from './Store/Showroom/UserView/Samples/Basket/resolvers';
import { readShowroomFilterValues } from './Store/Showroom/fieldPolicies';
import ShowroomState from './Store/Showroom/state';
import { spotlightMagazine, spotlightVisitor } from './Store/Spotlight/Collection/Details/fieldPolicies';
import { collectionByIdRead } from './Store/Spotlight/Collection/fieldPolicies';
import SpotlightState from './Store/Spotlight/state';
import { tagByIdRead } from './Store/Tags/fieldPolicies';
import typeDefs from './schema';
import { pageOffsetPagination } from './utils';

export const possibleTypes = {};
fragmentData.__schema.types.forEach(supertype => {
	if (supertype.possibleTypes) {
		possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name);
	}
});

export const cacheProps: InMemoryCacheConfig = {
	possibleTypes,
	typePolicies: {
		Query: {
			fields: {
				library: {
					read() {
						return LibraryState();
					}
				},
				showroom: {
					read() {
						return ShowroomState();
					}
				},
				spotlight: {
					read() {
						return SpotlightState();
					}
				},
				getShowroom: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'Showroom',
							id: args.id
						});
					}
				},
				getLibrary: {
					read(_, { args, toReference }) {
						if (args.id === null) return null;
						return toReference({
							__typename: 'Library',
							id: args.id
						});
					}
				},
				searchMedias: {
					keyArgs: ['libraryId', 'folderId', 'filters', 'sort', 'order'],
					merge: mergeMedias,
					read: readMedias
				},
				showroomSamplesBasket: {
					read: getShowroomSamplesBasketMedias
				},
				librarySelectionModeEnabled: librarySelectionModeEnabledRead,
				librarySelectedMedias: librarySelectedMediasRead,
				folderById: folderByIdRead,
				librarySelectedFilterValues: readSelectedFilterValues,
				libraryBoxSelectedFilterValues: readSelectedBoxFilterValues,
				libraryAllSelectedFilterValues: readAllSelectedFilterValues,
				cachedMedia: cachedMediaRead,
				cachedMedias: cachedMediasRead,
				tagById: tagByIdRead,
				mediaDetailContext: mediaDetailContextRead,
				currentShowroomSection: readCurrentShowroomSection,
				showroomSection: readShowroomSection,
				currentShowroomSectionMedias: readCurrentShowroomSectionMedias,
				showroomFilterValues: readShowroomFilterValues,
				mergedMediaDetails: mergedMediaDetailRead,
				collectionById: collectionByIdRead,
				collectionVisualContent: pageOffsetPagination(['filters', 'sortBy', 'order', 'fullList']),
				collectionPlacements: pageOffsetPagination(['filters', 'sortBy', 'order']),
				getPendingSamplesImports: {
					merge(_, incoming) {
						return incoming;
					}
				},
				spotlightMagazine: spotlightMagazine,
				spotlightVisitor: spotlightVisitor
			}
		},
		Folder: {
			fields: {
				level: {
					read(_, { readField }) {
						return getFolderLevel(readField('path'));
					}
				},
				isSamples: {
					read(existing) {
						return existing || false;
					}
				}
			}
		},
		MediaBucket: {
			// This is done because we want to distinguish the empty buckets based on the type
			keyFields: ['type', 'id']
		},
		// Temporary fix until we run the script to fix null paths on backend side
		Image: {
			fields: {
				path: mediaPathRead,
				tags: {
					merge(_, incoming) {
						return incoming;
					}
				},
				tagRelationships: {
					merge(_, incoming) {
						return incoming;
					}
				}
			}
		},
		Video: {
			fields: {
				path: mediaPathRead,
				tags: {
					merge(_, incoming) {
						return incoming;
					}
				},
				tagRelationships: {
					merge(_, incoming) {
						return incoming;
					}
				}
			}
		},
		Pdf: {
			fields: {
				path: mediaPathRead,
				tags: {
					merge(_, incoming) {
						return incoming;
					}
				}
			}
		},
		LibraryMediaDetailsContext: {
			fields: {
				sharedMedia: {
					merge(_, incoming) {
						return incoming;
					}
				}
			}
		},
		ShowroomFilter: {
			fields: {
				values: {
					merge(_, incoming) {
						return incoming;
					}
				}
			}
		},
		ShowroomSection: {
			fields: {
				rows: {
					read(rows) {
						return rows.filter(row => !isEmpty(row.medias));
					}
				},
				rowMediaIds: {
					read(rowMediaIdsRefs, { readField }) {
						if (!rowMediaIdsRefs) return rowMediaIdsRefs;
						return rowMediaIdsRefs.filter(rowMediaIdRef => {
							const rowMediaIds: readonly Reference[] = readField('mediaIds', rowMediaIdRef);
							return !isEmpty(rowMediaIds);
						});
					}
				}
			}
		}
	}
};

const cache = new InMemoryCache(cacheProps);
export const getCache = (): InMemoryCache => cache;

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path, extensions, originalError }) => {
			if (extensions.code === 'WRONG_TENANT') {
				window.location.replace('/');
			}
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations?.toString() || ''}, Path: ${path}`);
			if (message === 'Not Authorised!')
				cache.modify({
					fields: {
						getSession(existingSession) {
							return { ...existingSession, isAuthenticated: false };
						}
					}
				});
		});

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_APOLLO_URI,
	credentials: 'include',
	headers: {
		'keep-alive': 'true'
	}
});

const link = ApolloLink.from([errorLink, httpLink]);
const client = new ApolloClient({
	link,
	cache,
	typeDefs
});

export default client;
