import { makeVar } from '@apollo/client';

import { SpotlightUi } from 'generated/graphql';

import { initialState as visualContentModalState } from './Collection/VisualContentModal/state';
import { initialState as collectionInitialState } from './Collection/state';

const initialState: SpotlightUi = {
	collection: collectionInitialState,
	visualContentModal: visualContentModalState
};

export default makeVar(initialState);
