import { LibrarySearchUi } from 'generated/graphql';

const state: LibrarySearchUi = {
	selectedFilters: [],
	selectedBoxes: [],
	actionedFilter: null,
	filterLoading: null
};

export default state;
