import React from 'react';

import LoadingAnimation from 'components/Common/LoadingAnimation';

export default function UnauthenticatedRoute({ component: C, ...rest }) {
	return (
		<React.Suspense fallback={<LoadingAnimation />}>
			<C {...rest} />
		</React.Suspense>
	);
}
