import { FieldReadFunction } from '@apollo/client';

export const folderByIdRead: FieldReadFunction = (_, { toReference, args }) => {
	const { id } = args;
	if (!id) return null;
	return toReference({
		__typename: 'Folder',
		id
	});
};
