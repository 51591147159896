import clsx from 'clsx';
import { isNull } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { usePathManager } from 'lib/pathManager';
import useIsAuthorizedToEditTag from 'lib/useIsAuthorizedToEditTag';

import { Button, ClickAwayListener, Popper, Tab, Tabs } from '@material-ui/core';

import BlackTooltip from 'components/Common/BlackTooltip';

import { useStyles } from './styles';

export type ComponentProps = {
	disableShowrooms?: boolean;
};

const LayoutTabs = (props: ComponentProps) => {
	const { disableShowrooms } = props;
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { updateUrl } = usePathManager();
	const isAuthorizedToEditTag = useIsAuthorizedToEditTag();

	const currentSection = React.useMemo(() => {
		const path = location.pathname.split('/')[1];
		return path === 'library' || path === 'tags-management' ? path : 'amplification';
	}, [location]);

	const [currentTab, setCurrentTab] = React.useState(currentSection);
	const [amplificationMenuAnchor, setAmplificationMenuAnchor] = React.useState<HTMLElement>(null);

	const openAmplificationMenu = React.useCallback(
		(ev: React.MouseEvent) => {
			ev.stopPropagation();
			if (isNull(amplificationMenuAnchor)) {
				setAmplificationMenuAnchor(ev.currentTarget as HTMLElement);
			} else {
				setAmplificationMenuAnchor(null);
			}
		},
		[amplificationMenuAnchor]
	);

	const changeTabHandler = React.useCallback((_, newValue: string) => {
		if (newValue !== 'amplification') {
			setCurrentTab(newValue);
		}
	}, []);

	const handleMenuOption = React.useCallback(
		(option: string) => {
			setAmplificationMenuAnchor(null);
			changeTabHandler(null, 'amplification');
			navigate(option);
		},
		[navigate, changeTabHandler]
	);

	React.useEffect(() => {
		setCurrentTab(currentSection);
	}, [currentSection]);

	const amplificationMenuContent = React.useMemo(() => {
		const showroomButton = (
			<Button
				className={clsx(classes.tabMenuItem, { [classes.tabMenuItemWrapped]: disableShowrooms })}
				onClick={() => handleMenuOption('/showrooms')}
				disabled={disableShowrooms}
				id={'amplificationTab_showroomButton'}
			>
				{t('amplification.amplificationTabMenu.showroomsMenuOption')}
			</Button>
		);
		return (
			<Popper
				id="amplificationMenuPopper"
				className={classes.tabMenuPopper}
				anchorEl={amplificationMenuAnchor}
				open={!!amplificationMenuAnchor}
				modifiers={{
					flip: {
						enabled: true
					},
					arrow: {
						enabled: false
					}
				}}
				placement={'bottom-start'}
			>
				<ClickAwayListener onClickAway={() => setAmplificationMenuAnchor(null)}>
					<div className={classes.tabMenuContainer}>
						{disableShowrooms ? (
							<BlackTooltip
								title={t(`amplification.blackToolTip`)}
								aria-label="create-library-message"
								arrow
								PopperProps={{ placement: 'right-end' }}
							>
								<div className={classes.tooltipWrapper}>{showroomButton}</div>
							</BlackTooltip>
						) : (
							showroomButton
						)}
						<Button
							className={classes.tabMenuItem}
							onClick={() => handleMenuOption('/spotlight')}
							id={'amplificationTab_spotlightButton'}
						>
							{t('amplification.amplificationTabMenu.spotlightMenuOption')}
						</Button>
					</div>
				</ClickAwayListener>
			</Popper>
		);
	}, [amplificationMenuAnchor, classes, handleMenuOption, t, disableShowrooms]);

	const amplificationTab = React.useMemo(
		() => (
			<Tab
				className={clsx('layout-tab', classes.tab)}
				data-tab="amplification"
				onClick={openAmplificationMenu}
				label={t('amplification.tabLabel')}
				value="amplification"
				id={'amplificationTab'}
			/>
		),
		[classes.tab, openAmplificationMenu, t]
	);

	return (
		<Tabs id="LayoutTabs" onChange={changeTabHandler} value={currentTab} classes={{ root: clsx(classes.tabs) }}>
			<Tab
				className={clsx('layout-tab', classes.tab)}
				label={t('library.tabLabel')}
				data-tab="library"
				onClick={() => updateUrl()}
				value="library"
				id={'libraryTab'}
			/>
			{amplificationTab}
			{isAuthorizedToEditTag && (
				<Tab
					className={clsx('layout-tab', classes.tab)}
					label={t('tagsManagement.tabLabel')}
					data-tab="tags-management"
					value="tags-management"
					onClick={() => {
						navigate('/tags-management');
					}}
				/>
			)}
			{amplificationMenuContent}
		</Tabs>
	);
};
export default React.memo(LayoutTabs);
