import { FieldPolicy, Reference } from '@apollo/client';

import { getCache } from 'Apollo';

const convertKeyArgs = (fieldArgs: string): Record<string, any> => {
	// Some fields have their arguments wrapped with parentheses and others don't
	const isWrapped = fieldArgs?.startsWith('(') && fieldArgs.endsWith(')');
	const toParse = isWrapped ? fieldArgs.slice(1, fieldArgs.length - 1) : fieldArgs;
	try {
		const args = JSON.parse(toParse);
		return args;
	} catch {
		return null;
	}
};

export const extractFieldNameAndArgs = (fieldName: string, storeFieldName: string) => {
	const fieldArgs = storeFieldName.replace(fieldName, '');
	return convertKeyArgs(fieldArgs);
};

export const getByTypeName = (typename: string) => {
	const cache = getCache();
	return Object.keys(cache.extract()).filter(key => key.startsWith(`${typename}:`));
};

export const pageOffsetPagination = <T = Reference>(keyArgs?: FieldPolicy['keyArgs']): FieldPolicy<T[]> => {
	return {
		keyArgs,
		merge(existing, incoming, { args }) {
			const merged = existing ? existing.slice(0) : [];
			if (incoming) {
				if (args) {
					const { page = 1, limit = 0 } = args;
					const offset = (page - 1) * limit;

					for (let i = 0; i < incoming.length; ++i) {
						merged[offset + i] = incoming[i];
					}
				} else {
					merged.push.apply(merged, incoming);
				}
			}
			return merged;
		}
	};
};
