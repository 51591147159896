import { makeStyles } from '@material-ui/core/styles';

import { borderGreyColorStronger } from 'Styles/theme';

export const useStyles = makeStyles(theme => ({
	tabs: {
		color: '#FFF'
	},
	tabsDivider: {
		backgroundColor: 'white',
		height: 15,
		alignSelf: 'center',
		margin: '0 20px'
	},
	tab: {
		textTransform: 'none',
		minWidth: '8.31rem',
		fontSize: 17
	},
	buttonTab: {
		color: 'white',
		minWidth: '8.31rem',
		fontSize: 17,
		paddingRight: 40,
		paddingLeft: 40
	},
	tabMenuPopper: {
		marginTop: 3,
		backgroundColor: 'white',
		boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.05)',
		border: `1px solid ${borderGreyColorStronger}`,
		borderRadius: '2px 2px 4px 4px'
	},
	tabMenuContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& > :first-child': {
			marginTop: 8
		},
		'& > :last-child': {
			marginBottom: 8
		}
	},
	tabMenuItem: {
		fontSize: 12,
		fontWeight: 500,
		justifyContent: 'flex-start',
		padding: '8px 24px'
	},
	tooltipWrapper: {
		display: 'flex'
	},
	tabMenuItemWrapped: {
		flex: 1
	}
}));
