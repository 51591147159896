import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Theme = useTheme;
export const useStyles = makeStyles(theme => ({
	notificationContainer: {
		backgroundColor: 'white',
		color: 'black',
		paddingLeft: 15,
		paddingRight: 45
	},
	notificationContainerNoIcon: {
		paddingLeft: 35
	}
}));
