import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import countries from 'i18n-iso-countries';
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';

import { initI18n } from 'lib/i18n';

import Main from './Main';
import './index.scss';
import * as serviceWorker from './serviceWorker';

FullStory.init({
	orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
	devMode: !['production', 'staging'].includes(process.env.REACT_APP_STAGE)
});

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_KEY,
	environment: process.env.REACT_APP_STAGE,
	integrations: [new SentryFullStory(process.env.REACT_APP_SENTRY_ORG_SLUG)]
});

initI18n(Cookies.get('phraseEnabled') === 'true');

ReactDOM.render(
	<React.StrictMode>
		<Main />
	</React.StrictMode>,
	document.getElementById('root')
);

countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
