import { gql } from '@apollo/client';

export const LibraryFiletreeField = gql`
	fragment LibraryFiletreeFragment on Library {
		filetree {
			name
			id
			path
			pathIds
			level @client
			folders {
				name
				id
				path
				pathIds
				level @client
				folders {
					name
					id
					path
					pathIds
					isSamples
					level @client
				}
			}
		}
	}
`;

export const LibraryFields = gql`
	fragment LibraryFragment on Library {
		id
		name
		industry
		files {
			url
		}
		logo {
			publicId
			publicUrl
		}
		...LibraryFiletreeFragment
	}
	${LibraryFiletreeField}
`;

export const CREATE_LIBRARY = gql`
	mutation createLibrary($industry: String!, $name: String!, $paths: [String]!) {
		createLibrary(industry: $industry, name: $name, paths: $paths) {
			...LibraryFragment
		}
	}
	${LibraryFields}
`;

export const RENAME_LIBRARY = gql`
	mutation updateLibrary($libraryId: String!, $name: String!, $logo: LogoParams) {
		updateLibrary(libraryId: $libraryId, name: $name, logo: $logo) {
			id
			name
			logo {
				publicId
				publicUrl
			}
		}
	}
`;

export const UPLOAD_LIBRARY_LOGO = gql`
	mutation uploadLibraryLogo($libraryId: String!) {
		uploadLibraryLogo(libraryId: $libraryId)
	}
`;

export const REMOVE_LIBRARY = gql`
	mutation removeLibrary($libraryId: ID!) {
		removeLibrary(libraryId: $libraryId) {
			libraryId
			sampleIds
		}
	}
`;

export const CREATE_FOLDERS = gql`
	mutation createFolders($libraryId: String!, $paths: [String]!, $isSamples: Boolean) {
		createFolders(libraryId: $libraryId, paths: $paths, isSamples: $isSamples) {
			id
			...LibraryFiletreeFragment
		}
	}
	${LibraryFiletreeField}
`;

export const FOLDER_EXISTS = gql`
	query folderExists($libraryId: String, $path: String) {
		folderExists(libraryId: $libraryId, path: $path) {
			exists
		}
	}
`;

export const REMOVE_FOLDER = gql`
	mutation removeFolder($libraryId: String!, $path: String!, $pathIds: String) {
		removeFolder(libraryId: $libraryId, path: $path, pathIds: $pathIds) {
			library {
				id
				...LibraryFiletreeFragment
			}
			sampleIds
		}
	}
	${LibraryFiletreeField}
`;

export const LIBRARIES = gql`
	query getLibraries {
		getLibraries(fields: ["id", "name", "logo"]) {
			id
			name
			logo {
				publicId
				publicUrl
			}
		}
	}
`;

export const GET_COMPLETED_LIBRARIES = gql`
	query getCompletedLibraries {
		getLibraries {
			...LibraryFragment
		}
	}
	${LibraryFields}
`;

export const GET_LIBRARY = gql`
	query getLibrary($id: String!) {
		getLibrary(id: $id) {
			...LibraryFragment
		}
	}
	${LibraryFields}
`;

export const GET_CURRENT_LIBRARY_ID = gql`
	query getCurrentLibraryId {
		library @client {
			currentLibraryId
		}
	}
`;

export const LAST_CREATED_FOLDERS = gql`
	query getLastCreatedFolders {
		library @client {
			lastCreatedFolders
		}
	}
`;

export const GET_CURRENT_FOLDER_ID = gql`
	query getCurrentFolderId {
		library @client {
			currentFolderId
		}
	}
`;

export const GET_FOLDER_DATA = gql`
	query getCurrentFolderData($id: String!) {
		folderById(id: $id) @client {
			id
			name
			path
			pathIds
			isSamples
		}
	}
`;
