import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ToggleTranslation from 'components/Common/ToggleTranslation';

import Routes from './components/Routes';

const App = () => {
	return (
		<>
			<Router>
				<Routes data-testid="appRoutes" />
			</Router>
			{process.env.REACT_APP_STAGE !== 'production' && <ToggleTranslation />}
		</>
	);
};

export default App;
