import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import { GET_COMPLETED_LIBRARIES } from 'Apollo/Store/Library/queries';
import { changeCurrentLibrary } from 'Apollo/Store/Library/resolvers';
import { UPDATE_TENANT, UPLOAD_TENANT_LOGO } from 'Apollo/Store/User/queries';
import { IsAuthenticated, useGetCurrentLibraryIdQuery, useGetLibrariesQuery, useGetShowroomsQuery } from 'generated/graphql';

import CloudinaryApi from 'lib/CloudinaryApi';
import { useCustomNotifications } from 'lib/Hooks';
import { MAX_LOGO_RESOLUTION, verifyImageResolution } from 'lib/mediaThresholds';
import { usePathManager } from 'lib/pathManager';

import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ReactComponent as UploadImageError } from 'icons/sampleRequestError.svg';

import LayoutTabs from './LayoutTabs';
import { useStyles, MOBILE_HEADER_BREAKPOINT } from './styles';

type URLParams = {
	brand: string;
	season: string;
	collection: string;
	album: string;
};

const api = new CloudinaryApi({ headers: { 'Content-Type': 'multipart/form-data' } });

export type ComponentProps = {
	children: JSX.Element;
	session: IsAuthenticated;
};

export const LIBRARY_LIMIT = 100;

const Layout = ({ children, session }: ComponentProps) => {
	const { sessionData, user, tenant } = session;
	const classes = useStyles();
	const { t } = useTranslation();
	const isMobileHeader = useMediaQuery(`(max-width:${MOBILE_HEADER_BREAKPOINT})`);

	const location = useLocation();

	const { enqueueNotification } = useCustomNotifications();
	const { changeLibrary } = usePathManager();
	const { brand } = useParams<URLParams>();

	const {
		data: {
			library: { currentLibraryId }
		}
	} = useGetCurrentLibraryIdQuery();

	// Perform both queries from layout
	const { client, data: showroomsResponse } = useGetShowroomsQuery({ variables: { archive: false } });
	const { data: librariesResponse } = useGetLibrariesQuery({
		onCompleted: data => {
			const libraries = data.getLibraries;
			if (isEmpty(libraries)) return;
			// We retrieve all the information for the tenant if it hasn't a lot of brands to avoid displaying loadings when switching brands
			if (size(libraries) <= LIBRARY_LIMIT) {
				client.query({
					query: GET_COMPLETED_LIBRARIES
				});
			}
		}
	});
	const currentSection = React.useMemo(() => {
		let path = location.pathname.split('/')[1];
		if (!path) path = 'library';
		return path === 'library' || path === 'tags-management' ? path : 'amplification';
	}, [location]);

	React.useEffect(() => {
		if (!librariesResponse) return;
		const libraries = librariesResponse.getLibraries;
		const hasLibraries = !isEmpty(libraries);
		if (hasLibraries && !currentLibraryId && !brand) {
			currentSection === 'library' ? changeLibrary(libraries[0].id) : changeCurrentLibrary(libraries[0].id);
		} else if (!hasLibraries && currentSection === 'library') changeLibrary(null);
	}, [changeLibrary, currentLibraryId, librariesResponse, brand, currentSection]);

	const libraries = librariesResponse ? librariesResponse.getLibraries : [];

	const [imgLoaded, setImageLoaded] = React.useState(false);

	const [uploadFile] = useMutation(UPLOAD_TENANT_LOGO);
	const [updateTenant] = useMutation(UPDATE_TENANT);

	const handleUpload = React.useCallback(
		async (files: FileList) => {
			if (isEmpty(files)) return;
			const file = files[0];
			const imageUrl = URL.createObjectURL(file);
			const isAcceptable = await verifyImageResolution(imageUrl, MAX_LOGO_RESOLUTION);
			if (!isAcceptable) {
				enqueueNotification({
					title: t('common.layout.uploadLogoErrorAltImage'),
					text: <span>{t('common.layout.uploadLogoTooBig')}</span>,
					icon: <UploadImageError />
				});
				return;
			}
			try {
				setImageLoaded(false);
				const fileType = file.type.replace(/\/(.*)/, '');
				const formData = new FormData();
				formData.append('file', file, 'logo');
				const {
					data: { uploadTenantLogo: signedUrl }
				} = await uploadFile();
				const res = await api.post(`${process.env.REACT_APP_CLOUDINARY_APP}/${fileType}/upload?${signedUrl}`, formData);
				const {
					data: { public_id: publicId, secure_url: publicUrl }
				} = res;
				await updateTenant({
					variables: {
						logo: {
							publicId,
							publicUrl
						}
					}
				});
			} catch (err) {
				enqueueNotification({
					title: t('common.layout.uploadLogoErrorTitle'),
					text: (
						<span>
							{t('common.layout.uploadLogoErrorText')}
							<br />
							{t('common.layout.uploadLogoErrorRetry')}
						</span>
					),
					icon: <UploadImageError />
				});
			}
			setImageLoaded(true);
		},
		[enqueueNotification, t, updateTenant, uploadFile]
	);

	const tenantLogoUrl = tenant?.logo ? tenant.logo.publicUrl : `${process.env.PUBLIC_URL}/logo.png`;

	return (
		<div className={classes.layoutRoot}>
			<AppBar
				position="static"
				classes={{
					root: classes.appBar,
					positionStatic: classes.appBarStatic
				}}
			>
				<Toolbar className={clsx(classes.header, classes.headerPadding)} disableGutters>
					<img src={`${process.env.PUBLIC_URL}/logo.png`} width="266" alt="" />
					<div
						className={clsx(classes.headerButtonsContainer, {
							[classes.headerButtonsContainerResponsive]: isMobileHeader
						})}
					>
						<div className={classes.headerUserActionsContainer}>
							{
								<div className={classes.headerUserMenu}>
									<span className={classes.tenantLogoLabel}>Default Logo:</span>
									<div id="tenantMenuLogo" className={classes.tenantLogoWrapper}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="tenantLogoUpload"
											type="file"
											placeholder="logo tenant upload"
											onChange={({ target: { files } }) => handleUpload(files)}
										/>
										<label id="selectFilesLabelBox" htmlFor="tenantLogoUpload" className={classes.tenantLogoUploadLabel}>
											<div className={classes.updateLogoText}>EDIT</div>
											{imgLoaded ? null : <CircularProgress size={26} className={classes.progress}></CircularProgress>}
											<img
												className={clsx(classes.tenantLogo, { [classes.tenantLogoFade]: !imgLoaded })}
												src={tenantLogoUrl}
												aria-label="Tenant logo"
												alt="Tenant logo"
												onLoad={() => setImageLoaded(true)}
												onError={() => setImageLoaded(true)}
											/>
										</label>
									</div>
									<span className={classes.headerUserMenuText}>{`${user.firstName} ${user.lastName}`}</span>
								</div>
							}
							<div className={classes.headerTenantMenu}>
								<span>{sessionData.tenantName}</span>
							</div>
						</div>
						<div className={classes.appBarAppSwitcherBox}>
							{/*
						// @ts-ignore */}
							<app-switcher value={sessionData.tenant} apiUrl={process.env.REACT_APP_ECO_CONNECT_URL} />
						</div>
					</div>
				</Toolbar>
				<div className={clsx(classes.tabsSectionContainer, classes.headerPadding)}>
					<LayoutTabs disableShowrooms={isEmpty(libraries) && isEmpty(showroomsResponse?.getShowrooms)} />
				</div>
			</AppBar>
			<Container fixed className={classes.container}>
				{children}
			</Container>
		</div>
	);
};

export default React.memo(Layout);
