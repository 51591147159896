import { makeStyles, useTheme } from '@material-ui/core/styles';
export const Theme = useTheme;
export const useStyles = makeStyles(() => {
	return ({
		loadingContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%'
		}
	});
});
