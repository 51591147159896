import { makeVar } from '@apollo/client/cache';
import resultsState from './Results/state';
import searchState from './Search/state';
import folderFormState from './FolderForm/state';
import deleteFolderDialogState from './DeleteFolderDialog/state';
import mediaDetailModalState from './MediaDetailModal/state';
import { LibraryUi } from 'generated/graphql';

const initialState: LibraryUi = {
	results: {
		...resultsState
	},
	search: {
		...searchState
	},
	folderForm: {
		...folderFormState
	},
	deleteFolderDialog: {
		...deleteFolderDialogState
	},
	mediaDetailModal: {
		...mediaDetailModalState
	},
	// TODO: Move this field in a better place when implementing brand levels, maybe in Library object
	lastCreatedFolders: [],
	currentLibraryId: null,
	currentFolderId: null
};
export default makeVar(initialState);
