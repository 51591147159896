import FashionImg from './images/quickstart-fashion.png';
import LuxuryImg from './images/quickstart-luxury.png';
import BeautyImg from './images/quickstart-beauty.png';

export default [
	{
		title: 'Fashion',
		subtitle: 'By season & collection',
		img: FashionImg,
		label: "season",
		filetree: {
			id: 'root',
			name: 'Fall-Winter 2020',
			label: "season",
			children: [
				{
					id: '3',
					name: 'Ready to Wear',
					label: "collection",
					children: [
						{
							id: '4',
							name: 'Lookbook'
						},
						{
							id: '5',
							name: 'Fashion week'
						},
						{
							id: '6',
							name: 'Product shots'
						}
					]
				}
			]
		}
	},
	{
		title: 'Beauty',
		subtitle: 'By collection & product type',
		img: BeautyImg,
		label: "collection",
		filetree: {
			id: 'root',
			name: 'Perfume',
			label: "collection",
			children: [
				{
					id: '3',
					name: 'Women',
					label: "gender",
					children: [
						{
							id: '4',
							name: 'Classic'
						},
						{
							id: '5',
							name: 'Exclusivities'
						},
						{
							id: '6',
							name: 'Special'
						}

					]
				}
			]
		}
	},
	{
		title: 'Luxury',
		subtitle: 'By benefit & product type',
		label: "benefit",
		img: LuxuryImg,
		filetree: {
			id: 'root',
			name: 'Men',
			label: "gender",
			children: [
				{
					id: '3',
					name: 'Haute horlogerie',
					label: "product type",
					children: [
						{
							id: '4',
							name: 'Lookbook'
						},
						{
							id: '5',
							name: 'SIHH show'
						},
						{
							id: '6',
							name: 'Product shots'
						}
					]
				}
			]
		}
	}
];
