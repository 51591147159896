import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

import library from 'Apollo/Store/Library/state';
import showroom from 'Apollo/Store/Showroom/state';
import spotlight from 'Apollo/Store/Spotlight/state';
import user from 'Apollo/Store/User/state';

export default class StateManager<T = any> {
	static PROVIDERS = {
		library,
		showroom,
		user,
		spotlight
	};

	private path: string;
	private provider: any;

	constructor(path: string) {
		const levels = path.split('.');
		this.provider = StateManager.PROVIDERS[levels[0]];
		this.path = levels.slice(1).join('.') || null;
	}

	public get(): T {
		const fullPath = this.provider();
		return this.path ? get(fullPath, this.path) : fullPath;
	}

	public update(newState: Partial<T>) {
		const updatedValues = { ...this.get(), ...newState };
		let stateCopy = {};
		if (this.path) stateCopy = set(cloneDeep(this.provider()), this.path, updatedValues);
		else stateCopy = updatedValues;
		this.provider(stateCopy);
	}
}
