import clsx from 'clsx';
import React from 'react';

import { Notification as NotificationType } from 'Types/Notification';

import { useStyles } from './styles';

export type ComponentProps = {
	notification: NotificationType;
};

const Notification = (props: ComponentProps) => {
	const { notification } = props;
	const classes = useStyles();

	return (
		<div className={clsx(classes.notificationContentBody, notification.className, 'notification-body')}>
			{notification.icon ? <div className={classes.notificationIcon}>{notification.icon}</div> : null}
			<div className={classes.notificationDescription}>
				{notification.title ? <div className={classes.notificationTitle}>{notification.title}</div> : null}
				<div className={clsx(classes.notificationText, { [classes.textMargin]: notification.title })}>{notification.text}</div>
			</div>
		</div>
	);
};

export default React.memo(Notification);
