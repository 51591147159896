import { getCache } from 'Apollo';
import StateManager from 'Apollo/StateManager';
import { LibraryFields } from 'Apollo/Store/Library/queries';
import { LibraryUi } from 'generated/graphql';

import { clearResults } from './Results/resolvers';

const cache = getCache();

export const addLibrary = newLibrary => {
	cache.modify({
		fields: {
			getLibraries(prev) {
				const libRef = cache.writeFragment({
					data: newLibrary,
					fragment: LibraryFields,
					fragmentName: 'LibraryFragment'
				});
				return [...prev, libRef];
			}
		}
	});
};

export const removeLibrary = (libraryId: string) => {
	cache.modify({
		fields: {
			getLibraries: (libraries, { readField }) => libraries.filter(library => libraryId !== readField('id', library))
		}
	});
};

export const updateLastCreatedFolders = (paths: string[]) => {
	const stateManager = new StateManager('library');
	stateManager.update({ lastCreatedFolders: paths.map(path => '/' + path) });
};

export const changeCurrentLibrary = (libraryId: String) => {
	const stateManager = new StateManager('library');
	const search = { ...stateManager.get().search };
	search.selectedFilters = [];
	search.selectedBoxes = [];
	stateManager.update({ currentLibraryId: libraryId, search });
	changeCurrentFolderId(null);
};

export const changeCurrentFolderId = (folderId: string, clear = false) => {
	const stateManager = new StateManager<LibraryUi>('library');
	const state = stateManager.get();
	const search = { ...state.search };
	search.selectedFilters = [];
	search.selectedBoxes = [];
	if (clear) clearResults(state.currentLibraryId, folderId || '');
	stateManager.update({ currentFolderId: folderId, search });
};
