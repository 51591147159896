import { Direction, ShowroomListTab } from 'generated/graphql';

type SearchState = {
	query: string;
	page: number;
	field: string;
	direction: Direction;
	tab: ShowroomListTab;
	selectedIds: string[];
};
const initialState: SearchState = {
	query: '',
	page: 1,
	field: 'name',
	direction: Direction.Asc,
	tab: ShowroomListTab.Current,
	selectedIds: []
};
export default initialState;
