import { FieldReadFunction } from '@apollo/client';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';

import StateManager from 'Apollo/StateManager';
import { ShowroomEditor } from 'generated/graphql';

const stateManager = new StateManager<ShowroomEditor>('showroom.editor');

const getCurrentSection = () => {
	const state = stateManager.get();
	if (state.currentSection === null || state.currentSection === -1) return null;
	return state.showroomParams.sections[state.currentSection];
};

export const readCurrentShowroomSection: FieldReadFunction = () => getCurrentSection();

export const readCurrentShowroomSectionMedias: FieldReadFunction = () => {
	const currentSection = getCurrentSection();
	if (!currentSection) return [];
	return uniqBy(flatten(currentSection.rows.map(row => row.medias)), 'mediaId');
};

export const readShowroomSection: FieldReadFunction = (_, { args }) => {
	const { sections } = stateManager.get().showroomParams;
	return find(sections, { id: args.id });
};
