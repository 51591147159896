import { FieldReadFunction } from '@apollo/client';

export const spotlightMagazine: FieldReadFunction = (_, { args, toReference }) => {
	return toReference({
		__typename: 'Magazine',
		id: args.id
	});
};

export const spotlightVisitor: FieldReadFunction = (_, { args, toReference }) => {
	return toReference({
		__typename: 'Visitor',
		id: args.id
	});
};
