import { ShowroomUserViewUi } from 'generated/graphql';

import samples from './Samples/state';
import share from './Share/state';

const initialState: ShowroomUserViewUi = {
	selectedMedias: [],
	selectedPdfs: [],
	share,
	samples,
	selectedTab: null,
	activeMediaMobileMenu: null
};

export default initialState;
