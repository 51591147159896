import { useGetUserSessionQuery } from 'generated/graphql';

const useIsAuthorizedToEditTag = (): boolean => {
	const {
		data: { getSession: session }
	} = useGetUserSessionQuery();
	const { user, tenant } = session;
	return user.email.endsWith('@launchmetrics.com') && tenant.name === 'Imaxtree';
};

export default useIsAuthorizedToEditTag;
