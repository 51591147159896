import { FieldReadFunction, gql } from '@apollo/client';
import { flattenDeep } from 'lodash';
import findIndex from 'lodash/findIndex';

import { getCache } from 'Apollo';
import { GetShowroomQuery, MediaInfoFragment } from 'generated/graphql';

import ShowroomBasket from 'lib/ShowroomBasket';

export const getShowroomSamplesBasketMedias: FieldReadFunction<any> = (_, { cache, args, toReference }) => {
	try {
		const showroom = cache.readFragment<Pick<GetShowroomQuery['getShowroom'], 'id' | 'name' | 'sections'> & { tenant: { uuid: string } }>({
			id: 'Showroom:' + args.showroomId,
			fragment: gql`
				fragment MyShowroomMedias on Showroom {
					id
					name
					tenant {
						uuid
					}
					sections {
						rows {
							medias {
								id
							}
						}
					}
				}
			`
		});
		const showroomBasket = new ShowroomBasket('samples', showroom.tenant.uuid, args.showroomId);
		const basket = showroomBasket.getBasket();
		if (!basket) return [];

		const allMedias: MediaInfoFragment[] = flattenDeep(showroom.sections.map(section => section.rows.map(row => row.medias)));
		const indexedMedias: Record<string, MediaInfoFragment> = allMedias.reduce((medias, media: MediaInfoFragment) => {
			medias[media.id] = media;
			return medias;
		}, {});

		const basketMedias = [];
		basket.forEach(id => {
			if (indexedMedias[id]) basketMedias.push(toReference(cache.identify(indexedMedias[id])));
		});
		return basketMedias;
	} catch (e) {
		console.error(e);
		return [];
	}
};

export const toggleSampleBasketMedia = (tenantId: string, showroomId: string, mediaId: string): boolean => {
	const cache = getCache();
	const showroomBasket = new ShowroomBasket('samples', tenantId, showroomId);
	const basket = showroomBasket.getBasket();

	const mediaIndex = findIndex(basket, id => id === mediaId);
	const newMedia = mediaIndex === -1;
	if (newMedia) basket.push(mediaId);
	else basket.splice(mediaIndex, 1);

	showroomBasket.setBasket(basket);
	cache.evict({ fieldName: 'showroomSamplesBasket', args: { showroomId } });

	return newMedia;
};

export const clearSamplesBasket = (tenantId: string, showroomId: string) => {
	const cache = getCache();
	const showroomBasket = new ShowroomBasket('samples', tenantId, showroomId);
	showroomBasket.setBasket([]);
	cache.evict({ fieldName: 'showroomSamplesBasket', args: { showroomId } });
};
