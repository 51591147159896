import React from 'react';

import { Button, Grid } from '@material-ui/core';

import { useStyles } from './styles';

const NotFoundError = () => {
	const classes = useStyles();

	const handleClick = React.useCallback(() => {
		window.location.href = '/';
	}, []);

	return (
		<Grid container direction="row" alignItems="center" className={classes.mainContainer} justify="center" alignContent="center">
			<img src="/error404.png" alt="error-404" style={{ marginRight: 60 }} />
			<Grid direction="column" item>
				<span>Error 404</span>
				<h1>The content you are looking for is not available</h1>
				<span style={{ fontSize: 14 }}>It may have been deleted. Please, go back to your homepage to find your content.</span>
				<br />
				<Button onClick={handleClick} color="primary" variant="contained" disableElevation className={classes.backHomebutton}>
					Go back to homepage
				</Button>
			</Grid>
		</Grid>
	);
};

export default React.memo(NotFoundError);
