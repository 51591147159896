import { FieldFunctionOptions, FieldReadFunction } from '@apollo/client';

import { GetShowroomSectionFilterValuesQueryVariables } from 'generated/graphql';

export const readShowroomFilterValues: FieldReadFunction = (
	_,
	{ args, toReference }: FieldFunctionOptions<GetShowroomSectionFilterValuesQueryVariables>
) => {
	const { ids } = args;
	return ids.map(id => toReference({ __typename: 'ShowroomFilterValue', id }));
};
