import { FieldReadFunction } from '@apollo/client';

import result from 'generated/graphql';

export const mediaPathRead: FieldReadFunction<string, string> = existing => existing || '';

export const cachedMediaRead: FieldReadFunction = (_, { args, toReference, canRead }) => {
	const mediaId = args.mediaId;

	const possibleTypes = result.__schema.types.find(type => type.name === 'Media').possibleTypes;

	for (const type of possibleTypes) {
		const mediaReference = toReference({ id: mediaId, __typename: type.name });
		if (canRead(mediaReference)) return mediaReference;
	}
	return null;
};

export const cachedMediasRead: FieldReadFunction = (_, { args, toReference, canRead }) => {
	const mediaIds = args.mediaIds;
	if (!mediaIds || mediaIds.length === 0) return null;

	const possibleTypes = result.__schema.types.find(type => type.name === 'Media').possibleTypes;
	return mediaIds.reduce((acc, mediaId) => {
		for (const type of possibleTypes) {
			const mediaReference = toReference({ id: mediaId, __typename: type.name });
			if (canRead(mediaReference)) acc.push(mediaReference);
		}
		return acc;
	}, []);
};

export const mediaDetailContextRead: FieldReadFunction = (_, { args, toReference }) => {
	return toReference({ id: args.id, __typename: 'LibraryMediaDetailsContext' });
};

export const mergedMediaDetailRead: FieldReadFunction = (_, { args, toReference }) =>
	args.ids ? toReference({ __typename: 'MergedMediaDetails', id: args.ids.join('_') }) : null;
