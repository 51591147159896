import { LibraryFolderFormUi, LibraryFolderLevel } from "generated/graphql";

const initialState: LibraryFolderFormUi = {
	libraryId: null,
	open: false,
	currentStep: LibraryFolderLevel.Season,
	params: {
		season: '',
		collections: [],
		albums: []
	},
	parentFolder: null
};

export default initialState;