import { makeVar } from '@apollo/client/cache';

import { ShowroomUi } from 'generated/graphql';

import EditorState from './Editor/state';
import searchState from './Search/state';
import userView from './UserView/state';

export const initialState: ShowroomUi = {
	search: {
		...searchState
	},
	editor: {
		...EditorState
	},
	userView: {
		...userView
	}
};

export default makeVar(initialState);
