import i18n from 'i18next';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import en from 'lang/en.json';
import es from 'lang/es.json';
import fr from 'lang/fr.json';
import it from 'lang/it.json';
import ja from 'lang/ja.json';
import zh from 'lang/zh.json';
import { initReactI18next } from 'react-i18next';

const resources = {
	en: { translation: en },
	es: { translation: es },
	fr: { translation: fr },
	it: { translation: it },
	zh: { translation: zh },
	ja: { translation: ja }
};

export const initI18n = (phraseEnabled = false) => {
	i18n.use(initReactI18next) // passes i18n down to react-i18next
		.use(
			new PhraseInContextEditorPostProcessor({
				phraseEnabled: process.env.REACT_APP_STAGE !== 'production' && phraseEnabled,
				projectId: '23d3b082306867450c64fa2dc923f37e',
				autoLowercase: false
			})
		)
		.init({
			resources,
			lng: 'en',
			fallbackLng: 'en',
			interpolation: {
				escapeValue: false // react already safes from xss
			},
			postProcess: ['phraseInContextEditor']
		});
};

export default i18n;
