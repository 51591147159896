import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type AllowedRelationships = {
   __typename?: 'AllowedRelationships';
  tagId?: Maybe<Scalars['String']>;
  allowedRelationshipTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AmplificationMetrics = {
   __typename?: 'AmplificationMetrics';
  totalMiv?: Maybe<Scalars['Float']>;
  totalPlacements?: Maybe<Scalars['Int']>;
  totalViews?: Maybe<Scalars['Int']>;
  totalDownloads?: Maybe<Scalars['Int']>;
};

export type Archive = {
   __typename?: 'Archive';
  id?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  pdfIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<ArchiveStatus>;
  type?: Maybe<ArchiveType>;
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  generationDate?: Maybe<Scalars['Date']>;
  removeAfter?: Maybe<Scalars['Date']>;
  expiration?: Maybe<ArchiveExpiration>;
  isDirectDownload?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  tenant?: Maybe<Tenant>;
  medias?: Maybe<Array<Maybe<Media>>>;
};

export enum ArchiveExpiration {
  FiveMinutes = 'FIVE_MINUTES',
  OneWeek = 'ONE_WEEK',
  TwoWeeks = 'TWO_WEEKS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  SixMonths = 'SIX_MONTHS',
  OneYear = 'ONE_YEAR'
}

export enum ArchiveStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Expired = 'EXPIRED'
}

export enum ArchiveType {
  Download = 'DOWNLOAD',
  Share = 'SHARE',
  Showroom = 'SHOWROOM'
}

export type AutotagResponse = {
   __typename?: 'AutotagResponse';
  message?: Maybe<Scalars['String']>;
};

export type CloudFile = {
   __typename?: 'CloudFile';
  public_id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  resource_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  bytes?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  secure_url?: Maybe<Scalars['String']>;
  original_filename?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  eager?: Maybe<Array<Maybe<Transformation>>>;
  mediaId?: Maybe<Scalars['String']>;
};

export type CloudFolder = {
   __typename?: 'CloudFolder';
  path: Scalars['String'];
};

export type Collection = {
   __typename?: 'Collection';
  id?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  firstMediaPublicId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type CollectionEntityPerformance = {
   __typename?: 'CollectionEntityPerformance';
  metrics?: Maybe<AmplificationMetrics>;
  funnel?: Maybe<FunnelDataResponse>;
};

export type CollectionListResponse = {
   __typename?: 'CollectionListResponse';
  collections?: Maybe<Array<Maybe<Collection>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum CollectionListSortOption {
  FullName = 'fullName',
  PublishedAt = 'publishedAt',
  TotalViews = 'totalViews',
  TotalDownloads = 'totalDownloads',
  TotalPlacements = 'totalPlacements',
  TotalMiv = 'totalMiv'
}

export type Contact = {
   __typename?: 'Contact';
  uuid?: Maybe<Scalars['String']>;
  contactType?: Maybe<ContactType>;
  email?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

export enum ContactType {
  L2MContact = 'L2MContact',
  Email = 'Email'
}

export type CreateMediaParams = {
  folder: Scalars['String'];
  libraryId: Scalars['String'];
  publicId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  uniqueFileName?: Maybe<Scalars['Boolean']>;
  mediaName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  showDate?: Maybe<Scalars['String']>;
};

export type CreateMediaResponse = {
   __typename?: 'CreateMediaResponse';
  media?: Maybe<Media>;
  publicId?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
};

export type CreateTagParams = {
  name: Scalars['String'];
  parent: Scalars['String'];
  associatedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedRelationships?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type DeletedFolder = {
   __typename?: 'DeletedFolder';
  library?: Maybe<Library>;
  sampleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DeletedLibrary = {
   __typename?: 'DeletedLibrary';
  libraryId: Scalars['ID'];
  sampleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DeleteMediaResult = {
   __typename?: 'DeleteMediaResult';
  status?: Maybe<Scalars['Int']>;
  deletedMedias?: Maybe<Array<Maybe<Media>>>;
};

export enum Direction {
  Asc = 'asc',
  Desc = 'desc'
}

export type DownloadFile = {
   __typename?: 'DownloadFile';
  url: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
};

export type EvolutionDataResponse = {
   __typename?: 'EvolutionDataResponse';
  values?: Maybe<Array<Maybe<EvolutionDataValue>>>;
};

export type EvolutionDataValue = {
   __typename?: 'EvolutionDataValue';
  label?: Maybe<Scalars['String']>;
  miv?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Int']>;
};

export type FeatureFlags = {
   __typename?: 'FeatureFlags';
  AITagging?: Maybe<Scalars['Boolean']>;
  tagsEnabled?: Maybe<Scalars['Boolean']>;
  productDetails?: Maybe<Scalars['Boolean']>;
  smartCategory?: Maybe<Scalars['Boolean']>;
  showDate?: Maybe<Scalars['Boolean']>;
  metabase?: Maybe<Scalars['Boolean']>;
  amplification?: Maybe<Scalars['Boolean']>;
};

export type File = {
   __typename?: 'File';
  id: Scalars['ID'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
  uuid: Scalars['String'];
  url: Scalars['String'];
};

export type Folder = {
   __typename?: 'Folder';
  folders?: Maybe<Array<Maybe<Folder>>>;
  id?: Maybe<Scalars['ID']>;
  isSamples?: Maybe<Scalars['Boolean']>;
  level?: Maybe<LibraryFolderLevel>;
  libraryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  pathIds?: Maybe<Scalars['String']>;
};

export type FolderExistsResponse = {
   __typename?: 'FolderExistsResponse';
  exists?: Maybe<Scalars['Boolean']>;
};

export type FunnelDataResponse = {
   __typename?: 'FunnelDataResponse';
  published?: Maybe<Scalars['Int']>;
  viewed?: Maybe<Scalars['Int']>;
  downloaded?: Maybe<Scalars['Int']>;
  placed?: Maybe<Scalars['Int']>;
};

export type GenerateLibraryArchiveParams = {
  libraryId: Scalars['ID'];
  mediaIds: Array<Maybe<Scalars['String']>>;
  options: GenerateZipOptions;
};

export type GenerateShowroomArchiveParams = {
  showroomId?: Maybe<Scalars['ID']>;
  tenant?: Maybe<Scalars['String']>;
  mediaIds: Array<Maybe<Scalars['String']>>;
  pdfIds: Array<Maybe<Scalars['String']>>;
  options: GenerateZipOptions;
  isDirectDownload?: Maybe<Scalars['Boolean']>;
};

export type GenerateZipOptions = {
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  protectedByPassword?: Maybe<Scalars['Boolean']>;
  quality: QualityOptions;
  removeAfter?: Maybe<Scalars['String']>;
  expiration?: Maybe<ArchiveExpiration>;
};

export enum GenerateZipType {
  Share = 'SHARE',
  Download = 'DOWNLOAD',
  Showroom = 'SHOWROOM'
}

export type GlobalSelectedFilters = {
   __typename?: 'GlobalSelectedFilters';
  galleriesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GlobalSelectedFiltersParams = {
  galleriesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HeroMediaParams = {
  publicId: Scalars['String'];
  mediaType: Scalars['String'];
  publicUrl: Scalars['String'];
};

export type Image = Media & {
   __typename?: 'Image';
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryVersion?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  extension: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  globalDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSample?: Maybe<Scalars['Boolean']>;
  library: Library;
  libraryFolders?: Maybe<LibraryFolders>;
  name: Scalars['String'];
  mediaType: MediaType;
  path?: Maybe<Scalars['String']>;
  rootTagsLeaves?: Maybe<Array<Maybe<RootTagLeaves>>>;
  sampleInfo?: Maybe<SampleInfo>;
  seasonYear?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status: MediaStatus;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tagRelationships?: Maybe<Array<Maybe<TagRelationship>>>;
  tenant: Tenant;
  updatedAt: Scalars['String'];
  showDate?: Maybe<Scalars['String']>;
  placements?: Maybe<Array<Maybe<MediaPlacement>>>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageResponse = {
   __typename?: 'ImageResponse';
  totalCount: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
  files?: Maybe<Array<CloudFile>>;
};

export enum Industries {
  Fashion = 'FASHION',
  Beauty = 'BEAUTY',
  Luxury = 'LUXURY'
}

export type IsAuthenticated = {
   __typename?: 'IsAuthenticated';
  isAuthenticated?: Maybe<Scalars['Boolean']>;
  sessionData?: Maybe<Session>;
  user?: Maybe<User>;
  tenant?: Maybe<Tenant>;
  usePortal?: Maybe<Scalars['Boolean']>;
};

export type Library = {
   __typename?: 'Library';
  id: Scalars['ID'];
  name: Scalars['String'];
  industry: Industries;
  files?: Maybe<Array<Maybe<File>>>;
  tenant?: Maybe<Tenant>;
  filetree?: Maybe<Array<Maybe<Folder>>>;
  logo?: Maybe<LogoFile>;
};

export type LibraryDeleteFolderDialogUi = {
   __typename?: 'LibraryDeleteFolderDialogUI';
  open?: Maybe<Scalars['Boolean']>;
  libraryId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type LibraryFolderFormParams = {
   __typename?: 'LibraryFolderFormParams';
  season?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Maybe<Scalars['String']>>>;
  albums?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LibraryFolderFormUi = {
   __typename?: 'LibraryFolderFormUI';
  libraryId?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  currentStep?: Maybe<LibraryFolderLevel>;
  params?: Maybe<LibraryFolderFormParams>;
  parentFolder?: Maybe<Folder>;
};

export enum LibraryFolderLevel {
  Root = 'ROOT',
  Season = 'SEASON',
  Collection = 'COLLECTION',
  Album = 'ALBUM'
}

export type LibraryFolders = {
   __typename?: 'LibraryFolders';
  season?: Maybe<Folder>;
  album?: Maybe<Folder>;
  collection?: Maybe<Folder>;
};

export type LibraryMediaDetailModalUi = {
   __typename?: 'LibraryMediaDetailModalUI';
  open?: Maybe<Scalars['Boolean']>;
  display?: Maybe<MediaDetailDisplay>;
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  mediaDetailsContextIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tagPanel?: Maybe<LibraryMediaDetailTagPanelUi>;
};

export type LibraryMediaDetailsContext = {
   __typename?: 'LibraryMediaDetailsContext';
  id?: Maybe<Scalars['String']>;
  sharedMedia?: Maybe<LibraryMergedMediaDetailsUi>;
  originalTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  addedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  addedRelationships?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedRelationships?: Maybe<Array<Maybe<Scalars['String']>>>;
  editingMedia?: Maybe<Scalars['Boolean']>;
};

export type LibraryMediaDetailTagPanelUi = {
   __typename?: 'LibraryMediaDetailTagPanelUI';
  display?: Maybe<TagPanelDisplay>;
  initialCategory?: Maybe<Scalars['String']>;
  selectedTag?: Maybe<Scalars['String']>;
  isDescriptionTag?: Maybe<Scalars['Boolean']>;
  relationshipId?: Maybe<Scalars['String']>;
};

export type LibraryMediaTaggingModal = {
   __typename?: 'LibraryMediaTaggingModal';
  open?: Maybe<Scalars['Boolean']>;
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tagPanel?: Maybe<LibraryMediaDetailTagPanelUi>;
};

export type LibraryMergedMediaDetailsUi = {
   __typename?: 'LibraryMergedMediaDetailsUI';
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  rootTagsLeaves?: Maybe<Array<Maybe<RootTagLeaves>>>;
  tagRelationships?: Maybe<Array<Maybe<MergedTagRelationshipUi>>>;
  library?: Maybe<Library>;
  tenant?: Maybe<Tenant>;
  libraryFolders?: Maybe<LibraryFolders>;
  createdAt?: Maybe<Scalars['Date']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  orientation?: Maybe<Scalars['String']>;
  sampleInfo?: Maybe<SampleInfo>;
  seasonYear?: Maybe<Scalars['Int']>;
  showDate?: Maybe<Scalars['Date']>;
  globalDescription?: Maybe<Scalars['String']>;
  placements?: Maybe<Array<Maybe<MediaPlacement>>>;
  unlinkedSampleMedias?: Maybe<Array<Maybe<Scalars['String']>>>;
  aiTags?: Maybe<Array<Maybe<MediaAiTag>>>;
};

export type LibraryResultsUi = {
   __typename?: 'LibraryResultsUI';
  selectedMedias?: Maybe<Array<Maybe<SelectedMediaUi>>>;
  isUploadDrawerOpen?: Maybe<Scalars['Boolean']>;
  isRequestSpotlightDrawerOpen?: Maybe<Scalars['Boolean']>;
  bigPreview?: Maybe<Scalars['Boolean']>;
  uploadingQueue?: Maybe<Array<Maybe<LibraryUploads>>>;
  shouldDisplayReloadButton?: Maybe<Scalars['Boolean']>;
};

export type LibrarySearchBox = {
   __typename?: 'LibrarySearchBox';
  key: Scalars['String'];
  name: Scalars['String'];
  toggleSelected?: Maybe<Scalars['Boolean']>;
};

export type LibrarySearchFilter = {
   __typename?: 'LibrarySearchFilter';
  box: Scalars['String'];
  type: Scalars['String'];
  byValue?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LibrarySearchUi = {
   __typename?: 'LibrarySearchUI';
  selectedBoxes?: Maybe<Array<Maybe<LibrarySearchBox>>>;
  selectedFilters?: Maybe<Array<Maybe<LibrarySearchFilter>>>;
  actionedFilter?: Maybe<LibrarySearchFilter>;
  filterLoading?: Maybe<Scalars['String']>;
};

export type LibraryUi = {
   __typename?: 'LibraryUI';
  currentLibraryId?: Maybe<Scalars['String']>;
  currentFolderId?: Maybe<Scalars['String']>;
  results?: Maybe<LibraryResultsUi>;
  search?: Maybe<LibrarySearchUi>;
  folderForm?: Maybe<LibraryFolderFormUi>;
  mediaDetailModal?: Maybe<LibraryMediaDetailModalUi>;
  deleteFolderDialog?: Maybe<LibraryDeleteFolderDialogUi>;
  lastCreatedFolders?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LibraryUploadedFile = {
   __typename?: 'LibraryUploadedFile';
  publicId?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
};

export type LibraryUploadProcessTags = {
   __typename?: 'LibraryUploadProcessTags';
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LibraryUploads = {
   __typename?: 'LibraryUploads';
  path?: Maybe<Scalars['String']>;
  processes?: Maybe<Array<Maybe<LibraryUploadsProcess>>>;
  status?: Maybe<LibraryUploadStatus>;
  uploadedFiles?: Maybe<Array<Maybe<LibraryUploadedFile>>>;
  libraryId?: Maybe<Scalars['String']>;
  pathIds?: Maybe<Scalars['String']>;
};

export type LibraryUploadsProcess = {
   __typename?: 'LibraryUploadsProcess';
  images?: Maybe<Scalars['Int']>;
  videos?: Maybe<Scalars['Int']>;
  processedFiles?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  tags?: Maybe<LibraryUploadProcessTags>;
};

export enum LibraryUploadStatus {
  Uploading = 'UPLOADING',
  Completed = 'COMPLETED',
  Fetching = 'FETCHING',
  Fetched = 'FETCHED'
}

export type LogoFile = {
   __typename?: 'LogoFile';
  publicId?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type LogoParams = {
  publicId: Scalars['String'];
  publicUrl: Scalars['String'];
};

export type Magazine = {
   __typename?: 'Magazine';
  id?: Maybe<Scalars['String']>;
  magazine?: Maybe<Scalars['String']>;
  voice?: Maybe<Voice>;
  country?: Maybe<Scalars['String']>;
  visitors?: Maybe<Scalars['Int']>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type MagazineByDates = {
   __typename?: 'MagazineByDates';
  id?: Maybe<Scalars['String']>;
  magazine?: Maybe<Magazine>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type MagazinesListResponse = {
   __typename?: 'MagazinesListResponse';
  magazines?: Maybe<Array<Maybe<MagazineByDates>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum MagazinesSortByField {
  Magazine = 'magazine',
  TotalViews = 'totalViews',
  TotalDownloads = 'totalDownloads',
  Country = 'country',
  Visitors = 'visitors'
}

export type MapCountryValue = {
   __typename?: 'MapCountryValue';
  countryCode?: Maybe<Scalars['String']>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type MapDataResponse = {
   __typename?: 'MapDataResponse';
  countries?: Maybe<Array<Maybe<MapCountryValue>>>;
  worldSource?: Maybe<Scalars['String']>;
};

export type Media = {
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryVersion?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  extension: Scalars['String'];
  globalDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSample?: Maybe<Scalars['Boolean']>;
  library: Library;
  libraryFolders?: Maybe<LibraryFolders>;
  mediaType: MediaType;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  rootTagsLeaves?: Maybe<Array<Maybe<RootTagLeaves>>>;
  sampleInfo?: Maybe<SampleInfo>;
  seasonYear?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status: MediaStatus;
  tagRelationships?: Maybe<Array<Maybe<TagRelationship>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tenant: Tenant;
  updatedAt: Scalars['String'];
  showDate?: Maybe<Scalars['String']>;
  placements?: Maybe<Array<Maybe<MediaPlacement>>>;
};

export type MediaAggregationBuckets = {
   __typename?: 'MediaAggregationBuckets';
  type: Scalars['String'];
  byValue?: Maybe<Array<Maybe<MediaBucket>>>;
};

export type MediaAiTag = {
   __typename?: 'MediaAITag';
  tag?: Maybe<Tag>;
  probability?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
};

export type MediaBucket = {
   __typename?: 'MediaBucket';
  id: Scalars['String'];
  key: Scalars['String'];
  count: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum MediaDetailDisplay {
  OneByOne = 'ONE_BY_ONE',
  Bulk = 'BULK'
}

export type MediaFilter = {
  key: Scalars['String'];
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MediaMobileMenu = {
   __typename?: 'MediaMobileMenu';
  mediaId?: Maybe<Scalars['String']>;
  type?: Maybe<ShowroomMobileMenuType>;
};

export type MediaParams = {
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryVersion?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  libraryId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  numberOfPages?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  seasonYear?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status?: Maybe<MediaStatus>;
  tags?: Maybe<MediaTagParams>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Scalars['Int']>;
  showDate?: Maybe<Scalars['String']>;
};

export type MediaPlacement = {
   __typename?: 'MediaPlacement';
  id?: Maybe<Scalars['String']>;
  databackendId?: Maybe<Scalars['String']>;
  articleLink?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  sourceName?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  continentName?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  languageName?: Maybe<Scalars['String']>;
  miv?: Maybe<Scalars['Float']>;
  potentialReach?: Maybe<Scalars['Float']>;
  imagesCount?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  placementType?: Maybe<Scalars['String']>;
  engagement?: Maybe<Scalars['Int']>;
  socialName?: Maybe<Scalars['String']>;
  socialAccountLink?: Maybe<Scalars['String']>;
  socialViews?: Maybe<Scalars['Int']>;
};

export type MediasAvailableGalleriesField = {
   __typename?: 'MediasAvailableGalleriesField';
  name: Scalars['String'];
};

export type MediasAvailableSamplesField = {
   __typename?: 'MediasAvailableSamplesField';
  name: Scalars['String'];
};

export type MediasCounterResponse = {
   __typename?: 'MediasCounterResponse';
  count?: Maybe<Scalars['Int']>;
};

export type MediaSearchResponse = {
   __typename?: 'MediaSearchResponse';
  totalCount: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  medias?: Maybe<Array<Maybe<Media>>>;
  searchId?: Maybe<Scalars['String']>;
};

export enum MediaSortField {
  IndexedAt = 'indexedAt',
  CreatedAt = 'createdAt',
  Name = 'name'
}

export enum MediaSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum MediaStatus {
  Uploading = 'UPLOADING',
  Ready = 'READY',
  Deleted = 'DELETED',
  UploadError = 'UPLOAD_ERROR'
}

export type MediaTagParams = {
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Pdf = 'PDF'
}

export type MergedMediaDetails = {
   __typename?: 'MergedMediaDetails';
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  library?: Maybe<Library>;
  libraryFolders?: Maybe<LibraryFolders>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tagRelationships?: Maybe<Array<Maybe<MergedTagRelationship>>>;
  rootTagsLeaves?: Maybe<Array<Maybe<RootTagLeaves>>>;
  seasonYear?: Maybe<Scalars['Int']>;
  showDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  sampleInfo?: Maybe<SampleInfo>;
  isSample?: Maybe<Scalars['Boolean']>;
  unlinkedSampleMedias?: Maybe<Array<Maybe<Scalars['String']>>>;
  placements?: Maybe<Array<Maybe<MediaPlacement>>>;
  aiTags?: Maybe<Array<Maybe<MediaAiTag>>>;
};

export type MergedTagRelationship = {
   __typename?: 'MergedTagRelationship';
  ids: Array<Maybe<Scalars['String']>>;
  target: Tag;
  relations?: Maybe<Array<Tag>>;
  isSampleLinked?: Maybe<Scalars['Boolean']>;
};

export type MergedTagRelationshipUi = {
   __typename?: 'MergedTagRelationshipUI';
  id?: Maybe<Scalars['String']>;
  relations?: Maybe<Array<Maybe<Tag>>>;
  target?: Maybe<Tag>;
  isSampleLinked?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  updateTenant: Tenant;
  uploadTenantLogo?: Maybe<Scalars['String']>;
  createLibrary: Library;
  updateLibrary?: Maybe<Library>;
  uploadLibraryLogo?: Maybe<Scalars['String']>;
  removeLibrary?: Maybe<DeletedLibrary>;
  createFolders?: Maybe<Library>;
  removeFolder?: Maybe<DeletedFolder>;
  createShowroomV2: Showroom;
  updateShowroomV2: Showroom;
  updateShowrooms: Array<Maybe<Showroom>>;
  deleteShowroom?: Maybe<Scalars['String']>;
  deleteShowrooms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadShowroomHero?: Maybe<Scalars['String']>;
  duplicateShowroom?: Maybe<Showroom>;
  singleUpload: Scalars['String'];
  createMedia: CreateMediaResponse;
  createMedias: Array<Maybe<CreateMediaResponse>>;
  updateNewMedia?: Maybe<Media>;
  updateMedia?: Maybe<Media>;
  updateMediaDetails?: Maybe<Array<Maybe<Media>>>;
  updateMediaDetailsOneByOne?: Maybe<Array<Maybe<Media>>>;
  deleteFiles?: Maybe<Scalars['String']>;
  removeMedia?: Maybe<RemoveMediaResult>;
  removeMedias?: Maybe<RemoveMediaResult>;
  setMediasAsDeleted?: Maybe<DeleteMediaResult>;
  generateArchive?: Maybe<Archive>;
  generateShowroomArchive?: Maybe<Archive>;
  autoTagMedia?: Maybe<AutotagResponse>;
  createTeam?: Maybe<Team>;
  importSamples?: Maybe<SampleImport>;
  requestSamples?: Maybe<SampleRequest>;
  refreshSamples?: Maybe<SampleImport>;
  createTag?: Maybe<Tag>;
  updateTag?: Maybe<Tag>;
  updateTagTranslation?: Maybe<Tag>;
  removeTag?: Maybe<RemoveTagResult>;
  acceptPrivacyTerms?: Maybe<UserPrivacy>;
  sendSpotlightRequest?: Maybe<Scalars['String']>;
};


export type MutationUpdateTenantArgs = {
  logo?: Maybe<LogoParams>;
};


export type MutationCreateLibraryArgs = {
  name: Scalars['String'];
  industry: Scalars['String'];
  paths?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateLibraryArgs = {
  libraryId: Scalars['String'];
  name: Scalars['String'];
  logo?: Maybe<LogoParams>;
};


export type MutationUploadLibraryLogoArgs = {
  libraryId: Scalars['String'];
};


export type MutationRemoveLibraryArgs = {
  libraryId: Scalars['ID'];
};


export type MutationCreateFoldersArgs = {
  libraryId: Scalars['String'];
  paths: Array<Maybe<Scalars['String']>>;
  isSamples?: Maybe<Scalars['Boolean']>;
};


export type MutationRemoveFolderArgs = {
  libraryId: Scalars['String'];
  path: Scalars['String'];
  pathIds?: Maybe<Scalars['String']>;
};


export type MutationCreateShowroomV2Args = {
  name: Scalars['String'];
  params?: Maybe<ShowroomUpdateParams>;
};


export type MutationUpdateShowroomV2Args = {
  _id: Scalars['ID'];
  name: Scalars['String'];
  params?: Maybe<ShowroomUpdateParams>;
  updatePassword?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateShowroomsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
  params?: Maybe<ShowroomUpdateParams>;
};


export type MutationDeleteShowroomArgs = {
  showroomId: Scalars['String'];
};


export type MutationDeleteShowroomsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type MutationDuplicateShowroomArgs = {
  showroomId: Scalars['String'];
};


export type MutationSingleUploadArgs = {
  folder: Scalars['String'];
  libraryId: Scalars['String'];
  publicId?: Maybe<Scalars['String']>;
  uniqueFileName?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateMediaArgs = {
  folder: Scalars['String'];
  libraryId: Scalars['String'];
  publicId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  uniqueFileName?: Maybe<Scalars['Boolean']>;
  mediaName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  showDate?: Maybe<Scalars['String']>;
};


export type MutationCreateMediasArgs = {
  createMediaParams: Array<Maybe<CreateMediaParams>>;
};


export type MutationUpdateNewMediaArgs = {
  mediaParams?: Maybe<MediaParams>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMediaArgs = {
  mediaParams?: Maybe<MediaParams>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMediaDetailsArgs = {
  mediaDetails?: Maybe<UpdateMediaTagsParams>;
};


export type MutationUpdateMediaDetailsOneByOneArgs = {
  mediaDetails?: Maybe<Array<Maybe<UpdateMediaTagsParams>>>;
};


export type MutationDeleteFilesArgs = {
  images: Array<Maybe<Scalars['String']>>;
  videos: Array<Maybe<Scalars['String']>>;
};


export type MutationRemoveMediaArgs = {
  id: Scalars['String'];
};


export type MutationRemoveMediasArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type MutationSetMediasAsDeletedArgs = {
  imageIds: Array<Maybe<Scalars['String']>>;
  videoIds: Array<Maybe<Scalars['String']>>;
};


export type MutationGenerateArchiveArgs = {
  type: GenerateZipType;
  params: GenerateLibraryArchiveParams;
};


export type MutationGenerateShowroomArchiveArgs = {
  params: GenerateShowroomArchiveParams;
};


export type MutationAutoTagMediaArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
  overwrite?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateTeamArgs = {
  tenantId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationImportSamplesArgs = {
  samplesDesigner?: Maybe<Scalars['String']>;
  samplesSeason?: Maybe<Scalars['String']>;
  samplesCollection?: Maybe<Scalars['String']>;
  galleriesBrand: Scalars['String'];
  galleriesSeason: Scalars['String'];
  galleriesCollection: Scalars['String'];
  galleriesAlbum: Scalars['String'];
};


export type MutationRequestSamplesArgs = {
  params?: Maybe<RequestSamplesParams>;
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  showroomId?: Maybe<Scalars['String']>;
  showroomViewerId?: Maybe<Scalars['String']>;
};


export type MutationRefreshSamplesArgs = {
  galleriesAlbum: Scalars['String'];
};


export type MutationCreateTagArgs = {
  params?: Maybe<CreateTagParams>;
};


export type MutationUpdateTagArgs = {
  id: Scalars['String'];
  params: UpdateTagParams;
};


export type MutationUpdateTagTranslationArgs = {
  id: Scalars['String'];
  params: UpdateTagParams;
};


export type MutationRemoveTagArgs = {
  removeTagParams?: Maybe<RemoveTagParams>;
};


export type MutationAcceptPrivacyTermsArgs = {
  privacy?: Maybe<PrivacyAcceptance>;
};

export enum OccasionType {
  Photo = 'PHOTO',
  Celebrity = 'CELEBRITY',
  Other = 'OTHER',
  None = 'NONE'
}

export enum OrderOption {
  Asc = 'asc',
  Desc = 'desc'
}

export type PaginationOptions = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
};

export type Pdf = Media & {
   __typename?: 'Pdf';
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryVersion?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  extension: Scalars['String'];
  globalDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSample?: Maybe<Scalars['Boolean']>;
  library: Library;
  libraryFolders?: Maybe<LibraryFolders>;
  name: Scalars['String'];
  mediaType: MediaType;
  numberOfPages?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  rootTagsLeaves?: Maybe<Array<Maybe<RootTagLeaves>>>;
  sampleInfo?: Maybe<SampleInfo>;
  seasonYear?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status: MediaStatus;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tagRelationships?: Maybe<Array<Maybe<TagRelationship>>>;
  tenant: Tenant;
  showDate?: Maybe<Scalars['String']>;
  placements?: Maybe<Array<Maybe<MediaPlacement>>>;
  updatedAt: Scalars['String'];
};

export type PdfFileUi = {
   __typename?: 'PdfFileUI';
  mediaType?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type PdfParams = {
  mediaType?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Placement = {
   __typename?: 'Placement';
  databackendId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  articleLink?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  imageLink?: Maybe<Scalars['String']>;
  miv?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  numberOfMedias?: Maybe<Scalars['Int']>;
  voiceId?: Maybe<Scalars['String']>;
  placementType?: Maybe<Scalars['String']>;
  engagement?: Maybe<Scalars['Int']>;
  socialName?: Maybe<Scalars['String']>;
  socialAccountLink?: Maybe<Scalars['String']>;
  socialViews?: Maybe<Scalars['Int']>;
};

export enum PlacementSortingValue {
  Miv = 'miv',
  Reach = 'reach'
}

export type PrivacyAcceptance = {
  email?: Maybe<Scalars['String']>;
};

export type ProductLayerSettings = {
   __typename?: 'ProductLayerSettings';
  galleriesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum QualityOptions {
  Screen = 'SCREEN',
  Original = 'ORIGINAL'
}

export type Query = {
   __typename?: 'Query';
  aggregateMedias?: Maybe<Array<Maybe<MediaAggregationBuckets>>>;
  allowedRelationships?: Maybe<Array<Maybe<AllowedRelationships>>>;
  archiveFiles?: Maybe<Array<Maybe<DownloadFile>>>;
  cachedMedia?: Maybe<Media>;
  cachedMedias?: Maybe<Array<Maybe<Media>>>;
  categoryFilterTags?: Maybe<Array<Maybe<Tag>>>;
  categoryTagTree?: Maybe<Array<Maybe<TagTree>>>;
  collecfunnelData?: Maybe<FunnelDataResponse>;
  collectionById?: Maybe<Collection>;
  collectionEntityPerformance?: Maybe<CollectionEntityPerformance>;
  collectionEntityVisualContent?: Maybe<Array<Maybe<VisualContentByDates>>>;
  collectionEvolutionData?: Maybe<EvolutionDataResponse>;
  collectionFunnelData?: Maybe<FunnelDataResponse>;
  collectionInfo?: Maybe<Collection>;
  collectionList?: Maybe<CollectionListResponse>;
  collectionMagazinesList?: Maybe<MagazinesListResponse>;
  collectionMapData?: Maybe<MapDataResponse>;
  collectionPlacements?: Maybe<Array<Maybe<Placement>>>;
  collectionPlacementsCount?: Maybe<Scalars['Int']>;
  collectionSummaryCounters?: Maybe<AmplificationMetrics>;
  collectionTotalMedias?: Maybe<Scalars['Int']>;
  collectionVisitorsList?: Maybe<VisitorsListResponse>;
  collectionVisualContent?: Maybe<Array<Maybe<VisualContent>>>;
  collectionVoicePerformance?: Maybe<CollectionEntityPerformance>;
  collectionVoicePlacements?: Maybe<Array<Maybe<Placement>>>;
  collectionVoiceVisualContent?: Maybe<Array<Maybe<VisualContentByDates>>>;
  currentShowroomSection?: Maybe<ShowroomSectionUi>;
  currentShowroomSectionMedias?: Maybe<Array<Maybe<SectionFileUi>>>;
  featureFlags?: Maybe<FeatureFlags>;
  folderById?: Maybe<Folder>;
  folderExists?: Maybe<FolderExistsResponse>;
  getArchive?: Maybe<Archive>;
  getContacts?: Maybe<Array<Maybe<Contact>>>;
  getLibraries?: Maybe<Array<Maybe<Library>>>;
  getLibrary?: Maybe<Library>;
  getLocations?: Maybe<Array<Maybe<Tag>>>;
  getPendingSamplesImports?: Maybe<Array<Maybe<SampleImport>>>;
  getSamplesImport?: Maybe<SampleImport>;
  getSession?: Maybe<IsAuthenticated>;
  getShowroom?: Maybe<Showroom>;
  getShowrooms?: Maybe<Array<Maybe<Showroom>>>;
  getTenantTeams?: Maybe<Array<Maybe<Team>>>;
  library?: Maybe<LibraryUi>;
  libraryAllSelectedFilterValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  libraryBoxSelectedFilterValues?: Maybe<Array<Maybe<MediaBucket>>>;
  librarySelectedFilterValues?: Maybe<Array<Maybe<MediaBucket>>>;
  librarySelectedMedias?: Maybe<Array<Maybe<Media>>>;
  librarySelectionModeEnabled?: Maybe<Scalars['Boolean']>;
  mediaDetailContext?: Maybe<LibraryMediaDetailsContext>;
  mediaDetails?: Maybe<Array<Maybe<CloudFile>>>;
  mediaPlacementsByDate?: Maybe<Array<Maybe<MediaPlacement>>>;
  mediasAvailableGalleriesFields?: Maybe<Array<Maybe<MediasAvailableGalleriesField>>>;
  mediasAvailableSamplesFields?: Maybe<Array<Maybe<MediasAvailableSamplesField>>>;
  mediasByIds?: Maybe<Array<Maybe<Media>>>;
  mediasWithTagAttachedCounter?: Maybe<MediasCounterResponse>;
  mergedMediaDetails?: Maybe<MergedMediaDetails>;
  mergedMediaDetailsOneByOne?: Maybe<Array<Maybe<MergedMediaDetails>>>;
  publicShowroomFields?: Maybe<ShowroomPublicFields>;
  rootTagTrees?: Maybe<Array<Maybe<TagTree>>>;
  rootTags?: Maybe<Array<Maybe<Tag>>>;
  samplesDepartments?: Maybe<Array<Maybe<SamplesDepartment>>>;
  searchFiles: SearchFilesResponse;
  searchMedias: MediaSearchResponse;
  searchTags?: Maybe<TagSearchResponse>;
  showroom?: Maybe<ShowroomUi>;
  showroomAnalyticsGuestData?: Maybe<ShowroomAnalyticsGuestDataResponse>;
  showroomAnalyticsGuests?: Maybe<ShowroomAnalyticsGuestsResponse>;
  showroomAvailableFilters?: Maybe<Array<Maybe<ShowroomAvailableFilter>>>;
  showroomFilterValues?: Maybe<Array<Maybe<ShowroomFilterValue>>>;
  showroomGlobalFilters?: Maybe<ShowroomGlobalFilters>;
  showroomMedia?: Maybe<Media>;
  showroomSamplesBasket?: Maybe<Array<Maybe<Media>>>;
  showroomSection?: Maybe<ShowroomSectionUi>;
  showroomSectionFilters?: Maybe<Array<Maybe<ShowroomFilter>>>;
  spotlight?: Maybe<SpotlightUi>;
  spotlightMagazine?: Maybe<Magazine>;
  spotlightUrl?: Maybe<Scalars['String']>;
  spotlightVisitor?: Maybe<Visitor>;
  tagById?: Maybe<Tag>;
  tagByName?: Maybe<Tag>;
  tagList?: Maybe<Array<Maybe<Tag>>>;
  tagsByType?: Maybe<Array<Maybe<Tag>>>;
  tagsByTypes?: Maybe<Array<Maybe<TagByType>>>;
  tenant?: Maybe<Tenant>;
};


export type QueryAggregateMediasArgs = {
  libraryId: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
  tags: Array<Maybe<Scalars['String']>>;
  filters?: Maybe<Array<Maybe<MediaFilter>>>;
};


export type QueryArchiveFilesArgs = {
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryCachedMediaArgs = {
  mediaId?: Maybe<Scalars['String']>;
};


export type QueryCachedMediasArgs = {
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCollecfunnelDataArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type QueryCollectionByIdArgs = {
  id: Scalars['String'];
};


export type QueryCollectionEntityPerformanceArgs = {
  collectionId: Scalars['String'];
  params: VisualContentPerformanceParameters;
};


export type QueryCollectionEntityVisualContentArgs = {
  collectionId: Scalars['String'];
  params: VisualContentPerformanceParameters;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCollectionEvolutionDataArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type QueryCollectionFunnelDataArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type QueryCollectionInfoArgs = {
  brand?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
};


export type QueryCollectionListArgs = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy?: Maybe<CollectionListSortOption>;
  order?: Maybe<OrderOption>;
};


export type QueryCollectionMagazinesListArgs = {
  tableFilters?: Maybe<SpotlightCollectionMagazinesTableFilters>;
};


export type QueryCollectionMapDataArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type QueryCollectionPlacementsArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  fullList?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<PlacementSortingValue>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderOption>;
};


export type QueryCollectionPlacementsCountArgs = {
  collectionId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<TimeRange>;
};


export type QueryCollectionSummaryCountersArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type QueryCollectionTotalMediasArgs = {
  collectionId: Scalars['String'];
};


export type QueryCollectionVisitorsListArgs = {
  tableFilters?: Maybe<SpotlightCollectionVisitorsTableFilters>;
};


export type QueryCollectionVisualContentArgs = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  fullList?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<VisualContentSortingValue>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderOption>;
};


export type QueryCollectionVoicePerformanceArgs = {
  collectionId: Scalars['String'];
  voiceId: Scalars['String'];
  dates: TimeRange;
};


export type QueryCollectionVoicePlacementsArgs = {
  collectionId: Scalars['String'];
  voiceId: Scalars['String'];
  dates: TimeRange;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCollectionVoiceVisualContentArgs = {
  collectionId: Scalars['String'];
  voiceId: Scalars['String'];
  dates: TimeRange;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFolderByIdArgs = {
  id: Scalars['String'];
};


export type QueryFolderExistsArgs = {
  libraryId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};


export type QueryGetArchiveArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetContactsArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetLibrariesArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetLibraryArgs = {
  id: Scalars['String'];
};


export type QueryGetSamplesImportArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetShowroomArgs = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryGetShowroomsArgs = {
  archive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTenantTeamsArgs = {
  id: Scalars['String'];
};


export type QueryLibraryBoxSelectedFilterValuesArgs = {
  filterBox: Scalars['String'];
};


export type QueryLibrarySelectedFilterValuesArgs = {
  filterType: Scalars['String'];
};


export type QueryMediaDetailContextArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryMediaDetailsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryMediaPlacementsByDateArgs = {
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryMediasAvailableGalleriesFieldsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryMediasAvailableSamplesFieldsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryMediasByIdsArgs = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type QueryMediasWithTagAttachedCounterArgs = {
  tagId?: Maybe<Scalars['String']>;
  isDescriptionTag?: Maybe<Scalars['Boolean']>;
};


export type QueryMergedMediaDetailsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryMergedMediaDetailsOneByOneArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryPublicShowroomFieldsArgs = {
  id: Scalars['String'];
  tenant: Scalars['String'];
};


export type QueryRootTagTreesArgs = {
  types: Array<Maybe<Scalars['String']>>;
};


export type QuerySearchFilesArgs = {
  libraryId: Scalars['String'];
  nextCursor?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  maxResults?: Maybe<Scalars['Int']>;
};


export type QuerySearchMediasArgs = {
  libraryId: Scalars['String'];
  folderId: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<Maybe<MediaFilter>>>;
  sort?: Maybe<MediaSortField>;
  order?: Maybe<MediaSortOrder>;
};


export type QuerySearchTagsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryShowroomAnalyticsGuestDataArgs = {
  showroomId: Scalars['String'];
  guestId: Scalars['String'];
};


export type QueryShowroomAnalyticsGuestsArgs = {
  showroomId: Scalars['String'];
};


export type QueryShowroomAvailableFiltersArgs = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type QueryShowroomFilterValuesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryShowroomGlobalFiltersArgs = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type QueryShowroomMediaArgs = {
  params: ShowroomGetParams;
  mediaId: Scalars['String'];
};


export type QueryShowroomSamplesBasketArgs = {
  showroomId: Scalars['String'];
};


export type QueryShowroomSectionArgs = {
  id: Scalars['String'];
};


export type QueryShowroomSectionFiltersArgs = {
  params: ShowroomGetParams;
  section: Scalars['Int'];
};


export type QuerySpotlightMagazineArgs = {
  id: Scalars['String'];
};


export type QuerySpotlightUrlArgs = {
  collectionParams?: Maybe<SpotlightSignParameters>;
};


export type QuerySpotlightVisitorArgs = {
  id: Scalars['String'];
};


export type QueryTagByIdArgs = {
  id: Scalars['String'];
};


export type QueryTagByNameArgs = {
  name: Scalars['String'];
};


export type QueryTagsByTypeArgs = {
  type: Scalars['String'];
};


export type QueryTagsByTypesArgs = {
  types: Array<Scalars['String']>;
};

export type RemoveMediaResult = {
   __typename?: 'RemoveMediaResult';
  status?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Int']>;
};

export type RemoveTagParams = {
  tagId?: Maybe<Scalars['String']>;
  isDescriptionTag?: Maybe<Scalars['Boolean']>;
};

export type RemoveTagResult = {
   __typename?: 'RemoveTagResult';
  id?: Maybe<Scalars['String']>;
};

export type RequestSamplesParams = {
  deliveryDate?: Maybe<Scalars['Date']>;
  returnDate?: Maybe<Scalars['Date']>;
  streetAdress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  prefixNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  occasionType?: Maybe<OccasionType>;
  runThroughDate?: Maybe<Scalars['Date']>;
  publication?: Maybe<Scalars['String']>;
  storyTheme?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  celebrity?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  additionalComments?: Maybe<Scalars['String']>;
  shootDateStart?: Maybe<Scalars['Date']>;
  shootDateEnd?: Maybe<Scalars['Date']>;
  shootLocation?: Maybe<Scalars['String']>;
  photographer?: Maybe<Scalars['String']>;
};

export type RootTagLeaves = {
   __typename?: 'RootTagLeaves';
  tag?: Maybe<Tag>;
  leaves?: Maybe<Array<Maybe<Tag>>>;
};

export type SampleImage = {
   __typename?: 'SampleImage';
  name?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SampleImport = {
   __typename?: 'SampleImport';
  id: Scalars['ID'];
  tenant: Tenant;
  samplesSeason?: Maybe<Scalars['String']>;
  samplesCollection?: Maybe<Scalars['String']>;
  samplesDesigner?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  galleriesBrand: Scalars['String'];
  galleriesSeason: Scalars['String'];
  galleriesCollection: Scalars['String'];
  galleriesAlbum: Scalars['String'];
  status: SampleImportStatus;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum SampleImportStatus {
  Pending = 'PENDING',
  Searching = 'SEARCHING',
  Dedup = 'DEDUP',
  Uploading = 'UPLOADING',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type SampleInfo = {
   __typename?: 'SampleInfo';
  sampleId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  styleNumber?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
  designer?: Maybe<Scalars['String']>;
  image?: Maybe<SampleImage>;
  sampleType?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  fabric?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  sizeRange?: Maybe<Scalars['String']>;
  retailPrices?: Maybe<Array<Maybe<SamplePrice>>>;
  salePrices?: Maybe<Array<Maybe<SamplePrice>>>;
  region?: Maybe<Scalars['String']>;
  looks?: Maybe<Array<Maybe<Scalars['String']>>>;
  misc?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  vendorDate?: Maybe<Scalars['String']>;
  vendorCode?: Maybe<Scalars['String']>;
  sampleMadeIn?: Maybe<Scalars['String']>;
  customsPrices?: Maybe<Array<Maybe<SamplePrice>>>;
  extraPrices?: Maybe<Array<Maybe<SamplePrice>>>;
};

export type SamplePrice = {
   __typename?: 'SamplePrice';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SampleRequest = {
   __typename?: 'SampleRequest';
  id: Scalars['ID'];
  tenant: Tenant;
  showroom: Showroom;
  department: Scalars['String'];
  deliveryDate: Scalars['Date'];
  returnDate: Scalars['Date'];
  streetAdress: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  occasionType?: Maybe<OccasionType>;
  runThroughDate?: Maybe<Scalars['Date']>;
  publication?: Maybe<Scalars['String']>;
  storyTheme?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  celebrity?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  additionalComments?: Maybe<Scalars['String']>;
  medias?: Maybe<Array<Maybe<Media>>>;
  showroomViewerId?: Maybe<Scalars['String']>;
  requestNumber?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  status?: Maybe<SampleRequestStatus>;
  shootDateStart?: Maybe<Scalars['Date']>;
  shootDateEnd?: Maybe<Scalars['Date']>;
  shootLocation?: Maybe<Scalars['String']>;
  photographer?: Maybe<Scalars['String']>;
};

export type SampleRequestInfo = {
  sampleId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  styleNumber?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
  designer?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export enum SampleRequestStatus {
  Pending = 'PENDING',
  Done = 'DONE',
  Error = 'ERROR'
}

export type SamplesCollection = {
   __typename?: 'SamplesCollection';
  department?: Maybe<Scalars['String']>;
  designer?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SamplesDepartment = {
   __typename?: 'SamplesDepartment';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SearchFilesResponse = {
   __typename?: 'SearchFilesResponse';
  images?: Maybe<ImageResponse>;
  videos?: Maybe<VideoResponse>;
};

export type SectionFile = {
  mediaType?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type SectionFileUi = {
   __typename?: 'SectionFileUI';
  mediaId: Scalars['String'];
  mediaType: Scalars['String'];
  publicId: Scalars['String'];
  cloudinaryVersion: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  originalFilename: Scalars['String'];
  createdAt: Scalars['Date'];
  isSample?: Maybe<Scalars['Boolean']>;
};

export type SectionOptions = {
  spacing?: Maybe<Scalars['Float']>;
  fullWidth?: Maybe<Scalars['Boolean']>;
  displayLabel?: Maybe<Scalars['Boolean']>;
  zoomLayer?: Maybe<Scalars['Boolean']>;
  productLayer?: Maybe<Scalars['Boolean']>;
  productLayerSettings?: Maybe<ShowroomSectionProductLayerSettingsParams>;
  selectedFilters?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalSelectedFilters?: Maybe<GlobalSelectedFiltersParams>;
};

export type SectionParams = {
  name: Scalars['String'];
  rows?: Maybe<Array<Maybe<SectionRow>>>;
  options?: Maybe<SectionOptions>;
};

export type SectionRow = {
  files?: Maybe<Array<Maybe<SectionFile>>>;
  medias?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SectionRowUi = {
   __typename?: 'SectionRowUI';
  medias?: Maybe<Array<Maybe<SectionFileUi>>>;
};

export type SelectedMediaUi = {
   __typename?: 'SelectedMediaUI';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
};

export type Session = {
   __typename?: 'Session';
  id?: Maybe<Scalars['String']>;
  nameIDFormat?: Maybe<Scalars['String']>;
  nameID?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  tenantEcosystemId?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  EcosystemAccountID?: Maybe<Scalars['String']>;
  EcosystemAccountName?: Maybe<Scalars['String']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceAccountParentId?: Maybe<Scalars['String']>;
};

export type Showroom = {
   __typename?: 'Showroom';
  id: Scalars['ID'];
  name: Scalars['String'];
  downloadEnabled: Scalars['Boolean'];
  downloadPdfEnabled?: Maybe<Scalars['Boolean']>;
  requestSamplesEnabled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  tenant: Tenant;
  library?: Maybe<Library>;
  samplesDepartmentId?: Maybe<Scalars['String']>;
  industry?: Maybe<Industries>;
  librarySeason?: Maybe<Scalars['String']>;
  libraryCollection?: Maybe<Scalars['String']>;
  displayHeroMedia?: Maybe<Scalars['Boolean']>;
  displayHeroContent?: Maybe<Scalars['Boolean']>;
  heroMedia?: Maybe<ShowroomHeroFile>;
  heroTitle?: Maybe<Scalars['String']>;
  heroContent?: Maybe<Scalars['String']>;
  heroFontColor?: Maybe<Scalars['String']>;
  heroBackgroundColor?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  pdfs?: Maybe<Array<Maybe<ShowroomPdf>>>;
  sections?: Maybe<Array<Maybe<ShowroomSection>>>;
  prContacts?: Maybe<Array<Maybe<ShowroomPrContact>>>;
  hasSamples?: Maybe<Scalars['Boolean']>;
  archive?: Maybe<Scalars['Boolean']>;
};

export type ShowroomAnalyticsGuest = {
   __typename?: 'ShowroomAnalyticsGuest';
  email?: Maybe<Scalars['String']>;
  viewerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  visits?: Maybe<Scalars['Int']>;
  downloads?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
};

export type ShowroomAnalyticsGuestDataResponse = {
   __typename?: 'ShowroomAnalyticsGuestDataResponse';
  viewerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  downloads?: Maybe<Scalars['Int']>;
  visits?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  showroomDownloadEnabled?: Maybe<Scalars['Boolean']>;
  showroomViewDetailsEnabled?: Maybe<Scalars['Boolean']>;
  downloadedMedias?: Maybe<ShowroomAnalyticsGuestDownloadedMediaStats>;
  viewedMedias?: Maybe<ShowroomAnalyticsGuestViewedMediaStats>;
};

export type ShowroomAnalyticsGuestDownloadedMediaStats = ShowroomAnalyticsGuestMediaStats & {
   __typename?: 'ShowroomAnalyticsGuestDownloadedMediaStats';
  pdfs?: Maybe<Array<Maybe<ShowroomAnalyticsGuestDownloadedMediaStatsMedia>>>;
  images?: Maybe<Array<Maybe<ShowroomAnalyticsGuestDownloadedMediaStatsMedia>>>;
  videos?: Maybe<Array<Maybe<ShowroomAnalyticsGuestDownloadedMediaStatsMedia>>>;
};

export type ShowroomAnalyticsGuestDownloadedMediaStatsMedia = ShowroomAnalyticsGuestMediaStatsMedia & {
   __typename?: 'ShowroomAnalyticsGuestDownloadedMediaStatsMedia';
  mediaId?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
};

export type ShowroomAnalyticsGuestMediaStats = {
  pdfs?: Maybe<Array<Maybe<ShowroomAnalyticsGuestMediaStatsMedia>>>;
  images?: Maybe<Array<Maybe<ShowroomAnalyticsGuestMediaStatsMedia>>>;
  videos?: Maybe<Array<Maybe<ShowroomAnalyticsGuestMediaStatsMedia>>>;
};

export type ShowroomAnalyticsGuestMediaStatsMedia = {
  mediaId?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
};

export type ShowroomAnalyticsGuestsResponse = {
   __typename?: 'ShowroomAnalyticsGuestsResponse';
  guests?: Maybe<Array<Maybe<ShowroomAnalyticsGuest>>>;
};

export type ShowroomAnalyticsGuestViewedMediaStats = ShowroomAnalyticsGuestMediaStats & {
   __typename?: 'ShowroomAnalyticsGuestViewedMediaStats';
  pdfs?: Maybe<Array<Maybe<ShowroomAnalyticsGuestViewedMediaStatsMedia>>>;
  images?: Maybe<Array<Maybe<ShowroomAnalyticsGuestViewedMediaStatsMedia>>>;
  videos?: Maybe<Array<Maybe<ShowroomAnalyticsGuestViewedMediaStatsMedia>>>;
};

export type ShowroomAnalyticsGuestViewedMediaStatsMedia = ShowroomAnalyticsGuestMediaStatsMedia & {
   __typename?: 'ShowroomAnalyticsGuestViewedMediaStatsMedia';
  mediaId?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
};

export type ShowroomAvailableFilter = {
   __typename?: 'ShowroomAvailableFilter';
  name?: Maybe<Scalars['String']>;
};

export type ShowroomEditor = {
   __typename?: 'ShowroomEditor';
  isOpen?: Maybe<Scalars['Boolean']>;
  showroomId?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Int']>;
  currentSection?: Maybe<Scalars['Int']>;
  displaySectionOptions?: Maybe<Scalars['String']>;
  showroomParams?: Maybe<ShowroomParams>;
};

export type ShowroomFile = {
  mediaType: Scalars['String'];
  publicId: Scalars['String'];
  publicUrl: Scalars['String'];
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ShowroomFilter = {
   __typename?: 'ShowroomFilter';
  id?: Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  values?: Maybe<Array<Maybe<ShowroomFilterValue>>>;
};

export type ShowroomFilterValue = {
   __typename?: 'ShowroomFilterValue';
  id?: Maybe<Scalars['ID']>;
  groupId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomGetParams = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type ShowroomGlobalFilters = {
   __typename?: 'ShowroomGlobalFilters';
  galleriesFilters?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFilters?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomHeroFile = {
   __typename?: 'ShowroomHeroFile';
  mediaType?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type ShowroomHeroParams = {
   __typename?: 'ShowroomHeroParams';
  mediaType?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type ShowroomImage = ShowroomFile & {
   __typename?: 'ShowroomImage';
  mediaType: Scalars['String'];
  publicId: Scalars['String'];
  publicUrl: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export enum ShowroomListTab {
  Current = 'current',
  Archive = 'archive'
}

export enum ShowroomMobileMenuType {
  Section = 'SECTION',
  SamplesBasket = 'SAMPLES_BASKET'
}

export type ShowroomParams = {
   __typename?: 'ShowroomParams';
  name?: Maybe<Scalars['String']>;
  downloadEnabled?: Maybe<Scalars['Boolean']>;
  downloadPdfEnabled?: Maybe<Scalars['Boolean']>;
  requestSamplesEnabled?: Maybe<Scalars['Boolean']>;
  samplesDepartmentId?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  library?: Maybe<Scalars['String']>;
  librarySeason?: Maybe<Scalars['String']>;
  libraryCollection?: Maybe<Scalars['String']>;
  heroTitle?: Maybe<Scalars['String']>;
  heroContent?: Maybe<Scalars['String']>;
  heroFontColor?: Maybe<Scalars['String']>;
  heroBackgroundColor?: Maybe<Scalars['String']>;
  heroMedia?: Maybe<ShowroomHeroParams>;
  displayHeroContent?: Maybe<Scalars['Boolean']>;
  displayHeroMedia?: Maybe<Scalars['Boolean']>;
  pdfs?: Maybe<Array<Maybe<PdfFileUi>>>;
  sections?: Maybe<Array<Maybe<ShowroomSectionUi>>>;
  prContacts?: Maybe<Array<Maybe<ShowroomPrContactUi>>>;
};

export type ShowroomPdf = {
   __typename?: 'ShowroomPdf';
  mediaType: Scalars['String'];
  publicId: Scalars['String'];
  publicUrl: Scalars['String'];
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ShowroomPrContact = {
   __typename?: 'ShowroomPrContact';
  id: Scalars['String'];
  name: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type ShowroomPrContactParams = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ShowroomPrContactUi = {
   __typename?: 'ShowroomPrContactUI';
  name?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ShowroomPublicFields = {
   __typename?: 'ShowroomPublicFields';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  heroMedia?: Maybe<ShowroomHeroFile>;
  logo?: Maybe<Scalars['String']>;
  defaultBackground?: Maybe<ShowroomFile>;
  librarySeason?: Maybe<Scalars['String']>;
  libraryCollection?: Maybe<Scalars['String']>;
};

export type ShowroomSearch = {
   __typename?: 'ShowroomSearch';
  query?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  direction?: Maybe<Direction>;
  tab?: Maybe<ShowroomListTab>;
  selectedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomSection = {
   __typename?: 'ShowroomSection';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rows?: Maybe<Array<Maybe<ShowroomSectionRows>>>;
  options?: Maybe<ShowroomSectionOptions>;
  rowMediaIds?: Maybe<Array<Maybe<ShowroomSectionRowMediaIds>>>;
};

export type ShowroomSectionGlobalSelectedFiltersUi = {
   __typename?: 'ShowroomSectionGlobalSelectedFiltersUI';
  galleriesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomSectionOptions = {
   __typename?: 'ShowroomSectionOptions';
  spacing?: Maybe<Scalars['Float']>;
  fullWidth?: Maybe<Scalars['Boolean']>;
  displayLabel?: Maybe<Scalars['Boolean']>;
  zoomLayer?: Maybe<Scalars['Boolean']>;
  productLayer?: Maybe<Scalars['Boolean']>;
  productLayerSettings?: Maybe<ProductLayerSettings>;
  selectedFilters?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalSelectedFilters?: Maybe<GlobalSelectedFilters>;
};

export type ShowroomSectionOptionsUi = {
   __typename?: 'ShowroomSectionOptionsUI';
  spacing?: Maybe<Scalars['Float']>;
  fullWidth?: Maybe<Scalars['Boolean']>;
  displayLabel?: Maybe<Scalars['Boolean']>;
  zoomLayer?: Maybe<Scalars['Boolean']>;
  productLayer?: Maybe<Scalars['Boolean']>;
  productLayerSettings?: Maybe<ShowroomSectionProductLayerSettingsUi>;
  globalSelectedFilters?: Maybe<ShowroomSectionGlobalSelectedFiltersUi>;
};

export type ShowroomSectionProductLayerSettingsParams = {
  galleriesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomSectionProductLayerSettingsUi = {
   __typename?: 'ShowroomSectionProductLayerSettingsUI';
  galleriesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  samplesFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomSectionRowMediaIds = {
   __typename?: 'ShowroomSectionRowMediaIds';
  id?: Maybe<Scalars['String']>;
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShowroomSectionRows = {
   __typename?: 'ShowroomSectionRows';
  id?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<ShowroomFile>>>;
  medias?: Maybe<Array<Maybe<Media>>>;
};

export type ShowroomSectionUi = {
   __typename?: 'ShowroomSectionUI';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rows?: Maybe<Array<Maybe<SectionRowUi>>>;
  rowMediaIds?: Maybe<Array<Maybe<ShowroomSectionRowMediaIds>>>;
  options?: Maybe<ShowroomSectionOptionsUi>;
  loading?: Maybe<Scalars['Boolean']>;
  loaded?: Maybe<Scalars['Boolean']>;
};

export enum ShowroomTab {
  Samples = 'SAMPLES'
}

export type ShowroomUi = {
   __typename?: 'ShowroomUI';
  search?: Maybe<ShowroomSearch>;
  editor?: Maybe<ShowroomEditor>;
  userView?: Maybe<ShowroomUserViewUi>;
};

export type ShowroomUpdateParams = {
  library?: Maybe<Scalars['String']>;
  downloadPdfEnabled?: Maybe<Scalars['Boolean']>;
  downloadEnabled?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  librarySeason?: Maybe<Scalars['String']>;
  libraryCollection?: Maybe<Scalars['String']>;
  displayHeroMedia?: Maybe<Scalars['Boolean']>;
  displayHeroContent?: Maybe<Scalars['Boolean']>;
  heroTitle?: Maybe<Scalars['String']>;
  heroContent?: Maybe<Scalars['String']>;
  heroFontColor?: Maybe<Scalars['String']>;
  heroBackgroundColor?: Maybe<Scalars['String']>;
  heroMedia?: Maybe<HeroMediaParams>;
  sections?: Maybe<Array<Maybe<SectionParams>>>;
  pdfs?: Maybe<Array<Maybe<PdfParams>>>;
  prContacts?: Maybe<Array<Maybe<ShowroomPrContactParams>>>;
  requestSamplesEnabled?: Maybe<Scalars['Boolean']>;
  samplesDepartmentId?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
};

export type ShowroomUserViewSamplesFormUi = {
   __typename?: 'ShowroomUserViewSamplesFormUi';
  deliveryDate?: Maybe<Scalars['Date']>;
  returnDate?: Maybe<Scalars['Date']>;
  streetAdress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  prefixNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  occasionType?: Maybe<OccasionType>;
  runThroughDate?: Maybe<Scalars['Date']>;
  publication?: Maybe<Scalars['String']>;
  storyTheme?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  celebrity?: Maybe<Scalars['String']>;
  additionalComments?: Maybe<Scalars['String']>;
  shootDateStart?: Maybe<Scalars['Date']>;
  shootDateEnd?: Maybe<Scalars['Date']>;
  shootLocation?: Maybe<Scalars['String']>;
  photographer?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
};

export type ShowroomUserViewSamplesUi = {
   __typename?: 'ShowroomUserViewSamplesUi';
  isRequestSamplesFormOpen?: Maybe<Scalars['Boolean']>;
  isRequestSamplesFormValid?: Maybe<Scalars['Boolean']>;
  form?: Maybe<ShowroomUserViewSamplesFormUi>;
};

export type ShowroomUserViewShareUi = {
   __typename?: 'ShowroomUserViewShareUI';
  isDialogOpen?: Maybe<Scalars['Boolean']>;
  mediaQuality?: Maybe<QualityOptions>;
};

export type ShowroomUserViewUi = {
   __typename?: 'ShowroomUserViewUI';
  selectedPdfs?: Maybe<Array<Maybe<ShowroomPdf>>>;
  selectedMedias?: Maybe<Array<Maybe<Media>>>;
  share?: Maybe<ShowroomUserViewShareUi>;
  samples?: Maybe<ShowroomUserViewSamplesUi>;
  selectedTab?: Maybe<ShowroomTab>;
  activeMediaMobileMenu?: Maybe<MediaMobileMenu>;
};

export type ShowroomVideo = ShowroomFile & {
   __typename?: 'ShowroomVideo';
  mediaType: Scalars['String'];
  publicId: Scalars['String'];
  publicUrl: Scalars['String'];
  originalFilename?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type SpotlightCollectionFilters = {
  brand: Scalars['String'];
  collection: Scalars['String'];
  collectionId?: Maybe<Scalars['String']>;
  dateRange: TimeRange;
  season: Scalars['String'];
};

export type SpotlightCollectionFiltersUi = {
   __typename?: 'SpotlightCollectionFiltersUI';
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type SpotlightCollectionMagazinesTableFilters = {
  filters?: Maybe<SpotlightCollectionFilters>;
  pagination?: Maybe<PaginationOptions>;
  sortBy?: Maybe<MagazinesSortByField>;
  order?: Maybe<OrderOption>;
};

export type SpotlightCollectionUi = {
   __typename?: 'SpotlightCollectionUI';
  filters?: Maybe<SpotlightCollectionFiltersUi>;
  currentCollectionId?: Maybe<Scalars['String']>;
  visitorSelectTab?: Maybe<VisitorTab>;
};

export type SpotlightCollectionVisitorsTableFilters = {
  filters?: Maybe<SpotlightCollectionFilters>;
  pagination?: Maybe<PaginationOptions>;
  sortBy?: Maybe<VisitorsSortByFields>;
  order?: Maybe<OrderOption>;
};

export type SpotlightDrawerParamsUi = {
   __typename?: 'SpotlightDrawerParamsUI';
  id?: Maybe<Scalars['String']>;
};

export type SpotlightDrawerUi = {
   __typename?: 'SpotlightDrawerUI';
  open?: Maybe<Scalars['Boolean']>;
  type?: Maybe<VisualContentPerformanceType>;
  params?: Maybe<SpotlightDrawerParamsUi>;
};

export type SpotlightSignParameters = {
  path?: Maybe<Scalars['String']>;
};

export type SpotlightUi = {
   __typename?: 'SpotlightUI';
  collection?: Maybe<SpotlightCollectionUi>;
  visualContentModal?: Maybe<VisualContentModalUi>;
  spotlightDrawer?: Maybe<SpotlightDrawerUi>;
};

export type Tag = {
   __typename?: 'Tag';
  id: Scalars['ID'];
  type: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Tag>;
  childs?: Maybe<Array<Maybe<Tag>>>;
  isLeaf?: Maybe<Scalars['Boolean']>;
  colorCode?: Maybe<Scalars['String']>;
  canBeAdded?: Maybe<Scalars['Boolean']>;
  associatedTags?: Maybe<Array<Maybe<Tag>>>;
  allowedRelationships?: Maybe<Array<Maybe<Tag>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  allAllowedRelationships?: Maybe<Array<Maybe<Tag>>>;
  tenant?: Maybe<Tenant>;
  isCustom?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
  translationsAndAliases?: Maybe<Array<Maybe<TranslationsAndAliases>>>;
};

export type TagByType = {
   __typename?: 'TagByType';
  type?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export enum TagPanelDisplay {
  List = 'LIST',
  Form = 'FORM',
  SampleInfo = 'SAMPLE_INFO'
}

export type TagRelations = {
  added?: Maybe<Array<Maybe<Scalars['String']>>>;
  deleted?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagRelationship = {
   __typename?: 'TagRelationship';
  id: Scalars['ID'];
  target: Tag;
  relations?: Maybe<Array<Tag>>;
  isSampleLinked?: Maybe<Scalars['Boolean']>;
};

export type TagRelationshipInfo = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  target: Scalars['String'];
  relations: TagRelations;
  isSampleLinked?: Maybe<Scalars['Boolean']>;
};

export type TagSearchResponse = {
   __typename?: 'TagSearchResponse';
  searchDepth?: Maybe<Scalars['Int']>;
  matchingTags?: Maybe<Array<Maybe<Tag>>>;
  tagIdsList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagTree = {
   __typename?: 'TagTree';
  tag: Tag;
  childs?: Maybe<Array<Maybe<TagTree>>>;
};

export enum TagType {
  LocationTag = 'LocationTag'
}

export type Team = {
   __typename?: 'Team';
  id: Scalars['String'];
  name: Scalars['String'];
  users?: Maybe<Array<Maybe<User>>>;
  library?: Maybe<Library>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isDefault: Scalars['Boolean'];
};

export enum TeamRole {
  Viewer = 'VIEWER',
  Contributor = 'CONTRIBUTOR',
  Admin = 'ADMIN'
}

export type Tenant = {
   __typename?: 'Tenant';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  tenant: Scalars['String'];
  libraries?: Maybe<Array<Maybe<Library>>>;
  showrooms?: Maybe<Array<Maybe<Showroom>>>;
  EcosystemAccountID?: Maybe<Scalars['String']>;
  EcosystemAccountName?: Maybe<Scalars['String']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceAccountParentId?: Maybe<Scalars['String']>;
  samplesApiKey?: Maybe<Scalars['String']>;
  samplesApiUrl?: Maybe<Scalars['String']>;
  samplesCollections?: Maybe<Array<Maybe<SamplesCollection>>>;
  isSamplesEnabled?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<LogoFile>;
  isTagsEnabled?: Maybe<Scalars['Boolean']>;
  dev?: Maybe<Scalars['Boolean']>;
  featureFlags?: Maybe<FeatureFlags>;
  designers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimeRange = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type Transformation = {
   __typename?: 'Transformation';
  transformation?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  secure_url?: Maybe<Scalars['String']>;
};

export type TranslationsAndAliases = {
   __typename?: 'TranslationsAndAliases';
  key?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
  aliases?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TranslationsAndAliasesParams = {
  key?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
  aliases?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateMediaTagsParams = {
  addedRelationships?: Maybe<Array<Maybe<TagRelationshipInfo>>>;
  addedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedRelationships?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalDescription?: Maybe<Scalars['String']>;
  ids: Array<Scalars['String']>;
  seasonYear?: Maybe<Scalars['Int']>;
  showDate?: Maybe<Scalars['Date']>;
};

export type UpdateTagParams = {
  name?: Maybe<Scalars['String']>;
  translationsAndAliases?: Maybe<Array<Maybe<TranslationsAndAliasesParams>>>;
};

export type User = {
   __typename?: 'User';
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Maybe<UserTeam>>>;
  lastConnection?: Maybe<Scalars['Date']>;
};

export type UserPrivacy = {
   __typename?: 'UserPrivacy';
  email?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
};

export type UserTeam = {
   __typename?: 'UserTeam';
  team: Team;
  libraryRole: TeamRole;
  showroomRole: TeamRole;
};

export type Video = Media & {
   __typename?: 'Video';
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryVersion?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  extension: Scalars['String'];
  globalDescription?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isSample?: Maybe<Scalars['Boolean']>;
  library: Library;
  libraryFolders?: Maybe<LibraryFolders>;
  name: Scalars['String'];
  mediaType: MediaType;
  path?: Maybe<Scalars['String']>;
  rootTagsLeaves?: Maybe<Array<Maybe<RootTagLeaves>>>;
  sampleInfo?: Maybe<SampleInfo>;
  seasonYear?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status: MediaStatus;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tagRelationships?: Maybe<Array<Maybe<TagRelationship>>>;
  tenant: Tenant;
  updatedAt: Scalars['String'];
  showDate?: Maybe<Scalars['String']>;
  placements?: Maybe<Array<Maybe<MediaPlacement>>>;
  width?: Maybe<Scalars['Int']>;
};

export type VideoResponse = {
   __typename?: 'VideoResponse';
  totalCount: Scalars['Int'];
  files?: Maybe<Array<CloudFile>>;
};

export type Visitor = {
   __typename?: 'Visitor';
  id?: Maybe<Scalars['String']>;
  visitor?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  magazine?: Maybe<Scalars['String']>;
  magazineId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type VisitorByDates = {
   __typename?: 'VisitorByDates';
  id?: Maybe<Scalars['String']>;
  visitor?: Maybe<Visitor>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type VisitorsListResponse = {
   __typename?: 'VisitorsListResponse';
  visitors?: Maybe<Array<Maybe<VisitorByDates>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum VisitorsSortByFields {
  Visitor = 'visitor',
  JobTitle = 'jobTitle',
  TotalViews = 'totalViews',
  TotalDownloads = 'totalDownloads',
  Country = 'country',
  Magazine = 'magazine',
  Email = 'email'
}

export enum VisitorTab {
  Visitors = 'visitors',
  Magazines = 'magazines'
}

export type VisualContent = {
   __typename?: 'VisualContent';
  mediaId?: Maybe<Scalars['String']>;
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryVersion?: Maybe<Scalars['String']>;
  miv?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  media?: Maybe<Media>;
};

export type VisualContentByDates = {
   __typename?: 'VisualContentByDates';
  visualContent?: Maybe<VisualContent>;
  metrics?: Maybe<AmplificationMetrics>;
};

export type VisualContentModalUi = {
   __typename?: 'VisualContentModalUI';
  open?: Maybe<Scalars['Boolean']>;
  visualContent?: Maybe<VisualContent>;
};

export type VisualContentPerformanceParameters = {
  type: VisualContentPerformanceType;
  id: Scalars['String'];
  dates: TimeRange;
};

export enum VisualContentPerformanceType {
  Visitor = 'VISITOR',
  Magazine = 'MAGAZINE'
}

export enum VisualContentSortingValue {
  Miv = 'miv',
  Downloads = 'downloads',
  Views = 'views'
}

export type Voice = {
   __typename?: 'Voice';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type GetContactsQueryVariables = {
  email?: Maybe<Scalars['String']>;
};


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { getContacts?: Maybe<Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'email'>
  )>>> }
);

export type FeatureFlagsInfoFragment = (
  { __typename?: 'FeatureFlags' }
  & Pick<FeatureFlags, 'AITagging' | 'productDetails' | 'tagsEnabled' | 'smartCategory' | 'showDate' | 'metabase' | 'amplification'>
);

export type GetFeatureFlagsQueryVariables = {};


export type GetFeatureFlagsQuery = (
  { __typename?: 'Query' }
  & { featureFlags?: Maybe<(
    { __typename?: 'FeatureFlags' }
    & FeatureFlagsInfoFragment
  )> }
);

export type SingleUploadMutationVariables = {
  libraryId: Scalars['String'];
  folder: Scalars['String'];
  publicId?: Maybe<Scalars['String']>;
  uniqueFileName?: Maybe<Scalars['Boolean']>;
};


export type SingleUploadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'singleUpload'>
);

export type GenerateArchiveMutationVariables = {
  type: GenerateZipType;
  params: GenerateLibraryArchiveParams;
};


export type GenerateArchiveMutation = (
  { __typename?: 'Mutation' }
  & { generateArchive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'id'>
  )> }
);

export type GetArchiveQueryVariables = {
  id?: Maybe<Scalars['String']>;
};


export type GetArchiveQuery = (
  { __typename?: 'Query' }
  & { getArchive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'path' | 'hasPassword' | 'pdfIds' | 'videoIds' | 'imageIds' | 'isExpired'>
    & { tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & { logo?: Maybe<(
        { __typename?: 'LogoFile' }
        & Pick<LogoFile, 'publicId' | 'publicUrl'>
      )> }
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'cloudinaryId' | 'mediaType'>
    ) | (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'cloudinaryId' | 'mediaType'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id' | 'cloudinaryId' | 'mediaType'>
    )>>> }
  )> }
);

export type GetArchiveStatusQueryVariables = {
  id?: Maybe<Scalars['String']>;
};


export type GetArchiveStatusQuery = (
  { __typename?: 'Query' }
  & { getArchive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'id' | 'status'>
  )> }
);

export type GetZipFileQueryVariables = {
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type GetZipFileQuery = (
  { __typename?: 'Query' }
  & { archiveFiles?: Maybe<Array<Maybe<(
    { __typename?: 'DownloadFile' }
    & Pick<DownloadFile, 'url' | 'filename'>
  )>>> }
);

export type GetDeleteFolderDialogQueryVariables = {};


export type GetDeleteFolderDialogQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { deleteFolderDialog?: Maybe<(
      { __typename?: 'LibraryDeleteFolderDialogUI' }
      & Pick<LibraryDeleteFolderDialogUi, 'open' | 'libraryId' | 'path'>
    )> }
  )> }
);

export type GetLibraryFolderFormQueryVariables = {};


export type GetLibraryFolderFormQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { folderForm?: Maybe<(
      { __typename?: 'LibraryFolderFormUI' }
      & Pick<LibraryFolderFormUi, 'libraryId' | 'open' | 'currentStep'>
      & { parentFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'level'>
        & { folders?: Maybe<Array<Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'name'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetLibraryFolderFormParamsQueryVariables = {};


export type GetLibraryFolderFormParamsQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { folderForm?: Maybe<(
      { __typename?: 'LibraryFolderFormUI' }
      & { params?: Maybe<(
        { __typename?: 'LibraryFolderFormParams' }
        & Pick<LibraryFolderFormParams, 'season' | 'collections' | 'albums'>
      )> }
    )> }
  )> }
);

export type GetMediaDetailModalQueryVariables = {};


export type GetMediaDetailModalQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { mediaDetailModal?: Maybe<(
      { __typename?: 'LibraryMediaDetailModalUI' }
      & Pick<LibraryMediaDetailModalUi, 'open' | 'mediaIds' | 'display'>
      & { tagPanel?: Maybe<(
        { __typename?: 'LibraryMediaDetailTagPanelUI' }
        & Pick<LibraryMediaDetailTagPanelUi, 'display'>
      )> }
    )> }
  )> }
);

export type GetMediaDetailTagPanelDisplayQueryVariables = {};


export type GetMediaDetailTagPanelDisplayQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { mediaDetailModal?: Maybe<(
      { __typename?: 'LibraryMediaDetailModalUI' }
      & Pick<LibraryMediaDetailModalUi, 'display'>
      & { tagPanel?: Maybe<(
        { __typename?: 'LibraryMediaDetailTagPanelUI' }
        & Pick<LibraryMediaDetailTagPanelUi, 'display'>
      )> }
    )> }
  )> }
);

export type GetTagFormInitialCategoryQueryVariables = {};


export type GetTagFormInitialCategoryQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { mediaDetailModal?: Maybe<(
      { __typename?: 'LibraryMediaDetailModalUI' }
      & { tagPanel?: Maybe<(
        { __typename?: 'LibraryMediaDetailTagPanelUI' }
        & Pick<LibraryMediaDetailTagPanelUi, 'initialCategory' | 'selectedTag' | 'isDescriptionTag' | 'relationshipId'>
      )> }
    )> }
  )> }
);

export type GetMediaDetailContextsQueryVariables = {};


export type GetMediaDetailContextsQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { mediaDetailModal?: Maybe<(
      { __typename?: 'LibraryMediaDetailModalUI' }
      & Pick<LibraryMediaDetailModalUi, 'mediaDetailsContextIds'>
    )> }
  )> }
);

export type GetMediaDetailDataQueryVariables = {
  id: Scalars['String'];
};


export type GetMediaDetailDataQuery = (
  { __typename?: 'Query' }
  & { cachedMedia?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'mediaType' | 'isSample'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'mediaType' | 'isSample'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'mediaType' | 'isSample'>
  )> }
);

export type MediaDetailsBaseContextFragmentFragment = (
  { __typename?: 'LibraryMediaDetailsContext' }
  & Pick<LibraryMediaDetailsContext, 'id' | 'originalTags' | 'currentTags' | 'addedTags' | 'deletedTags' | 'addedRelationships' | 'deletedRelationships' | 'editingMedia'>
  & { sharedMedia?: Maybe<(
    { __typename?: 'LibraryMergedMediaDetailsUI' }
    & Pick<LibraryMergedMediaDetailsUi, 'id' | 'ids' | 'name' | 'mediaType' | 'isSample' | 'globalDescription' | 'height' | 'width' | 'orientation'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
    )>>> }
  )> }
);

export type MediaDetailsContextFragmentFragment = (
  { __typename?: 'LibraryMediaDetailsContext' }
  & Pick<LibraryMediaDetailsContext, 'id' | 'originalTags' | 'currentTags' | 'addedTags' | 'deletedTags' | 'addedRelationships' | 'deletedRelationships' | 'editingMedia'>
  & { sharedMedia?: Maybe<(
    { __typename?: 'LibraryMergedMediaDetailsUI' }
    & Pick<LibraryMergedMediaDetailsUi, 'id' | 'ids' | 'name' | 'mediaType' | 'isSample' | 'seasonYear' | 'createdAt' | 'showDate' | 'globalDescription' | 'width' | 'height' | 'orientation' | 'unlinkedSampleMedias'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
    )>>>, library?: Maybe<(
      { __typename?: 'Library' }
      & Pick<Library, 'id' | 'name' | 'industry'>
    )>, libraryFolders?: Maybe<(
      { __typename?: 'LibraryFolders' }
      & { album?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'name'>
      )>, collection?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'name'>
      )>, season?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'name'>
      )> }
    )>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'MergedTagRelationshipUI' }
      & Pick<MergedTagRelationshipUi, 'id' | 'isSampleLinked'>
      & { target?: Maybe<(
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      )>, relations?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      )>>> }
    )>>>, rootTagsLeaves?: Maybe<Array<Maybe<(
      { __typename?: 'RootTagLeaves' }
      & { leaves?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      )>>>, tag?: Maybe<(
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      )> }
    )>>>, sampleInfo?: Maybe<(
      { __typename?: 'SampleInfo' }
      & SampleFieldsFragment
    )>, placements?: Maybe<Array<Maybe<(
      { __typename?: 'MediaPlacement' }
      & MediaPlacementFragmentFragment
    )>>>, aiTags?: Maybe<Array<Maybe<(
      { __typename?: 'MediaAITag' }
      & MediaAiTagFragmentFragment
    )>>> }
  )> }
);

export type GetMediaDetailContextQueryVariables = {
  id: Scalars['String'];
};


export type GetMediaDetailContextQuery = (
  { __typename?: 'Query' }
  & { mediaDetailContext?: Maybe<(
    { __typename?: 'LibraryMediaDetailsContext' }
    & MediaDetailsContextFragmentFragment
  )> }
);

export type GetMediaMergedDetailModalQueryVariables = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type GetMediaMergedDetailModalQuery = (
  { __typename?: 'Query' }
  & { cachedMedias?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'width' | 'height' | 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'mediaType' | 'isSample'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'width' | 'height' | 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'mediaType' | 'isSample'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'mediaType' | 'isSample'>
  )>>> }
);

export type GetLibrarySelectedMediasUiQueryVariables = {};


export type GetLibrarySelectedMediasUiQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { results?: Maybe<(
      { __typename?: 'LibraryResultsUI' }
      & { selectedMedias?: Maybe<Array<Maybe<(
        { __typename?: 'SelectedMediaUI' }
        & Pick<SelectedMediaUi, 'id' | 'type'>
      )>>> }
    )> }
  )> }
);

export type GetLibrarySelectedMediasQueryVariables = {};


export type GetLibrarySelectedMediasQuery = (
  { __typename?: 'Query' }
  & { librarySelectedMedias?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'mediaType' | 'cloudinaryId' | 'isSample' | 'name' | 'createdAt'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'mediaType' | 'cloudinaryId' | 'isSample' | 'name' | 'createdAt'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'mediaType' | 'cloudinaryId' | 'isSample' | 'name' | 'createdAt'>
  )>>> }
);

export type GetLibrarySelectedMediasSamplesInfoQueryVariables = {};


export type GetLibrarySelectedMediasSamplesInfoQuery = (
  { __typename?: 'Query' }
  & { librarySelectedMedias?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'isSample'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'isSample'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'isSample'>
  )>>> }
);

export type GetLibrarySelectionModeQueryVariables = {};


export type GetLibrarySelectionModeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'librarySelectionModeEnabled'>
);

export type IsUploadDrawerOpenQueryVariables = {};


export type IsUploadDrawerOpenQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { results?: Maybe<(
      { __typename?: 'LibraryResultsUI' }
      & Pick<LibraryResultsUi, 'isUploadDrawerOpen'>
    )> }
  )> }
);

export type IsRequestSpotlightDrawerOpenQueryVariables = {};


export type IsRequestSpotlightDrawerOpenQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { results?: Maybe<(
      { __typename?: 'LibraryResultsUI' }
      & Pick<LibraryResultsUi, 'isRequestSpotlightDrawerOpen'>
    )> }
  )> }
);

export type GetUploadingQueueQueryVariables = {};


export type GetUploadingQueueQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { results?: Maybe<(
      { __typename?: 'LibraryResultsUI' }
      & { uploadingQueue?: Maybe<Array<Maybe<(
        { __typename?: 'LibraryUploads' }
        & Pick<LibraryUploads, 'path' | 'status' | 'libraryId'>
        & { uploadedFiles?: Maybe<Array<Maybe<(
          { __typename?: 'LibraryUploadedFile' }
          & Pick<LibraryUploadedFile, 'mediaType' | 'publicId'>
        )>>>, processes?: Maybe<Array<Maybe<(
          { __typename?: 'LibraryUploadsProcess' }
          & Pick<LibraryUploadsProcess, 'images' | 'videos' | 'processedFiles' | 'error' | 'id'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetShouldDisplayReloadButtonQueryVariables = {};


export type GetShouldDisplayReloadButtonQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { results?: Maybe<(
      { __typename?: 'LibraryResultsUI' }
      & Pick<LibraryResultsUi, 'shouldDisplayReloadButton'>
    )> }
  )> }
);

export type GetLibraryBigPreviewStatusQueryVariables = {};


export type GetLibraryBigPreviewStatusQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { results?: Maybe<(
      { __typename?: 'LibraryResultsUI' }
      & Pick<LibraryResultsUi, 'bigPreview'>
    )> }
  )> }
);

export type SamplesImportInfoFragment = (
  { __typename?: 'SampleImport' }
  & Pick<SampleImport, 'id' | 'status' | 'galleriesBrand' | 'galleriesCollection' | 'galleriesSeason' | 'galleriesAlbum'>
);

export type GetPendingSamplesImportsQueryVariables = {};


export type GetPendingSamplesImportsQuery = (
  { __typename?: 'Query' }
  & { getPendingSamplesImports?: Maybe<Array<Maybe<(
    { __typename?: 'SampleImport' }
    & Pick<SampleImport, 'id' | 'status' | 'galleriesBrand' | 'galleriesCollection' | 'galleriesSeason' | 'galleriesAlbum' | 'path'>
  )>>> }
);

export type GetSamplesImportQueryVariables = {
  id: Scalars['String'];
};


export type GetSamplesImportQuery = (
  { __typename?: 'Query' }
  & { getSamplesImport?: Maybe<(
    { __typename?: 'SampleImport' }
    & Pick<SampleImport, 'id' | 'status' | 'galleriesBrand' | 'galleriesCollection' | 'galleriesSeason' | 'galleriesAlbum'>
  )> }
);

export type ImportSamplesMutationVariables = {
  samplesDesigner?: Maybe<Scalars['String']>;
  samplesSeason?: Maybe<Scalars['String']>;
  samplesCollection?: Maybe<Scalars['String']>;
  galleriesBrand: Scalars['String'];
  galleriesSeason: Scalars['String'];
  galleriesCollection: Scalars['String'];
  galleriesAlbum: Scalars['String'];
};


export type ImportSamplesMutation = (
  { __typename?: 'Mutation' }
  & { importSamples?: Maybe<(
    { __typename?: 'SampleImport' }
    & Pick<SampleImport, 'id' | 'galleriesBrand' | 'galleriesSeason' | 'galleriesCollection' | 'galleriesAlbum' | 'status'>
  )> }
);

export type RefreshSamplesMutationVariables = {
  galleriesAlbum: Scalars['String'];
};


export type RefreshSamplesMutation = (
  { __typename?: 'Mutation' }
  & { refreshSamples?: Maybe<(
    { __typename?: 'SampleImport' }
    & SamplesImportInfoFragment
  )> }
);

export type GetMediasQueryVariables = {
  libraryId: Scalars['String'];
  folderId: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<Maybe<MediaFilter>>>;
  sort?: Maybe<MediaSortField>;
  order?: Maybe<MediaSortOrder>;
};


export type GetMediasQuery = (
  { __typename?: 'Query' }
  & { searchMedias: (
    { __typename?: 'MediaSearchResponse' }
    & Pick<MediaSearchResponse, 'totalCount' | 'cursor' | 'searchId'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'width' | 'height' | 'duration' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'name' | 'createdAt' | 'path' | 'isSample'>
    ) | (
      { __typename?: 'Image' }
      & Pick<Image, 'width' | 'height' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'name' | 'createdAt' | 'path' | 'isSample'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'name' | 'createdAt' | 'path' | 'isSample'>
    )>>> }
  ) }
);

export type GetUploadedMediasQueryVariables = {
  libraryId: Scalars['String'];
  folderId: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<Maybe<MediaFilter>>>;
  sort?: Maybe<MediaSortField>;
  order?: Maybe<MediaSortOrder>;
};


export type GetUploadedMediasQuery = (
  { __typename?: 'Query' }
  & { searchMedias: (
    { __typename?: 'MediaSearchResponse' }
    & Pick<MediaSearchResponse, 'totalCount' | 'searchId'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'width' | 'height' | 'duration' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'name' | 'createdAt' | 'path' | 'isSample'>
    ) | (
      { __typename?: 'Image' }
      & Pick<Image, 'width' | 'height' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'name' | 'createdAt' | 'path' | 'isSample'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'name' | 'createdAt' | 'path' | 'isSample'>
    )>>> }
  ) }
);

export type GetMediasCounterQueryVariables = {
  libraryId: Scalars['String'];
  folderId: Scalars['String'];
};


export type GetMediasCounterQuery = (
  { __typename?: 'Query' }
  & { searchMedias: (
    { __typename?: 'MediaSearchResponse' }
    & Pick<MediaSearchResponse, 'totalCount'>
  ) }
);

export type GetSearchContextQueryVariables = {};


export type GetSearchContextQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & Pick<LibraryUi, 'currentLibraryId' | 'currentFolderId'>
    & { search?: Maybe<(
      { __typename?: 'LibrarySearchUI' }
      & { selectedFilters?: Maybe<Array<Maybe<(
        { __typename?: 'LibrarySearchFilter' }
        & Pick<LibrarySearchFilter, 'type' | 'box' | 'byValue'>
      )>>>, selectedBoxes?: Maybe<Array<Maybe<(
        { __typename?: 'LibrarySearchBox' }
        & Pick<LibrarySearchBox, 'key' | 'name' | 'toggleSelected'>
      )>>> }
    )> }
  )> }
);

export type GetSelectedFilterValuesQueryVariables = {
  filterType: Scalars['String'];
};


export type GetSelectedFilterValuesQuery = (
  { __typename?: 'Query' }
  & { librarySelectedFilterValues?: Maybe<Array<Maybe<(
    { __typename?: 'MediaBucket' }
    & Pick<MediaBucket, 'key' | 'id' | 'name'>
  )>>> }
);

export type GetBoxSelectedFilterValuesQueryVariables = {
  filterBox: Scalars['String'];
};


export type GetBoxSelectedFilterValuesQuery = (
  { __typename?: 'Query' }
  & { libraryBoxSelectedFilterValues?: Maybe<Array<Maybe<(
    { __typename?: 'MediaBucket' }
    & Pick<MediaBucket, 'key' | 'id' | 'name'>
  )>>> }
);

export type GetAllSelectedFilterValuesQueryVariables = {};


export type GetAllSelectedFilterValuesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'libraryAllSelectedFilterValues'>
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { search?: Maybe<(
      { __typename?: 'LibrarySearchUI' }
      & { selectedFilters?: Maybe<Array<Maybe<(
        { __typename?: 'LibrarySearchFilter' }
        & Pick<LibrarySearchFilter, 'type' | 'box' | 'byValue'>
      )>>> }
    )> }
  )> }
);

export type GetActionedFilterQueryVariables = {};


export type GetActionedFilterQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { search?: Maybe<(
      { __typename?: 'LibrarySearchUI' }
      & { actionedFilter?: Maybe<(
        { __typename?: 'LibrarySearchFilter' }
        & Pick<LibrarySearchFilter, 'type' | 'box'>
      )> }
    )> }
  )> }
);

export type GetFilterLoadingQueryVariables = {};


export type GetFilterLoadingQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & { search?: Maybe<(
      { __typename?: 'LibrarySearchUI' }
      & Pick<LibrarySearchUi, 'filterLoading'>
    )> }
  )> }
);

export type GetAggregationQueryQueryVariables = {
  libraryId: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
  tags: Array<Maybe<Scalars['String']>>;
  filters?: Maybe<Array<Maybe<MediaFilter>>>;
};


export type GetAggregationQueryQuery = (
  { __typename?: 'Query' }
  & { aggregateMedias?: Maybe<Array<Maybe<(
    { __typename?: 'MediaAggregationBuckets' }
    & Pick<MediaAggregationBuckets, 'type'>
    & { byValue?: Maybe<Array<Maybe<(
      { __typename?: 'MediaBucket' }
      & Pick<MediaBucket, 'id' | 'key' | 'count' | 'type' | 'name'>
    )>>> }
  )>>> }
);

export type RootTagsQueryVariables = {};


export type RootTagsQuery = (
  { __typename?: 'Query' }
  & { rootTags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'key' | 'name' | 'type'>
    & { childs?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'key' | 'name' | 'type'>
    )>>> }
  )>>> }
);

export type GetTagsByTypeQueryVariables = {
  type: Scalars['String'];
};


export type GetTagsByTypeQuery = (
  { __typename?: 'Query' }
  & { tagsByType?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'key' | 'name' | 'type' | 'isCustom' | 'canBeAdded'>
    & { parent?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'type' | 'key' | 'name' | 'isCustom' | 'canBeAdded' | 'path'>
    )> }
  )>>> }
);

export type SearchTagsQueryVariables = {
  search?: Maybe<Scalars['String']>;
};


export type SearchTagsQuery = (
  { __typename?: 'Query' }
  & { searchTags?: Maybe<(
    { __typename?: 'TagSearchResponse' }
    & Pick<TagSearchResponse, 'searchDepth' | 'tagIdsList'>
    & { matchingTags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )>>> }
  )> }
);

export type GetCategoryFilterTagsQueryVariables = {};


export type GetCategoryFilterTagsQuery = (
  { __typename?: 'Query' }
  & { categoryFilterTags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'key' | 'name' | 'type'>
    & { parent?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )>>> }
);

export type GetRepeatedTagQueryVariables = {
  name: Scalars['String'];
};


export type GetRepeatedTagQuery = (
  { __typename?: 'Query' }
  & { tagByName?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'key'>
    & { parent?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key'>
      & { parent?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'key'>
        & { parent?: Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'name' | 'key'>
        )> }
      )> }
    )> }
  )> }
);

export type NewTagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'path'>
  & { parent?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'type'>
  )>, allAllowedRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'key'>
  )>>>, tenant?: Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id'>
  )>, associatedTags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
  )>>> }
  & MediaTagFragmentFragment
);

export type UpdateTagMutationVariables = {
  id: Scalars['String'];
  params: UpdateTagParams;
};


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type UpdateTagTranslationMutationVariables = {
  id: Scalars['String'];
  params: UpdateTagParams;
};


export type UpdateTagTranslationMutation = (
  { __typename?: 'Mutation' }
  & { updateTagTranslation?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type RemoveTagMutationVariables = {
  removeTagParams: RemoveTagParams;
};


export type RemoveTagMutation = (
  { __typename?: 'Mutation' }
  & { removeTag?: Maybe<(
    { __typename?: 'RemoveTagResult' }
    & Pick<RemoveTagResult, 'id'>
  )> }
);

export type CountMediasWithTagQueryVariables = {
  tagId?: Maybe<Scalars['String']>;
  isDescriptionTag?: Maybe<Scalars['Boolean']>;
};


export type CountMediasWithTagQuery = (
  { __typename?: 'Query' }
  & { mediasWithTagAttachedCounter?: Maybe<(
    { __typename?: 'MediasCounterResponse' }
    & Pick<MediasCounterResponse, 'count'>
  )> }
);

export type CreateTagMutationVariables = {
  params?: Maybe<CreateTagParams>;
};


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag?: Maybe<(
    { __typename?: 'Tag' }
    & NewTagFragment
  )> }
);

export type GetEditTagQueryVariables = {
  id: Scalars['String'];
  isDescriptionTag?: Maybe<Scalars['Boolean']>;
};


export type GetEditTagQuery = (
  { __typename?: 'Query' }
  & { tagById?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'type' | 'name' | 'path'>
    & { parent?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )>, associatedTags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )>>> }
  )>, mediasWithTagAttachedCounter?: Maybe<(
    { __typename?: 'MediasCounterResponse' }
    & Pick<MediasCounterResponse, 'count'>
  )> }
);

export type LibraryFiletreeFragmentFragment = (
  { __typename?: 'Library' }
  & { filetree?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'name' | 'id' | 'path' | 'pathIds' | 'level'>
    & { folders?: Maybe<Array<Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name' | 'id' | 'path' | 'pathIds' | 'level'>
      & { folders?: Maybe<Array<Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'name' | 'id' | 'path' | 'pathIds' | 'isSamples' | 'level'>
      )>>> }
    )>>> }
  )>>> }
);

export type LibraryFragmentFragment = (
  { __typename?: 'Library' }
  & Pick<Library, 'id' | 'name' | 'industry'>
  & { files?: Maybe<Array<Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'url'>
  )>>>, logo?: Maybe<(
    { __typename?: 'LogoFile' }
    & Pick<LogoFile, 'publicId' | 'publicUrl'>
  )> }
  & LibraryFiletreeFragmentFragment
);

export type CreateLibraryMutationVariables = {
  industry: Scalars['String'];
  name: Scalars['String'];
  paths: Array<Maybe<Scalars['String']>>;
};


export type CreateLibraryMutation = (
  { __typename?: 'Mutation' }
  & { createLibrary: (
    { __typename?: 'Library' }
    & LibraryFragmentFragment
  ) }
);

export type UpdateLibraryMutationVariables = {
  libraryId: Scalars['String'];
  name: Scalars['String'];
  logo?: Maybe<LogoParams>;
};


export type UpdateLibraryMutation = (
  { __typename?: 'Mutation' }
  & { updateLibrary?: Maybe<(
    { __typename?: 'Library' }
    & Pick<Library, 'id' | 'name'>
    & { logo?: Maybe<(
      { __typename?: 'LogoFile' }
      & Pick<LogoFile, 'publicId' | 'publicUrl'>
    )> }
  )> }
);

export type UploadLibraryLogoMutationVariables = {
  libraryId: Scalars['String'];
};


export type UploadLibraryLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadLibraryLogo'>
);

export type RemoveLibraryMutationVariables = {
  libraryId: Scalars['ID'];
};


export type RemoveLibraryMutation = (
  { __typename?: 'Mutation' }
  & { removeLibrary?: Maybe<(
    { __typename?: 'DeletedLibrary' }
    & Pick<DeletedLibrary, 'libraryId' | 'sampleIds'>
  )> }
);

export type CreateFoldersMutationVariables = {
  libraryId: Scalars['String'];
  paths: Array<Maybe<Scalars['String']>>;
  isSamples?: Maybe<Scalars['Boolean']>;
};


export type CreateFoldersMutation = (
  { __typename?: 'Mutation' }
  & { createFolders?: Maybe<(
    { __typename?: 'Library' }
    & Pick<Library, 'id'>
    & LibraryFiletreeFragmentFragment
  )> }
);

export type FolderExistsQueryVariables = {
  libraryId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};


export type FolderExistsQuery = (
  { __typename?: 'Query' }
  & { folderExists?: Maybe<(
    { __typename?: 'FolderExistsResponse' }
    & Pick<FolderExistsResponse, 'exists'>
  )> }
);

export type RemoveFolderMutationVariables = {
  libraryId: Scalars['String'];
  path: Scalars['String'];
  pathIds?: Maybe<Scalars['String']>;
};


export type RemoveFolderMutation = (
  { __typename?: 'Mutation' }
  & { removeFolder?: Maybe<(
    { __typename?: 'DeletedFolder' }
    & Pick<DeletedFolder, 'sampleIds'>
    & { library?: Maybe<(
      { __typename?: 'Library' }
      & Pick<Library, 'id'>
      & LibraryFiletreeFragmentFragment
    )> }
  )> }
);

export type GetLibrariesQueryVariables = {};


export type GetLibrariesQuery = (
  { __typename?: 'Query' }
  & { getLibraries?: Maybe<Array<Maybe<(
    { __typename?: 'Library' }
    & Pick<Library, 'id' | 'name'>
    & { logo?: Maybe<(
      { __typename?: 'LogoFile' }
      & Pick<LogoFile, 'publicId' | 'publicUrl'>
    )> }
  )>>> }
);

export type GetCompletedLibrariesQueryVariables = {};


export type GetCompletedLibrariesQuery = (
  { __typename?: 'Query' }
  & { getLibraries?: Maybe<Array<Maybe<(
    { __typename?: 'Library' }
    & LibraryFragmentFragment
  )>>> }
);

export type GetLibraryQueryVariables = {
  id: Scalars['String'];
};


export type GetLibraryQuery = (
  { __typename?: 'Query' }
  & { getLibrary?: Maybe<(
    { __typename?: 'Library' }
    & LibraryFragmentFragment
  )> }
);

export type GetCurrentLibraryIdQueryVariables = {};


export type GetCurrentLibraryIdQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & Pick<LibraryUi, 'currentLibraryId'>
  )> }
);

export type GetLastCreatedFoldersQueryVariables = {};


export type GetLastCreatedFoldersQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & Pick<LibraryUi, 'lastCreatedFolders'>
  )> }
);

export type GetCurrentFolderIdQueryVariables = {};


export type GetCurrentFolderIdQuery = (
  { __typename?: 'Query' }
  & { library?: Maybe<(
    { __typename?: 'LibraryUI' }
    & Pick<LibraryUi, 'currentFolderId'>
  )> }
);

export type GetCurrentFolderDataQueryVariables = {
  id: Scalars['String'];
};


export type GetCurrentFolderDataQuery = (
  { __typename?: 'Query' }
  & { folderById?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'path' | 'pathIds' | 'isSamples'>
  )> }
);

type MediaNameFragment_Video_Fragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'name' | 'mediaType'>
);

type MediaNameFragment_Image_Fragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'name' | 'mediaType'>
);

type MediaNameFragment_Pdf_Fragment = (
  { __typename?: 'Pdf' }
  & Pick<Pdf, 'name' | 'mediaType'>
);

export type MediaNameFragmentFragment = MediaNameFragment_Video_Fragment | MediaNameFragment_Image_Fragment | MediaNameFragment_Pdf_Fragment;

type MediaFragment_Video_Fragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'duration' | 'width' | 'height' | 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'isSample' | 'mediaType' | 'path' | 'createdAt'>
);

type MediaFragment_Image_Fragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'width' | 'height' | 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'isSample' | 'mediaType' | 'path' | 'createdAt'>
);

type MediaFragment_Pdf_Fragment = (
  { __typename?: 'Pdf' }
  & Pick<Pdf, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'globalDescription' | 'isSample' | 'mediaType' | 'path' | 'createdAt'>
);

export type MediaFragmentFragment = MediaFragment_Video_Fragment | MediaFragment_Image_Fragment | MediaFragment_Pdf_Fragment;

export type MediaTagFragmentFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom' | 'canBeAdded'>
);

export type MediaAiTagFragmentFragment = (
  { __typename?: 'MediaAITag' }
  & Pick<MediaAiTag, 'path' | 'probability'>
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'isLeaf'>
    & MediaTagFragmentFragment
  )> }
);

export type GetMediasByIdsQueryVariables = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type GetMediasByIdsQuery = (
  { __typename?: 'Query' }
  & { mediasByIds?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'width' | 'height' | 'id' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType' | 'name' | 'createdAt' | 'isSample'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'width' | 'height' | 'id' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType' | 'name' | 'createdAt' | 'isSample'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType' | 'name' | 'createdAt' | 'isSample'>
  )>>> }
);

export type CreateMediaMutationVariables = {
  folder: Scalars['String'];
  libraryId: Scalars['String'];
  publicId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  uniqueFileName?: Maybe<Scalars['Boolean']>;
  mediaName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  showDate?: Maybe<Scalars['String']>;
};


export type CreateMediaMutation = (
  { __typename?: 'Mutation' }
  & { createMedia: (
    { __typename?: 'CreateMediaResponse' }
    & Pick<CreateMediaResponse, 'signedUrl' | 'publicId'>
    & { media?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'name'>
    ) | (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id' | 'name'>
    )> }
  ) }
);

export type CreateMediasMutationVariables = {
  createMediaParams: Array<Maybe<CreateMediaParams>>;
};


export type CreateMediasMutation = (
  { __typename?: 'Mutation' }
  & { createMedias: Array<Maybe<(
    { __typename?: 'CreateMediaResponse' }
    & Pick<CreateMediaResponse, 'signedUrl' | 'publicId' | 'failed'>
    & { media?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'name'>
    ) | (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id' | 'name'>
    )> }
  )>> }
);

export type UpdateNewMediaMutationVariables = {
  mediaParams?: Maybe<MediaParams>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type UpdateNewMediaMutation = (
  { __typename?: 'Mutation' }
  & { updateNewMedia?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name' | 'status'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name' | 'status'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name' | 'status'>
  )> }
);

export type UpdateMediaMutationVariables = {
  mediaParams?: Maybe<MediaParams>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type UpdateMediaMutation = (
  { __typename?: 'Mutation' }
  & { updateMedia?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name' | 'status'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name' | 'status'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name' | 'status'>
  )> }
);

export type AutoTagMediaMutationVariables = {
  id: Scalars['String'];
  type: Scalars['String'];
  overwrite?: Maybe<Scalars['Boolean']>;
};


export type AutoTagMediaMutation = (
  { __typename?: 'Mutation' }
  & { autoTagMedia?: Maybe<(
    { __typename?: 'AutotagResponse' }
    & Pick<AutotagResponse, 'message'>
  )> }
);

export type UpdateMediaDetailsMutationVariables = {
  mediaDetails?: Maybe<UpdateMediaTagsParams>;
};


export type UpdateMediaDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateMediaDetails?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom'>
    )>>>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'TagRelationship' }
      & Pick<TagRelationship, 'id' | 'isSampleLinked'>
      & { target: (
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      ), relations?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & MediaTagFragmentFragment
      )>> }
    )>>> }
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom'>
    )>>>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'TagRelationship' }
      & Pick<TagRelationship, 'id' | 'isSampleLinked'>
      & { target: (
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      ), relations?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & MediaTagFragmentFragment
      )>> }
    )>>> }
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom'>
    )>>>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'TagRelationship' }
      & Pick<TagRelationship, 'id' | 'isSampleLinked'>
      & { target: (
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      ), relations?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & MediaTagFragmentFragment
      )>> }
    )>>> }
  )>>> }
);

export type UpdateMediaDetailsOneByOneMutationVariables = {
  mediaDetails?: Maybe<Array<Maybe<UpdateMediaTagsParams>>>;
};


export type UpdateMediaDetailsOneByOneMutation = (
  { __typename?: 'Mutation' }
  & { updateMediaDetailsOneByOne?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom'>
    )>>>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'TagRelationship' }
      & Pick<TagRelationship, 'id' | 'isSampleLinked'>
      & { target: (
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      ), relations?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & MediaTagFragmentFragment
      )>> }
    )>>> }
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom'>
    )>>>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'TagRelationship' }
      & Pick<TagRelationship, 'id' | 'isSampleLinked'>
      & { target: (
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      ), relations?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & MediaTagFragmentFragment
      )>> }
    )>>> }
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key' | 'type' | 'isCustom'>
    )>>>, tagRelationships?: Maybe<Array<Maybe<(
      { __typename?: 'TagRelationship' }
      & Pick<TagRelationship, 'id' | 'isSampleLinked'>
      & { target: (
        { __typename?: 'Tag' }
        & MediaTagFragmentFragment
      ), relations?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & MediaTagFragmentFragment
      )>> }
    )>>> }
  )>>> }
);

export type DeleteMediasMutationVariables = {
  imageIds: Array<Maybe<Scalars['String']>>;
  videoIds: Array<Maybe<Scalars['String']>>;
};


export type DeleteMediasMutation = (
  { __typename?: 'Mutation' }
  & { setMediasAsDeleted?: Maybe<(
    { __typename?: 'DeleteMediaResult' }
    & Pick<DeleteMediaResult, 'status'>
    & { deletedMedias?: Maybe<Array<Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'status'>
    ) | (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'status'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id' | 'status'>
    )>>> }
  )> }
);

export type RemoveMediaMutationVariables = {
  id: Scalars['String'];
};


export type RemoveMediaMutation = (
  { __typename?: 'Mutation' }
  & { removeMedia?: Maybe<(
    { __typename?: 'RemoveMediaResult' }
    & Pick<RemoveMediaResult, 'status' | 'removed'>
  )> }
);

export type RemoveMediasMutationVariables = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type RemoveMediasMutation = (
  { __typename?: 'Mutation' }
  & { removeMedias?: Maybe<(
    { __typename?: 'RemoveMediaResult' }
    & Pick<RemoveMediaResult, 'status' | 'removed'>
  )> }
);

export type GetMediaDetailsQueryVariables = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type GetMediaDetailsQuery = (
  { __typename?: 'Query' }
  & { mediaDetails?: Maybe<Array<Maybe<(
    { __typename?: 'CloudFile' }
    & Pick<CloudFile, 'public_id' | 'secure_url' | 'filename' | 'width' | 'height'>
  )>>> }
);

export type SampleFieldsFragment = (
  { __typename?: 'SampleInfo' }
  & Pick<SampleInfo, 'sampleId' | 'department' | 'barcode' | 'styleNumber' | 'season' | 'collection' | 'designer' | 'sampleType' | 'gender' | 'color' | 'pattern' | 'fabric' | 'description' | 'manufacturer' | 'size' | 'sizeRange' | 'region' | 'looks' | 'misc' | 'vendor' | 'vendorDate' | 'vendorCode' | 'sampleMadeIn'>
  & { image?: Maybe<(
    { __typename?: 'SampleImage' }
    & Pick<SampleImage, 'url' | 'name'>
  )>, retailPrices?: Maybe<Array<Maybe<(
    { __typename?: 'SamplePrice' }
    & Pick<SamplePrice, 'currency' | 'value'>
  )>>>, salePrices?: Maybe<Array<Maybe<(
    { __typename?: 'SamplePrice' }
    & Pick<SamplePrice, 'currency' | 'value'>
  )>>>, customsPrices?: Maybe<Array<Maybe<(
    { __typename?: 'SamplePrice' }
    & Pick<SamplePrice, 'currency' | 'value'>
  )>>>, extraPrices?: Maybe<Array<Maybe<(
    { __typename?: 'SamplePrice' }
    & Pick<SamplePrice, 'currency' | 'value'>
  )>>> }
);

export type MediaPlacementFragmentFragment = (
  { __typename?: 'MediaPlacement' }
  & Pick<MediaPlacement, 'id' | 'databackendId' | 'date' | 'source' | 'sourceName' | 'articleLink' | 'countryId' | 'countryName' | 'continentName' | 'languageCode' | 'languageName' | 'imagesCount' | 'shortDescription' | 'miv' | 'potentialReach' | 'image' | 'placementType' | 'engagement' | 'socialName' | 'socialAccountLink' | 'socialViews'>
);

export type MergedMediaDetailInfoFragment = (
  { __typename?: 'MergedMediaDetails' }
  & Pick<MergedMediaDetails, 'id' | 'ids' | 'seasonYear' | 'createdAt' | 'showDate' | 'unlinkedSampleMedias'>
  & { tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'type' | 'key' | 'isCustom'>
  )>>>, tagRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'MergedTagRelationship' }
    & Pick<MergedTagRelationship, 'ids' | 'isSampleLinked'>
    & { target: (
      { __typename?: 'Tag' }
      & MediaTagFragmentFragment
    ), relations?: Maybe<Array<(
      { __typename?: 'Tag' }
      & MediaTagFragmentFragment
    )>> }
  )>>>, library?: Maybe<(
    { __typename?: 'Library' }
    & Pick<Library, 'id' | 'name' | 'industry'>
  )>, libraryFolders?: Maybe<(
    { __typename?: 'LibraryFolders' }
    & { collection?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, album?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, season?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )> }
  )>, rootTagsLeaves?: Maybe<Array<Maybe<(
    { __typename?: 'RootTagLeaves' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & MediaTagFragmentFragment
    )>, leaves?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & MediaTagFragmentFragment
    )>>> }
  )>>>, sampleInfo?: Maybe<(
    { __typename?: 'SampleInfo' }
    & SampleFieldsFragment
  )>, placements?: Maybe<Array<Maybe<(
    { __typename?: 'MediaPlacement' }
    & MediaPlacementFragmentFragment
  )>>>, aiTags?: Maybe<Array<Maybe<(
    { __typename?: 'MediaAITag' }
    & MediaAiTagFragmentFragment
  )>>> }
);

export type GetMergedMediaDetailQueryVariables = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type GetMergedMediaDetailQuery = (
  { __typename?: 'Query' }
  & { mergedMediaDetails?: Maybe<(
    { __typename?: 'MergedMediaDetails' }
    & MergedMediaDetailInfoFragment
  )> }
);

export type GetMergedMediaDetailsOneByOneQueryVariables = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type GetMergedMediaDetailsOneByOneQuery = (
  { __typename?: 'Query' }
  & { mergedMediaDetailsOneByOne?: Maybe<Array<Maybe<(
    { __typename?: 'MergedMediaDetails' }
    & MergedMediaDetailInfoFragment
  )>>> }
);

export type GetSamplesDepartmentsQueryVariables = {};


export type GetSamplesDepartmentsQuery = (
  { __typename?: 'Query' }
  & { samplesDepartments?: Maybe<Array<Maybe<(
    { __typename?: 'SamplesDepartment' }
    & Pick<SamplesDepartment, 'id' | 'name'>
  )>>> }
);

export type GetShowroomAnalyticsGuestsQueryVariables = {
  showroomId: Scalars['String'];
};


export type GetShowroomAnalyticsGuestsQuery = (
  { __typename?: 'Query' }
  & { showroomAnalyticsGuests?: Maybe<(
    { __typename?: 'ShowroomAnalyticsGuestsResponse' }
    & { guests?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomAnalyticsGuest' }
      & Pick<ShowroomAnalyticsGuest, 'viewerIds' | 'email' | 'visits' | 'downloads' | 'clicks'>
    )>>> }
  )> }
);

export type GetShowroomAnalyticsGuestDataQueryVariables = {
  showroomId: Scalars['String'];
  guestId: Scalars['String'];
};


export type GetShowroomAnalyticsGuestDataQuery = (
  { __typename?: 'Query' }
  & { showroomAnalyticsGuestData?: Maybe<(
    { __typename?: 'ShowroomAnalyticsGuestDataResponse' }
    & Pick<ShowroomAnalyticsGuestDataResponse, 'viewerId' | 'email' | 'visits' | 'downloads' | 'views'>
    & { downloadedMedias?: Maybe<(
      { __typename?: 'ShowroomAnalyticsGuestDownloadedMediaStats' }
      & { videos?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomAnalyticsGuestDownloadedMediaStatsMedia' }
        & Pick<ShowroomAnalyticsGuestDownloadedMediaStatsMedia, 'mediaId' | 'counter'>
      )>>>, images?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomAnalyticsGuestDownloadedMediaStatsMedia' }
        & Pick<ShowroomAnalyticsGuestDownloadedMediaStatsMedia, 'mediaId' | 'counter'>
      )>>>, pdfs?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomAnalyticsGuestDownloadedMediaStatsMedia' }
        & Pick<ShowroomAnalyticsGuestDownloadedMediaStatsMedia, 'mediaId' | 'counter'>
      )>>> }
    )>, viewedMedias?: Maybe<(
      { __typename?: 'ShowroomAnalyticsGuestViewedMediaStats' }
      & { videos?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomAnalyticsGuestViewedMediaStatsMedia' }
        & Pick<ShowroomAnalyticsGuestViewedMediaStatsMedia, 'mediaId' | 'counter'>
      )>>>, images?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomAnalyticsGuestViewedMediaStatsMedia' }
        & Pick<ShowroomAnalyticsGuestViewedMediaStatsMedia, 'mediaId' | 'counter'>
      )>>>, pdfs?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomAnalyticsGuestViewedMediaStatsMedia' }
        & Pick<ShowroomAnalyticsGuestViewedMediaStatsMedia, 'mediaId' | 'counter'>
      )>>> }
    )> }
  )> }
);

export type IsEditorModeQueryVariables = {};


export type IsEditorModeQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { editor?: Maybe<(
      { __typename?: 'ShowroomEditor' }
      & Pick<ShowroomEditor, 'isOpen' | 'showroomId' | 'step' | 'currentSection' | 'displaySectionOptions'>
      & { showroomParams?: Maybe<(
        { __typename?: 'ShowroomParams' }
        & Pick<ShowroomParams, 'name' | 'downloadEnabled' | 'downloadPdfEnabled' | 'requestSamplesEnabled' | 'samplesDepartmentId' | 'displayHeroMedia' | 'displayHeroContent' | 'language' | 'library' | 'librarySeason' | 'libraryCollection' | 'heroTitle' | 'heroContent' | 'heroFontColor' | 'heroBackgroundColor'>
        & { heroMedia?: Maybe<(
          { __typename?: 'ShowroomHeroParams' }
          & Pick<ShowroomHeroParams, 'mediaType' | 'publicId' | 'publicUrl'>
        )>, pdfs?: Maybe<Array<Maybe<(
          { __typename?: 'PdfFileUI' }
          & Pick<PdfFileUi, 'mediaType' | 'publicId' | 'publicUrl' | 'originalFilename' | 'createdAt'>
        )>>>, sections?: Maybe<Array<Maybe<(
          { __typename?: 'ShowroomSectionUI' }
          & Pick<ShowroomSectionUi, 'id' | 'name' | 'loaded' | 'loading'>
          & { rows?: Maybe<Array<Maybe<(
            { __typename?: 'SectionRowUI' }
            & { medias?: Maybe<Array<Maybe<(
              { __typename?: 'SectionFileUI' }
              & Pick<SectionFileUi, 'mediaId' | 'mediaType' | 'publicId' | 'cloudinaryVersion' | 'width' | 'height' | 'originalFilename' | 'createdAt' | 'isSample'>
            )>>> }
          )>>>, options?: Maybe<(
            { __typename?: 'ShowroomSectionOptionsUI' }
            & Pick<ShowroomSectionOptionsUi, 'spacing' | 'fullWidth' | 'displayLabel' | 'zoomLayer' | 'productLayer'>
            & { productLayerSettings?: Maybe<(
              { __typename?: 'ShowroomSectionProductLayerSettingsUI' }
              & Pick<ShowroomSectionProductLayerSettingsUi, 'galleriesFields' | 'samplesFields'>
            )>, globalSelectedFilters?: Maybe<(
              { __typename?: 'ShowroomSectionGlobalSelectedFiltersUI' }
              & Pick<ShowroomSectionGlobalSelectedFiltersUi, 'galleriesFields' | 'samplesFields'>
            )> }
          )>, rowMediaIds?: Maybe<Array<Maybe<(
            { __typename?: 'ShowroomSectionRowMediaIds' }
            & Pick<ShowroomSectionRowMediaIds, 'id' | 'mediaIds'>
          )>>> }
        )>>>, prContacts?: Maybe<Array<Maybe<(
          { __typename?: 'ShowroomPrContactUI' }
          & Pick<ShowroomPrContactUi, 'name' | 'jobTitle' | 'email' | 'phone'>
        )>>> }
      )> }
    )> }
  )> }
);

export type CreateShowroomMutationVariables = {
  name: Scalars['String'];
  params?: Maybe<ShowroomUpdateParams>;
};


export type CreateShowroomMutation = (
  { __typename?: 'Mutation' }
  & { createShowroomV2: (
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'password'>
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSection' }
      & ShowroomSectionInfoFragment
    )>>> }
    & ShowroomInfoFragment
  ) }
);

export type UpdateShowroomMutationVariables = {
  _id: Scalars['ID'];
  name: Scalars['String'];
  params?: Maybe<ShowroomUpdateParams>;
  updatePassword?: Maybe<Scalars['Boolean']>;
};


export type UpdateShowroomMutation = (
  { __typename?: 'Mutation' }
  & { updateShowroomV2: (
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'password'>
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSection' }
      & ShowroomSectionInfoWithRowMediaIdsFragment
    )>>> }
    & ShowroomInfoFragment
  ) }
);

export type UploadShowroomHeroMutationVariables = {};


export type UploadShowroomHeroMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadShowroomHero'>
);

export type GetLibrarySelectedMediasEditorQueryVariables = {};


export type GetLibrarySelectedMediasEditorQuery = (
  { __typename?: 'Query' }
  & { librarySelectedMedias?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'width' | 'height' | 'id' | 'mediaType' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'createdAt' | 'isSample'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'width' | 'height' | 'id' | 'mediaType' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'createdAt' | 'isSample'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'mediaType' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'createdAt' | 'isSample'>
  )>>> }
);

export type GetMediaSectionSamplesFieldsQueryVariables = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type GetMediaSectionSamplesFieldsQuery = (
  { __typename?: 'Query' }
  & { mediasAvailableSamplesFields?: Maybe<Array<Maybe<(
    { __typename?: 'MediasAvailableSamplesField' }
    & Pick<MediasAvailableSamplesField, 'name'>
  )>>> }
);

export type GetMediaSectionGalleriesFieldsQueryVariables = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type GetMediaSectionGalleriesFieldsQuery = (
  { __typename?: 'Query' }
  & { mediasAvailableGalleriesFields?: Maybe<Array<Maybe<(
    { __typename?: 'MediasAvailableGalleriesField' }
    & Pick<MediasAvailableGalleriesField, 'name'>
  )>>> }
);

export type GetShowroomCurrentSectionNumberQueryVariables = {};


export type GetShowroomCurrentSectionNumberQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { editor?: Maybe<(
      { __typename?: 'ShowroomEditor' }
      & Pick<ShowroomEditor, 'currentSection'>
    )> }
  )> }
);

export type GetShowroomSectionFiltersQueryVariables = {};


export type GetShowroomSectionFiltersQuery = (
  { __typename?: 'Query' }
  & { currentShowroomSection?: Maybe<(
    { __typename?: 'ShowroomSectionUI' }
    & { options?: Maybe<(
      { __typename?: 'ShowroomSectionOptionsUI' }
      & { globalSelectedFilters?: Maybe<(
        { __typename?: 'ShowroomSectionGlobalSelectedFiltersUI' }
        & Pick<ShowroomSectionGlobalSelectedFiltersUi, 'galleriesFields' | 'samplesFields'>
      )> }
    )> }
  )> }
);

export type GetShowroomSectionMediasIdsQueryVariables = {};


export type GetShowroomSectionMediasIdsQuery = (
  { __typename?: 'Query' }
  & { currentShowroomSectionMedias?: Maybe<Array<Maybe<(
    { __typename?: 'SectionFileUI' }
    & Pick<SectionFileUi, 'mediaId'>
  )>>> }
);

export type GetShowroomSectionLoadingMediaFieldsQueryVariables = {
  id: Scalars['String'];
};


export type GetShowroomSectionLoadingMediaFieldsQuery = (
  { __typename?: 'Query' }
  & { showroomSection?: Maybe<(
    { __typename?: 'ShowroomSectionUI' }
    & Pick<ShowroomSectionUi, 'loading' | 'loaded'>
    & { rowMediaIds?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSectionRowMediaIds' }
      & Pick<ShowroomSectionRowMediaIds, 'id' | 'mediaIds'>
    )>>> }
  )> }
);

export type GetShowroomGlobalFiltersQueryVariables = {
  mediaIds: Array<Maybe<Scalars['String']>>;
};


export type GetShowroomGlobalFiltersQuery = (
  { __typename?: 'Query' }
  & { showroomGlobalFilters?: Maybe<(
    { __typename?: 'ShowroomGlobalFilters' }
    & Pick<ShowroomGlobalFilters, 'samplesFilters' | 'galleriesFilters'>
  )> }
);

export type GetShowroomSearchStateQueryVariables = {};


export type GetShowroomSearchStateQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { search?: Maybe<(
      { __typename?: 'ShowroomSearch' }
      & Pick<ShowroomSearch, 'query' | 'page' | 'field' | 'direction' | 'tab' | 'selectedIds'>
    )> }
  )> }
);

export type GetShowroomSamplesBasketQueryVariables = {
  showroomId: Scalars['String'];
};


export type GetShowroomSamplesBasketQuery = (
  { __typename?: 'Query' }
  & { showroomSamplesBasket?: Maybe<Array<Maybe<(
    { __typename?: 'Video' }
    & MediaInfo_Video_Fragment
  ) | (
    { __typename?: 'Image' }
    & MediaInfo_Image_Fragment
  ) | (
    { __typename?: 'Pdf' }
    & MediaInfo_Pdf_Fragment
  )>>> }
);

export type MyShowroomMediasFragment = (
  { __typename?: 'Showroom' }
  & Pick<Showroom, 'id' | 'name'>
  & { tenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'uuid'>
  ), sections?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomSection' }
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSectionRows' }
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id'>
      ) | (
        { __typename?: 'Image' }
        & Pick<Image, 'id'>
      ) | (
        { __typename?: 'Pdf' }
        & Pick<Pdf, 'id'>
      )>>> }
    )>>> }
  )>>> }
);

export type GetShowroomSamplesFormQueryVariables = {};


export type GetShowroomSamplesFormQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { userView?: Maybe<(
      { __typename?: 'ShowroomUserViewUI' }
      & { samples?: Maybe<(
        { __typename?: 'ShowroomUserViewSamplesUi' }
        & { form?: Maybe<(
          { __typename?: 'ShowroomUserViewSamplesFormUi' }
          & Pick<ShowroomUserViewSamplesFormUi, 'deliveryDate' | 'returnDate' | 'streetAdress' | 'city' | 'zipCode' | 'country' | 'state' | 'firstName' | 'lastName' | 'jobTitle' | 'company' | 'email' | 'prefixNumber' | 'phoneNumber' | 'occasionType' | 'runThroughDate' | 'publication' | 'storyTheme' | 'eventName' | 'celebrity' | 'additionalComments' | 'shootDateStart' | 'shootDateEnd' | 'shootLocation' | 'photographer' | 'modelName'>
        )> }
      )> }
    )> }
  )> }
);

export type GetShowroomSamplesOptionsQueryVariables = {};


export type GetShowroomSamplesOptionsQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { userView?: Maybe<(
      { __typename?: 'ShowroomUserViewUI' }
      & { samples?: Maybe<(
        { __typename?: 'ShowroomUserViewSamplesUi' }
        & Pick<ShowroomUserViewSamplesUi, 'isRequestSamplesFormOpen' | 'isRequestSamplesFormValid'>
      )> }
    )> }
  )> }
);

export type RequestSamplesMutationVariables = {
  params?: Maybe<RequestSamplesParams>;
  mediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  showroomId?: Maybe<Scalars['String']>;
  showroomViewerId?: Maybe<Scalars['String']>;
};


export type RequestSamplesMutation = (
  { __typename?: 'Mutation' }
  & { requestSamples?: Maybe<(
    { __typename?: 'SampleRequest' }
    & Pick<SampleRequest, 'id' | 'requestNumber'>
  )> }
);

export type GetShowroomShareOptionsQueryVariables = {};


export type GetShowroomShareOptionsQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { userView?: Maybe<(
      { __typename?: 'ShowroomUserViewUI' }
      & { share?: Maybe<(
        { __typename?: 'ShowroomUserViewShareUI' }
        & Pick<ShowroomUserViewShareUi, 'isDialogOpen' | 'mediaQuality'>
      )> }
    )> }
  )> }
);

export type GetShowroomSelectedMediaQueryVariables = {};


export type GetShowroomSelectedMediaQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { userView?: Maybe<(
      { __typename?: 'ShowroomUserViewUI' }
      & { selectedPdfs?: Maybe<Array<Maybe<(
        { __typename?: 'ShowroomPdf' }
        & Pick<ShowroomPdf, 'publicId'>
      )>>>, selectedMedias?: Maybe<Array<Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'cloudinaryId' | 'mediaType'>
      ) | (
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'cloudinaryId' | 'mediaType'>
      ) | (
        { __typename?: 'Pdf' }
        & Pick<Pdf, 'id' | 'cloudinaryId' | 'mediaType'>
      )>>> }
    )> }
  )> }
);

export type GetShowroomSelectedTabQueryVariables = {};


export type GetShowroomSelectedTabQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { userView?: Maybe<(
      { __typename?: 'ShowroomUserViewUI' }
      & Pick<ShowroomUserViewUi, 'selectedTab'>
    )> }
  )> }
);

export type GetMobileMenuOpenedQueryVariables = {};


export type GetMobileMenuOpenedQuery = (
  { __typename?: 'Query' }
  & { showroom?: Maybe<(
    { __typename?: 'ShowroomUI' }
    & { userView?: Maybe<(
      { __typename?: 'ShowroomUserViewUI' }
      & { activeMediaMobileMenu?: Maybe<(
        { __typename?: 'MediaMobileMenu' }
        & Pick<MediaMobileMenu, 'mediaId' | 'type'>
      )> }
    )> }
  )> }
);

export type GetShowroomMediaMobileInfoQueryVariables = {
  mediaId: Scalars['String'];
};


export type GetShowroomMediaMobileInfoQuery = (
  { __typename?: 'Query' }
  & { cachedMedia?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name' | 'cloudinaryId' | 'mediaType' | 'isSample' | 'extension'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name' | 'cloudinaryId' | 'mediaType' | 'isSample' | 'extension'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'name' | 'cloudinaryId' | 'mediaType' | 'isSample' | 'extension'>
  )> }
);

type ProductLayerDetailsFields_Video_Fragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'width' | 'height' | 'duration' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'globalDescription' | 'isSample' | 'seasonYear'>
  & { library: (
    { __typename?: 'Library' }
    & Pick<Library, 'id' | 'name'>
  ), tenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name'>
  ), libraryFolders?: Maybe<(
    { __typename?: 'LibraryFolders' }
    & { collection?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, album?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, season?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )> }
  )>, sampleInfo?: Maybe<(
    { __typename?: 'SampleInfo' }
    & SampleFieldsFragment
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
  )>>>, tagRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'TagRelationship' }
    & Pick<TagRelationship, 'id' | 'isSampleLinked'>
    & { target: (
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      & { associatedTags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      )>>>, allowedRelationships?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      )>>> }
    ), relations?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key' | 'colorCode'>
      & { parent?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'type'>
      )> }
    )>> }
  )>>> }
);

type ProductLayerDetailsFields_Image_Fragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'width' | 'height' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'globalDescription' | 'isSample' | 'seasonYear'>
  & { library: (
    { __typename?: 'Library' }
    & Pick<Library, 'id' | 'name'>
  ), tenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name'>
  ), libraryFolders?: Maybe<(
    { __typename?: 'LibraryFolders' }
    & { collection?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, album?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, season?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )> }
  )>, sampleInfo?: Maybe<(
    { __typename?: 'SampleInfo' }
    & SampleFieldsFragment
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
  )>>>, tagRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'TagRelationship' }
    & Pick<TagRelationship, 'id' | 'isSampleLinked'>
    & { target: (
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      & { associatedTags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      )>>>, allowedRelationships?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      )>>> }
    ), relations?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key' | 'colorCode'>
      & { parent?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'type'>
      )> }
    )>> }
  )>>> }
);

type ProductLayerDetailsFields_Pdf_Fragment = (
  { __typename?: 'Pdf' }
  & Pick<Pdf, 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'globalDescription' | 'isSample' | 'seasonYear'>
  & { library: (
    { __typename?: 'Library' }
    & Pick<Library, 'id' | 'name'>
  ), tenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name'>
  ), libraryFolders?: Maybe<(
    { __typename?: 'LibraryFolders' }
    & { collection?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, album?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )>, season?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name'>
    )> }
  )>, sampleInfo?: Maybe<(
    { __typename?: 'SampleInfo' }
    & SampleFieldsFragment
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
  )>>>, tagRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'TagRelationship' }
    & Pick<TagRelationship, 'id' | 'isSampleLinked'>
    & { target: (
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      & { associatedTags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      )>>>, allowedRelationships?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      )>>> }
    ), relations?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key' | 'colorCode'>
      & { parent?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'type'>
      )> }
    )>> }
  )>>> }
);

export type ProductLayerDetailsFieldsFragment = ProductLayerDetailsFields_Video_Fragment | ProductLayerDetailsFields_Image_Fragment | ProductLayerDetailsFields_Pdf_Fragment;

export type GetShowroomMediaQueryVariables = {
  params: ShowroomGetParams;
  mediaId: Scalars['String'];
};


export type GetShowroomMediaQuery = (
  { __typename?: 'Query' }
  & { showroomMedia?: Maybe<(
    { __typename?: 'Video' }
    & ProductLayerDetailsFields_Video_Fragment
  ) | (
    { __typename?: 'Image' }
    & ProductLayerDetailsFields_Image_Fragment
  ) | (
    { __typename?: 'Pdf' }
    & ProductLayerDetailsFields_Pdf_Fragment
  )> }
);

type MediaInfo_Video_Fragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'width' | 'height' | 'duration' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'globalDescription' | 'isSample' | 'extension'>
);

type MediaInfo_Image_Fragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'width' | 'height' | 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'globalDescription' | 'isSample' | 'extension'>
);

type MediaInfo_Pdf_Fragment = (
  { __typename?: 'Pdf' }
  & Pick<Pdf, 'id' | 'mediaType' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'globalDescription' | 'isSample' | 'extension'>
);

export type MediaInfoFragment = MediaInfo_Video_Fragment | MediaInfo_Image_Fragment | MediaInfo_Pdf_Fragment;

export type ShowroomSectionInfoFragment = (
  { __typename?: 'ShowroomSection' }
  & Pick<ShowroomSection, 'id' | 'name'>
  & { rows?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomSectionRows' }
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'Video' }
      & MediaInfo_Video_Fragment
    ) | (
      { __typename?: 'Image' }
      & MediaInfo_Image_Fragment
    ) | (
      { __typename?: 'Pdf' }
      & MediaInfo_Pdf_Fragment
    )>>> }
  )>>>, options?: Maybe<(
    { __typename?: 'ShowroomSectionOptions' }
    & Pick<ShowroomSectionOptions, 'spacing' | 'fullWidth' | 'displayLabel' | 'zoomLayer' | 'productLayer'>
    & { productLayerSettings?: Maybe<(
      { __typename?: 'ProductLayerSettings' }
      & Pick<ProductLayerSettings, 'galleriesFields' | 'samplesFields'>
    )>, globalSelectedFilters?: Maybe<(
      { __typename?: 'GlobalSelectedFilters' }
      & Pick<GlobalSelectedFilters, 'galleriesFields' | 'samplesFields'>
    )> }
  )> }
);

export type ShowroomSectionInfoWithRowMediaIdsFragment = (
  { __typename?: 'ShowroomSection' }
  & { rowMediaIds?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomSectionRowMediaIds' }
    & Pick<ShowroomSectionRowMediaIds, 'id' | 'mediaIds'>
  )>>> }
  & ShowroomSectionInfoFragment
);

export type ShowroomInfoFragment = (
  { __typename?: 'Showroom' }
  & Pick<Showroom, 'id' | 'name' | 'downloadEnabled' | 'downloadPdfEnabled' | 'requestSamplesEnabled' | 'samplesDepartmentId' | 'displayHeroMedia' | 'displayHeroContent' | 'heroTitle' | 'heroContent' | 'heroFontColor' | 'heroBackgroundColor' | 'industry' | 'createdAt' | 'updatedAt' | 'language' | 'archive' | 'librarySeason' | 'libraryCollection' | 'hasSamples'>
  & { heroMedia?: Maybe<(
    { __typename?: 'ShowroomHeroFile' }
    & Pick<ShowroomHeroFile, 'publicId' | 'publicUrl' | 'mediaType'>
  )>, library?: Maybe<(
    { __typename?: 'Library' }
    & Pick<Library, 'id'>
    & { logo?: Maybe<(
      { __typename?: 'LogoFile' }
      & Pick<LogoFile, 'publicId' | 'publicUrl'>
    )> }
  )>, tenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'uuid' | 'name' | 'tenant' | 'EcosystemAccountID' | 'EcosystemAccountName' | 'salesforceAccountId' | 'salesforceAccountParentId' | 'isSamplesEnabled'>
    & { logo?: Maybe<(
      { __typename?: 'LogoFile' }
      & Pick<LogoFile, 'publicId' | 'publicUrl'>
    )> }
  ), pdfs?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomPdf' }
    & Pick<ShowroomPdf, 'mediaType' | 'publicId' | 'publicUrl' | 'originalFilename' | 'createdAt'>
  )>>>, prContacts?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomPrContact' }
    & Pick<ShowroomPrContact, 'id' | 'name' | 'jobTitle' | 'email' | 'phone'>
  )>>> }
);

export type GetShowroomsQueryVariables = {
  archive?: Maybe<Scalars['Boolean']>;
};


export type GetShowroomsQuery = (
  { __typename?: 'Query' }
  & { getShowrooms?: Maybe<Array<Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id' | 'name' | 'password'>
  )>>> }
);

export type GetPublicShowroomQueryVariables = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type GetPublicShowroomQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSection' }
      & ShowroomSectionInfoWithRowMediaIdsFragment
    )>>> }
    & ShowroomInfoFragment
  )> }
);

export type GetShowroomSectionsQueryVariables = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type GetShowroomSectionsQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSection' }
      & ShowroomSectionInfoWithRowMediaIdsFragment
    )>>> }
  )> }
);

export type GetPublicShowroomWithoutSectionsQueryVariables = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type GetPublicShowroomWithoutSectionsQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & ShowroomInfoFragment
  )> }
);

export type GetShowroomQueryVariables = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type GetShowroomQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'password'>
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomSection' }
      & ShowroomSectionInfoWithRowMediaIdsFragment
    )>>> }
    & ShowroomInfoFragment
  )> }
);

export type GetShowroomWithoutSectionsQueryVariables = {
  id: Scalars['String'];
  tenant?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type GetShowroomWithoutSectionsQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'password'>
    & ShowroomInfoFragment
  )> }
);

export type GetPublicShowroomFieldsQueryVariables = {
  id: Scalars['String'];
  tenant: Scalars['String'];
};


export type GetPublicShowroomFieldsQuery = (
  { __typename?: 'Query' }
  & { publicShowroomFields?: Maybe<(
    { __typename?: 'ShowroomPublicFields' }
    & Pick<ShowroomPublicFields, 'id' | 'name' | 'language' | 'libraryCollection' | 'librarySeason' | 'logo'>
    & { heroMedia?: Maybe<(
      { __typename?: 'ShowroomHeroFile' }
      & Pick<ShowroomHeroFile, 'mediaType' | 'publicId' | 'publicUrl'>
    )>, defaultBackground?: Maybe<(
      { __typename?: 'ShowroomImage' }
      & Pick<ShowroomImage, 'width' | 'height' | 'publicUrl' | 'publicId' | 'mediaType'>
    ) | (
      { __typename?: 'ShowroomVideo' }
      & Pick<ShowroomVideo, 'publicUrl' | 'publicId' | 'mediaType'>
    )> }
  )> }
);

export type DeleteShowroomMutationVariables = {
  showroomId: Scalars['String'];
};


export type DeleteShowroomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShowroom'>
);

export type DeleteShowroomsMutationVariables = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type DeleteShowroomsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShowrooms'>
);

export type UpdateShowroomsFromListMutationVariables = {
  ids: Array<Maybe<Scalars['String']>>;
  params?: Maybe<ShowroomUpdateParams>;
};


export type UpdateShowroomsFromListMutation = (
  { __typename?: 'Mutation' }
  & { updateShowrooms: Array<Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id' | 'name' | 'password'>
  )>> }
);

export type GenerateShowroomArchiveMutationVariables = {
  params: GenerateShowroomArchiveParams;
};


export type GenerateShowroomArchiveMutation = (
  { __typename?: 'Mutation' }
  & { generateShowroomArchive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'id'>
  )> }
);

export type DuplicateShowroomMutationVariables = {
  showroomId: Scalars['String'];
};


export type DuplicateShowroomMutation = (
  { __typename?: 'Mutation' }
  & { duplicateShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id' | 'name' | 'password' | 'archive'>
  )> }
);

export type GetShowroomShareInfoQueryVariables = {
  showroomId: Scalars['String'];
};


export type GetShowroomShareInfoQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id' | 'name' | 'password'>
  )> }
);

export type GetShowroomDownloadEnabledQueryVariables = {
  showroomId: Scalars['String'];
};


export type GetShowroomDownloadEnabledQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id' | 'downloadEnabled'>
  )> }
);

export type GetShowroomBasketInfoQueryVariables = {
  showroomId: Scalars['String'];
};


export type GetShowroomBasketInfoQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id' | 'requestSamplesEnabled' | 'hasSamples'>
    & { tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'isSamplesEnabled'>
    ) }
  )> }
);

export type GetShowroomSectionFiltersUserViewQueryVariables = {
  params: ShowroomGetParams;
  section: Scalars['Int'];
};


export type GetShowroomSectionFiltersUserViewQuery = (
  { __typename?: 'Query' }
  & { showroomSectionFilters?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomFilter' }
    & Pick<ShowroomFilter, 'id' | 'groups'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomFilterValue' }
      & Pick<ShowroomFilterValue, 'id' | 'groupId' | 'value' | 'mediaIds'>
    )>>> }
  )>>> }
);

export type ShowroomFilterValueInfoFragment = (
  { __typename?: 'ShowroomFilterValue' }
  & Pick<ShowroomFilterValue, 'id' | 'groupId' | 'mediaIds'>
);

export type ShowroomFilterGroupsInfoFragment = (
  { __typename?: 'ShowroomFilter' }
  & Pick<ShowroomFilter, 'id' | 'groups'>
);

export type GetShowroomSectionFilterValuesQueryVariables = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetShowroomSectionFilterValuesQuery = (
  { __typename?: 'Query' }
  & { showroomFilterValues?: Maybe<Array<Maybe<(
    { __typename?: 'ShowroomFilterValue' }
    & ShowroomFilterValueInfoFragment
  )>>> }
);

export type GetShowroomPrContactsQueryVariables = {
  showroomId: Scalars['String'];
};


export type GetShowroomPrContactsQuery = (
  { __typename?: 'Query' }
  & { getShowroom?: Maybe<(
    { __typename?: 'Showroom' }
    & Pick<Showroom, 'id'>
    & { prContacts?: Maybe<Array<Maybe<(
      { __typename?: 'ShowroomPrContact' }
      & Pick<ShowroomPrContact, 'id' | 'name' | 'jobTitle' | 'email' | 'phone'>
    )>>> }
  )> }
);

export type NewShowroomFragmentFragment = (
  { __typename?: 'Showroom' }
  & Pick<Showroom, 'id' | 'name'>
);

export type GetSpotlightDrawerStateQueryVariables = {};


export type GetSpotlightDrawerStateQuery = (
  { __typename?: 'Query' }
  & { spotlight?: Maybe<(
    { __typename?: 'SpotlightUI' }
    & { spotlightDrawer?: Maybe<(
      { __typename?: 'SpotlightDrawerUI' }
      & Pick<SpotlightDrawerUi, 'open' | 'type'>
      & { params?: Maybe<(
        { __typename?: 'SpotlightDrawerParamsUI' }
        & Pick<SpotlightDrawerParamsUi, 'id'>
      )> }
    )> }
  )> }
);

export type GetSpotlightVisitorQueryVariables = {
  id: Scalars['String'];
};


export type GetSpotlightVisitorQuery = (
  { __typename?: 'Query' }
  & { spotlightVisitor?: Maybe<(
    { __typename?: 'Visitor' }
    & VisitorFragmentFragment
  )> }
);

export type GetSpotlightMagazineQueryVariables = {
  id: Scalars['String'];
};


export type GetSpotlightMagazineQuery = (
  { __typename?: 'Query' }
  & { spotlightMagazine?: Maybe<(
    { __typename?: 'Magazine' }
    & MagazineFragmentFragment
  )> }
);

export type GetCollectionMagazineEntityVisualContentQueryVariables = {
  collectionId: Scalars['String'];
  params: VisualContentPerformanceParameters;
  limit?: Maybe<Scalars['Int']>;
};


export type GetCollectionMagazineEntityVisualContentQuery = (
  { __typename?: 'Query' }
  & { collectionEntityVisualContent?: Maybe<Array<Maybe<(
    { __typename?: 'VisualContentByDates' }
    & { visualContent?: Maybe<(
      { __typename?: 'VisualContent' }
      & { media?: Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType'>
      ) | (
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType'>
      ) | (
        { __typename?: 'Pdf' }
        & Pick<Pdf, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType'>
      )> }
    )>, metrics?: Maybe<(
      { __typename?: 'AmplificationMetrics' }
      & Pick<AmplificationMetrics, 'totalDownloads' | 'totalViews'>
    )> }
  )>>> }
);

export type GetCollectionVoiceVisualContentQueryVariables = {
  collectionId: Scalars['String'];
  voiceId: Scalars['String'];
  dates: TimeRange;
  limit?: Maybe<Scalars['Int']>;
};


export type GetCollectionVoiceVisualContentQuery = (
  { __typename?: 'Query' }
  & { collectionVoiceVisualContent?: Maybe<Array<Maybe<(
    { __typename?: 'VisualContentByDates' }
    & { visualContent?: Maybe<(
      { __typename?: 'VisualContent' }
      & { media?: Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType'>
      ) | (
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType'>
      ) | (
        { __typename?: 'Pdf' }
        & Pick<Pdf, 'id' | 'name' | 'cloudinaryId' | 'cloudinaryVersion' | 'mediaType'>
      )> }
    )>, metrics?: Maybe<(
      { __typename?: 'AmplificationMetrics' }
      & Pick<AmplificationMetrics, 'totalDownloads' | 'totalViews' | 'totalMiv' | 'totalPlacements'>
    )> }
  )>>> }
);

export type GetCollectionEntityPerformanceQueryVariables = {
  collectionId: Scalars['String'];
  params: VisualContentPerformanceParameters;
};


export type GetCollectionEntityPerformanceQuery = (
  { __typename?: 'Query' }
  & { collectionEntityPerformance?: Maybe<(
    { __typename?: 'CollectionEntityPerformance' }
    & { metrics?: Maybe<(
      { __typename?: 'AmplificationMetrics' }
      & Pick<AmplificationMetrics, 'totalMiv' | 'totalPlacements' | 'totalViews' | 'totalDownloads'>
    )>, funnel?: Maybe<(
      { __typename?: 'FunnelDataResponse' }
      & Pick<FunnelDataResponse, 'published' | 'viewed' | 'downloaded' | 'placed'>
    )> }
  )> }
);

export type GetCollectionVoicePerformanceQueryVariables = {
  collectionId: Scalars['String'];
  voiceId: Scalars['String'];
  dates: TimeRange;
};


export type GetCollectionVoicePerformanceQuery = (
  { __typename?: 'Query' }
  & { collectionVoicePerformance?: Maybe<(
    { __typename?: 'CollectionEntityPerformance' }
    & { metrics?: Maybe<(
      { __typename?: 'AmplificationMetrics' }
      & Pick<AmplificationMetrics, 'totalMiv' | 'totalPlacements' | 'totalViews' | 'totalDownloads'>
    )>, funnel?: Maybe<(
      { __typename?: 'FunnelDataResponse' }
      & Pick<FunnelDataResponse, 'published' | 'viewed' | 'downloaded' | 'placed'>
    )> }
  )> }
);

export type GetCollectionVoicePlacementsQueryVariables = {
  collectionId: Scalars['String'];
  voiceId: Scalars['String'];
  dates: TimeRange;
  limit?: Maybe<Scalars['Int']>;
};


export type GetCollectionVoicePlacementsQuery = (
  { __typename?: 'Query' }
  & { collectionVoicePlacements?: Maybe<Array<Maybe<(
    { __typename?: 'Placement' }
    & Pick<Placement, 'databackendId' | 'date' | 'articleLink' | 'source' | 'imageLink' | 'miv' | 'reach' | 'title' | 'countryCode' | 'numberOfMedias' | 'voiceId' | 'placementType' | 'engagement' | 'socialName' | 'socialAccountLink' | 'socialViews'>
  )>>> }
);

export type VisualContentModalStateQueryVariables = {};


export type VisualContentModalStateQuery = (
  { __typename?: 'Query' }
  & { spotlight?: Maybe<(
    { __typename?: 'SpotlightUI' }
    & { visualContentModal?: Maybe<(
      { __typename?: 'VisualContentModalUI' }
      & Pick<VisualContentModalUi, 'open'>
      & { visualContent?: Maybe<(
        { __typename?: 'VisualContent' }
        & VisualContentDataFragment
      )> }
    )> }
  )> }
);

export type GetMediaPlacementsByDateQueryVariables = {
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type GetMediaPlacementsByDateQuery = (
  { __typename?: 'Query' }
  & { mediaPlacementsByDate?: Maybe<Array<Maybe<(
    { __typename?: 'MediaPlacement' }
    & MediaPlacementFragmentFragment
  )>>> }
);

export type CollectionInfoQueryVariables = {
  brand?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
};


export type CollectionInfoQuery = (
  { __typename?: 'Query' }
  & { collectionInfo?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'brandName' | 'seasonName' | 'collectionName' | 'publishedAt' | 'firstMediaPublicId'>
  )> }
);

export type GetCurrentCollectionAndFiltersQueryVariables = {};


export type GetCurrentCollectionAndFiltersQuery = (
  { __typename?: 'Query' }
  & { spotlight?: Maybe<(
    { __typename?: 'SpotlightUI' }
    & { collection?: Maybe<(
      { __typename?: 'SpotlightCollectionUI' }
      & Pick<SpotlightCollectionUi, 'currentCollectionId'>
      & { filters?: Maybe<(
        { __typename?: 'SpotlightCollectionFiltersUI' }
        & Pick<SpotlightCollectionFiltersUi, 'startDate' | 'endDate'>
      )> }
    )> }
  )> }
);

export type GetCurrentCollectiondIdQueryVariables = {};


export type GetCurrentCollectiondIdQuery = (
  { __typename?: 'Query' }
  & { spotlight?: Maybe<(
    { __typename?: 'SpotlightUI' }
    & { collection?: Maybe<(
      { __typename?: 'SpotlightCollectionUI' }
      & Pick<SpotlightCollectionUi, 'currentCollectionId'>
    )> }
  )> }
);

export type GetCollectionLocalDataQueryVariables = {
  id: Scalars['String'];
};


export type GetCollectionLocalDataQuery = (
  { __typename?: 'Query' }
  & { collectionById?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'brandName' | 'seasonName' | 'collectionName' | 'publishedAt'>
  )> }
);

export type CollectionFunnelDataQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type CollectionFunnelDataQuery = (
  { __typename?: 'Query' }
  & { collectionFunnelData?: Maybe<(
    { __typename?: 'FunnelDataResponse' }
    & Pick<FunnelDataResponse, 'published' | 'viewed' | 'downloaded' | 'placed'>
  )> }
);

export type CollectionMapDataQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type CollectionMapDataQuery = (
  { __typename?: 'Query' }
  & { collectionMapData?: Maybe<(
    { __typename?: 'MapDataResponse' }
    & Pick<MapDataResponse, 'worldSource'>
    & { countries?: Maybe<Array<Maybe<(
      { __typename?: 'MapCountryValue' }
      & Pick<MapCountryValue, 'countryCode'>
      & { metrics?: Maybe<(
        { __typename?: 'AmplificationMetrics' }
        & Pick<AmplificationMetrics, 'totalMiv' | 'totalViews' | 'totalDownloads' | 'totalPlacements'>
      )> }
    )>>> }
  )> }
);

export type CollectionSummaryCountersQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type CollectionSummaryCountersQuery = (
  { __typename?: 'Query' }
  & { collectionSummaryCounters?: Maybe<(
    { __typename?: 'AmplificationMetrics' }
    & Pick<AmplificationMetrics, 'totalMiv' | 'totalDownloads' | 'totalViews' | 'totalPlacements'>
  )> }
);

export type GetSpotlightCollectionVisitorSelectedTabQueryVariables = {};


export type GetSpotlightCollectionVisitorSelectedTabQuery = (
  { __typename?: 'Query' }
  & { spotlight?: Maybe<(
    { __typename?: 'SpotlightUI' }
    & { collection?: Maybe<(
      { __typename?: 'SpotlightCollectionUI' }
      & Pick<SpotlightCollectionUi, 'visitorSelectTab'>
    )> }
  )> }
);

export type VisitorFragmentFragment = (
  { __typename?: 'Visitor' }
  & Pick<Visitor, 'id' | 'visitor' | 'jobTitle' | 'magazine' | 'magazineId' | 'country' | 'email'>
);

export type MagazineFragmentFragment = (
  { __typename?: 'Magazine' }
  & Pick<Magazine, 'id' | 'magazine' | 'country' | 'visitors'>
  & { voice?: Maybe<(
    { __typename?: 'Voice' }
    & Pick<Voice, 'id' | 'name' | 'country' | 'type'>
  )> }
);

export type MetricsFragmentFragment = (
  { __typename?: 'AmplificationMetrics' }
  & Pick<AmplificationMetrics, 'totalViews' | 'totalDownloads' | 'totalMiv' | 'totalPlacements'>
);

export type VisitorByDatesFragmentFragment = (
  { __typename?: 'VisitorByDates' }
  & Pick<VisitorByDates, 'id'>
  & { visitor?: Maybe<(
    { __typename?: 'Visitor' }
    & VisitorFragmentFragment
  )>, metrics?: Maybe<(
    { __typename?: 'AmplificationMetrics' }
    & MetricsFragmentFragment
  )> }
);

export type MagazineByDatesFragmentFragment = (
  { __typename?: 'MagazineByDates' }
  & Pick<MagazineByDates, 'id'>
  & { magazine?: Maybe<(
    { __typename?: 'Magazine' }
    & MagazineFragmentFragment
  )>, metrics?: Maybe<(
    { __typename?: 'AmplificationMetrics' }
    & MetricsFragmentFragment
  )> }
);

export type CollectionVisitorsDataQueryVariables = {
  tableFilters?: Maybe<SpotlightCollectionVisitorsTableFilters>;
};


export type CollectionVisitorsDataQuery = (
  { __typename?: 'Query' }
  & { collectionVisitorsList?: Maybe<(
    { __typename?: 'VisitorsListResponse' }
    & Pick<VisitorsListResponse, 'totalCount'>
    & { visitors?: Maybe<Array<Maybe<(
      { __typename?: 'VisitorByDates' }
      & VisitorByDatesFragmentFragment
    )>>> }
  )> }
);

export type GetCollectionTopMagazinesQueryVariables = {
  tableFilters?: Maybe<SpotlightCollectionMagazinesTableFilters>;
};


export type GetCollectionTopMagazinesQuery = (
  { __typename?: 'Query' }
  & { collectionMagazinesList?: Maybe<(
    { __typename?: 'MagazinesListResponse' }
    & { magazines?: Maybe<Array<Maybe<(
      { __typename?: 'MagazineByDates' }
      & MagazineByDatesFragmentFragment
    )>>> }
  )> }
);

export type GetCollectionMagazinesDataQueryVariables = {
  tableFilters?: Maybe<SpotlightCollectionMagazinesTableFilters>;
};


export type GetCollectionMagazinesDataQuery = (
  { __typename?: 'Query' }
  & { collectionMagazinesList?: Maybe<(
    { __typename?: 'MagazinesListResponse' }
    & Pick<MagazinesListResponse, 'totalCount'>
    & { magazines?: Maybe<Array<Maybe<(
      { __typename?: 'MagazineByDates' }
      & MagazineByDatesFragmentFragment
    )>>> }
  )> }
);

export type GetCollectionEvolutionDataQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
};


export type GetCollectionEvolutionDataQuery = (
  { __typename?: 'Query' }
  & { collectionEvolutionData?: Maybe<(
    { __typename?: 'EvolutionDataResponse' }
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'EvolutionDataValue' }
      & Pick<EvolutionDataValue, 'label' | 'miv' | 'downloads'>
    )>>> }
  )> }
);

export type VisualContentDataFragment = (
  { __typename?: 'VisualContent' }
  & Pick<VisualContent, 'mediaId' | 'cloudinaryId' | 'miv' | 'downloads' | 'views'>
  & { media?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'width' | 'height' | 'id' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'mediaType'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'width' | 'height' | 'id' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'mediaType'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'cloudinaryId' | 'cloudinaryVersion' | 'name' | 'mediaType'>
  )> }
);

export type CollectionVisualContentQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  sortBy?: Maybe<VisualContentSortingValue>;
  order?: Maybe<OrderOption>;
  page?: Maybe<Scalars['Int']>;
};


export type CollectionVisualContentQuery = (
  { __typename?: 'Query' }
  & { collectionVisualContent?: Maybe<Array<Maybe<(
    { __typename?: 'VisualContent' }
    & VisualContentDataFragment
  )>>> }
);

export type GetCollectionVisualContentListWithTotalQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  sortBy?: Maybe<VisualContentSortingValue>;
  order?: Maybe<OrderOption>;
  page?: Maybe<Scalars['Int']>;
  collectionId: Scalars['String'];
  fullList?: Maybe<Scalars['Boolean']>;
};


export type GetCollectionVisualContentListWithTotalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'collectionTotalMedias'>
  & { collectionVisualContent?: Maybe<Array<Maybe<(
    { __typename?: 'VisualContent' }
    & VisualContentDataFragment
  )>>> }
);

export type GetCollectionVisualContentListQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  sortBy?: Maybe<VisualContentSortingValue>;
  order?: Maybe<OrderOption>;
  page?: Maybe<Scalars['Int']>;
  fullList?: Maybe<Scalars['Boolean']>;
};


export type GetCollectionVisualContentListQuery = (
  { __typename?: 'Query' }
  & { collectionVisualContent?: Maybe<Array<Maybe<(
    { __typename?: 'VisualContent' }
    & VisualContentDataFragment
  )>>> }
);

export type PlacementDataFragment = (
  { __typename?: 'Placement' }
  & Pick<Placement, 'databackendId' | 'date' | 'articleLink' | 'source' | 'imageLink' | 'miv' | 'reach' | 'title' | 'countryCode' | 'numberOfMedias' | 'voiceId' | 'placementType' | 'engagement' | 'socialName' | 'socialAccountLink' | 'socialViews'>
);

export type GetCollectionPlacementListQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  sortBy?: Maybe<PlacementSortingValue>;
  order?: Maybe<OrderOption>;
  page?: Maybe<Scalars['Int']>;
};


export type GetCollectionPlacementListQuery = (
  { __typename?: 'Query' }
  & { collectionPlacements?: Maybe<Array<Maybe<(
    { __typename?: 'Placement' }
    & PlacementDataFragment
  )>>> }
);

export type GetCollectionPlacementListWithTotalQueryVariables = {
  filters?: Maybe<SpotlightCollectionFilters>;
  limit: Scalars['Int'];
  sortBy?: Maybe<PlacementSortingValue>;
  order?: Maybe<OrderOption>;
  page?: Maybe<Scalars['Int']>;
  collectionId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<TimeRange>;
};


export type GetCollectionPlacementListWithTotalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'collectionPlacementsCount'>
  & { collectionPlacements?: Maybe<Array<Maybe<(
    { __typename?: 'Placement' }
    & PlacementDataFragment
  )>>> }
);

export type GetCollectionListQueryVariables = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy?: Maybe<CollectionListSortOption>;
  order?: Maybe<OrderOption>;
};


export type GetCollectionListQuery = (
  { __typename?: 'Query' }
  & { collectionList?: Maybe<(
    { __typename?: 'CollectionListResponse' }
    & Pick<CollectionListResponse, 'totalCount'>
    & { collections?: Maybe<Array<Maybe<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'id' | 'brandName' | 'seasonName' | 'collectionName' | 'publishedAt'>
      & { metrics?: Maybe<(
        { __typename?: 'AmplificationMetrics' }
        & Pick<AmplificationMetrics, 'totalMiv' | 'totalPlacements' | 'totalViews' | 'totalDownloads'>
      )> }
    )>>> }
  )> }
);

export type GetSpotlightUiFiltersQueryVariables = {};


export type GetSpotlightUiFiltersQuery = (
  { __typename?: 'Query' }
  & { spotlight?: Maybe<(
    { __typename?: 'SpotlightUI' }
    & { collection?: Maybe<(
      { __typename?: 'SpotlightCollectionUI' }
      & { filters?: Maybe<(
        { __typename?: 'SpotlightCollectionFiltersUI' }
        & Pick<SpotlightCollectionFiltersUi, 'startDate' | 'endDate'>
      )> }
    )> }
  )> }
);

export type GetSpotlightUrlQueryVariables = {
  collectionParams?: Maybe<SpotlightSignParameters>;
};


export type GetSpotlightUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'spotlightUrl'>
);

export type SendSpotlightRequestMutationVariables = {};


export type SendSpotlightRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendSpotlightRequest'>
);

export type TagTreeFragmentFragment = (
  { __typename?: 'TagTree' }
  & { tag: (
    { __typename?: 'Tag' }
    & MediaTagFragmentFragment
  ) }
);

export type TagFragmentFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
);

export type TagNameFragmentFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'name'>
);

export type GetRootTagTreesQueryVariables = {
  types: Array<Maybe<Scalars['String']>>;
};


export type GetRootTagTreesQuery = (
  { __typename?: 'Query' }
  & { rootTagTrees?: Maybe<Array<Maybe<(
    { __typename?: 'TagTree' }
    & { childs?: Maybe<Array<Maybe<(
      { __typename?: 'TagTree' }
      & { childs?: Maybe<Array<Maybe<(
        { __typename?: 'TagTree' }
        & { childs?: Maybe<Array<Maybe<(
          { __typename?: 'TagTree' }
          & TagTreeFragmentFragment
        )>>> }
        & TagTreeFragmentFragment
      )>>> }
      & TagTreeFragmentFragment
    )>>> }
    & TagTreeFragmentFragment
  )>>> }
);

export type GetDescriptionTagsQueryVariables = {};


export type GetDescriptionTagsQuery = (
  { __typename?: 'Query' }
  & { tagsByTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TagByType' }
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      & { parent?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'key' | 'type'>
      )> }
    )>>> }
  )>>> }
);

export type GetTagsByTypesWithColorQueryVariables = {
  types: Array<Scalars['String']>;
};


export type GetTagsByTypesWithColorQuery = (
  { __typename?: 'Query' }
  & { tagsByTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TagByType' }
    & Pick<TagByType, 'type'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & { parent?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'type'>
      )>, associatedTags?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id'>
      )>>>, childs?: Maybe<Array<Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'colorCode'>
        & { parent?: Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'type'>
        )>, associatedTags?: Maybe<Array<Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id'>
        )>>> }
        & MediaTagFragmentFragment
      )>>> }
      & MediaTagFragmentFragment
    )>>> }
  )>>> }
);

export type GetAllowedRelationshipsQueryVariables = {};


export type GetAllowedRelationshipsQuery = (
  { __typename?: 'Query' }
  & { allowedRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'AllowedRelationships' }
    & Pick<AllowedRelationships, 'tagId' | 'allowedRelationshipTypes'>
  )>>> }
);

export type GetCategoryTagTreesQueryVariables = {};


export type GetCategoryTagTreesQuery = (
  { __typename?: 'Query' }
  & { tagsByTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TagByType' }
    & Pick<TagByType, 'type'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'key'>
    )>>> }
  )>>>, categoryTagTree?: Maybe<Array<Maybe<(
    { __typename?: 'TagTree' }
    & { tag: (
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
    ), childs?: Maybe<Array<Maybe<(
      { __typename?: 'TagTree' }
      & { tag: (
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
      ), childs?: Maybe<Array<Maybe<(
        { __typename?: 'TagTree' }
        & { tag: (
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'name' | 'type' | 'key'>
        ) }
      )>>> }
    )>>> }
  )>>> }
);

export type GetTagsListQueryVariables = {};


export type GetTagsListQuery = (
  { __typename?: 'Query' }
  & { tagList?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'name' | 'isCustom' | 'id' | 'type' | 'key'>
    & { translationsAndAliases?: Maybe<Array<Maybe<(
      { __typename?: 'TranslationsAndAliases' }
      & Pick<TranslationsAndAliases, 'key' | 'translation' | 'aliases'>
    )>>> }
  )>>> }
);

export type GetUserSessionQueryVariables = {};


export type GetUserSessionQuery = (
  { __typename?: 'Query' }
  & { getSession?: Maybe<(
    { __typename?: 'IsAuthenticated' }
    & Pick<IsAuthenticated, 'isAuthenticated' | 'usePortal'>
    & { sessionData?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'tenant' | 'tenantName' | 'tenantEcosystemId' | 'EcosystemAccountID' | 'EcosystemAccountName' | 'salesforceAccountId' | 'salesforceAccountParentId'>
    )>, tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'isSamplesEnabled' | 'isTagsEnabled' | 'dev' | 'name' | 'designers'>
      & { logo?: Maybe<(
        { __typename?: 'LogoFile' }
        & Pick<LogoFile, 'publicId' | 'publicUrl'>
      )>, featureFlags?: Maybe<(
        { __typename?: 'FeatureFlags' }
        & FeatureFlagsInfoFragment
      )> }
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'uuid' | 'email' | 'firstName' | 'lastName' | 'role'>
    )> }
  )> }
);

export type GetFeatureFlagsFromSessionQueryVariables = {};


export type GetFeatureFlagsFromSessionQuery = (
  { __typename?: 'Query' }
  & { getSession?: Maybe<(
    { __typename?: 'IsAuthenticated' }
    & { tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & { featureFlags?: Maybe<(
        { __typename?: 'FeatureFlags' }
        & FeatureFlagsInfoFragment
      )> }
    )> }
  )> }
);

export type GetTagsEnabledQueryVariables = {};


export type GetTagsEnabledQuery = (
  { __typename?: 'Query' }
  & { getSession?: Maybe<(
    { __typename?: 'IsAuthenticated' }
    & { tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'isTagsEnabled'>
    )> }
  )> }
);

export type GetSessionTenantQueryVariables = {};


export type GetSessionTenantQuery = (
  { __typename?: 'Query' }
  & { getSession?: Maybe<(
    { __typename?: 'IsAuthenticated' }
    & { sessionData?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'tenant'>
    )> }
  )> }
);

export type UpdateTenantMutationVariables = {
  logo?: Maybe<LogoParams>;
};


export type UpdateTenantMutation = (
  { __typename?: 'Mutation' }
  & { updateTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id'>
    & { logo?: Maybe<(
      { __typename?: 'LogoFile' }
      & Pick<LogoFile, 'publicId' | 'publicUrl'>
    )> }
  ) }
);

export type UploadTenantLogoMutationVariables = {};


export type UploadTenantLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadTenantLogo'>
);

export type GetSamplesCollectionsQueryVariables = {};


export type GetSamplesCollectionsQuery = (
  { __typename?: 'Query' }
  & { tenant?: Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'isSamplesEnabled'>
    & { samplesCollections?: Maybe<Array<Maybe<(
      { __typename?: 'SamplesCollection' }
      & Pick<SamplesCollection, 'name' | 'season' | 'designer'>
    )>>> }
  )> }
);

export type AcceptPrivacyTermsMutationVariables = {
  privacy?: Maybe<PrivacyAcceptance>;
};


export type AcceptPrivacyTermsMutation = (
  { __typename?: 'Mutation' }
  & { acceptPrivacyTerms?: Maybe<(
    { __typename?: 'UserPrivacy' }
    & Pick<UserPrivacy, 'email'>
  )> }
);

export const FeatureFlagsInfoFragmentDoc = gql`
    fragment FeatureFlagsInfo on FeatureFlags {
  AITagging
  productDetails
  tagsEnabled
  smartCategory
  showDate
  metabase
  amplification
}
    `;
export const MediaDetailsBaseContextFragmentFragmentDoc = gql`
    fragment MediaDetailsBaseContextFragment on LibraryMediaDetailsContext {
  id
  sharedMedia {
    id
    ids
    name
    mediaType
    isSample
    tags {
      id
      name
      type
      key
    }
    globalDescription
    height
    width
    orientation
  }
  originalTags
  currentTags
  addedTags
  deletedTags
  addedRelationships
  deletedRelationships
  editingMedia
}
    `;
export const MediaTagFragmentFragmentDoc = gql`
    fragment MediaTagFragment on Tag {
  id
  name
  key
  type
  isCustom
  canBeAdded
}
    `;
export const SampleFieldsFragmentDoc = gql`
    fragment SampleFields on SampleInfo {
  sampleId
  department
  barcode
  styleNumber
  season
  collection
  designer
  sampleType
  gender
  color
  pattern
  fabric
  description
  manufacturer
  size
  sizeRange
  region
  looks
  misc
  vendor
  vendorDate
  vendorCode
  sampleMadeIn
  image {
    url
    name
  }
  retailPrices {
    currency
    value
  }
  salePrices {
    currency
    value
  }
  customsPrices {
    currency
    value
  }
  extraPrices {
    currency
    value
  }
}
    `;
export const MediaPlacementFragmentFragmentDoc = gql`
    fragment MediaPlacementFragment on MediaPlacement {
  id
  databackendId
  date
  source
  sourceName
  articleLink
  countryId
  countryName
  continentName
  languageCode
  languageName
  imagesCount
  source
  shortDescription
  miv
  potentialReach
  image
  placementType
  engagement
  socialName
  socialAccountLink
  socialViews
}
    `;
export const MediaAiTagFragmentFragmentDoc = gql`
    fragment MediaAITagFragment on MediaAITag {
  tag {
    ...MediaTagFragment
    isLeaf
  }
  path
  probability
}
    ${MediaTagFragmentFragmentDoc}`;
export const MediaDetailsContextFragmentFragmentDoc = gql`
    fragment MediaDetailsContextFragment on LibraryMediaDetailsContext {
  id
  sharedMedia {
    id
    ids
    name
    mediaType
    isSample
    tags {
      id
      name
      type
      key
    }
    library {
      id
      name
      industry
    }
    libraryFolders {
      album {
        name
      }
      collection {
        name
      }
      season {
        name
      }
    }
    seasonYear
    createdAt
    showDate
    tagRelationships {
      id
      target {
        ...MediaTagFragment
      }
      relations {
        ...MediaTagFragment
      }
      isSampleLinked
    }
    rootTagsLeaves {
      leaves {
        ...MediaTagFragment
      }
      tag {
        ...MediaTagFragment
      }
    }
    globalDescription
    width
    height
    orientation
    isSample
    sampleInfo {
      ...SampleFields
    }
    placements {
      ...MediaPlacementFragment
    }
    unlinkedSampleMedias
    aiTags {
      ...MediaAITagFragment
    }
  }
  originalTags
  currentTags
  addedTags
  deletedTags
  addedRelationships
  deletedRelationships
  editingMedia
}
    ${MediaTagFragmentFragmentDoc}
${SampleFieldsFragmentDoc}
${MediaPlacementFragmentFragmentDoc}
${MediaAiTagFragmentFragmentDoc}`;
export const SamplesImportInfoFragmentDoc = gql`
    fragment SamplesImportInfo on SampleImport {
  id
  status
  galleriesBrand
  galleriesCollection
  galleriesSeason
  galleriesAlbum
}
    `;
export const NewTagFragmentDoc = gql`
    fragment NewTag on Tag {
  ...MediaTagFragment
  path
  parent {
    id
    type
  }
  allAllowedRelationships {
    id
    key
  }
  tenant {
    id
  }
  associatedTags {
    id
  }
}
    ${MediaTagFragmentFragmentDoc}`;
export const LibraryFiletreeFragmentFragmentDoc = gql`
    fragment LibraryFiletreeFragment on Library {
  filetree {
    name
    id
    path
    pathIds
    level @client
    folders {
      name
      id
      path
      pathIds
      level @client
      folders {
        name
        id
        path
        pathIds
        isSamples
        level @client
      }
    }
  }
}
    `;
export const LibraryFragmentFragmentDoc = gql`
    fragment LibraryFragment on Library {
  id
  name
  industry
  files {
    url
  }
  logo {
    publicId
    publicUrl
  }
  ...LibraryFiletreeFragment
}
    ${LibraryFiletreeFragmentFragmentDoc}`;
export const MediaNameFragmentFragmentDoc = gql`
    fragment MediaNameFragment on Media {
  name
  mediaType
}
    `;
export const MediaFragmentFragmentDoc = gql`
    fragment MediaFragment on Media {
  id
  name
  cloudinaryId
  cloudinaryVersion
  globalDescription
  isSample
  mediaType
  path
  createdAt
  ... on Image {
    width
    height
  }
  ... on Video {
    duration
    width
    height
  }
}
    `;
export const MergedMediaDetailInfoFragmentDoc = gql`
    fragment MergedMediaDetailInfo on MergedMediaDetails {
  id
  ids
  seasonYear
  createdAt
  tags {
    id
    name
    type
    key
    isCustom
  }
  tagRelationships {
    ids
    target {
      ...MediaTagFragment
    }
    relations {
      ...MediaTagFragment
    }
    isSampleLinked
  }
  showDate
  library {
    id
    name
    industry
  }
  libraryFolders {
    collection {
      name
    }
    album {
      name
    }
    season {
      name
    }
  }
  rootTagsLeaves {
    tag {
      ...MediaTagFragment
    }
    leaves {
      ...MediaTagFragment
    }
  }
  sampleInfo {
    ...SampleFields
  }
  placements {
    ...MediaPlacementFragment
  }
  unlinkedSampleMedias
  aiTags {
    ...MediaAITagFragment
  }
}
    ${MediaTagFragmentFragmentDoc}
${SampleFieldsFragmentDoc}
${MediaPlacementFragmentFragmentDoc}
${MediaAiTagFragmentFragmentDoc}`;
export const MyShowroomMediasFragmentDoc = gql`
    fragment MyShowroomMedias on Showroom {
  id
  name
  tenant {
    uuid
  }
  sections {
    rows {
      medias {
        id
      }
    }
  }
}
    `;
export const ProductLayerDetailsFieldsFragmentDoc = gql`
    fragment ProductLayerDetailsFields on Media {
  id
  mediaType
  cloudinaryId
  cloudinaryVersion
  name
  globalDescription
  isSample
  seasonYear
  library {
    id
    name
  }
  tenant {
    id
    name
  }
  libraryFolders {
    collection {
      name
    }
    album {
      name
    }
    season {
      name
    }
  }
  sampleInfo {
    ...SampleFields
  }
  ... on Image {
    width
    height
  }
  ... on Video {
    width
    height
    duration
  }
  tags {
    id
    name
    type
    key
  }
  tagRelationships {
    id
    target {
      id
      name
      type
      key
      associatedTags {
        id
        name
        type
        key
      }
      allowedRelationships {
        id
        name
        type
        key
      }
    }
    relations {
      id
      name
      type
      key
      colorCode
      parent {
        type
      }
    }
    isSampleLinked
  }
}
    ${SampleFieldsFragmentDoc}`;
export const MediaInfoFragmentDoc = gql`
    fragment MediaInfo on Media {
  id
  mediaType
  cloudinaryId
  cloudinaryVersion
  name
  globalDescription
  isSample
  extension
  ... on Image {
    width
    height
  }
  ... on Video {
    width
    height
    duration
  }
}
    `;
export const ShowroomSectionInfoFragmentDoc = gql`
    fragment ShowroomSectionInfo on ShowroomSection {
  id
  name
  rows {
    medias {
      ...MediaInfo
    }
  }
  options {
    spacing
    fullWidth
    displayLabel
    zoomLayer
    productLayer
    productLayerSettings {
      galleriesFields
      samplesFields
    }
    globalSelectedFilters {
      galleriesFields
      samplesFields
    }
  }
}
    ${MediaInfoFragmentDoc}`;
export const ShowroomSectionInfoWithRowMediaIdsFragmentDoc = gql`
    fragment ShowroomSectionInfoWithRowMediaIds on ShowroomSection {
  ...ShowroomSectionInfo
  rowMediaIds {
    id
    mediaIds
  }
}
    ${ShowroomSectionInfoFragmentDoc}`;
export const ShowroomInfoFragmentDoc = gql`
    fragment ShowroomInfo on Showroom {
  id
  name
  downloadEnabled
  downloadPdfEnabled
  requestSamplesEnabled
  samplesDepartmentId
  heroMedia {
    publicId
    publicUrl
    mediaType
  }
  displayHeroMedia
  displayHeroContent
  heroTitle
  heroContent
  heroFontColor
  heroBackgroundColor
  industry
  createdAt
  updatedAt
  language
  archive
  library {
    id
    logo {
      publicId
      publicUrl
    }
  }
  librarySeason
  libraryCollection
  tenant {
    id
    uuid
    name
    tenant
    EcosystemAccountID
    EcosystemAccountName
    salesforceAccountId
    salesforceAccountParentId
    logo {
      publicId
      publicUrl
    }
    isSamplesEnabled
  }
  hasSamples
  pdfs {
    mediaType
    publicId
    publicUrl
    originalFilename
    createdAt
  }
  prContacts {
    id
    name
    jobTitle
    email
    phone
  }
}
    `;
export const ShowroomFilterValueInfoFragmentDoc = gql`
    fragment ShowroomFilterValueInfo on ShowroomFilterValue {
  id
  groupId
  mediaIds
}
    `;
export const ShowroomFilterGroupsInfoFragmentDoc = gql`
    fragment ShowroomFilterGroupsInfo on ShowroomFilter {
  id
  groups
}
    `;
export const NewShowroomFragmentFragmentDoc = gql`
    fragment NewShowroomFragment on Showroom {
  id
  name
}
    `;
export const VisitorFragmentFragmentDoc = gql`
    fragment VisitorFragment on Visitor {
  id
  visitor
  jobTitle
  magazine
  magazineId
  country
  email
}
    `;
export const MetricsFragmentFragmentDoc = gql`
    fragment MetricsFragment on AmplificationMetrics {
  totalViews
  totalDownloads
  totalMiv
  totalPlacements
}
    `;
export const VisitorByDatesFragmentFragmentDoc = gql`
    fragment VisitorByDatesFragment on VisitorByDates {
  id
  visitor {
    ...VisitorFragment
  }
  metrics {
    ...MetricsFragment
  }
}
    ${VisitorFragmentFragmentDoc}
${MetricsFragmentFragmentDoc}`;
export const MagazineFragmentFragmentDoc = gql`
    fragment MagazineFragment on Magazine {
  id
  magazine
  voice {
    id
    name
    country
    type
  }
  country
  visitors
}
    `;
export const MagazineByDatesFragmentFragmentDoc = gql`
    fragment MagazineByDatesFragment on MagazineByDates {
  id
  magazine {
    ...MagazineFragment
  }
  metrics {
    ...MetricsFragment
  }
}
    ${MagazineFragmentFragmentDoc}
${MetricsFragmentFragmentDoc}`;
export const VisualContentDataFragmentDoc = gql`
    fragment VisualContentData on VisualContent {
  mediaId
  cloudinaryId
  miv
  downloads
  views
  media {
    id
    cloudinaryId
    cloudinaryVersion
    name
    mediaType
    ... on Image {
      width
      height
    }
    ... on Video {
      width
      height
    }
  }
}
    `;
export const PlacementDataFragmentDoc = gql`
    fragment PlacementData on Placement {
  databackendId
  date
  articleLink
  source
  imageLink
  miv
  reach
  title
  countryCode
  numberOfMedias
  voiceId
  placementType
  engagement
  socialName
  socialAccountLink
  socialViews
}
    `;
export const TagTreeFragmentFragmentDoc = gql`
    fragment TagTreeFragment on TagTree {
  tag {
    ...MediaTagFragment
  }
}
    ${MediaTagFragmentFragmentDoc}`;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on Tag {
  id
  name
  type
  key
}
    `;
export const TagNameFragmentFragmentDoc = gql`
    fragment TagNameFragment on Tag {
  name
}
    `;
export const GetContactsDocument = gql`
    query getContacts($email: String) {
  getContacts(email: $email) {
    email
  }
}
    `;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
      }
export function useGetContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = ApolloReactCommon.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetFeatureFlagsDocument = gql`
    query getFeatureFlags {
  featureFlags {
    ...FeatureFlagsInfo
  }
}
    ${FeatureFlagsInfoFragmentDoc}`;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, baseOptions);
      }
export function useGetFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, baseOptions);
        }
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>;
export const SingleUploadDocument = gql`
    mutation singleUpload($libraryId: String!, $folder: String!, $publicId: String, $uniqueFileName: Boolean) {
  singleUpload(libraryId: $libraryId, folder: $folder, publicId: $publicId, uniqueFileName: $uniqueFileName)
}
    `;
export type SingleUploadMutationFn = ApolloReactCommon.MutationFunction<SingleUploadMutation, SingleUploadMutationVariables>;

/**
 * __useSingleUploadMutation__
 *
 * To run a mutation, you first call `useSingleUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleUploadMutation, { data, loading, error }] = useSingleUploadMutation({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      folder: // value for 'folder'
 *      publicId: // value for 'publicId'
 *      uniqueFileName: // value for 'uniqueFileName'
 *   },
 * });
 */
export function useSingleUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SingleUploadMutation, SingleUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<SingleUploadMutation, SingleUploadMutationVariables>(SingleUploadDocument, baseOptions);
      }
export type SingleUploadMutationHookResult = ReturnType<typeof useSingleUploadMutation>;
export type SingleUploadMutationResult = ApolloReactCommon.MutationResult<SingleUploadMutation>;
export type SingleUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<SingleUploadMutation, SingleUploadMutationVariables>;
export const GenerateArchiveDocument = gql`
    mutation generateArchive($type: GenerateZipType!, $params: GenerateLibraryArchiveParams!) {
  generateArchive(type: $type, params: $params) {
    id
  }
}
    `;
export type GenerateArchiveMutationFn = ApolloReactCommon.MutationFunction<GenerateArchiveMutation, GenerateArchiveMutationVariables>;

/**
 * __useGenerateArchiveMutation__
 *
 * To run a mutation, you first call `useGenerateArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateArchiveMutation, { data, loading, error }] = useGenerateArchiveMutation({
 *   variables: {
 *      type: // value for 'type'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGenerateArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateArchiveMutation, GenerateArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateArchiveMutation, GenerateArchiveMutationVariables>(GenerateArchiveDocument, baseOptions);
      }
export type GenerateArchiveMutationHookResult = ReturnType<typeof useGenerateArchiveMutation>;
export type GenerateArchiveMutationResult = ApolloReactCommon.MutationResult<GenerateArchiveMutation>;
export type GenerateArchiveMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateArchiveMutation, GenerateArchiveMutationVariables>;
export const GetArchiveDocument = gql`
    query getArchive($id: String) {
  getArchive(id: $id) {
    path
    hasPassword
    pdfIds
    videoIds
    imageIds
    isExpired
    tenant {
      logo {
        publicId
        publicUrl
      }
    }
    medias {
      id
      cloudinaryId
      mediaType
    }
  }
}
    `;

/**
 * __useGetArchiveQuery__
 *
 * To run a query within a React component, call `useGetArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArchiveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArchiveQuery, GetArchiveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArchiveQuery, GetArchiveQueryVariables>(GetArchiveDocument, baseOptions);
      }
export function useGetArchiveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArchiveQuery, GetArchiveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArchiveQuery, GetArchiveQueryVariables>(GetArchiveDocument, baseOptions);
        }
export type GetArchiveQueryHookResult = ReturnType<typeof useGetArchiveQuery>;
export type GetArchiveLazyQueryHookResult = ReturnType<typeof useGetArchiveLazyQuery>;
export type GetArchiveQueryResult = ApolloReactCommon.QueryResult<GetArchiveQuery, GetArchiveQueryVariables>;
export const GetArchiveStatusDocument = gql`
    query getArchiveStatus($id: String) {
  getArchive(id: $id) {
    id
    status
  }
}
    `;

/**
 * __useGetArchiveStatusQuery__
 *
 * To run a query within a React component, call `useGetArchiveStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchiveStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchiveStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArchiveStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArchiveStatusQuery, GetArchiveStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArchiveStatusQuery, GetArchiveStatusQueryVariables>(GetArchiveStatusDocument, baseOptions);
      }
export function useGetArchiveStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArchiveStatusQuery, GetArchiveStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArchiveStatusQuery, GetArchiveStatusQueryVariables>(GetArchiveStatusDocument, baseOptions);
        }
export type GetArchiveStatusQueryHookResult = ReturnType<typeof useGetArchiveStatusQuery>;
export type GetArchiveStatusLazyQueryHookResult = ReturnType<typeof useGetArchiveStatusLazyQuery>;
export type GetArchiveStatusQueryResult = ApolloReactCommon.QueryResult<GetArchiveStatusQuery, GetArchiveStatusQueryVariables>;
export const GetZipFileDocument = gql`
    query getZipFile($id: String, $password: String) {
  archiveFiles(id: $id, password: $password) {
    url
    filename
  }
}
    `;

/**
 * __useGetZipFileQuery__
 *
 * To run a query within a React component, call `useGetZipFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZipFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZipFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetZipFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetZipFileQuery, GetZipFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetZipFileQuery, GetZipFileQueryVariables>(GetZipFileDocument, baseOptions);
      }
export function useGetZipFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetZipFileQuery, GetZipFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetZipFileQuery, GetZipFileQueryVariables>(GetZipFileDocument, baseOptions);
        }
export type GetZipFileQueryHookResult = ReturnType<typeof useGetZipFileQuery>;
export type GetZipFileLazyQueryHookResult = ReturnType<typeof useGetZipFileLazyQuery>;
export type GetZipFileQueryResult = ApolloReactCommon.QueryResult<GetZipFileQuery, GetZipFileQueryVariables>;
export const GetDeleteFolderDialogDocument = gql`
    query getDeleteFolderDialog {
  library @client {
    deleteFolderDialog {
      open
      libraryId
      path
    }
  }
}
    `;

/**
 * __useGetDeleteFolderDialogQuery__
 *
 * To run a query within a React component, call `useGetDeleteFolderDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeleteFolderDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeleteFolderDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeleteFolderDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeleteFolderDialogQuery, GetDeleteFolderDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeleteFolderDialogQuery, GetDeleteFolderDialogQueryVariables>(GetDeleteFolderDialogDocument, baseOptions);
      }
export function useGetDeleteFolderDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeleteFolderDialogQuery, GetDeleteFolderDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeleteFolderDialogQuery, GetDeleteFolderDialogQueryVariables>(GetDeleteFolderDialogDocument, baseOptions);
        }
export type GetDeleteFolderDialogQueryHookResult = ReturnType<typeof useGetDeleteFolderDialogQuery>;
export type GetDeleteFolderDialogLazyQueryHookResult = ReturnType<typeof useGetDeleteFolderDialogLazyQuery>;
export type GetDeleteFolderDialogQueryResult = ApolloReactCommon.QueryResult<GetDeleteFolderDialogQuery, GetDeleteFolderDialogQueryVariables>;
export const GetLibraryFolderFormDocument = gql`
    query getLibraryFolderForm {
  library @client {
    folderForm {
      libraryId
      open
      currentStep
      parentFolder {
        level @client
        folders {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetLibraryFolderFormQuery__
 *
 * To run a query within a React component, call `useGetLibraryFolderFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryFolderFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryFolderFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibraryFolderFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibraryFolderFormQuery, GetLibraryFolderFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibraryFolderFormQuery, GetLibraryFolderFormQueryVariables>(GetLibraryFolderFormDocument, baseOptions);
      }
export function useGetLibraryFolderFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibraryFolderFormQuery, GetLibraryFolderFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibraryFolderFormQuery, GetLibraryFolderFormQueryVariables>(GetLibraryFolderFormDocument, baseOptions);
        }
export type GetLibraryFolderFormQueryHookResult = ReturnType<typeof useGetLibraryFolderFormQuery>;
export type GetLibraryFolderFormLazyQueryHookResult = ReturnType<typeof useGetLibraryFolderFormLazyQuery>;
export type GetLibraryFolderFormQueryResult = ApolloReactCommon.QueryResult<GetLibraryFolderFormQuery, GetLibraryFolderFormQueryVariables>;
export const GetLibraryFolderFormParamsDocument = gql`
    query getLibraryFolderFormParams {
  library @client {
    folderForm {
      params {
        season
        collections
        albums
      }
    }
  }
}
    `;

/**
 * __useGetLibraryFolderFormParamsQuery__
 *
 * To run a query within a React component, call `useGetLibraryFolderFormParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryFolderFormParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryFolderFormParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibraryFolderFormParamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibraryFolderFormParamsQuery, GetLibraryFolderFormParamsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibraryFolderFormParamsQuery, GetLibraryFolderFormParamsQueryVariables>(GetLibraryFolderFormParamsDocument, baseOptions);
      }
export function useGetLibraryFolderFormParamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibraryFolderFormParamsQuery, GetLibraryFolderFormParamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibraryFolderFormParamsQuery, GetLibraryFolderFormParamsQueryVariables>(GetLibraryFolderFormParamsDocument, baseOptions);
        }
export type GetLibraryFolderFormParamsQueryHookResult = ReturnType<typeof useGetLibraryFolderFormParamsQuery>;
export type GetLibraryFolderFormParamsLazyQueryHookResult = ReturnType<typeof useGetLibraryFolderFormParamsLazyQuery>;
export type GetLibraryFolderFormParamsQueryResult = ApolloReactCommon.QueryResult<GetLibraryFolderFormParamsQuery, GetLibraryFolderFormParamsQueryVariables>;
export const GetMediaDetailModalDocument = gql`
    query getMediaDetailModal {
  library @client {
    mediaDetailModal {
      open
      mediaIds
      display
      tagPanel {
        display
      }
    }
  }
}
    `;

/**
 * __useGetMediaDetailModalQuery__
 *
 * To run a query within a React component, call `useGetMediaDetailModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDetailModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaDetailModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaDetailModalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaDetailModalQuery, GetMediaDetailModalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaDetailModalQuery, GetMediaDetailModalQueryVariables>(GetMediaDetailModalDocument, baseOptions);
      }
export function useGetMediaDetailModalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaDetailModalQuery, GetMediaDetailModalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaDetailModalQuery, GetMediaDetailModalQueryVariables>(GetMediaDetailModalDocument, baseOptions);
        }
export type GetMediaDetailModalQueryHookResult = ReturnType<typeof useGetMediaDetailModalQuery>;
export type GetMediaDetailModalLazyQueryHookResult = ReturnType<typeof useGetMediaDetailModalLazyQuery>;
export type GetMediaDetailModalQueryResult = ApolloReactCommon.QueryResult<GetMediaDetailModalQuery, GetMediaDetailModalQueryVariables>;
export const GetMediaDetailTagPanelDisplayDocument = gql`
    query getMediaDetailTagPanelDisplay {
  library @client {
    mediaDetailModal {
      tagPanel {
        display
      }
      display
    }
  }
}
    `;

/**
 * __useGetMediaDetailTagPanelDisplayQuery__
 *
 * To run a query within a React component, call `useGetMediaDetailTagPanelDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDetailTagPanelDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaDetailTagPanelDisplayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaDetailTagPanelDisplayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaDetailTagPanelDisplayQuery, GetMediaDetailTagPanelDisplayQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaDetailTagPanelDisplayQuery, GetMediaDetailTagPanelDisplayQueryVariables>(GetMediaDetailTagPanelDisplayDocument, baseOptions);
      }
export function useGetMediaDetailTagPanelDisplayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaDetailTagPanelDisplayQuery, GetMediaDetailTagPanelDisplayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaDetailTagPanelDisplayQuery, GetMediaDetailTagPanelDisplayQueryVariables>(GetMediaDetailTagPanelDisplayDocument, baseOptions);
        }
export type GetMediaDetailTagPanelDisplayQueryHookResult = ReturnType<typeof useGetMediaDetailTagPanelDisplayQuery>;
export type GetMediaDetailTagPanelDisplayLazyQueryHookResult = ReturnType<typeof useGetMediaDetailTagPanelDisplayLazyQuery>;
export type GetMediaDetailTagPanelDisplayQueryResult = ApolloReactCommon.QueryResult<GetMediaDetailTagPanelDisplayQuery, GetMediaDetailTagPanelDisplayQueryVariables>;
export const GetTagFormInitialCategoryDocument = gql`
    query getTagFormInitialCategory {
  library @client {
    mediaDetailModal {
      tagPanel {
        initialCategory
        selectedTag
        isDescriptionTag
        relationshipId
      }
    }
  }
}
    `;

/**
 * __useGetTagFormInitialCategoryQuery__
 *
 * To run a query within a React component, call `useGetTagFormInitialCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagFormInitialCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagFormInitialCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagFormInitialCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagFormInitialCategoryQuery, GetTagFormInitialCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTagFormInitialCategoryQuery, GetTagFormInitialCategoryQueryVariables>(GetTagFormInitialCategoryDocument, baseOptions);
      }
export function useGetTagFormInitialCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagFormInitialCategoryQuery, GetTagFormInitialCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTagFormInitialCategoryQuery, GetTagFormInitialCategoryQueryVariables>(GetTagFormInitialCategoryDocument, baseOptions);
        }
export type GetTagFormInitialCategoryQueryHookResult = ReturnType<typeof useGetTagFormInitialCategoryQuery>;
export type GetTagFormInitialCategoryLazyQueryHookResult = ReturnType<typeof useGetTagFormInitialCategoryLazyQuery>;
export type GetTagFormInitialCategoryQueryResult = ApolloReactCommon.QueryResult<GetTagFormInitialCategoryQuery, GetTagFormInitialCategoryQueryVariables>;
export const GetMediaDetailContextsDocument = gql`
    query getMediaDetailContexts {
  library @client {
    mediaDetailModal {
      mediaDetailsContextIds
    }
  }
}
    `;

/**
 * __useGetMediaDetailContextsQuery__
 *
 * To run a query within a React component, call `useGetMediaDetailContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDetailContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaDetailContextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaDetailContextsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaDetailContextsQuery, GetMediaDetailContextsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaDetailContextsQuery, GetMediaDetailContextsQueryVariables>(GetMediaDetailContextsDocument, baseOptions);
      }
export function useGetMediaDetailContextsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaDetailContextsQuery, GetMediaDetailContextsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaDetailContextsQuery, GetMediaDetailContextsQueryVariables>(GetMediaDetailContextsDocument, baseOptions);
        }
export type GetMediaDetailContextsQueryHookResult = ReturnType<typeof useGetMediaDetailContextsQuery>;
export type GetMediaDetailContextsLazyQueryHookResult = ReturnType<typeof useGetMediaDetailContextsLazyQuery>;
export type GetMediaDetailContextsQueryResult = ApolloReactCommon.QueryResult<GetMediaDetailContextsQuery, GetMediaDetailContextsQueryVariables>;
export const GetMediaDetailDataDocument = gql`
    query getMediaDetailData($id: String!) {
  cachedMedia(mediaId: $id) @client {
    id
    name
    cloudinaryId
    cloudinaryVersion
    globalDescription
    mediaType
    isSample
  }
}
    `;

/**
 * __useGetMediaDetailDataQuery__
 *
 * To run a query within a React component, call `useGetMediaDetailDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDetailDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaDetailDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaDetailDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaDetailDataQuery, GetMediaDetailDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaDetailDataQuery, GetMediaDetailDataQueryVariables>(GetMediaDetailDataDocument, baseOptions);
      }
export function useGetMediaDetailDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaDetailDataQuery, GetMediaDetailDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaDetailDataQuery, GetMediaDetailDataQueryVariables>(GetMediaDetailDataDocument, baseOptions);
        }
export type GetMediaDetailDataQueryHookResult = ReturnType<typeof useGetMediaDetailDataQuery>;
export type GetMediaDetailDataLazyQueryHookResult = ReturnType<typeof useGetMediaDetailDataLazyQuery>;
export type GetMediaDetailDataQueryResult = ApolloReactCommon.QueryResult<GetMediaDetailDataQuery, GetMediaDetailDataQueryVariables>;
export const GetMediaDetailContextDocument = gql`
    query getMediaDetailContext($id: String!) {
  mediaDetailContext(id: $id) @client {
    ...MediaDetailsContextFragment
  }
}
    ${MediaDetailsContextFragmentFragmentDoc}`;

/**
 * __useGetMediaDetailContextQuery__
 *
 * To run a query within a React component, call `useGetMediaDetailContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDetailContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaDetailContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaDetailContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaDetailContextQuery, GetMediaDetailContextQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaDetailContextQuery, GetMediaDetailContextQueryVariables>(GetMediaDetailContextDocument, baseOptions);
      }
export function useGetMediaDetailContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaDetailContextQuery, GetMediaDetailContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaDetailContextQuery, GetMediaDetailContextQueryVariables>(GetMediaDetailContextDocument, baseOptions);
        }
export type GetMediaDetailContextQueryHookResult = ReturnType<typeof useGetMediaDetailContextQuery>;
export type GetMediaDetailContextLazyQueryHookResult = ReturnType<typeof useGetMediaDetailContextLazyQuery>;
export type GetMediaDetailContextQueryResult = ApolloReactCommon.QueryResult<GetMediaDetailContextQuery, GetMediaDetailContextQueryVariables>;
export const GetMediaMergedDetailModalDocument = gql`
    query getMediaMergedDetailModal($ids: [String]!) {
  cachedMedias(mediaIds: $ids) @client {
    id
    name
    cloudinaryId
    cloudinaryVersion
    globalDescription
    mediaType
    ... on Image {
      width
      height
    }
    ... on Video {
      width
      height
    }
    isSample
  }
}
    `;

/**
 * __useGetMediaMergedDetailModalQuery__
 *
 * To run a query within a React component, call `useGetMediaMergedDetailModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaMergedDetailModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaMergedDetailModalQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMediaMergedDetailModalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaMergedDetailModalQuery, GetMediaMergedDetailModalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaMergedDetailModalQuery, GetMediaMergedDetailModalQueryVariables>(GetMediaMergedDetailModalDocument, baseOptions);
      }
export function useGetMediaMergedDetailModalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaMergedDetailModalQuery, GetMediaMergedDetailModalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaMergedDetailModalQuery, GetMediaMergedDetailModalQueryVariables>(GetMediaMergedDetailModalDocument, baseOptions);
        }
export type GetMediaMergedDetailModalQueryHookResult = ReturnType<typeof useGetMediaMergedDetailModalQuery>;
export type GetMediaMergedDetailModalLazyQueryHookResult = ReturnType<typeof useGetMediaMergedDetailModalLazyQuery>;
export type GetMediaMergedDetailModalQueryResult = ApolloReactCommon.QueryResult<GetMediaMergedDetailModalQuery, GetMediaMergedDetailModalQueryVariables>;
export const GetLibrarySelectedMediasUiDocument = gql`
    query getLibrarySelectedMediasUi {
  library @client {
    results {
      selectedMedias {
        id
        type
      }
    }
  }
}
    `;

/**
 * __useGetLibrarySelectedMediasUiQuery__
 *
 * To run a query within a React component, call `useGetLibrarySelectedMediasUiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibrarySelectedMediasUiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibrarySelectedMediasUiQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibrarySelectedMediasUiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibrarySelectedMediasUiQuery, GetLibrarySelectedMediasUiQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibrarySelectedMediasUiQuery, GetLibrarySelectedMediasUiQueryVariables>(GetLibrarySelectedMediasUiDocument, baseOptions);
      }
export function useGetLibrarySelectedMediasUiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibrarySelectedMediasUiQuery, GetLibrarySelectedMediasUiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibrarySelectedMediasUiQuery, GetLibrarySelectedMediasUiQueryVariables>(GetLibrarySelectedMediasUiDocument, baseOptions);
        }
export type GetLibrarySelectedMediasUiQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasUiQuery>;
export type GetLibrarySelectedMediasUiLazyQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasUiLazyQuery>;
export type GetLibrarySelectedMediasUiQueryResult = ApolloReactCommon.QueryResult<GetLibrarySelectedMediasUiQuery, GetLibrarySelectedMediasUiQueryVariables>;
export const GetLibrarySelectedMediasDocument = gql`
    query getLibrarySelectedMedias {
  librarySelectedMedias @client {
    id
    mediaType
    cloudinaryId
    isSample
    name
    createdAt
  }
}
    `;

/**
 * __useGetLibrarySelectedMediasQuery__
 *
 * To run a query within a React component, call `useGetLibrarySelectedMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibrarySelectedMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibrarySelectedMediasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibrarySelectedMediasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibrarySelectedMediasQuery, GetLibrarySelectedMediasQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibrarySelectedMediasQuery, GetLibrarySelectedMediasQueryVariables>(GetLibrarySelectedMediasDocument, baseOptions);
      }
export function useGetLibrarySelectedMediasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibrarySelectedMediasQuery, GetLibrarySelectedMediasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibrarySelectedMediasQuery, GetLibrarySelectedMediasQueryVariables>(GetLibrarySelectedMediasDocument, baseOptions);
        }
export type GetLibrarySelectedMediasQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasQuery>;
export type GetLibrarySelectedMediasLazyQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasLazyQuery>;
export type GetLibrarySelectedMediasQueryResult = ApolloReactCommon.QueryResult<GetLibrarySelectedMediasQuery, GetLibrarySelectedMediasQueryVariables>;
export const GetLibrarySelectedMediasSamplesInfoDocument = gql`
    query getLibrarySelectedMediasSamplesInfo {
  librarySelectedMedias @client {
    id
    isSample
  }
}
    `;

/**
 * __useGetLibrarySelectedMediasSamplesInfoQuery__
 *
 * To run a query within a React component, call `useGetLibrarySelectedMediasSamplesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibrarySelectedMediasSamplesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibrarySelectedMediasSamplesInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibrarySelectedMediasSamplesInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibrarySelectedMediasSamplesInfoQuery, GetLibrarySelectedMediasSamplesInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibrarySelectedMediasSamplesInfoQuery, GetLibrarySelectedMediasSamplesInfoQueryVariables>(GetLibrarySelectedMediasSamplesInfoDocument, baseOptions);
      }
export function useGetLibrarySelectedMediasSamplesInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibrarySelectedMediasSamplesInfoQuery, GetLibrarySelectedMediasSamplesInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibrarySelectedMediasSamplesInfoQuery, GetLibrarySelectedMediasSamplesInfoQueryVariables>(GetLibrarySelectedMediasSamplesInfoDocument, baseOptions);
        }
export type GetLibrarySelectedMediasSamplesInfoQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasSamplesInfoQuery>;
export type GetLibrarySelectedMediasSamplesInfoLazyQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasSamplesInfoLazyQuery>;
export type GetLibrarySelectedMediasSamplesInfoQueryResult = ApolloReactCommon.QueryResult<GetLibrarySelectedMediasSamplesInfoQuery, GetLibrarySelectedMediasSamplesInfoQueryVariables>;
export const GetLibrarySelectionModeDocument = gql`
    query getLibrarySelectionMode {
  librarySelectionModeEnabled @client
}
    `;

/**
 * __useGetLibrarySelectionModeQuery__
 *
 * To run a query within a React component, call `useGetLibrarySelectionModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibrarySelectionModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibrarySelectionModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibrarySelectionModeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibrarySelectionModeQuery, GetLibrarySelectionModeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibrarySelectionModeQuery, GetLibrarySelectionModeQueryVariables>(GetLibrarySelectionModeDocument, baseOptions);
      }
export function useGetLibrarySelectionModeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibrarySelectionModeQuery, GetLibrarySelectionModeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibrarySelectionModeQuery, GetLibrarySelectionModeQueryVariables>(GetLibrarySelectionModeDocument, baseOptions);
        }
export type GetLibrarySelectionModeQueryHookResult = ReturnType<typeof useGetLibrarySelectionModeQuery>;
export type GetLibrarySelectionModeLazyQueryHookResult = ReturnType<typeof useGetLibrarySelectionModeLazyQuery>;
export type GetLibrarySelectionModeQueryResult = ApolloReactCommon.QueryResult<GetLibrarySelectionModeQuery, GetLibrarySelectionModeQueryVariables>;
export const IsUploadDrawerOpenDocument = gql`
    query isUploadDrawerOpen {
  library @client {
    results {
      isUploadDrawerOpen
    }
  }
}
    `;

/**
 * __useIsUploadDrawerOpenQuery__
 *
 * To run a query within a React component, call `useIsUploadDrawerOpenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUploadDrawerOpenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUploadDrawerOpenQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUploadDrawerOpenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsUploadDrawerOpenQuery, IsUploadDrawerOpenQueryVariables>) {
        return ApolloReactHooks.useQuery<IsUploadDrawerOpenQuery, IsUploadDrawerOpenQueryVariables>(IsUploadDrawerOpenDocument, baseOptions);
      }
export function useIsUploadDrawerOpenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsUploadDrawerOpenQuery, IsUploadDrawerOpenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsUploadDrawerOpenQuery, IsUploadDrawerOpenQueryVariables>(IsUploadDrawerOpenDocument, baseOptions);
        }
export type IsUploadDrawerOpenQueryHookResult = ReturnType<typeof useIsUploadDrawerOpenQuery>;
export type IsUploadDrawerOpenLazyQueryHookResult = ReturnType<typeof useIsUploadDrawerOpenLazyQuery>;
export type IsUploadDrawerOpenQueryResult = ApolloReactCommon.QueryResult<IsUploadDrawerOpenQuery, IsUploadDrawerOpenQueryVariables>;
export const IsRequestSpotlightDrawerOpenDocument = gql`
    query isRequestSpotlightDrawerOpen {
  library @client {
    results {
      isRequestSpotlightDrawerOpen
    }
  }
}
    `;

/**
 * __useIsRequestSpotlightDrawerOpenQuery__
 *
 * To run a query within a React component, call `useIsRequestSpotlightDrawerOpenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRequestSpotlightDrawerOpenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRequestSpotlightDrawerOpenQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsRequestSpotlightDrawerOpenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRequestSpotlightDrawerOpenQuery, IsRequestSpotlightDrawerOpenQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRequestSpotlightDrawerOpenQuery, IsRequestSpotlightDrawerOpenQueryVariables>(IsRequestSpotlightDrawerOpenDocument, baseOptions);
      }
export function useIsRequestSpotlightDrawerOpenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRequestSpotlightDrawerOpenQuery, IsRequestSpotlightDrawerOpenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRequestSpotlightDrawerOpenQuery, IsRequestSpotlightDrawerOpenQueryVariables>(IsRequestSpotlightDrawerOpenDocument, baseOptions);
        }
export type IsRequestSpotlightDrawerOpenQueryHookResult = ReturnType<typeof useIsRequestSpotlightDrawerOpenQuery>;
export type IsRequestSpotlightDrawerOpenLazyQueryHookResult = ReturnType<typeof useIsRequestSpotlightDrawerOpenLazyQuery>;
export type IsRequestSpotlightDrawerOpenQueryResult = ApolloReactCommon.QueryResult<IsRequestSpotlightDrawerOpenQuery, IsRequestSpotlightDrawerOpenQueryVariables>;
export const GetUploadingQueueDocument = gql`
    query getUploadingQueue {
  library @client {
    results {
      uploadingQueue {
        path
        status
        libraryId
        uploadedFiles {
          mediaType
          publicId
        }
        processes {
          images
          videos
          processedFiles
          error
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetUploadingQueueQuery__
 *
 * To run a query within a React component, call `useGetUploadingQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadingQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadingQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadingQueueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUploadingQueueQuery, GetUploadingQueueQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUploadingQueueQuery, GetUploadingQueueQueryVariables>(GetUploadingQueueDocument, baseOptions);
      }
export function useGetUploadingQueueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUploadingQueueQuery, GetUploadingQueueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUploadingQueueQuery, GetUploadingQueueQueryVariables>(GetUploadingQueueDocument, baseOptions);
        }
export type GetUploadingQueueQueryHookResult = ReturnType<typeof useGetUploadingQueueQuery>;
export type GetUploadingQueueLazyQueryHookResult = ReturnType<typeof useGetUploadingQueueLazyQuery>;
export type GetUploadingQueueQueryResult = ApolloReactCommon.QueryResult<GetUploadingQueueQuery, GetUploadingQueueQueryVariables>;
export const GetShouldDisplayReloadButtonDocument = gql`
    query getShouldDisplayReloadButton {
  library @client {
    results {
      shouldDisplayReloadButton
    }
  }
}
    `;

/**
 * __useGetShouldDisplayReloadButtonQuery__
 *
 * To run a query within a React component, call `useGetShouldDisplayReloadButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShouldDisplayReloadButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShouldDisplayReloadButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShouldDisplayReloadButtonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShouldDisplayReloadButtonQuery, GetShouldDisplayReloadButtonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShouldDisplayReloadButtonQuery, GetShouldDisplayReloadButtonQueryVariables>(GetShouldDisplayReloadButtonDocument, baseOptions);
      }
export function useGetShouldDisplayReloadButtonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShouldDisplayReloadButtonQuery, GetShouldDisplayReloadButtonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShouldDisplayReloadButtonQuery, GetShouldDisplayReloadButtonQueryVariables>(GetShouldDisplayReloadButtonDocument, baseOptions);
        }
export type GetShouldDisplayReloadButtonQueryHookResult = ReturnType<typeof useGetShouldDisplayReloadButtonQuery>;
export type GetShouldDisplayReloadButtonLazyQueryHookResult = ReturnType<typeof useGetShouldDisplayReloadButtonLazyQuery>;
export type GetShouldDisplayReloadButtonQueryResult = ApolloReactCommon.QueryResult<GetShouldDisplayReloadButtonQuery, GetShouldDisplayReloadButtonQueryVariables>;
export const GetLibraryBigPreviewStatusDocument = gql`
    query getLibraryBigPreviewStatus {
  library @client {
    results {
      bigPreview
    }
  }
}
    `;

/**
 * __useGetLibraryBigPreviewStatusQuery__
 *
 * To run a query within a React component, call `useGetLibraryBigPreviewStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryBigPreviewStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryBigPreviewStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibraryBigPreviewStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibraryBigPreviewStatusQuery, GetLibraryBigPreviewStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibraryBigPreviewStatusQuery, GetLibraryBigPreviewStatusQueryVariables>(GetLibraryBigPreviewStatusDocument, baseOptions);
      }
export function useGetLibraryBigPreviewStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibraryBigPreviewStatusQuery, GetLibraryBigPreviewStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibraryBigPreviewStatusQuery, GetLibraryBigPreviewStatusQueryVariables>(GetLibraryBigPreviewStatusDocument, baseOptions);
        }
export type GetLibraryBigPreviewStatusQueryHookResult = ReturnType<typeof useGetLibraryBigPreviewStatusQuery>;
export type GetLibraryBigPreviewStatusLazyQueryHookResult = ReturnType<typeof useGetLibraryBigPreviewStatusLazyQuery>;
export type GetLibraryBigPreviewStatusQueryResult = ApolloReactCommon.QueryResult<GetLibraryBigPreviewStatusQuery, GetLibraryBigPreviewStatusQueryVariables>;
export const GetPendingSamplesImportsDocument = gql`
    query getPendingSamplesImports {
  getPendingSamplesImports {
    id
    status
    galleriesBrand
    galleriesCollection
    galleriesSeason
    galleriesAlbum
    path
  }
}
    `;

/**
 * __useGetPendingSamplesImportsQuery__
 *
 * To run a query within a React component, call `useGetPendingSamplesImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingSamplesImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingSamplesImportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingSamplesImportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPendingSamplesImportsQuery, GetPendingSamplesImportsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPendingSamplesImportsQuery, GetPendingSamplesImportsQueryVariables>(GetPendingSamplesImportsDocument, baseOptions);
      }
export function useGetPendingSamplesImportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPendingSamplesImportsQuery, GetPendingSamplesImportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPendingSamplesImportsQuery, GetPendingSamplesImportsQueryVariables>(GetPendingSamplesImportsDocument, baseOptions);
        }
export type GetPendingSamplesImportsQueryHookResult = ReturnType<typeof useGetPendingSamplesImportsQuery>;
export type GetPendingSamplesImportsLazyQueryHookResult = ReturnType<typeof useGetPendingSamplesImportsLazyQuery>;
export type GetPendingSamplesImportsQueryResult = ApolloReactCommon.QueryResult<GetPendingSamplesImportsQuery, GetPendingSamplesImportsQueryVariables>;
export const GetSamplesImportDocument = gql`
    query getSamplesImport($id: String!) {
  getSamplesImport(id: $id) {
    id
    status
    galleriesBrand
    galleriesCollection
    galleriesSeason
    galleriesAlbum
  }
}
    `;

/**
 * __useGetSamplesImportQuery__
 *
 * To run a query within a React component, call `useGetSamplesImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSamplesImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSamplesImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSamplesImportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSamplesImportQuery, GetSamplesImportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSamplesImportQuery, GetSamplesImportQueryVariables>(GetSamplesImportDocument, baseOptions);
      }
export function useGetSamplesImportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSamplesImportQuery, GetSamplesImportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSamplesImportQuery, GetSamplesImportQueryVariables>(GetSamplesImportDocument, baseOptions);
        }
export type GetSamplesImportQueryHookResult = ReturnType<typeof useGetSamplesImportQuery>;
export type GetSamplesImportLazyQueryHookResult = ReturnType<typeof useGetSamplesImportLazyQuery>;
export type GetSamplesImportQueryResult = ApolloReactCommon.QueryResult<GetSamplesImportQuery, GetSamplesImportQueryVariables>;
export const ImportSamplesDocument = gql`
    mutation importSamples($samplesDesigner: String, $samplesSeason: String, $samplesCollection: String, $galleriesBrand: String!, $galleriesSeason: String!, $galleriesCollection: String!, $galleriesAlbum: String!) {
  importSamples(samplesDesigner: $samplesDesigner, samplesSeason: $samplesSeason, samplesCollection: $samplesCollection, galleriesBrand: $galleriesBrand, galleriesSeason: $galleriesSeason, galleriesCollection: $galleriesCollection, galleriesAlbum: $galleriesAlbum) {
    id
    galleriesBrand
    galleriesSeason
    galleriesCollection
    galleriesAlbum
    status
  }
}
    `;
export type ImportSamplesMutationFn = ApolloReactCommon.MutationFunction<ImportSamplesMutation, ImportSamplesMutationVariables>;

/**
 * __useImportSamplesMutation__
 *
 * To run a mutation, you first call `useImportSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSamplesMutation, { data, loading, error }] = useImportSamplesMutation({
 *   variables: {
 *      samplesDesigner: // value for 'samplesDesigner'
 *      samplesSeason: // value for 'samplesSeason'
 *      samplesCollection: // value for 'samplesCollection'
 *      galleriesBrand: // value for 'galleriesBrand'
 *      galleriesSeason: // value for 'galleriesSeason'
 *      galleriesCollection: // value for 'galleriesCollection'
 *      galleriesAlbum: // value for 'galleriesAlbum'
 *   },
 * });
 */
export function useImportSamplesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportSamplesMutation, ImportSamplesMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportSamplesMutation, ImportSamplesMutationVariables>(ImportSamplesDocument, baseOptions);
      }
export type ImportSamplesMutationHookResult = ReturnType<typeof useImportSamplesMutation>;
export type ImportSamplesMutationResult = ApolloReactCommon.MutationResult<ImportSamplesMutation>;
export type ImportSamplesMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportSamplesMutation, ImportSamplesMutationVariables>;
export const RefreshSamplesDocument = gql`
    mutation refreshSamples($galleriesAlbum: String!) {
  refreshSamples(galleriesAlbum: $galleriesAlbum) {
    ...SamplesImportInfo
  }
}
    ${SamplesImportInfoFragmentDoc}`;
export type RefreshSamplesMutationFn = ApolloReactCommon.MutationFunction<RefreshSamplesMutation, RefreshSamplesMutationVariables>;

/**
 * __useRefreshSamplesMutation__
 *
 * To run a mutation, you first call `useRefreshSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshSamplesMutation, { data, loading, error }] = useRefreshSamplesMutation({
 *   variables: {
 *      galleriesAlbum: // value for 'galleriesAlbum'
 *   },
 * });
 */
export function useRefreshSamplesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshSamplesMutation, RefreshSamplesMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshSamplesMutation, RefreshSamplesMutationVariables>(RefreshSamplesDocument, baseOptions);
      }
export type RefreshSamplesMutationHookResult = ReturnType<typeof useRefreshSamplesMutation>;
export type RefreshSamplesMutationResult = ApolloReactCommon.MutationResult<RefreshSamplesMutation>;
export type RefreshSamplesMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshSamplesMutation, RefreshSamplesMutationVariables>;
export const GetMediasDocument = gql`
    query getMedias($libraryId: String!, $folderId: String!, $cursor: String, $size: Int, $filters: [MediaFilter], $sort: MediaSortField, $order: MediaSortOrder) {
  searchMedias(libraryId: $libraryId, folderId: $folderId, cursor: $cursor, size: $size, filters: $filters, sort: $sort, order: $order) {
    totalCount
    cursor
    searchId
    medias {
      id
      mediaType
      cloudinaryId
      cloudinaryVersion
      globalDescription
      name
      createdAt
      path
      ... on Image {
        width
        height
      }
      ... on Video {
        width
        height
        duration
      }
      isSample
    }
  }
}
    `;

/**
 * __useGetMediasQuery__
 *
 * To run a query within a React component, call `useGetMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediasQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      folderId: // value for 'folderId'
 *      cursor: // value for 'cursor'
 *      size: // value for 'size'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetMediasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediasQuery, GetMediasQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediasQuery, GetMediasQueryVariables>(GetMediasDocument, baseOptions);
      }
export function useGetMediasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediasQuery, GetMediasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediasQuery, GetMediasQueryVariables>(GetMediasDocument, baseOptions);
        }
export type GetMediasQueryHookResult = ReturnType<typeof useGetMediasQuery>;
export type GetMediasLazyQueryHookResult = ReturnType<typeof useGetMediasLazyQuery>;
export type GetMediasQueryResult = ApolloReactCommon.QueryResult<GetMediasQuery, GetMediasQueryVariables>;
export const GetUploadedMediasDocument = gql`
    query getUploadedMedias($libraryId: String!, $folderId: String!, $cursor: String, $size: Int, $filters: [MediaFilter], $sort: MediaSortField, $order: MediaSortOrder) {
  searchMedias(libraryId: $libraryId, folderId: $folderId, cursor: $cursor, size: $size, filters: $filters, sort: $sort, order: $order) {
    totalCount
    searchId
    medias {
      id
      mediaType
      cloudinaryId
      cloudinaryVersion
      globalDescription
      name
      createdAt
      path
      ... on Image {
        width
        height
      }
      ... on Video {
        width
        height
        duration
      }
      isSample
    }
  }
}
    `;

/**
 * __useGetUploadedMediasQuery__
 *
 * To run a query within a React component, call `useGetUploadedMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedMediasQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      folderId: // value for 'folderId'
 *      cursor: // value for 'cursor'
 *      size: // value for 'size'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUploadedMediasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUploadedMediasQuery, GetUploadedMediasQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUploadedMediasQuery, GetUploadedMediasQueryVariables>(GetUploadedMediasDocument, baseOptions);
      }
export function useGetUploadedMediasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUploadedMediasQuery, GetUploadedMediasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUploadedMediasQuery, GetUploadedMediasQueryVariables>(GetUploadedMediasDocument, baseOptions);
        }
export type GetUploadedMediasQueryHookResult = ReturnType<typeof useGetUploadedMediasQuery>;
export type GetUploadedMediasLazyQueryHookResult = ReturnType<typeof useGetUploadedMediasLazyQuery>;
export type GetUploadedMediasQueryResult = ApolloReactCommon.QueryResult<GetUploadedMediasQuery, GetUploadedMediasQueryVariables>;
export const GetMediasCounterDocument = gql`
    query getMediasCounter($libraryId: String!, $folderId: String!) {
  searchMedias(libraryId: $libraryId, folderId: $folderId) @client {
    totalCount
  }
}
    `;

/**
 * __useGetMediasCounterQuery__
 *
 * To run a query within a React component, call `useGetMediasCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediasCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediasCounterQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetMediasCounterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediasCounterQuery, GetMediasCounterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediasCounterQuery, GetMediasCounterQueryVariables>(GetMediasCounterDocument, baseOptions);
      }
export function useGetMediasCounterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediasCounterQuery, GetMediasCounterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediasCounterQuery, GetMediasCounterQueryVariables>(GetMediasCounterDocument, baseOptions);
        }
export type GetMediasCounterQueryHookResult = ReturnType<typeof useGetMediasCounterQuery>;
export type GetMediasCounterLazyQueryHookResult = ReturnType<typeof useGetMediasCounterLazyQuery>;
export type GetMediasCounterQueryResult = ApolloReactCommon.QueryResult<GetMediasCounterQuery, GetMediasCounterQueryVariables>;
export const GetSearchContextDocument = gql`
    query getSearchContext {
  library @client {
    currentLibraryId
    currentFolderId
    search {
      selectedFilters {
        type
        box
        byValue
      }
      selectedBoxes {
        key
        name
        toggleSelected
      }
    }
  }
}
    `;

/**
 * __useGetSearchContextQuery__
 *
 * To run a query within a React component, call `useGetSearchContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSearchContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSearchContextQuery, GetSearchContextQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSearchContextQuery, GetSearchContextQueryVariables>(GetSearchContextDocument, baseOptions);
      }
export function useGetSearchContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSearchContextQuery, GetSearchContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSearchContextQuery, GetSearchContextQueryVariables>(GetSearchContextDocument, baseOptions);
        }
export type GetSearchContextQueryHookResult = ReturnType<typeof useGetSearchContextQuery>;
export type GetSearchContextLazyQueryHookResult = ReturnType<typeof useGetSearchContextLazyQuery>;
export type GetSearchContextQueryResult = ApolloReactCommon.QueryResult<GetSearchContextQuery, GetSearchContextQueryVariables>;
export const GetSelectedFilterValuesDocument = gql`
    query getSelectedFilterValues($filterType: String!) {
  librarySelectedFilterValues(filterType: $filterType) @client {
    key
    id
    name
  }
}
    `;

/**
 * __useGetSelectedFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetSelectedFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedFilterValuesQuery({
 *   variables: {
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useGetSelectedFilterValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelectedFilterValuesQuery, GetSelectedFilterValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelectedFilterValuesQuery, GetSelectedFilterValuesQueryVariables>(GetSelectedFilterValuesDocument, baseOptions);
      }
export function useGetSelectedFilterValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelectedFilterValuesQuery, GetSelectedFilterValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelectedFilterValuesQuery, GetSelectedFilterValuesQueryVariables>(GetSelectedFilterValuesDocument, baseOptions);
        }
export type GetSelectedFilterValuesQueryHookResult = ReturnType<typeof useGetSelectedFilterValuesQuery>;
export type GetSelectedFilterValuesLazyQueryHookResult = ReturnType<typeof useGetSelectedFilterValuesLazyQuery>;
export type GetSelectedFilterValuesQueryResult = ApolloReactCommon.QueryResult<GetSelectedFilterValuesQuery, GetSelectedFilterValuesQueryVariables>;
export const GetBoxSelectedFilterValuesDocument = gql`
    query getBoxSelectedFilterValues($filterBox: String!) {
  libraryBoxSelectedFilterValues(filterBox: $filterBox) @client {
    key
    id
    name
  }
}
    `;

/**
 * __useGetBoxSelectedFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetBoxSelectedFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoxSelectedFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoxSelectedFilterValuesQuery({
 *   variables: {
 *      filterBox: // value for 'filterBox'
 *   },
 * });
 */
export function useGetBoxSelectedFilterValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBoxSelectedFilterValuesQuery, GetBoxSelectedFilterValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBoxSelectedFilterValuesQuery, GetBoxSelectedFilterValuesQueryVariables>(GetBoxSelectedFilterValuesDocument, baseOptions);
      }
export function useGetBoxSelectedFilterValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBoxSelectedFilterValuesQuery, GetBoxSelectedFilterValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBoxSelectedFilterValuesQuery, GetBoxSelectedFilterValuesQueryVariables>(GetBoxSelectedFilterValuesDocument, baseOptions);
        }
export type GetBoxSelectedFilterValuesQueryHookResult = ReturnType<typeof useGetBoxSelectedFilterValuesQuery>;
export type GetBoxSelectedFilterValuesLazyQueryHookResult = ReturnType<typeof useGetBoxSelectedFilterValuesLazyQuery>;
export type GetBoxSelectedFilterValuesQueryResult = ApolloReactCommon.QueryResult<GetBoxSelectedFilterValuesQuery, GetBoxSelectedFilterValuesQueryVariables>;
export const GetAllSelectedFilterValuesDocument = gql`
    query getAllSelectedFilterValues {
  libraryAllSelectedFilterValues @client
  library @client {
    search {
      selectedFilters {
        type
        box
        byValue
      }
    }
  }
}
    `;

/**
 * __useGetAllSelectedFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetAllSelectedFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSelectedFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSelectedFilterValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSelectedFilterValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSelectedFilterValuesQuery, GetAllSelectedFilterValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSelectedFilterValuesQuery, GetAllSelectedFilterValuesQueryVariables>(GetAllSelectedFilterValuesDocument, baseOptions);
      }
export function useGetAllSelectedFilterValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSelectedFilterValuesQuery, GetAllSelectedFilterValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSelectedFilterValuesQuery, GetAllSelectedFilterValuesQueryVariables>(GetAllSelectedFilterValuesDocument, baseOptions);
        }
export type GetAllSelectedFilterValuesQueryHookResult = ReturnType<typeof useGetAllSelectedFilterValuesQuery>;
export type GetAllSelectedFilterValuesLazyQueryHookResult = ReturnType<typeof useGetAllSelectedFilterValuesLazyQuery>;
export type GetAllSelectedFilterValuesQueryResult = ApolloReactCommon.QueryResult<GetAllSelectedFilterValuesQuery, GetAllSelectedFilterValuesQueryVariables>;
export const GetActionedFilterDocument = gql`
    query getActionedFilter {
  library @client {
    search {
      actionedFilter {
        type
        box
      }
    }
  }
}
    `;

/**
 * __useGetActionedFilterQuery__
 *
 * To run a query within a React component, call `useGetActionedFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionedFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionedFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActionedFilterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActionedFilterQuery, GetActionedFilterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActionedFilterQuery, GetActionedFilterQueryVariables>(GetActionedFilterDocument, baseOptions);
      }
export function useGetActionedFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActionedFilterQuery, GetActionedFilterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActionedFilterQuery, GetActionedFilterQueryVariables>(GetActionedFilterDocument, baseOptions);
        }
export type GetActionedFilterQueryHookResult = ReturnType<typeof useGetActionedFilterQuery>;
export type GetActionedFilterLazyQueryHookResult = ReturnType<typeof useGetActionedFilterLazyQuery>;
export type GetActionedFilterQueryResult = ApolloReactCommon.QueryResult<GetActionedFilterQuery, GetActionedFilterQueryVariables>;
export const GetFilterLoadingDocument = gql`
    query getFilterLoading {
  library @client {
    search {
      filterLoading
    }
  }
}
    `;

/**
 * __useGetFilterLoadingQuery__
 *
 * To run a query within a React component, call `useGetFilterLoadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterLoadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterLoadingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterLoadingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFilterLoadingQuery, GetFilterLoadingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFilterLoadingQuery, GetFilterLoadingQueryVariables>(GetFilterLoadingDocument, baseOptions);
      }
export function useGetFilterLoadingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFilterLoadingQuery, GetFilterLoadingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFilterLoadingQuery, GetFilterLoadingQueryVariables>(GetFilterLoadingDocument, baseOptions);
        }
export type GetFilterLoadingQueryHookResult = ReturnType<typeof useGetFilterLoadingQuery>;
export type GetFilterLoadingLazyQueryHookResult = ReturnType<typeof useGetFilterLoadingLazyQuery>;
export type GetFilterLoadingQueryResult = ApolloReactCommon.QueryResult<GetFilterLoadingQuery, GetFilterLoadingQueryVariables>;
export const GetAggregationQueryDocument = gql`
    query getAggregationQuery($libraryId: String!, $folderId: String, $tags: [String]!, $filters: [MediaFilter]) {
  aggregateMedias(libraryId: $libraryId, folderId: $folderId, tags: $tags, filters: $filters) {
    type
    byValue {
      id
      key
      count
      type
      name
    }
  }
}
    `;

/**
 * __useGetAggregationQueryQuery__
 *
 * To run a query within a React component, call `useGetAggregationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregationQueryQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      folderId: // value for 'folderId'
 *      tags: // value for 'tags'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAggregationQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAggregationQueryQuery, GetAggregationQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAggregationQueryQuery, GetAggregationQueryQueryVariables>(GetAggregationQueryDocument, baseOptions);
      }
export function useGetAggregationQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAggregationQueryQuery, GetAggregationQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAggregationQueryQuery, GetAggregationQueryQueryVariables>(GetAggregationQueryDocument, baseOptions);
        }
export type GetAggregationQueryQueryHookResult = ReturnType<typeof useGetAggregationQueryQuery>;
export type GetAggregationQueryLazyQueryHookResult = ReturnType<typeof useGetAggregationQueryLazyQuery>;
export type GetAggregationQueryQueryResult = ApolloReactCommon.QueryResult<GetAggregationQueryQuery, GetAggregationQueryQueryVariables>;
export const RootTagsDocument = gql`
    query rootTags {
  rootTags {
    id
    key
    name
    type
    childs {
      id
      key
      name
      type
    }
  }
}
    `;

/**
 * __useRootTagsQuery__
 *
 * To run a query within a React component, call `useRootTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RootTagsQuery, RootTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<RootTagsQuery, RootTagsQueryVariables>(RootTagsDocument, baseOptions);
      }
export function useRootTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RootTagsQuery, RootTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RootTagsQuery, RootTagsQueryVariables>(RootTagsDocument, baseOptions);
        }
export type RootTagsQueryHookResult = ReturnType<typeof useRootTagsQuery>;
export type RootTagsLazyQueryHookResult = ReturnType<typeof useRootTagsLazyQuery>;
export type RootTagsQueryResult = ApolloReactCommon.QueryResult<RootTagsQuery, RootTagsQueryVariables>;
export const GetTagsByTypeDocument = gql`
    query getTagsByType($type: String!) {
  tagsByType(type: $type) {
    id
    key
    name
    type
    isCustom
    canBeAdded
    parent {
      id
      type
      key
      name
      isCustom
      canBeAdded
      path
    }
  }
}
    `;

/**
 * __useGetTagsByTypeQuery__
 *
 * To run a query within a React component, call `useGetTagsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetTagsByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagsByTypeQuery, GetTagsByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTagsByTypeQuery, GetTagsByTypeQueryVariables>(GetTagsByTypeDocument, baseOptions);
      }
export function useGetTagsByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagsByTypeQuery, GetTagsByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTagsByTypeQuery, GetTagsByTypeQueryVariables>(GetTagsByTypeDocument, baseOptions);
        }
export type GetTagsByTypeQueryHookResult = ReturnType<typeof useGetTagsByTypeQuery>;
export type GetTagsByTypeLazyQueryHookResult = ReturnType<typeof useGetTagsByTypeLazyQuery>;
export type GetTagsByTypeQueryResult = ApolloReactCommon.QueryResult<GetTagsByTypeQuery, GetTagsByTypeQueryVariables>;
export const SearchTagsDocument = gql`
    query searchTags($search: String) {
  searchTags(search: $search) {
    searchDepth
    matchingTags {
      id
    }
    tagIdsList
  }
}
    `;

/**
 * __useSearchTagsQuery__
 *
 * To run a query within a React component, call `useSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, baseOptions);
      }
export function useSearchTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, baseOptions);
        }
export type SearchTagsQueryHookResult = ReturnType<typeof useSearchTagsQuery>;
export type SearchTagsLazyQueryHookResult = ReturnType<typeof useSearchTagsLazyQuery>;
export type SearchTagsQueryResult = ApolloReactCommon.QueryResult<SearchTagsQuery, SearchTagsQueryVariables>;
export const GetCategoryFilterTagsDocument = gql`
    query getCategoryFilterTags {
  categoryFilterTags {
    id
    key
    name
    type
    parent {
      id
    }
  }
}
    `;

/**
 * __useGetCategoryFilterTagsQuery__
 *
 * To run a query within a React component, call `useGetCategoryFilterTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryFilterTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryFilterTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryFilterTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoryFilterTagsQuery, GetCategoryFilterTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoryFilterTagsQuery, GetCategoryFilterTagsQueryVariables>(GetCategoryFilterTagsDocument, baseOptions);
      }
export function useGetCategoryFilterTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoryFilterTagsQuery, GetCategoryFilterTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoryFilterTagsQuery, GetCategoryFilterTagsQueryVariables>(GetCategoryFilterTagsDocument, baseOptions);
        }
export type GetCategoryFilterTagsQueryHookResult = ReturnType<typeof useGetCategoryFilterTagsQuery>;
export type GetCategoryFilterTagsLazyQueryHookResult = ReturnType<typeof useGetCategoryFilterTagsLazyQuery>;
export type GetCategoryFilterTagsQueryResult = ApolloReactCommon.QueryResult<GetCategoryFilterTagsQuery, GetCategoryFilterTagsQueryVariables>;
export const GetRepeatedTagDocument = gql`
    query getRepeatedTag($name: String!) {
  tagByName(name: $name) {
    id
    name
    key
    parent {
      id
      name
      key
      parent {
        id
        name
        key
        parent {
          id
          name
          key
        }
      }
    }
  }
}
    `;

/**
 * __useGetRepeatedTagQuery__
 *
 * To run a query within a React component, call `useGetRepeatedTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepeatedTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepeatedTagQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetRepeatedTagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRepeatedTagQuery, GetRepeatedTagQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRepeatedTagQuery, GetRepeatedTagQueryVariables>(GetRepeatedTagDocument, baseOptions);
      }
export function useGetRepeatedTagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRepeatedTagQuery, GetRepeatedTagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRepeatedTagQuery, GetRepeatedTagQueryVariables>(GetRepeatedTagDocument, baseOptions);
        }
export type GetRepeatedTagQueryHookResult = ReturnType<typeof useGetRepeatedTagQuery>;
export type GetRepeatedTagLazyQueryHookResult = ReturnType<typeof useGetRepeatedTagLazyQuery>;
export type GetRepeatedTagQueryResult = ApolloReactCommon.QueryResult<GetRepeatedTagQuery, GetRepeatedTagQueryVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($id: String!, $params: UpdateTagParams!) {
  updateTag(id: $id, params: $params) {
    id
    name
  }
}
    `;
export type UpdateTagMutationFn = ApolloReactCommon.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, baseOptions);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = ApolloReactCommon.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdateTagTranslationDocument = gql`
    mutation updateTagTranslation($id: String!, $params: UpdateTagParams!) {
  updateTagTranslation(id: $id, params: $params) {
    id
    name
  }
}
    `;
export type UpdateTagTranslationMutationFn = ApolloReactCommon.MutationFunction<UpdateTagTranslationMutation, UpdateTagTranslationMutationVariables>;

/**
 * __useUpdateTagTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateTagTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagTranslationMutation, { data, loading, error }] = useUpdateTagTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateTagTranslationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTagTranslationMutation, UpdateTagTranslationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTagTranslationMutation, UpdateTagTranslationMutationVariables>(UpdateTagTranslationDocument, baseOptions);
      }
export type UpdateTagTranslationMutationHookResult = ReturnType<typeof useUpdateTagTranslationMutation>;
export type UpdateTagTranslationMutationResult = ApolloReactCommon.MutationResult<UpdateTagTranslationMutation>;
export type UpdateTagTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTagTranslationMutation, UpdateTagTranslationMutationVariables>;
export const RemoveTagDocument = gql`
    mutation removeTag($removeTagParams: RemoveTagParams!) {
  removeTag(removeTagParams: $removeTagParams) {
    id
  }
}
    `;
export type RemoveTagMutationFn = ApolloReactCommon.MutationFunction<RemoveTagMutation, RemoveTagMutationVariables>;

/**
 * __useRemoveTagMutation__
 *
 * To run a mutation, you first call `useRemoveTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagMutation, { data, loading, error }] = useRemoveTagMutation({
 *   variables: {
 *      removeTagParams: // value for 'removeTagParams'
 *   },
 * });
 */
export function useRemoveTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTagMutation, RemoveTagMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTagMutation, RemoveTagMutationVariables>(RemoveTagDocument, baseOptions);
      }
export type RemoveTagMutationHookResult = ReturnType<typeof useRemoveTagMutation>;
export type RemoveTagMutationResult = ApolloReactCommon.MutationResult<RemoveTagMutation>;
export type RemoveTagMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTagMutation, RemoveTagMutationVariables>;
export const CountMediasWithTagDocument = gql`
    query countMediasWithTag($tagId: String, $isDescriptionTag: Boolean) {
  mediasWithTagAttachedCounter(tagId: $tagId, isDescriptionTag: $isDescriptionTag) {
    count
  }
}
    `;

/**
 * __useCountMediasWithTagQuery__
 *
 * To run a query within a React component, call `useCountMediasWithTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountMediasWithTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMediasWithTagQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      isDescriptionTag: // value for 'isDescriptionTag'
 *   },
 * });
 */
export function useCountMediasWithTagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountMediasWithTagQuery, CountMediasWithTagQueryVariables>) {
        return ApolloReactHooks.useQuery<CountMediasWithTagQuery, CountMediasWithTagQueryVariables>(CountMediasWithTagDocument, baseOptions);
      }
export function useCountMediasWithTagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountMediasWithTagQuery, CountMediasWithTagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountMediasWithTagQuery, CountMediasWithTagQueryVariables>(CountMediasWithTagDocument, baseOptions);
        }
export type CountMediasWithTagQueryHookResult = ReturnType<typeof useCountMediasWithTagQuery>;
export type CountMediasWithTagLazyQueryHookResult = ReturnType<typeof useCountMediasWithTagLazyQuery>;
export type CountMediasWithTagQueryResult = ApolloReactCommon.QueryResult<CountMediasWithTagQuery, CountMediasWithTagQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($params: CreateTagParams) {
  createTag(params: $params) {
    ...NewTag
  }
}
    ${NewTagFragmentDoc}`;
export type CreateTagMutationFn = ApolloReactCommon.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = ApolloReactCommon.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const GetEditTagDocument = gql`
    query getEditTag($id: String!, $isDescriptionTag: Boolean) {
  tagById(id: $id) {
    id
    type
    name
    parent {
      id
    }
    associatedTags {
      id
    }
    path
  }
  mediasWithTagAttachedCounter(tagId: $id, isDescriptionTag: $isDescriptionTag) {
    count
  }
}
    `;

/**
 * __useGetEditTagQuery__
 *
 * To run a query within a React component, call `useGetEditTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isDescriptionTag: // value for 'isDescriptionTag'
 *   },
 * });
 */
export function useGetEditTagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditTagQuery, GetEditTagQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditTagQuery, GetEditTagQueryVariables>(GetEditTagDocument, baseOptions);
      }
export function useGetEditTagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditTagQuery, GetEditTagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditTagQuery, GetEditTagQueryVariables>(GetEditTagDocument, baseOptions);
        }
export type GetEditTagQueryHookResult = ReturnType<typeof useGetEditTagQuery>;
export type GetEditTagLazyQueryHookResult = ReturnType<typeof useGetEditTagLazyQuery>;
export type GetEditTagQueryResult = ApolloReactCommon.QueryResult<GetEditTagQuery, GetEditTagQueryVariables>;
export const CreateLibraryDocument = gql`
    mutation createLibrary($industry: String!, $name: String!, $paths: [String]!) {
  createLibrary(industry: $industry, name: $name, paths: $paths) {
    ...LibraryFragment
  }
}
    ${LibraryFragmentFragmentDoc}`;
export type CreateLibraryMutationFn = ApolloReactCommon.MutationFunction<CreateLibraryMutation, CreateLibraryMutationVariables>;

/**
 * __useCreateLibraryMutation__
 *
 * To run a mutation, you first call `useCreateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLibraryMutation, { data, loading, error }] = useCreateLibraryMutation({
 *   variables: {
 *      industry: // value for 'industry'
 *      name: // value for 'name'
 *      paths: // value for 'paths'
 *   },
 * });
 */
export function useCreateLibraryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLibraryMutation, CreateLibraryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLibraryMutation, CreateLibraryMutationVariables>(CreateLibraryDocument, baseOptions);
      }
export type CreateLibraryMutationHookResult = ReturnType<typeof useCreateLibraryMutation>;
export type CreateLibraryMutationResult = ApolloReactCommon.MutationResult<CreateLibraryMutation>;
export type CreateLibraryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLibraryMutation, CreateLibraryMutationVariables>;
export const UpdateLibraryDocument = gql`
    mutation updateLibrary($libraryId: String!, $name: String!, $logo: LogoParams) {
  updateLibrary(libraryId: $libraryId, name: $name, logo: $logo) {
    id
    name
    logo {
      publicId
      publicUrl
    }
  }
}
    `;
export type UpdateLibraryMutationFn = ApolloReactCommon.MutationFunction<UpdateLibraryMutation, UpdateLibraryMutationVariables>;

/**
 * __useUpdateLibraryMutation__
 *
 * To run a mutation, you first call `useUpdateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLibraryMutation, { data, loading, error }] = useUpdateLibraryMutation({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateLibraryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLibraryMutation, UpdateLibraryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLibraryMutation, UpdateLibraryMutationVariables>(UpdateLibraryDocument, baseOptions);
      }
export type UpdateLibraryMutationHookResult = ReturnType<typeof useUpdateLibraryMutation>;
export type UpdateLibraryMutationResult = ApolloReactCommon.MutationResult<UpdateLibraryMutation>;
export type UpdateLibraryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLibraryMutation, UpdateLibraryMutationVariables>;
export const UploadLibraryLogoDocument = gql`
    mutation uploadLibraryLogo($libraryId: String!) {
  uploadLibraryLogo(libraryId: $libraryId)
}
    `;
export type UploadLibraryLogoMutationFn = ApolloReactCommon.MutationFunction<UploadLibraryLogoMutation, UploadLibraryLogoMutationVariables>;

/**
 * __useUploadLibraryLogoMutation__
 *
 * To run a mutation, you first call `useUploadLibraryLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLibraryLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLibraryLogoMutation, { data, loading, error }] = useUploadLibraryLogoMutation({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useUploadLibraryLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadLibraryLogoMutation, UploadLibraryLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadLibraryLogoMutation, UploadLibraryLogoMutationVariables>(UploadLibraryLogoDocument, baseOptions);
      }
export type UploadLibraryLogoMutationHookResult = ReturnType<typeof useUploadLibraryLogoMutation>;
export type UploadLibraryLogoMutationResult = ApolloReactCommon.MutationResult<UploadLibraryLogoMutation>;
export type UploadLibraryLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadLibraryLogoMutation, UploadLibraryLogoMutationVariables>;
export const RemoveLibraryDocument = gql`
    mutation removeLibrary($libraryId: ID!) {
  removeLibrary(libraryId: $libraryId) {
    libraryId
    sampleIds
  }
}
    `;
export type RemoveLibraryMutationFn = ApolloReactCommon.MutationFunction<RemoveLibraryMutation, RemoveLibraryMutationVariables>;

/**
 * __useRemoveLibraryMutation__
 *
 * To run a mutation, you first call `useRemoveLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLibraryMutation, { data, loading, error }] = useRemoveLibraryMutation({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useRemoveLibraryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLibraryMutation, RemoveLibraryMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveLibraryMutation, RemoveLibraryMutationVariables>(RemoveLibraryDocument, baseOptions);
      }
export type RemoveLibraryMutationHookResult = ReturnType<typeof useRemoveLibraryMutation>;
export type RemoveLibraryMutationResult = ApolloReactCommon.MutationResult<RemoveLibraryMutation>;
export type RemoveLibraryMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLibraryMutation, RemoveLibraryMutationVariables>;
export const CreateFoldersDocument = gql`
    mutation createFolders($libraryId: String!, $paths: [String]!, $isSamples: Boolean) {
  createFolders(libraryId: $libraryId, paths: $paths, isSamples: $isSamples) {
    id
    ...LibraryFiletreeFragment
  }
}
    ${LibraryFiletreeFragmentFragmentDoc}`;
export type CreateFoldersMutationFn = ApolloReactCommon.MutationFunction<CreateFoldersMutation, CreateFoldersMutationVariables>;

/**
 * __useCreateFoldersMutation__
 *
 * To run a mutation, you first call `useCreateFoldersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoldersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoldersMutation, { data, loading, error }] = useCreateFoldersMutation({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      paths: // value for 'paths'
 *      isSamples: // value for 'isSamples'
 *   },
 * });
 */
export function useCreateFoldersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFoldersMutation, CreateFoldersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFoldersMutation, CreateFoldersMutationVariables>(CreateFoldersDocument, baseOptions);
      }
export type CreateFoldersMutationHookResult = ReturnType<typeof useCreateFoldersMutation>;
export type CreateFoldersMutationResult = ApolloReactCommon.MutationResult<CreateFoldersMutation>;
export type CreateFoldersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFoldersMutation, CreateFoldersMutationVariables>;
export const FolderExistsDocument = gql`
    query folderExists($libraryId: String, $path: String) {
  folderExists(libraryId: $libraryId, path: $path) {
    exists
  }
}
    `;

/**
 * __useFolderExistsQuery__
 *
 * To run a query within a React component, call `useFolderExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderExistsQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useFolderExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolderExistsQuery, FolderExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<FolderExistsQuery, FolderExistsQueryVariables>(FolderExistsDocument, baseOptions);
      }
export function useFolderExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolderExistsQuery, FolderExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolderExistsQuery, FolderExistsQueryVariables>(FolderExistsDocument, baseOptions);
        }
export type FolderExistsQueryHookResult = ReturnType<typeof useFolderExistsQuery>;
export type FolderExistsLazyQueryHookResult = ReturnType<typeof useFolderExistsLazyQuery>;
export type FolderExistsQueryResult = ApolloReactCommon.QueryResult<FolderExistsQuery, FolderExistsQueryVariables>;
export const RemoveFolderDocument = gql`
    mutation removeFolder($libraryId: String!, $path: String!, $pathIds: String) {
  removeFolder(libraryId: $libraryId, path: $path, pathIds: $pathIds) {
    library {
      id
      ...LibraryFiletreeFragment
    }
    sampleIds
  }
}
    ${LibraryFiletreeFragmentFragmentDoc}`;
export type RemoveFolderMutationFn = ApolloReactCommon.MutationFunction<RemoveFolderMutation, RemoveFolderMutationVariables>;

/**
 * __useRemoveFolderMutation__
 *
 * To run a mutation, you first call `useRemoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFolderMutation, { data, loading, error }] = useRemoveFolderMutation({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      path: // value for 'path'
 *      pathIds: // value for 'pathIds'
 *   },
 * });
 */
export function useRemoveFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFolderMutation, RemoveFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFolderMutation, RemoveFolderMutationVariables>(RemoveFolderDocument, baseOptions);
      }
export type RemoveFolderMutationHookResult = ReturnType<typeof useRemoveFolderMutation>;
export type RemoveFolderMutationResult = ApolloReactCommon.MutationResult<RemoveFolderMutation>;
export type RemoveFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFolderMutation, RemoveFolderMutationVariables>;
export const GetLibrariesDocument = gql`
    query getLibraries {
  getLibraries(fields: ["id", "name", "logo"]) {
    id
    name
    logo {
      publicId
      publicUrl
    }
  }
}
    `;

/**
 * __useGetLibrariesQuery__
 *
 * To run a query within a React component, call `useGetLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibrariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibrariesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibrariesQuery, GetLibrariesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibrariesQuery, GetLibrariesQueryVariables>(GetLibrariesDocument, baseOptions);
      }
export function useGetLibrariesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibrariesQuery, GetLibrariesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibrariesQuery, GetLibrariesQueryVariables>(GetLibrariesDocument, baseOptions);
        }
export type GetLibrariesQueryHookResult = ReturnType<typeof useGetLibrariesQuery>;
export type GetLibrariesLazyQueryHookResult = ReturnType<typeof useGetLibrariesLazyQuery>;
export type GetLibrariesQueryResult = ApolloReactCommon.QueryResult<GetLibrariesQuery, GetLibrariesQueryVariables>;
export const GetCompletedLibrariesDocument = gql`
    query getCompletedLibraries {
  getLibraries {
    ...LibraryFragment
  }
}
    ${LibraryFragmentFragmentDoc}`;

/**
 * __useGetCompletedLibrariesQuery__
 *
 * To run a query within a React component, call `useGetCompletedLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedLibrariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompletedLibrariesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompletedLibrariesQuery, GetCompletedLibrariesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompletedLibrariesQuery, GetCompletedLibrariesQueryVariables>(GetCompletedLibrariesDocument, baseOptions);
      }
export function useGetCompletedLibrariesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompletedLibrariesQuery, GetCompletedLibrariesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompletedLibrariesQuery, GetCompletedLibrariesQueryVariables>(GetCompletedLibrariesDocument, baseOptions);
        }
export type GetCompletedLibrariesQueryHookResult = ReturnType<typeof useGetCompletedLibrariesQuery>;
export type GetCompletedLibrariesLazyQueryHookResult = ReturnType<typeof useGetCompletedLibrariesLazyQuery>;
export type GetCompletedLibrariesQueryResult = ApolloReactCommon.QueryResult<GetCompletedLibrariesQuery, GetCompletedLibrariesQueryVariables>;
export const GetLibraryDocument = gql`
    query getLibrary($id: String!) {
  getLibrary(id: $id) {
    ...LibraryFragment
  }
}
    ${LibraryFragmentFragmentDoc}`;

/**
 * __useGetLibraryQuery__
 *
 * To run a query within a React component, call `useGetLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLibraryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibraryQuery, GetLibraryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibraryQuery, GetLibraryQueryVariables>(GetLibraryDocument, baseOptions);
      }
export function useGetLibraryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibraryQuery, GetLibraryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibraryQuery, GetLibraryQueryVariables>(GetLibraryDocument, baseOptions);
        }
export type GetLibraryQueryHookResult = ReturnType<typeof useGetLibraryQuery>;
export type GetLibraryLazyQueryHookResult = ReturnType<typeof useGetLibraryLazyQuery>;
export type GetLibraryQueryResult = ApolloReactCommon.QueryResult<GetLibraryQuery, GetLibraryQueryVariables>;
export const GetCurrentLibraryIdDocument = gql`
    query getCurrentLibraryId {
  library @client {
    currentLibraryId
  }
}
    `;

/**
 * __useGetCurrentLibraryIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentLibraryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentLibraryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentLibraryIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentLibraryIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentLibraryIdQuery, GetCurrentLibraryIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentLibraryIdQuery, GetCurrentLibraryIdQueryVariables>(GetCurrentLibraryIdDocument, baseOptions);
      }
export function useGetCurrentLibraryIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentLibraryIdQuery, GetCurrentLibraryIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentLibraryIdQuery, GetCurrentLibraryIdQueryVariables>(GetCurrentLibraryIdDocument, baseOptions);
        }
export type GetCurrentLibraryIdQueryHookResult = ReturnType<typeof useGetCurrentLibraryIdQuery>;
export type GetCurrentLibraryIdLazyQueryHookResult = ReturnType<typeof useGetCurrentLibraryIdLazyQuery>;
export type GetCurrentLibraryIdQueryResult = ApolloReactCommon.QueryResult<GetCurrentLibraryIdQuery, GetCurrentLibraryIdQueryVariables>;
export const GetLastCreatedFoldersDocument = gql`
    query getLastCreatedFolders {
  library @client {
    lastCreatedFolders
  }
}
    `;

/**
 * __useGetLastCreatedFoldersQuery__
 *
 * To run a query within a React component, call `useGetLastCreatedFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastCreatedFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastCreatedFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastCreatedFoldersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastCreatedFoldersQuery, GetLastCreatedFoldersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLastCreatedFoldersQuery, GetLastCreatedFoldersQueryVariables>(GetLastCreatedFoldersDocument, baseOptions);
      }
export function useGetLastCreatedFoldersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastCreatedFoldersQuery, GetLastCreatedFoldersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLastCreatedFoldersQuery, GetLastCreatedFoldersQueryVariables>(GetLastCreatedFoldersDocument, baseOptions);
        }
export type GetLastCreatedFoldersQueryHookResult = ReturnType<typeof useGetLastCreatedFoldersQuery>;
export type GetLastCreatedFoldersLazyQueryHookResult = ReturnType<typeof useGetLastCreatedFoldersLazyQuery>;
export type GetLastCreatedFoldersQueryResult = ApolloReactCommon.QueryResult<GetLastCreatedFoldersQuery, GetLastCreatedFoldersQueryVariables>;
export const GetCurrentFolderIdDocument = gql`
    query getCurrentFolderId {
  library @client {
    currentFolderId
  }
}
    `;

/**
 * __useGetCurrentFolderIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentFolderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentFolderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentFolderIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentFolderIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentFolderIdQuery, GetCurrentFolderIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentFolderIdQuery, GetCurrentFolderIdQueryVariables>(GetCurrentFolderIdDocument, baseOptions);
      }
export function useGetCurrentFolderIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentFolderIdQuery, GetCurrentFolderIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentFolderIdQuery, GetCurrentFolderIdQueryVariables>(GetCurrentFolderIdDocument, baseOptions);
        }
export type GetCurrentFolderIdQueryHookResult = ReturnType<typeof useGetCurrentFolderIdQuery>;
export type GetCurrentFolderIdLazyQueryHookResult = ReturnType<typeof useGetCurrentFolderIdLazyQuery>;
export type GetCurrentFolderIdQueryResult = ApolloReactCommon.QueryResult<GetCurrentFolderIdQuery, GetCurrentFolderIdQueryVariables>;
export const GetCurrentFolderDataDocument = gql`
    query getCurrentFolderData($id: String!) {
  folderById(id: $id) @client {
    id
    name
    path
    pathIds
    isSamples
  }
}
    `;

/**
 * __useGetCurrentFolderDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentFolderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentFolderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentFolderDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentFolderDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentFolderDataQuery, GetCurrentFolderDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentFolderDataQuery, GetCurrentFolderDataQueryVariables>(GetCurrentFolderDataDocument, baseOptions);
      }
export function useGetCurrentFolderDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentFolderDataQuery, GetCurrentFolderDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentFolderDataQuery, GetCurrentFolderDataQueryVariables>(GetCurrentFolderDataDocument, baseOptions);
        }
export type GetCurrentFolderDataQueryHookResult = ReturnType<typeof useGetCurrentFolderDataQuery>;
export type GetCurrentFolderDataLazyQueryHookResult = ReturnType<typeof useGetCurrentFolderDataLazyQuery>;
export type GetCurrentFolderDataQueryResult = ApolloReactCommon.QueryResult<GetCurrentFolderDataQuery, GetCurrentFolderDataQueryVariables>;
export const GetMediasByIdsDocument = gql`
    query getMediasByIds($mediaIds: [String]!) {
  mediasByIds(mediaIds: $mediaIds) {
    id
    cloudinaryId
    cloudinaryVersion
    mediaType
    ... on Image {
      width
      height
    }
    ... on Video {
      width
      height
    }
    name
    createdAt
    isSample
  }
}
    `;

/**
 * __useGetMediasByIdsQuery__
 *
 * To run a query within a React component, call `useGetMediasByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediasByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediasByIdsQuery({
 *   variables: {
 *      mediaIds: // value for 'mediaIds'
 *   },
 * });
 */
export function useGetMediasByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediasByIdsQuery, GetMediasByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediasByIdsQuery, GetMediasByIdsQueryVariables>(GetMediasByIdsDocument, baseOptions);
      }
export function useGetMediasByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediasByIdsQuery, GetMediasByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediasByIdsQuery, GetMediasByIdsQueryVariables>(GetMediasByIdsDocument, baseOptions);
        }
export type GetMediasByIdsQueryHookResult = ReturnType<typeof useGetMediasByIdsQuery>;
export type GetMediasByIdsLazyQueryHookResult = ReturnType<typeof useGetMediasByIdsLazyQuery>;
export type GetMediasByIdsQueryResult = ApolloReactCommon.QueryResult<GetMediasByIdsQuery, GetMediasByIdsQueryVariables>;
export const CreateMediaDocument = gql`
    mutation createMedia($folder: String!, $libraryId: String!, $publicId: String, $path: String!, $uniqueFileName: Boolean, $mediaName: String, $location: String, $mediaType: MediaType, $showDate: String) {
  createMedia(libraryId: $libraryId, folder: $folder, publicId: $publicId, path: $path, uniqueFileName: $uniqueFileName, mediaName: $mediaName, location: $location, mediaType: $mediaType, showDate: $showDate) {
    signedUrl
    media {
      id
      name
    }
    publicId
  }
}
    `;
export type CreateMediaMutationFn = ApolloReactCommon.MutationFunction<CreateMediaMutation, CreateMediaMutationVariables>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      folder: // value for 'folder'
 *      libraryId: // value for 'libraryId'
 *      publicId: // value for 'publicId'
 *      path: // value for 'path'
 *      uniqueFileName: // value for 'uniqueFileName'
 *      mediaName: // value for 'mediaName'
 *      location: // value for 'location'
 *      mediaType: // value for 'mediaType'
 *      showDate: // value for 'showDate'
 *   },
 * });
 */
export function useCreateMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(CreateMediaDocument, baseOptions);
      }
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = ApolloReactCommon.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMediaMutation, CreateMediaMutationVariables>;
export const CreateMediasDocument = gql`
    mutation createMedias($createMediaParams: [CreateMediaParams]!) {
  createMedias(createMediaParams: $createMediaParams) {
    signedUrl
    media {
      id
      name
    }
    publicId
    failed
  }
}
    `;
export type CreateMediasMutationFn = ApolloReactCommon.MutationFunction<CreateMediasMutation, CreateMediasMutationVariables>;

/**
 * __useCreateMediasMutation__
 *
 * To run a mutation, you first call `useCreateMediasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediasMutation, { data, loading, error }] = useCreateMediasMutation({
 *   variables: {
 *      createMediaParams: // value for 'createMediaParams'
 *   },
 * });
 */
export function useCreateMediasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMediasMutation, CreateMediasMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMediasMutation, CreateMediasMutationVariables>(CreateMediasDocument, baseOptions);
      }
export type CreateMediasMutationHookResult = ReturnType<typeof useCreateMediasMutation>;
export type CreateMediasMutationResult = ApolloReactCommon.MutationResult<CreateMediasMutation>;
export type CreateMediasMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMediasMutation, CreateMediasMutationVariables>;
export const UpdateNewMediaDocument = gql`
    mutation updateNewMedia($mediaParams: MediaParams, $upsert: Boolean) {
  updateNewMedia(mediaParams: $mediaParams, upsert: $upsert) {
    id
    name
    status
  }
}
    `;
export type UpdateNewMediaMutationFn = ApolloReactCommon.MutationFunction<UpdateNewMediaMutation, UpdateNewMediaMutationVariables>;

/**
 * __useUpdateNewMediaMutation__
 *
 * To run a mutation, you first call `useUpdateNewMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewMediaMutation, { data, loading, error }] = useUpdateNewMediaMutation({
 *   variables: {
 *      mediaParams: // value for 'mediaParams'
 *      upsert: // value for 'upsert'
 *   },
 * });
 */
export function useUpdateNewMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNewMediaMutation, UpdateNewMediaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNewMediaMutation, UpdateNewMediaMutationVariables>(UpdateNewMediaDocument, baseOptions);
      }
export type UpdateNewMediaMutationHookResult = ReturnType<typeof useUpdateNewMediaMutation>;
export type UpdateNewMediaMutationResult = ApolloReactCommon.MutationResult<UpdateNewMediaMutation>;
export type UpdateNewMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNewMediaMutation, UpdateNewMediaMutationVariables>;
export const UpdateMediaDocument = gql`
    mutation updateMedia($mediaParams: MediaParams, $upsert: Boolean) {
  updateMedia(mediaParams: $mediaParams, upsert: $upsert) {
    id
    name
    status
  }
}
    `;
export type UpdateMediaMutationFn = ApolloReactCommon.MutationFunction<UpdateMediaMutation, UpdateMediaMutationVariables>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      mediaParams: // value for 'mediaParams'
 *      upsert: // value for 'upsert'
 *   },
 * });
 */
export function useUpdateMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(UpdateMediaDocument, baseOptions);
      }
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = ApolloReactCommon.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMediaMutation, UpdateMediaMutationVariables>;
export const AutoTagMediaDocument = gql`
    mutation autoTagMedia($id: String!, $type: String!, $overwrite: Boolean) {
  autoTagMedia(id: $id, type: $type, overwrite: $overwrite) {
    message
  }
}
    `;
export type AutoTagMediaMutationFn = ApolloReactCommon.MutationFunction<AutoTagMediaMutation, AutoTagMediaMutationVariables>;

/**
 * __useAutoTagMediaMutation__
 *
 * To run a mutation, you first call `useAutoTagMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoTagMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoTagMediaMutation, { data, loading, error }] = useAutoTagMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      overwrite: // value for 'overwrite'
 *   },
 * });
 */
export function useAutoTagMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AutoTagMediaMutation, AutoTagMediaMutationVariables>) {
        return ApolloReactHooks.useMutation<AutoTagMediaMutation, AutoTagMediaMutationVariables>(AutoTagMediaDocument, baseOptions);
      }
export type AutoTagMediaMutationHookResult = ReturnType<typeof useAutoTagMediaMutation>;
export type AutoTagMediaMutationResult = ApolloReactCommon.MutationResult<AutoTagMediaMutation>;
export type AutoTagMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<AutoTagMediaMutation, AutoTagMediaMutationVariables>;
export const UpdateMediaDetailsDocument = gql`
    mutation updateMediaDetails($mediaDetails: UpdateMediaTagsParams) {
  updateMediaDetails(mediaDetails: $mediaDetails) {
    id
    name
    tags {
      id
      name
      key
      type
      isCustom
    }
    tagRelationships {
      id
      target {
        ...MediaTagFragment
      }
      relations {
        ...MediaTagFragment
        colorCode
      }
      isSampleLinked
    }
  }
}
    ${MediaTagFragmentFragmentDoc}`;
export type UpdateMediaDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateMediaDetailsMutation, UpdateMediaDetailsMutationVariables>;

/**
 * __useUpdateMediaDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMediaDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaDetailsMutation, { data, loading, error }] = useUpdateMediaDetailsMutation({
 *   variables: {
 *      mediaDetails: // value for 'mediaDetails'
 *   },
 * });
 */
export function useUpdateMediaDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMediaDetailsMutation, UpdateMediaDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMediaDetailsMutation, UpdateMediaDetailsMutationVariables>(UpdateMediaDetailsDocument, baseOptions);
      }
export type UpdateMediaDetailsMutationHookResult = ReturnType<typeof useUpdateMediaDetailsMutation>;
export type UpdateMediaDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateMediaDetailsMutation>;
export type UpdateMediaDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMediaDetailsMutation, UpdateMediaDetailsMutationVariables>;
export const UpdateMediaDetailsOneByOneDocument = gql`
    mutation updateMediaDetailsOneByOne($mediaDetails: [UpdateMediaTagsParams]) {
  updateMediaDetailsOneByOne(mediaDetails: $mediaDetails) {
    id
    name
    tags {
      id
      name
      key
      type
      isCustom
    }
    tagRelationships {
      id
      target {
        ...MediaTagFragment
      }
      relations {
        ...MediaTagFragment
        colorCode
      }
      isSampleLinked
    }
  }
}
    ${MediaTagFragmentFragmentDoc}`;
export type UpdateMediaDetailsOneByOneMutationFn = ApolloReactCommon.MutationFunction<UpdateMediaDetailsOneByOneMutation, UpdateMediaDetailsOneByOneMutationVariables>;

/**
 * __useUpdateMediaDetailsOneByOneMutation__
 *
 * To run a mutation, you first call `useUpdateMediaDetailsOneByOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaDetailsOneByOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaDetailsOneByOneMutation, { data, loading, error }] = useUpdateMediaDetailsOneByOneMutation({
 *   variables: {
 *      mediaDetails: // value for 'mediaDetails'
 *   },
 * });
 */
export function useUpdateMediaDetailsOneByOneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMediaDetailsOneByOneMutation, UpdateMediaDetailsOneByOneMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMediaDetailsOneByOneMutation, UpdateMediaDetailsOneByOneMutationVariables>(UpdateMediaDetailsOneByOneDocument, baseOptions);
      }
export type UpdateMediaDetailsOneByOneMutationHookResult = ReturnType<typeof useUpdateMediaDetailsOneByOneMutation>;
export type UpdateMediaDetailsOneByOneMutationResult = ApolloReactCommon.MutationResult<UpdateMediaDetailsOneByOneMutation>;
export type UpdateMediaDetailsOneByOneMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMediaDetailsOneByOneMutation, UpdateMediaDetailsOneByOneMutationVariables>;
export const DeleteMediasDocument = gql`
    mutation deleteMedias($imageIds: [String]!, $videoIds: [String]!) {
  setMediasAsDeleted(imageIds: $imageIds, videoIds: $videoIds) {
    status
    deletedMedias {
      id
      status
    }
  }
}
    `;
export type DeleteMediasMutationFn = ApolloReactCommon.MutationFunction<DeleteMediasMutation, DeleteMediasMutationVariables>;

/**
 * __useDeleteMediasMutation__
 *
 * To run a mutation, you first call `useDeleteMediasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediasMutation, { data, loading, error }] = useDeleteMediasMutation({
 *   variables: {
 *      imageIds: // value for 'imageIds'
 *      videoIds: // value for 'videoIds'
 *   },
 * });
 */
export function useDeleteMediasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMediasMutation, DeleteMediasMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMediasMutation, DeleteMediasMutationVariables>(DeleteMediasDocument, baseOptions);
      }
export type DeleteMediasMutationHookResult = ReturnType<typeof useDeleteMediasMutation>;
export type DeleteMediasMutationResult = ApolloReactCommon.MutationResult<DeleteMediasMutation>;
export type DeleteMediasMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMediasMutation, DeleteMediasMutationVariables>;
export const RemoveMediaDocument = gql`
    mutation removeMedia($id: String!) {
  removeMedia(id: $id) {
    status
    removed
  }
}
    `;
export type RemoveMediaMutationFn = ApolloReactCommon.MutationFunction<RemoveMediaMutation, RemoveMediaMutationVariables>;

/**
 * __useRemoveMediaMutation__
 *
 * To run a mutation, you first call `useRemoveMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaMutation, { data, loading, error }] = useRemoveMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMediaMutation, RemoveMediaMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMediaMutation, RemoveMediaMutationVariables>(RemoveMediaDocument, baseOptions);
      }
export type RemoveMediaMutationHookResult = ReturnType<typeof useRemoveMediaMutation>;
export type RemoveMediaMutationResult = ApolloReactCommon.MutationResult<RemoveMediaMutation>;
export type RemoveMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMediaMutation, RemoveMediaMutationVariables>;
export const RemoveMediasDocument = gql`
    mutation removeMedias($ids: [String]!) {
  removeMedias(ids: $ids) {
    status
    removed
  }
}
    `;
export type RemoveMediasMutationFn = ApolloReactCommon.MutationFunction<RemoveMediasMutation, RemoveMediasMutationVariables>;

/**
 * __useRemoveMediasMutation__
 *
 * To run a mutation, you first call `useRemoveMediasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediasMutation, { data, loading, error }] = useRemoveMediasMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveMediasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMediasMutation, RemoveMediasMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMediasMutation, RemoveMediasMutationVariables>(RemoveMediasDocument, baseOptions);
      }
export type RemoveMediasMutationHookResult = ReturnType<typeof useRemoveMediasMutation>;
export type RemoveMediasMutationResult = ApolloReactCommon.MutationResult<RemoveMediasMutation>;
export type RemoveMediasMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMediasMutation, RemoveMediasMutationVariables>;
export const GetMediaDetailsDocument = gql`
    query getMediaDetails($ids: [String]!) {
  mediaDetails(ids: $ids) {
    public_id
    secure_url
    filename
    width
    height
  }
}
    `;

/**
 * __useGetMediaDetailsQuery__
 *
 * To run a query within a React component, call `useGetMediaDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaDetailsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMediaDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaDetailsQuery, GetMediaDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaDetailsQuery, GetMediaDetailsQueryVariables>(GetMediaDetailsDocument, baseOptions);
      }
export function useGetMediaDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaDetailsQuery, GetMediaDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaDetailsQuery, GetMediaDetailsQueryVariables>(GetMediaDetailsDocument, baseOptions);
        }
export type GetMediaDetailsQueryHookResult = ReturnType<typeof useGetMediaDetailsQuery>;
export type GetMediaDetailsLazyQueryHookResult = ReturnType<typeof useGetMediaDetailsLazyQuery>;
export type GetMediaDetailsQueryResult = ApolloReactCommon.QueryResult<GetMediaDetailsQuery, GetMediaDetailsQueryVariables>;
export const GetMergedMediaDetailDocument = gql`
    query getMergedMediaDetail($mediaIds: [String]!) {
  mergedMediaDetails(ids: $mediaIds) {
    ...MergedMediaDetailInfo
  }
}
    ${MergedMediaDetailInfoFragmentDoc}`;

/**
 * __useGetMergedMediaDetailQuery__
 *
 * To run a query within a React component, call `useGetMergedMediaDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMergedMediaDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMergedMediaDetailQuery({
 *   variables: {
 *      mediaIds: // value for 'mediaIds'
 *   },
 * });
 */
export function useGetMergedMediaDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMergedMediaDetailQuery, GetMergedMediaDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMergedMediaDetailQuery, GetMergedMediaDetailQueryVariables>(GetMergedMediaDetailDocument, baseOptions);
      }
export function useGetMergedMediaDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMergedMediaDetailQuery, GetMergedMediaDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMergedMediaDetailQuery, GetMergedMediaDetailQueryVariables>(GetMergedMediaDetailDocument, baseOptions);
        }
export type GetMergedMediaDetailQueryHookResult = ReturnType<typeof useGetMergedMediaDetailQuery>;
export type GetMergedMediaDetailLazyQueryHookResult = ReturnType<typeof useGetMergedMediaDetailLazyQuery>;
export type GetMergedMediaDetailQueryResult = ApolloReactCommon.QueryResult<GetMergedMediaDetailQuery, GetMergedMediaDetailQueryVariables>;
export const GetMergedMediaDetailsOneByOneDocument = gql`
    query getMergedMediaDetailsOneByOne($mediaIds: [String]!) {
  mergedMediaDetailsOneByOne(ids: $mediaIds) {
    ...MergedMediaDetailInfo
  }
}
    ${MergedMediaDetailInfoFragmentDoc}`;

/**
 * __useGetMergedMediaDetailsOneByOneQuery__
 *
 * To run a query within a React component, call `useGetMergedMediaDetailsOneByOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMergedMediaDetailsOneByOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMergedMediaDetailsOneByOneQuery({
 *   variables: {
 *      mediaIds: // value for 'mediaIds'
 *   },
 * });
 */
export function useGetMergedMediaDetailsOneByOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMergedMediaDetailsOneByOneQuery, GetMergedMediaDetailsOneByOneQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMergedMediaDetailsOneByOneQuery, GetMergedMediaDetailsOneByOneQueryVariables>(GetMergedMediaDetailsOneByOneDocument, baseOptions);
      }
export function useGetMergedMediaDetailsOneByOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMergedMediaDetailsOneByOneQuery, GetMergedMediaDetailsOneByOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMergedMediaDetailsOneByOneQuery, GetMergedMediaDetailsOneByOneQueryVariables>(GetMergedMediaDetailsOneByOneDocument, baseOptions);
        }
export type GetMergedMediaDetailsOneByOneQueryHookResult = ReturnType<typeof useGetMergedMediaDetailsOneByOneQuery>;
export type GetMergedMediaDetailsOneByOneLazyQueryHookResult = ReturnType<typeof useGetMergedMediaDetailsOneByOneLazyQuery>;
export type GetMergedMediaDetailsOneByOneQueryResult = ApolloReactCommon.QueryResult<GetMergedMediaDetailsOneByOneQuery, GetMergedMediaDetailsOneByOneQueryVariables>;
export const GetSamplesDepartmentsDocument = gql`
    query getSamplesDepartments {
  samplesDepartments {
    id
    name
  }
}
    `;

/**
 * __useGetSamplesDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetSamplesDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSamplesDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSamplesDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSamplesDepartmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSamplesDepartmentsQuery, GetSamplesDepartmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSamplesDepartmentsQuery, GetSamplesDepartmentsQueryVariables>(GetSamplesDepartmentsDocument, baseOptions);
      }
export function useGetSamplesDepartmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSamplesDepartmentsQuery, GetSamplesDepartmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSamplesDepartmentsQuery, GetSamplesDepartmentsQueryVariables>(GetSamplesDepartmentsDocument, baseOptions);
        }
export type GetSamplesDepartmentsQueryHookResult = ReturnType<typeof useGetSamplesDepartmentsQuery>;
export type GetSamplesDepartmentsLazyQueryHookResult = ReturnType<typeof useGetSamplesDepartmentsLazyQuery>;
export type GetSamplesDepartmentsQueryResult = ApolloReactCommon.QueryResult<GetSamplesDepartmentsQuery, GetSamplesDepartmentsQueryVariables>;
export const GetShowroomAnalyticsGuestsDocument = gql`
    query getShowroomAnalyticsGuests($showroomId: String!) {
  showroomAnalyticsGuests(showroomId: $showroomId) {
    guests {
      viewerIds
      email
      visits
      downloads
      clicks
    }
  }
}
    `;

/**
 * __useGetShowroomAnalyticsGuestsQuery__
 *
 * To run a query within a React component, call `useGetShowroomAnalyticsGuestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomAnalyticsGuestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomAnalyticsGuestsQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomAnalyticsGuestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomAnalyticsGuestsQuery, GetShowroomAnalyticsGuestsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomAnalyticsGuestsQuery, GetShowroomAnalyticsGuestsQueryVariables>(GetShowroomAnalyticsGuestsDocument, baseOptions);
      }
export function useGetShowroomAnalyticsGuestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomAnalyticsGuestsQuery, GetShowroomAnalyticsGuestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomAnalyticsGuestsQuery, GetShowroomAnalyticsGuestsQueryVariables>(GetShowroomAnalyticsGuestsDocument, baseOptions);
        }
export type GetShowroomAnalyticsGuestsQueryHookResult = ReturnType<typeof useGetShowroomAnalyticsGuestsQuery>;
export type GetShowroomAnalyticsGuestsLazyQueryHookResult = ReturnType<typeof useGetShowroomAnalyticsGuestsLazyQuery>;
export type GetShowroomAnalyticsGuestsQueryResult = ApolloReactCommon.QueryResult<GetShowroomAnalyticsGuestsQuery, GetShowroomAnalyticsGuestsQueryVariables>;
export const GetShowroomAnalyticsGuestDataDocument = gql`
    query getShowroomAnalyticsGuestData($showroomId: String!, $guestId: String!) {
  showroomAnalyticsGuestData(showroomId: $showroomId, guestId: $guestId) {
    viewerId
    email
    visits
    downloads
    views
    downloadedMedias {
      videos {
        mediaId
        counter
      }
      images {
        mediaId
        counter
      }
      pdfs {
        mediaId
        counter
      }
    }
    viewedMedias {
      videos {
        mediaId
        counter
      }
      images {
        mediaId
        counter
      }
      pdfs {
        mediaId
        counter
      }
    }
  }
}
    `;

/**
 * __useGetShowroomAnalyticsGuestDataQuery__
 *
 * To run a query within a React component, call `useGetShowroomAnalyticsGuestDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomAnalyticsGuestDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomAnalyticsGuestDataQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *      guestId: // value for 'guestId'
 *   },
 * });
 */
export function useGetShowroomAnalyticsGuestDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomAnalyticsGuestDataQuery, GetShowroomAnalyticsGuestDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomAnalyticsGuestDataQuery, GetShowroomAnalyticsGuestDataQueryVariables>(GetShowroomAnalyticsGuestDataDocument, baseOptions);
      }
export function useGetShowroomAnalyticsGuestDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomAnalyticsGuestDataQuery, GetShowroomAnalyticsGuestDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomAnalyticsGuestDataQuery, GetShowroomAnalyticsGuestDataQueryVariables>(GetShowroomAnalyticsGuestDataDocument, baseOptions);
        }
export type GetShowroomAnalyticsGuestDataQueryHookResult = ReturnType<typeof useGetShowroomAnalyticsGuestDataQuery>;
export type GetShowroomAnalyticsGuestDataLazyQueryHookResult = ReturnType<typeof useGetShowroomAnalyticsGuestDataLazyQuery>;
export type GetShowroomAnalyticsGuestDataQueryResult = ApolloReactCommon.QueryResult<GetShowroomAnalyticsGuestDataQuery, GetShowroomAnalyticsGuestDataQueryVariables>;
export const IsEditorModeDocument = gql`
    query isEditorMode {
  showroom @client {
    editor {
      isOpen
      showroomId
      step
      currentSection
      displaySectionOptions
      showroomParams {
        name
        downloadEnabled
        downloadPdfEnabled
        requestSamplesEnabled
        samplesDepartmentId
        displayHeroMedia
        displayHeroContent
        language
        library
        librarySeason
        libraryCollection
        heroTitle
        heroContent
        heroFontColor
        heroBackgroundColor
        heroMedia {
          mediaType
          publicId
          publicUrl
        }
        pdfs {
          mediaType
          publicId
          publicUrl
          originalFilename
          createdAt
        }
        sections {
          id
          name
          rows {
            medias {
              mediaId
              mediaType
              publicId
              cloudinaryVersion
              width
              height
              originalFilename
              createdAt
              isSample
            }
          }
          options {
            spacing
            fullWidth
            displayLabel
            zoomLayer
            productLayer
            productLayerSettings {
              galleriesFields
              samplesFields
            }
            globalSelectedFilters {
              galleriesFields
              samplesFields
            }
          }
          rowMediaIds {
            id
            mediaIds
          }
          loaded
          loading
        }
        prContacts {
          name
          jobTitle
          email
          phone
        }
      }
    }
  }
}
    `;

/**
 * __useIsEditorModeQuery__
 *
 * To run a query within a React component, call `useIsEditorModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEditorModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEditorModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEditorModeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEditorModeQuery, IsEditorModeQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEditorModeQuery, IsEditorModeQueryVariables>(IsEditorModeDocument, baseOptions);
      }
export function useIsEditorModeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEditorModeQuery, IsEditorModeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEditorModeQuery, IsEditorModeQueryVariables>(IsEditorModeDocument, baseOptions);
        }
export type IsEditorModeQueryHookResult = ReturnType<typeof useIsEditorModeQuery>;
export type IsEditorModeLazyQueryHookResult = ReturnType<typeof useIsEditorModeLazyQuery>;
export type IsEditorModeQueryResult = ApolloReactCommon.QueryResult<IsEditorModeQuery, IsEditorModeQueryVariables>;
export const CreateShowroomDocument = gql`
    mutation createShowroom($name: String!, $params: ShowroomUpdateParams) {
  createShowroomV2(name: $name, params: $params) {
    ...ShowroomInfo
    password
    sections {
      ...ShowroomSectionInfo
    }
  }
}
    ${ShowroomInfoFragmentDoc}
${ShowroomSectionInfoFragmentDoc}`;
export type CreateShowroomMutationFn = ApolloReactCommon.MutationFunction<CreateShowroomMutation, CreateShowroomMutationVariables>;

/**
 * __useCreateShowroomMutation__
 *
 * To run a mutation, you first call `useCreateShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShowroomMutation, { data, loading, error }] = useCreateShowroomMutation({
 *   variables: {
 *      name: // value for 'name'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateShowroomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShowroomMutation, CreateShowroomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateShowroomMutation, CreateShowroomMutationVariables>(CreateShowroomDocument, baseOptions);
      }
export type CreateShowroomMutationHookResult = ReturnType<typeof useCreateShowroomMutation>;
export type CreateShowroomMutationResult = ApolloReactCommon.MutationResult<CreateShowroomMutation>;
export type CreateShowroomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateShowroomMutation, CreateShowroomMutationVariables>;
export const UpdateShowroomDocument = gql`
    mutation updateShowroom($_id: ID!, $name: String!, $params: ShowroomUpdateParams, $updatePassword: Boolean) {
  updateShowroomV2(_id: $_id, name: $name, params: $params, updatePassword: $updatePassword) {
    ...ShowroomInfo
    password
    sections {
      ...ShowroomSectionInfoWithRowMediaIds
    }
  }
}
    ${ShowroomInfoFragmentDoc}
${ShowroomSectionInfoWithRowMediaIdsFragmentDoc}`;
export type UpdateShowroomMutationFn = ApolloReactCommon.MutationFunction<UpdateShowroomMutation, UpdateShowroomMutationVariables>;

/**
 * __useUpdateShowroomMutation__
 *
 * To run a mutation, you first call `useUpdateShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowroomMutation, { data, loading, error }] = useUpdateShowroomMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      name: // value for 'name'
 *      params: // value for 'params'
 *      updatePassword: // value for 'updatePassword'
 *   },
 * });
 */
export function useUpdateShowroomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShowroomMutation, UpdateShowroomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShowroomMutation, UpdateShowroomMutationVariables>(UpdateShowroomDocument, baseOptions);
      }
export type UpdateShowroomMutationHookResult = ReturnType<typeof useUpdateShowroomMutation>;
export type UpdateShowroomMutationResult = ApolloReactCommon.MutationResult<UpdateShowroomMutation>;
export type UpdateShowroomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShowroomMutation, UpdateShowroomMutationVariables>;
export const UploadShowroomHeroDocument = gql`
    mutation uploadShowroomHero {
  uploadShowroomHero
}
    `;
export type UploadShowroomHeroMutationFn = ApolloReactCommon.MutationFunction<UploadShowroomHeroMutation, UploadShowroomHeroMutationVariables>;

/**
 * __useUploadShowroomHeroMutation__
 *
 * To run a mutation, you first call `useUploadShowroomHeroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadShowroomHeroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadShowroomHeroMutation, { data, loading, error }] = useUploadShowroomHeroMutation({
 *   variables: {
 *   },
 * });
 */
export function useUploadShowroomHeroMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadShowroomHeroMutation, UploadShowroomHeroMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadShowroomHeroMutation, UploadShowroomHeroMutationVariables>(UploadShowroomHeroDocument, baseOptions);
      }
export type UploadShowroomHeroMutationHookResult = ReturnType<typeof useUploadShowroomHeroMutation>;
export type UploadShowroomHeroMutationResult = ApolloReactCommon.MutationResult<UploadShowroomHeroMutation>;
export type UploadShowroomHeroMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadShowroomHeroMutation, UploadShowroomHeroMutationVariables>;
export const GetLibrarySelectedMediasEditorDocument = gql`
    query getLibrarySelectedMediasEditor {
  librarySelectedMedias @client {
    id
    mediaType
    name
    cloudinaryId
    cloudinaryVersion
    createdAt
    ... on Image {
      width
      height
    }
    ... on Video {
      width
      height
    }
    isSample
  }
}
    `;

/**
 * __useGetLibrarySelectedMediasEditorQuery__
 *
 * To run a query within a React component, call `useGetLibrarySelectedMediasEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibrarySelectedMediasEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibrarySelectedMediasEditorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibrarySelectedMediasEditorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLibrarySelectedMediasEditorQuery, GetLibrarySelectedMediasEditorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLibrarySelectedMediasEditorQuery, GetLibrarySelectedMediasEditorQueryVariables>(GetLibrarySelectedMediasEditorDocument, baseOptions);
      }
export function useGetLibrarySelectedMediasEditorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLibrarySelectedMediasEditorQuery, GetLibrarySelectedMediasEditorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLibrarySelectedMediasEditorQuery, GetLibrarySelectedMediasEditorQueryVariables>(GetLibrarySelectedMediasEditorDocument, baseOptions);
        }
export type GetLibrarySelectedMediasEditorQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasEditorQuery>;
export type GetLibrarySelectedMediasEditorLazyQueryHookResult = ReturnType<typeof useGetLibrarySelectedMediasEditorLazyQuery>;
export type GetLibrarySelectedMediasEditorQueryResult = ApolloReactCommon.QueryResult<GetLibrarySelectedMediasEditorQuery, GetLibrarySelectedMediasEditorQueryVariables>;
export const GetMediaSectionSamplesFieldsDocument = gql`
    query getMediaSectionSamplesFields($ids: [String]!) {
  mediasAvailableSamplesFields(ids: $ids) {
    name
  }
}
    `;

/**
 * __useGetMediaSectionSamplesFieldsQuery__
 *
 * To run a query within a React component, call `useGetMediaSectionSamplesFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaSectionSamplesFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaSectionSamplesFieldsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMediaSectionSamplesFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaSectionSamplesFieldsQuery, GetMediaSectionSamplesFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaSectionSamplesFieldsQuery, GetMediaSectionSamplesFieldsQueryVariables>(GetMediaSectionSamplesFieldsDocument, baseOptions);
      }
export function useGetMediaSectionSamplesFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaSectionSamplesFieldsQuery, GetMediaSectionSamplesFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaSectionSamplesFieldsQuery, GetMediaSectionSamplesFieldsQueryVariables>(GetMediaSectionSamplesFieldsDocument, baseOptions);
        }
export type GetMediaSectionSamplesFieldsQueryHookResult = ReturnType<typeof useGetMediaSectionSamplesFieldsQuery>;
export type GetMediaSectionSamplesFieldsLazyQueryHookResult = ReturnType<typeof useGetMediaSectionSamplesFieldsLazyQuery>;
export type GetMediaSectionSamplesFieldsQueryResult = ApolloReactCommon.QueryResult<GetMediaSectionSamplesFieldsQuery, GetMediaSectionSamplesFieldsQueryVariables>;
export const GetMediaSectionGalleriesFieldsDocument = gql`
    query getMediaSectionGalleriesFields($ids: [String]!) {
  mediasAvailableGalleriesFields(ids: $ids) {
    name
  }
}
    `;

/**
 * __useGetMediaSectionGalleriesFieldsQuery__
 *
 * To run a query within a React component, call `useGetMediaSectionGalleriesFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaSectionGalleriesFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaSectionGalleriesFieldsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMediaSectionGalleriesFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaSectionGalleriesFieldsQuery, GetMediaSectionGalleriesFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaSectionGalleriesFieldsQuery, GetMediaSectionGalleriesFieldsQueryVariables>(GetMediaSectionGalleriesFieldsDocument, baseOptions);
      }
export function useGetMediaSectionGalleriesFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaSectionGalleriesFieldsQuery, GetMediaSectionGalleriesFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaSectionGalleriesFieldsQuery, GetMediaSectionGalleriesFieldsQueryVariables>(GetMediaSectionGalleriesFieldsDocument, baseOptions);
        }
export type GetMediaSectionGalleriesFieldsQueryHookResult = ReturnType<typeof useGetMediaSectionGalleriesFieldsQuery>;
export type GetMediaSectionGalleriesFieldsLazyQueryHookResult = ReturnType<typeof useGetMediaSectionGalleriesFieldsLazyQuery>;
export type GetMediaSectionGalleriesFieldsQueryResult = ApolloReactCommon.QueryResult<GetMediaSectionGalleriesFieldsQuery, GetMediaSectionGalleriesFieldsQueryVariables>;
export const GetShowroomCurrentSectionNumberDocument = gql`
    query getShowroomCurrentSectionNumber {
  showroom @client {
    editor {
      currentSection
    }
  }
}
    `;

/**
 * __useGetShowroomCurrentSectionNumberQuery__
 *
 * To run a query within a React component, call `useGetShowroomCurrentSectionNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomCurrentSectionNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomCurrentSectionNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomCurrentSectionNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomCurrentSectionNumberQuery, GetShowroomCurrentSectionNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomCurrentSectionNumberQuery, GetShowroomCurrentSectionNumberQueryVariables>(GetShowroomCurrentSectionNumberDocument, baseOptions);
      }
export function useGetShowroomCurrentSectionNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomCurrentSectionNumberQuery, GetShowroomCurrentSectionNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomCurrentSectionNumberQuery, GetShowroomCurrentSectionNumberQueryVariables>(GetShowroomCurrentSectionNumberDocument, baseOptions);
        }
export type GetShowroomCurrentSectionNumberQueryHookResult = ReturnType<typeof useGetShowroomCurrentSectionNumberQuery>;
export type GetShowroomCurrentSectionNumberLazyQueryHookResult = ReturnType<typeof useGetShowroomCurrentSectionNumberLazyQuery>;
export type GetShowroomCurrentSectionNumberQueryResult = ApolloReactCommon.QueryResult<GetShowroomCurrentSectionNumberQuery, GetShowroomCurrentSectionNumberQueryVariables>;
export const GetShowroomSectionFiltersDocument = gql`
    query getShowroomSectionFilters {
  currentShowroomSection @client {
    options {
      globalSelectedFilters {
        galleriesFields
        samplesFields
      }
    }
  }
}
    `;

/**
 * __useGetShowroomSectionFiltersQuery__
 *
 * To run a query within a React component, call `useGetShowroomSectionFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSectionFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSectionFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSectionFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSectionFiltersQuery, GetShowroomSectionFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSectionFiltersQuery, GetShowroomSectionFiltersQueryVariables>(GetShowroomSectionFiltersDocument, baseOptions);
      }
export function useGetShowroomSectionFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSectionFiltersQuery, GetShowroomSectionFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSectionFiltersQuery, GetShowroomSectionFiltersQueryVariables>(GetShowroomSectionFiltersDocument, baseOptions);
        }
export type GetShowroomSectionFiltersQueryHookResult = ReturnType<typeof useGetShowroomSectionFiltersQuery>;
export type GetShowroomSectionFiltersLazyQueryHookResult = ReturnType<typeof useGetShowroomSectionFiltersLazyQuery>;
export type GetShowroomSectionFiltersQueryResult = ApolloReactCommon.QueryResult<GetShowroomSectionFiltersQuery, GetShowroomSectionFiltersQueryVariables>;
export const GetShowroomSectionMediasIdsDocument = gql`
    query getShowroomSectionMediasIds {
  currentShowroomSectionMedias @client {
    mediaId
  }
}
    `;

/**
 * __useGetShowroomSectionMediasIdsQuery__
 *
 * To run a query within a React component, call `useGetShowroomSectionMediasIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSectionMediasIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSectionMediasIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSectionMediasIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSectionMediasIdsQuery, GetShowroomSectionMediasIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSectionMediasIdsQuery, GetShowroomSectionMediasIdsQueryVariables>(GetShowroomSectionMediasIdsDocument, baseOptions);
      }
export function useGetShowroomSectionMediasIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSectionMediasIdsQuery, GetShowroomSectionMediasIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSectionMediasIdsQuery, GetShowroomSectionMediasIdsQueryVariables>(GetShowroomSectionMediasIdsDocument, baseOptions);
        }
export type GetShowroomSectionMediasIdsQueryHookResult = ReturnType<typeof useGetShowroomSectionMediasIdsQuery>;
export type GetShowroomSectionMediasIdsLazyQueryHookResult = ReturnType<typeof useGetShowroomSectionMediasIdsLazyQuery>;
export type GetShowroomSectionMediasIdsQueryResult = ApolloReactCommon.QueryResult<GetShowroomSectionMediasIdsQuery, GetShowroomSectionMediasIdsQueryVariables>;
export const GetShowroomSectionLoadingMediaFieldsDocument = gql`
    query getShowroomSectionLoadingMediaFields($id: String!) {
  showroomSection(id: $id) @client {
    loading
    loaded
    rowMediaIds {
      id
      mediaIds
    }
  }
}
    `;

/**
 * __useGetShowroomSectionLoadingMediaFieldsQuery__
 *
 * To run a query within a React component, call `useGetShowroomSectionLoadingMediaFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSectionLoadingMediaFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSectionLoadingMediaFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShowroomSectionLoadingMediaFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSectionLoadingMediaFieldsQuery, GetShowroomSectionLoadingMediaFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSectionLoadingMediaFieldsQuery, GetShowroomSectionLoadingMediaFieldsQueryVariables>(GetShowroomSectionLoadingMediaFieldsDocument, baseOptions);
      }
export function useGetShowroomSectionLoadingMediaFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSectionLoadingMediaFieldsQuery, GetShowroomSectionLoadingMediaFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSectionLoadingMediaFieldsQuery, GetShowroomSectionLoadingMediaFieldsQueryVariables>(GetShowroomSectionLoadingMediaFieldsDocument, baseOptions);
        }
export type GetShowroomSectionLoadingMediaFieldsQueryHookResult = ReturnType<typeof useGetShowroomSectionLoadingMediaFieldsQuery>;
export type GetShowroomSectionLoadingMediaFieldsLazyQueryHookResult = ReturnType<typeof useGetShowroomSectionLoadingMediaFieldsLazyQuery>;
export type GetShowroomSectionLoadingMediaFieldsQueryResult = ApolloReactCommon.QueryResult<GetShowroomSectionLoadingMediaFieldsQuery, GetShowroomSectionLoadingMediaFieldsQueryVariables>;
export const GetShowroomGlobalFiltersDocument = gql`
    query getShowroomGlobalFilters($mediaIds: [String]!) {
  showroomGlobalFilters(mediaIds: $mediaIds) {
    samplesFilters
    galleriesFilters
  }
}
    `;

/**
 * __useGetShowroomGlobalFiltersQuery__
 *
 * To run a query within a React component, call `useGetShowroomGlobalFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomGlobalFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomGlobalFiltersQuery({
 *   variables: {
 *      mediaIds: // value for 'mediaIds'
 *   },
 * });
 */
export function useGetShowroomGlobalFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomGlobalFiltersQuery, GetShowroomGlobalFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomGlobalFiltersQuery, GetShowroomGlobalFiltersQueryVariables>(GetShowroomGlobalFiltersDocument, baseOptions);
      }
export function useGetShowroomGlobalFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomGlobalFiltersQuery, GetShowroomGlobalFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomGlobalFiltersQuery, GetShowroomGlobalFiltersQueryVariables>(GetShowroomGlobalFiltersDocument, baseOptions);
        }
export type GetShowroomGlobalFiltersQueryHookResult = ReturnType<typeof useGetShowroomGlobalFiltersQuery>;
export type GetShowroomGlobalFiltersLazyQueryHookResult = ReturnType<typeof useGetShowroomGlobalFiltersLazyQuery>;
export type GetShowroomGlobalFiltersQueryResult = ApolloReactCommon.QueryResult<GetShowroomGlobalFiltersQuery, GetShowroomGlobalFiltersQueryVariables>;
export const GetShowroomSearchStateDocument = gql`
    query getShowroomSearchState {
  showroom @client {
    search {
      query
      page
      field
      direction
      tab
      selectedIds
    }
  }
}
    `;

/**
 * __useGetShowroomSearchStateQuery__
 *
 * To run a query within a React component, call `useGetShowroomSearchStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSearchStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSearchStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSearchStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSearchStateQuery, GetShowroomSearchStateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSearchStateQuery, GetShowroomSearchStateQueryVariables>(GetShowroomSearchStateDocument, baseOptions);
      }
export function useGetShowroomSearchStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSearchStateQuery, GetShowroomSearchStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSearchStateQuery, GetShowroomSearchStateQueryVariables>(GetShowroomSearchStateDocument, baseOptions);
        }
export type GetShowroomSearchStateQueryHookResult = ReturnType<typeof useGetShowroomSearchStateQuery>;
export type GetShowroomSearchStateLazyQueryHookResult = ReturnType<typeof useGetShowroomSearchStateLazyQuery>;
export type GetShowroomSearchStateQueryResult = ApolloReactCommon.QueryResult<GetShowroomSearchStateQuery, GetShowroomSearchStateQueryVariables>;
export const GetShowroomSamplesBasketDocument = gql`
    query getShowroomSamplesBasket($showroomId: String!) {
  showroomSamplesBasket(showroomId: $showroomId) @client {
    ...MediaInfo
  }
}
    ${MediaInfoFragmentDoc}`;

/**
 * __useGetShowroomSamplesBasketQuery__
 *
 * To run a query within a React component, call `useGetShowroomSamplesBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSamplesBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSamplesBasketQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomSamplesBasketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSamplesBasketQuery, GetShowroomSamplesBasketQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSamplesBasketQuery, GetShowroomSamplesBasketQueryVariables>(GetShowroomSamplesBasketDocument, baseOptions);
      }
export function useGetShowroomSamplesBasketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSamplesBasketQuery, GetShowroomSamplesBasketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSamplesBasketQuery, GetShowroomSamplesBasketQueryVariables>(GetShowroomSamplesBasketDocument, baseOptions);
        }
export type GetShowroomSamplesBasketQueryHookResult = ReturnType<typeof useGetShowroomSamplesBasketQuery>;
export type GetShowroomSamplesBasketLazyQueryHookResult = ReturnType<typeof useGetShowroomSamplesBasketLazyQuery>;
export type GetShowroomSamplesBasketQueryResult = ApolloReactCommon.QueryResult<GetShowroomSamplesBasketQuery, GetShowroomSamplesBasketQueryVariables>;
export const GetShowroomSamplesFormDocument = gql`
    query getShowroomSamplesForm {
  showroom @client {
    userView {
      samples {
        form {
          deliveryDate
          returnDate
          streetAdress
          city
          zipCode
          country
          state
          firstName
          lastName
          jobTitle
          company
          email
          prefixNumber
          phoneNumber
          occasionType
          runThroughDate
          publication
          storyTheme
          eventName
          celebrity
          additionalComments
          shootDateStart
          shootDateEnd
          shootLocation
          photographer
          modelName
        }
      }
    }
  }
}
    `;

/**
 * __useGetShowroomSamplesFormQuery__
 *
 * To run a query within a React component, call `useGetShowroomSamplesFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSamplesFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSamplesFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSamplesFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSamplesFormQuery, GetShowroomSamplesFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSamplesFormQuery, GetShowroomSamplesFormQueryVariables>(GetShowroomSamplesFormDocument, baseOptions);
      }
export function useGetShowroomSamplesFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSamplesFormQuery, GetShowroomSamplesFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSamplesFormQuery, GetShowroomSamplesFormQueryVariables>(GetShowroomSamplesFormDocument, baseOptions);
        }
export type GetShowroomSamplesFormQueryHookResult = ReturnType<typeof useGetShowroomSamplesFormQuery>;
export type GetShowroomSamplesFormLazyQueryHookResult = ReturnType<typeof useGetShowroomSamplesFormLazyQuery>;
export type GetShowroomSamplesFormQueryResult = ApolloReactCommon.QueryResult<GetShowroomSamplesFormQuery, GetShowroomSamplesFormQueryVariables>;
export const GetShowroomSamplesOptionsDocument = gql`
    query getShowroomSamplesOptions {
  showroom @client {
    userView {
      samples {
        isRequestSamplesFormOpen
        isRequestSamplesFormValid
      }
    }
  }
}
    `;

/**
 * __useGetShowroomSamplesOptionsQuery__
 *
 * To run a query within a React component, call `useGetShowroomSamplesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSamplesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSamplesOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSamplesOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSamplesOptionsQuery, GetShowroomSamplesOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSamplesOptionsQuery, GetShowroomSamplesOptionsQueryVariables>(GetShowroomSamplesOptionsDocument, baseOptions);
      }
export function useGetShowroomSamplesOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSamplesOptionsQuery, GetShowroomSamplesOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSamplesOptionsQuery, GetShowroomSamplesOptionsQueryVariables>(GetShowroomSamplesOptionsDocument, baseOptions);
        }
export type GetShowroomSamplesOptionsQueryHookResult = ReturnType<typeof useGetShowroomSamplesOptionsQuery>;
export type GetShowroomSamplesOptionsLazyQueryHookResult = ReturnType<typeof useGetShowroomSamplesOptionsLazyQuery>;
export type GetShowroomSamplesOptionsQueryResult = ApolloReactCommon.QueryResult<GetShowroomSamplesOptionsQuery, GetShowroomSamplesOptionsQueryVariables>;
export const RequestSamplesDocument = gql`
    mutation requestSamples($params: RequestSamplesParams, $mediaIds: [String], $showroomId: String, $showroomViewerId: String) {
  requestSamples(params: $params, mediaIds: $mediaIds, showroomId: $showroomId, showroomViewerId: $showroomViewerId) {
    id
    requestNumber
  }
}
    `;
export type RequestSamplesMutationFn = ApolloReactCommon.MutationFunction<RequestSamplesMutation, RequestSamplesMutationVariables>;

/**
 * __useRequestSamplesMutation__
 *
 * To run a mutation, you first call `useRequestSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSamplesMutation, { data, loading, error }] = useRequestSamplesMutation({
 *   variables: {
 *      params: // value for 'params'
 *      mediaIds: // value for 'mediaIds'
 *      showroomId: // value for 'showroomId'
 *      showroomViewerId: // value for 'showroomViewerId'
 *   },
 * });
 */
export function useRequestSamplesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestSamplesMutation, RequestSamplesMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestSamplesMutation, RequestSamplesMutationVariables>(RequestSamplesDocument, baseOptions);
      }
export type RequestSamplesMutationHookResult = ReturnType<typeof useRequestSamplesMutation>;
export type RequestSamplesMutationResult = ApolloReactCommon.MutationResult<RequestSamplesMutation>;
export type RequestSamplesMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestSamplesMutation, RequestSamplesMutationVariables>;
export const GetShowroomShareOptionsDocument = gql`
    query getShowroomShareOptions {
  showroom @client {
    userView {
      share {
        isDialogOpen
        mediaQuality
      }
    }
  }
}
    `;

/**
 * __useGetShowroomShareOptionsQuery__
 *
 * To run a query within a React component, call `useGetShowroomShareOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomShareOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomShareOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomShareOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomShareOptionsQuery, GetShowroomShareOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomShareOptionsQuery, GetShowroomShareOptionsQueryVariables>(GetShowroomShareOptionsDocument, baseOptions);
      }
export function useGetShowroomShareOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomShareOptionsQuery, GetShowroomShareOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomShareOptionsQuery, GetShowroomShareOptionsQueryVariables>(GetShowroomShareOptionsDocument, baseOptions);
        }
export type GetShowroomShareOptionsQueryHookResult = ReturnType<typeof useGetShowroomShareOptionsQuery>;
export type GetShowroomShareOptionsLazyQueryHookResult = ReturnType<typeof useGetShowroomShareOptionsLazyQuery>;
export type GetShowroomShareOptionsQueryResult = ApolloReactCommon.QueryResult<GetShowroomShareOptionsQuery, GetShowroomShareOptionsQueryVariables>;
export const GetShowroomSelectedMediaDocument = gql`
    query getShowroomSelectedMedia {
  showroom @client {
    userView {
      selectedPdfs {
        publicId
      }
      selectedMedias {
        id
        cloudinaryId
        mediaType
      }
    }
  }
}
    `;

/**
 * __useGetShowroomSelectedMediaQuery__
 *
 * To run a query within a React component, call `useGetShowroomSelectedMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSelectedMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSelectedMediaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSelectedMediaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSelectedMediaQuery, GetShowroomSelectedMediaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSelectedMediaQuery, GetShowroomSelectedMediaQueryVariables>(GetShowroomSelectedMediaDocument, baseOptions);
      }
export function useGetShowroomSelectedMediaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSelectedMediaQuery, GetShowroomSelectedMediaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSelectedMediaQuery, GetShowroomSelectedMediaQueryVariables>(GetShowroomSelectedMediaDocument, baseOptions);
        }
export type GetShowroomSelectedMediaQueryHookResult = ReturnType<typeof useGetShowroomSelectedMediaQuery>;
export type GetShowroomSelectedMediaLazyQueryHookResult = ReturnType<typeof useGetShowroomSelectedMediaLazyQuery>;
export type GetShowroomSelectedMediaQueryResult = ApolloReactCommon.QueryResult<GetShowroomSelectedMediaQuery, GetShowroomSelectedMediaQueryVariables>;
export const GetShowroomSelectedTabDocument = gql`
    query getShowroomSelectedTab {
  showroom @client {
    userView {
      selectedTab
    }
  }
}
    `;

/**
 * __useGetShowroomSelectedTabQuery__
 *
 * To run a query within a React component, call `useGetShowroomSelectedTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSelectedTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSelectedTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShowroomSelectedTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSelectedTabQuery, GetShowroomSelectedTabQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSelectedTabQuery, GetShowroomSelectedTabQueryVariables>(GetShowroomSelectedTabDocument, baseOptions);
      }
export function useGetShowroomSelectedTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSelectedTabQuery, GetShowroomSelectedTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSelectedTabQuery, GetShowroomSelectedTabQueryVariables>(GetShowroomSelectedTabDocument, baseOptions);
        }
export type GetShowroomSelectedTabQueryHookResult = ReturnType<typeof useGetShowroomSelectedTabQuery>;
export type GetShowroomSelectedTabLazyQueryHookResult = ReturnType<typeof useGetShowroomSelectedTabLazyQuery>;
export type GetShowroomSelectedTabQueryResult = ApolloReactCommon.QueryResult<GetShowroomSelectedTabQuery, GetShowroomSelectedTabQueryVariables>;
export const GetMobileMenuOpenedDocument = gql`
    query getMobileMenuOpened {
  showroom @client {
    userView {
      activeMediaMobileMenu {
        mediaId
        type
      }
    }
  }
}
    `;

/**
 * __useGetMobileMenuOpenedQuery__
 *
 * To run a query within a React component, call `useGetMobileMenuOpenedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileMenuOpenedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileMenuOpenedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobileMenuOpenedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMobileMenuOpenedQuery, GetMobileMenuOpenedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMobileMenuOpenedQuery, GetMobileMenuOpenedQueryVariables>(GetMobileMenuOpenedDocument, baseOptions);
      }
export function useGetMobileMenuOpenedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMobileMenuOpenedQuery, GetMobileMenuOpenedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMobileMenuOpenedQuery, GetMobileMenuOpenedQueryVariables>(GetMobileMenuOpenedDocument, baseOptions);
        }
export type GetMobileMenuOpenedQueryHookResult = ReturnType<typeof useGetMobileMenuOpenedQuery>;
export type GetMobileMenuOpenedLazyQueryHookResult = ReturnType<typeof useGetMobileMenuOpenedLazyQuery>;
export type GetMobileMenuOpenedQueryResult = ApolloReactCommon.QueryResult<GetMobileMenuOpenedQuery, GetMobileMenuOpenedQueryVariables>;
export const GetShowroomMediaMobileInfoDocument = gql`
    query getShowroomMediaMobileInfo($mediaId: String!) {
  cachedMedia(mediaId: $mediaId) @client {
    id
    name
    cloudinaryId
    mediaType
    isSample
    extension
  }
}
    `;

/**
 * __useGetShowroomMediaMobileInfoQuery__
 *
 * To run a query within a React component, call `useGetShowroomMediaMobileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomMediaMobileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomMediaMobileInfoQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetShowroomMediaMobileInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomMediaMobileInfoQuery, GetShowroomMediaMobileInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomMediaMobileInfoQuery, GetShowroomMediaMobileInfoQueryVariables>(GetShowroomMediaMobileInfoDocument, baseOptions);
      }
export function useGetShowroomMediaMobileInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomMediaMobileInfoQuery, GetShowroomMediaMobileInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomMediaMobileInfoQuery, GetShowroomMediaMobileInfoQueryVariables>(GetShowroomMediaMobileInfoDocument, baseOptions);
        }
export type GetShowroomMediaMobileInfoQueryHookResult = ReturnType<typeof useGetShowroomMediaMobileInfoQuery>;
export type GetShowroomMediaMobileInfoLazyQueryHookResult = ReturnType<typeof useGetShowroomMediaMobileInfoLazyQuery>;
export type GetShowroomMediaMobileInfoQueryResult = ApolloReactCommon.QueryResult<GetShowroomMediaMobileInfoQuery, GetShowroomMediaMobileInfoQueryVariables>;
export const GetShowroomMediaDocument = gql`
    query getShowroomMedia($params: ShowroomGetParams!, $mediaId: String!) {
  showroomMedia(params: $params, mediaId: $mediaId) {
    ...ProductLayerDetailsFields
  }
}
    ${ProductLayerDetailsFieldsFragmentDoc}`;

/**
 * __useGetShowroomMediaQuery__
 *
 * To run a query within a React component, call `useGetShowroomMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomMediaQuery({
 *   variables: {
 *      params: // value for 'params'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetShowroomMediaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomMediaQuery, GetShowroomMediaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomMediaQuery, GetShowroomMediaQueryVariables>(GetShowroomMediaDocument, baseOptions);
      }
export function useGetShowroomMediaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomMediaQuery, GetShowroomMediaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomMediaQuery, GetShowroomMediaQueryVariables>(GetShowroomMediaDocument, baseOptions);
        }
export type GetShowroomMediaQueryHookResult = ReturnType<typeof useGetShowroomMediaQuery>;
export type GetShowroomMediaLazyQueryHookResult = ReturnType<typeof useGetShowroomMediaLazyQuery>;
export type GetShowroomMediaQueryResult = ApolloReactCommon.QueryResult<GetShowroomMediaQuery, GetShowroomMediaQueryVariables>;
export const GetShowroomsDocument = gql`
    query getShowrooms($archive: Boolean) {
  getShowrooms(archive: $archive) {
    id
    name
    password
  }
}
    `;

/**
 * __useGetShowroomsQuery__
 *
 * To run a query within a React component, call `useGetShowroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomsQuery({
 *   variables: {
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useGetShowroomsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomsQuery, GetShowroomsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomsQuery, GetShowroomsQueryVariables>(GetShowroomsDocument, baseOptions);
      }
export function useGetShowroomsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomsQuery, GetShowroomsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomsQuery, GetShowroomsQueryVariables>(GetShowroomsDocument, baseOptions);
        }
export type GetShowroomsQueryHookResult = ReturnType<typeof useGetShowroomsQuery>;
export type GetShowroomsLazyQueryHookResult = ReturnType<typeof useGetShowroomsLazyQuery>;
export type GetShowroomsQueryResult = ApolloReactCommon.QueryResult<GetShowroomsQuery, GetShowroomsQueryVariables>;
export const GetPublicShowroomDocument = gql`
    query getPublicShowroom($id: String!, $tenant: String, $password: String) {
  getShowroom(id: $id, tenant: $tenant, password: $password) {
    ...ShowroomInfo
    sections {
      ...ShowroomSectionInfoWithRowMediaIds
    }
  }
}
    ${ShowroomInfoFragmentDoc}
${ShowroomSectionInfoWithRowMediaIdsFragmentDoc}`;

/**
 * __useGetPublicShowroomQuery__
 *
 * To run a query within a React component, call `useGetPublicShowroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicShowroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicShowroomQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetPublicShowroomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicShowroomQuery, GetPublicShowroomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicShowroomQuery, GetPublicShowroomQueryVariables>(GetPublicShowroomDocument, baseOptions);
      }
export function useGetPublicShowroomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicShowroomQuery, GetPublicShowroomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicShowroomQuery, GetPublicShowroomQueryVariables>(GetPublicShowroomDocument, baseOptions);
        }
export type GetPublicShowroomQueryHookResult = ReturnType<typeof useGetPublicShowroomQuery>;
export type GetPublicShowroomLazyQueryHookResult = ReturnType<typeof useGetPublicShowroomLazyQuery>;
export type GetPublicShowroomQueryResult = ApolloReactCommon.QueryResult<GetPublicShowroomQuery, GetPublicShowroomQueryVariables>;
export const GetShowroomSectionsDocument = gql`
    query getShowroomSections($id: String!, $tenant: String, $password: String) {
  getShowroom(id: $id, tenant: $tenant, password: $password) {
    sections {
      ...ShowroomSectionInfoWithRowMediaIds
    }
  }
}
    ${ShowroomSectionInfoWithRowMediaIdsFragmentDoc}`;

/**
 * __useGetShowroomSectionsQuery__
 *
 * To run a query within a React component, call `useGetShowroomSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetShowroomSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSectionsQuery, GetShowroomSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSectionsQuery, GetShowroomSectionsQueryVariables>(GetShowroomSectionsDocument, baseOptions);
      }
export function useGetShowroomSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSectionsQuery, GetShowroomSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSectionsQuery, GetShowroomSectionsQueryVariables>(GetShowroomSectionsDocument, baseOptions);
        }
export type GetShowroomSectionsQueryHookResult = ReturnType<typeof useGetShowroomSectionsQuery>;
export type GetShowroomSectionsLazyQueryHookResult = ReturnType<typeof useGetShowroomSectionsLazyQuery>;
export type GetShowroomSectionsQueryResult = ApolloReactCommon.QueryResult<GetShowroomSectionsQuery, GetShowroomSectionsQueryVariables>;
export const GetPublicShowroomWithoutSectionsDocument = gql`
    query getPublicShowroomWithoutSections($id: String!, $tenant: String, $password: String) {
  getShowroom(id: $id, tenant: $tenant, password: $password) {
    ...ShowroomInfo
  }
}
    ${ShowroomInfoFragmentDoc}`;

/**
 * __useGetPublicShowroomWithoutSectionsQuery__
 *
 * To run a query within a React component, call `useGetPublicShowroomWithoutSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicShowroomWithoutSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicShowroomWithoutSectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetPublicShowroomWithoutSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicShowroomWithoutSectionsQuery, GetPublicShowroomWithoutSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicShowroomWithoutSectionsQuery, GetPublicShowroomWithoutSectionsQueryVariables>(GetPublicShowroomWithoutSectionsDocument, baseOptions);
      }
export function useGetPublicShowroomWithoutSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicShowroomWithoutSectionsQuery, GetPublicShowroomWithoutSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicShowroomWithoutSectionsQuery, GetPublicShowroomWithoutSectionsQueryVariables>(GetPublicShowroomWithoutSectionsDocument, baseOptions);
        }
export type GetPublicShowroomWithoutSectionsQueryHookResult = ReturnType<typeof useGetPublicShowroomWithoutSectionsQuery>;
export type GetPublicShowroomWithoutSectionsLazyQueryHookResult = ReturnType<typeof useGetPublicShowroomWithoutSectionsLazyQuery>;
export type GetPublicShowroomWithoutSectionsQueryResult = ApolloReactCommon.QueryResult<GetPublicShowroomWithoutSectionsQuery, GetPublicShowroomWithoutSectionsQueryVariables>;
export const GetShowroomDocument = gql`
    query getShowroom($id: String!, $tenant: String, $password: String) {
  getShowroom(id: $id, tenant: $tenant, password: $password) {
    ...ShowroomInfo
    password
    sections {
      ...ShowroomSectionInfoWithRowMediaIds
    }
  }
}
    ${ShowroomInfoFragmentDoc}
${ShowroomSectionInfoWithRowMediaIdsFragmentDoc}`;

/**
 * __useGetShowroomQuery__
 *
 * To run a query within a React component, call `useGetShowroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetShowroomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomQuery, GetShowroomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomQuery, GetShowroomQueryVariables>(GetShowroomDocument, baseOptions);
      }
export function useGetShowroomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomQuery, GetShowroomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomQuery, GetShowroomQueryVariables>(GetShowroomDocument, baseOptions);
        }
export type GetShowroomQueryHookResult = ReturnType<typeof useGetShowroomQuery>;
export type GetShowroomLazyQueryHookResult = ReturnType<typeof useGetShowroomLazyQuery>;
export type GetShowroomQueryResult = ApolloReactCommon.QueryResult<GetShowroomQuery, GetShowroomQueryVariables>;
export const GetShowroomWithoutSectionsDocument = gql`
    query getShowroomWithoutSections($id: String!, $tenant: String, $password: String) {
  getShowroom(id: $id, tenant: $tenant, password: $password) {
    ...ShowroomInfo
    password
  }
}
    ${ShowroomInfoFragmentDoc}`;

/**
 * __useGetShowroomWithoutSectionsQuery__
 *
 * To run a query within a React component, call `useGetShowroomWithoutSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomWithoutSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomWithoutSectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetShowroomWithoutSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomWithoutSectionsQuery, GetShowroomWithoutSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomWithoutSectionsQuery, GetShowroomWithoutSectionsQueryVariables>(GetShowroomWithoutSectionsDocument, baseOptions);
      }
export function useGetShowroomWithoutSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomWithoutSectionsQuery, GetShowroomWithoutSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomWithoutSectionsQuery, GetShowroomWithoutSectionsQueryVariables>(GetShowroomWithoutSectionsDocument, baseOptions);
        }
export type GetShowroomWithoutSectionsQueryHookResult = ReturnType<typeof useGetShowroomWithoutSectionsQuery>;
export type GetShowroomWithoutSectionsLazyQueryHookResult = ReturnType<typeof useGetShowroomWithoutSectionsLazyQuery>;
export type GetShowroomWithoutSectionsQueryResult = ApolloReactCommon.QueryResult<GetShowroomWithoutSectionsQuery, GetShowroomWithoutSectionsQueryVariables>;
export const GetPublicShowroomFieldsDocument = gql`
    query getPublicShowroomFields($id: String!, $tenant: String!) {
  publicShowroomFields(id: $id, tenant: $tenant) {
    id
    name
    language
    libraryCollection
    librarySeason
    heroMedia {
      mediaType
      publicId
      publicUrl
    }
    logo
    defaultBackground {
      publicUrl
      publicId
      mediaType
      ... on ShowroomImage {
        width
        height
      }
    }
  }
}
    `;

/**
 * __useGetPublicShowroomFieldsQuery__
 *
 * To run a query within a React component, call `useGetPublicShowroomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicShowroomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicShowroomFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useGetPublicShowroomFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicShowroomFieldsQuery, GetPublicShowroomFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicShowroomFieldsQuery, GetPublicShowroomFieldsQueryVariables>(GetPublicShowroomFieldsDocument, baseOptions);
      }
export function useGetPublicShowroomFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicShowroomFieldsQuery, GetPublicShowroomFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicShowroomFieldsQuery, GetPublicShowroomFieldsQueryVariables>(GetPublicShowroomFieldsDocument, baseOptions);
        }
export type GetPublicShowroomFieldsQueryHookResult = ReturnType<typeof useGetPublicShowroomFieldsQuery>;
export type GetPublicShowroomFieldsLazyQueryHookResult = ReturnType<typeof useGetPublicShowroomFieldsLazyQuery>;
export type GetPublicShowroomFieldsQueryResult = ApolloReactCommon.QueryResult<GetPublicShowroomFieldsQuery, GetPublicShowroomFieldsQueryVariables>;
export const DeleteShowroomDocument = gql`
    mutation deleteShowroom($showroomId: String!) {
  deleteShowroom(showroomId: $showroomId)
}
    `;
export type DeleteShowroomMutationFn = ApolloReactCommon.MutationFunction<DeleteShowroomMutation, DeleteShowroomMutationVariables>;

/**
 * __useDeleteShowroomMutation__
 *
 * To run a mutation, you first call `useDeleteShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShowroomMutation, { data, loading, error }] = useDeleteShowroomMutation({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useDeleteShowroomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShowroomMutation, DeleteShowroomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShowroomMutation, DeleteShowroomMutationVariables>(DeleteShowroomDocument, baseOptions);
      }
export type DeleteShowroomMutationHookResult = ReturnType<typeof useDeleteShowroomMutation>;
export type DeleteShowroomMutationResult = ApolloReactCommon.MutationResult<DeleteShowroomMutation>;
export type DeleteShowroomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShowroomMutation, DeleteShowroomMutationVariables>;
export const DeleteShowroomsDocument = gql`
    mutation deleteShowrooms($ids: [String]!) {
  deleteShowrooms(ids: $ids)
}
    `;
export type DeleteShowroomsMutationFn = ApolloReactCommon.MutationFunction<DeleteShowroomsMutation, DeleteShowroomsMutationVariables>;

/**
 * __useDeleteShowroomsMutation__
 *
 * To run a mutation, you first call `useDeleteShowroomsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShowroomsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShowroomsMutation, { data, loading, error }] = useDeleteShowroomsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteShowroomsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShowroomsMutation, DeleteShowroomsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShowroomsMutation, DeleteShowroomsMutationVariables>(DeleteShowroomsDocument, baseOptions);
      }
export type DeleteShowroomsMutationHookResult = ReturnType<typeof useDeleteShowroomsMutation>;
export type DeleteShowroomsMutationResult = ApolloReactCommon.MutationResult<DeleteShowroomsMutation>;
export type DeleteShowroomsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShowroomsMutation, DeleteShowroomsMutationVariables>;
export const UpdateShowroomsFromListDocument = gql`
    mutation updateShowroomsFromList($ids: [String]!, $params: ShowroomUpdateParams) {
  updateShowrooms(ids: $ids, params: $params) {
    id
    name
    password
  }
}
    `;
export type UpdateShowroomsFromListMutationFn = ApolloReactCommon.MutationFunction<UpdateShowroomsFromListMutation, UpdateShowroomsFromListMutationVariables>;

/**
 * __useUpdateShowroomsFromListMutation__
 *
 * To run a mutation, you first call `useUpdateShowroomsFromListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowroomsFromListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowroomsFromListMutation, { data, loading, error }] = useUpdateShowroomsFromListMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateShowroomsFromListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShowroomsFromListMutation, UpdateShowroomsFromListMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShowroomsFromListMutation, UpdateShowroomsFromListMutationVariables>(UpdateShowroomsFromListDocument, baseOptions);
      }
export type UpdateShowroomsFromListMutationHookResult = ReturnType<typeof useUpdateShowroomsFromListMutation>;
export type UpdateShowroomsFromListMutationResult = ApolloReactCommon.MutationResult<UpdateShowroomsFromListMutation>;
export type UpdateShowroomsFromListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShowroomsFromListMutation, UpdateShowroomsFromListMutationVariables>;
export const GenerateShowroomArchiveDocument = gql`
    mutation generateShowroomArchive($params: GenerateShowroomArchiveParams!) {
  generateShowroomArchive(params: $params) {
    id
  }
}
    `;
export type GenerateShowroomArchiveMutationFn = ApolloReactCommon.MutationFunction<GenerateShowroomArchiveMutation, GenerateShowroomArchiveMutationVariables>;

/**
 * __useGenerateShowroomArchiveMutation__
 *
 * To run a mutation, you first call `useGenerateShowroomArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateShowroomArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateShowroomArchiveMutation, { data, loading, error }] = useGenerateShowroomArchiveMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGenerateShowroomArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateShowroomArchiveMutation, GenerateShowroomArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateShowroomArchiveMutation, GenerateShowroomArchiveMutationVariables>(GenerateShowroomArchiveDocument, baseOptions);
      }
export type GenerateShowroomArchiveMutationHookResult = ReturnType<typeof useGenerateShowroomArchiveMutation>;
export type GenerateShowroomArchiveMutationResult = ApolloReactCommon.MutationResult<GenerateShowroomArchiveMutation>;
export type GenerateShowroomArchiveMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateShowroomArchiveMutation, GenerateShowroomArchiveMutationVariables>;
export const DuplicateShowroomDocument = gql`
    mutation duplicateShowroom($showroomId: String!) {
  duplicateShowroom(showroomId: $showroomId) {
    id
    name
    password
    archive
  }
}
    `;
export type DuplicateShowroomMutationFn = ApolloReactCommon.MutationFunction<DuplicateShowroomMutation, DuplicateShowroomMutationVariables>;

/**
 * __useDuplicateShowroomMutation__
 *
 * To run a mutation, you first call `useDuplicateShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateShowroomMutation, { data, loading, error }] = useDuplicateShowroomMutation({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useDuplicateShowroomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateShowroomMutation, DuplicateShowroomMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateShowroomMutation, DuplicateShowroomMutationVariables>(DuplicateShowroomDocument, baseOptions);
      }
export type DuplicateShowroomMutationHookResult = ReturnType<typeof useDuplicateShowroomMutation>;
export type DuplicateShowroomMutationResult = ApolloReactCommon.MutationResult<DuplicateShowroomMutation>;
export type DuplicateShowroomMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateShowroomMutation, DuplicateShowroomMutationVariables>;
export const GetShowroomShareInfoDocument = gql`
    query getShowroomShareInfo($showroomId: String!) {
  getShowroom(id: $showroomId) {
    id
    name
    password
  }
}
    `;

/**
 * __useGetShowroomShareInfoQuery__
 *
 * To run a query within a React component, call `useGetShowroomShareInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomShareInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomShareInfoQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomShareInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomShareInfoQuery, GetShowroomShareInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomShareInfoQuery, GetShowroomShareInfoQueryVariables>(GetShowroomShareInfoDocument, baseOptions);
      }
export function useGetShowroomShareInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomShareInfoQuery, GetShowroomShareInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomShareInfoQuery, GetShowroomShareInfoQueryVariables>(GetShowroomShareInfoDocument, baseOptions);
        }
export type GetShowroomShareInfoQueryHookResult = ReturnType<typeof useGetShowroomShareInfoQuery>;
export type GetShowroomShareInfoLazyQueryHookResult = ReturnType<typeof useGetShowroomShareInfoLazyQuery>;
export type GetShowroomShareInfoQueryResult = ApolloReactCommon.QueryResult<GetShowroomShareInfoQuery, GetShowroomShareInfoQueryVariables>;
export const GetShowroomDownloadEnabledDocument = gql`
    query getShowroomDownloadEnabled($showroomId: String!) {
  getShowroom(id: $showroomId) {
    id
    downloadEnabled
  }
}
    `;

/**
 * __useGetShowroomDownloadEnabledQuery__
 *
 * To run a query within a React component, call `useGetShowroomDownloadEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomDownloadEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomDownloadEnabledQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomDownloadEnabledQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomDownloadEnabledQuery, GetShowroomDownloadEnabledQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomDownloadEnabledQuery, GetShowroomDownloadEnabledQueryVariables>(GetShowroomDownloadEnabledDocument, baseOptions);
      }
export function useGetShowroomDownloadEnabledLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomDownloadEnabledQuery, GetShowroomDownloadEnabledQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomDownloadEnabledQuery, GetShowroomDownloadEnabledQueryVariables>(GetShowroomDownloadEnabledDocument, baseOptions);
        }
export type GetShowroomDownloadEnabledQueryHookResult = ReturnType<typeof useGetShowroomDownloadEnabledQuery>;
export type GetShowroomDownloadEnabledLazyQueryHookResult = ReturnType<typeof useGetShowroomDownloadEnabledLazyQuery>;
export type GetShowroomDownloadEnabledQueryResult = ApolloReactCommon.QueryResult<GetShowroomDownloadEnabledQuery, GetShowroomDownloadEnabledQueryVariables>;
export const GetShowroomBasketInfoDocument = gql`
    query getShowroomBasketInfo($showroomId: String!) {
  getShowroom(id: $showroomId) {
    id
    tenant {
      isSamplesEnabled
    }
    requestSamplesEnabled
    hasSamples
  }
}
    `;

/**
 * __useGetShowroomBasketInfoQuery__
 *
 * To run a query within a React component, call `useGetShowroomBasketInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomBasketInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomBasketInfoQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomBasketInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomBasketInfoQuery, GetShowroomBasketInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomBasketInfoQuery, GetShowroomBasketInfoQueryVariables>(GetShowroomBasketInfoDocument, baseOptions);
      }
export function useGetShowroomBasketInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomBasketInfoQuery, GetShowroomBasketInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomBasketInfoQuery, GetShowroomBasketInfoQueryVariables>(GetShowroomBasketInfoDocument, baseOptions);
        }
export type GetShowroomBasketInfoQueryHookResult = ReturnType<typeof useGetShowroomBasketInfoQuery>;
export type GetShowroomBasketInfoLazyQueryHookResult = ReturnType<typeof useGetShowroomBasketInfoLazyQuery>;
export type GetShowroomBasketInfoQueryResult = ApolloReactCommon.QueryResult<GetShowroomBasketInfoQuery, GetShowroomBasketInfoQueryVariables>;
export const GetShowroomSectionFiltersUserViewDocument = gql`
    query getShowroomSectionFiltersUserView($params: ShowroomGetParams!, $section: Int!) {
  showroomSectionFilters(params: $params, section: $section) {
    id
    groups
    values {
      id
      groupId
      value
      mediaIds
    }
  }
}
    `;

/**
 * __useGetShowroomSectionFiltersUserViewQuery__
 *
 * To run a query within a React component, call `useGetShowroomSectionFiltersUserViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSectionFiltersUserViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSectionFiltersUserViewQuery({
 *   variables: {
 *      params: // value for 'params'
 *      section: // value for 'section'
 *   },
 * });
 */
export function useGetShowroomSectionFiltersUserViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSectionFiltersUserViewQuery, GetShowroomSectionFiltersUserViewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSectionFiltersUserViewQuery, GetShowroomSectionFiltersUserViewQueryVariables>(GetShowroomSectionFiltersUserViewDocument, baseOptions);
      }
export function useGetShowroomSectionFiltersUserViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSectionFiltersUserViewQuery, GetShowroomSectionFiltersUserViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSectionFiltersUserViewQuery, GetShowroomSectionFiltersUserViewQueryVariables>(GetShowroomSectionFiltersUserViewDocument, baseOptions);
        }
export type GetShowroomSectionFiltersUserViewQueryHookResult = ReturnType<typeof useGetShowroomSectionFiltersUserViewQuery>;
export type GetShowroomSectionFiltersUserViewLazyQueryHookResult = ReturnType<typeof useGetShowroomSectionFiltersUserViewLazyQuery>;
export type GetShowroomSectionFiltersUserViewQueryResult = ApolloReactCommon.QueryResult<GetShowroomSectionFiltersUserViewQuery, GetShowroomSectionFiltersUserViewQueryVariables>;
export const GetShowroomSectionFilterValuesDocument = gql`
    query getShowroomSectionFilterValues($ids: [String]) {
  showroomFilterValues(ids: $ids) @client {
    ...ShowroomFilterValueInfo
  }
}
    ${ShowroomFilterValueInfoFragmentDoc}`;

/**
 * __useGetShowroomSectionFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetShowroomSectionFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomSectionFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomSectionFilterValuesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetShowroomSectionFilterValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomSectionFilterValuesQuery, GetShowroomSectionFilterValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomSectionFilterValuesQuery, GetShowroomSectionFilterValuesQueryVariables>(GetShowroomSectionFilterValuesDocument, baseOptions);
      }
export function useGetShowroomSectionFilterValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomSectionFilterValuesQuery, GetShowroomSectionFilterValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomSectionFilterValuesQuery, GetShowroomSectionFilterValuesQueryVariables>(GetShowroomSectionFilterValuesDocument, baseOptions);
        }
export type GetShowroomSectionFilterValuesQueryHookResult = ReturnType<typeof useGetShowroomSectionFilterValuesQuery>;
export type GetShowroomSectionFilterValuesLazyQueryHookResult = ReturnType<typeof useGetShowroomSectionFilterValuesLazyQuery>;
export type GetShowroomSectionFilterValuesQueryResult = ApolloReactCommon.QueryResult<GetShowroomSectionFilterValuesQuery, GetShowroomSectionFilterValuesQueryVariables>;
export const GetShowroomPrContactsDocument = gql`
    query getShowroomPrContacts($showroomId: String!) {
  getShowroom(id: $showroomId) {
    id
    prContacts {
      id
      name
      jobTitle
      email
      phone
    }
  }
}
    `;

/**
 * __useGetShowroomPrContactsQuery__
 *
 * To run a query within a React component, call `useGetShowroomPrContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomPrContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomPrContactsQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomPrContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowroomPrContactsQuery, GetShowroomPrContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowroomPrContactsQuery, GetShowroomPrContactsQueryVariables>(GetShowroomPrContactsDocument, baseOptions);
      }
export function useGetShowroomPrContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowroomPrContactsQuery, GetShowroomPrContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowroomPrContactsQuery, GetShowroomPrContactsQueryVariables>(GetShowroomPrContactsDocument, baseOptions);
        }
export type GetShowroomPrContactsQueryHookResult = ReturnType<typeof useGetShowroomPrContactsQuery>;
export type GetShowroomPrContactsLazyQueryHookResult = ReturnType<typeof useGetShowroomPrContactsLazyQuery>;
export type GetShowroomPrContactsQueryResult = ApolloReactCommon.QueryResult<GetShowroomPrContactsQuery, GetShowroomPrContactsQueryVariables>;
export const GetSpotlightDrawerStateDocument = gql`
    query getSpotlightDrawerState {
  spotlight @client {
    spotlightDrawer {
      open
      type
      params {
        id
      }
    }
  }
}
    `;

/**
 * __useGetSpotlightDrawerStateQuery__
 *
 * To run a query within a React component, call `useGetSpotlightDrawerStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightDrawerStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightDrawerStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotlightDrawerStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotlightDrawerStateQuery, GetSpotlightDrawerStateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotlightDrawerStateQuery, GetSpotlightDrawerStateQueryVariables>(GetSpotlightDrawerStateDocument, baseOptions);
      }
export function useGetSpotlightDrawerStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotlightDrawerStateQuery, GetSpotlightDrawerStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotlightDrawerStateQuery, GetSpotlightDrawerStateQueryVariables>(GetSpotlightDrawerStateDocument, baseOptions);
        }
export type GetSpotlightDrawerStateQueryHookResult = ReturnType<typeof useGetSpotlightDrawerStateQuery>;
export type GetSpotlightDrawerStateLazyQueryHookResult = ReturnType<typeof useGetSpotlightDrawerStateLazyQuery>;
export type GetSpotlightDrawerStateQueryResult = ApolloReactCommon.QueryResult<GetSpotlightDrawerStateQuery, GetSpotlightDrawerStateQueryVariables>;
export const GetSpotlightVisitorDocument = gql`
    query getSpotlightVisitor($id: String!) {
  spotlightVisitor(id: $id) {
    ...VisitorFragment
  }
}
    ${VisitorFragmentFragmentDoc}`;

/**
 * __useGetSpotlightVisitorQuery__
 *
 * To run a query within a React component, call `useGetSpotlightVisitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightVisitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightVisitorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSpotlightVisitorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotlightVisitorQuery, GetSpotlightVisitorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotlightVisitorQuery, GetSpotlightVisitorQueryVariables>(GetSpotlightVisitorDocument, baseOptions);
      }
export function useGetSpotlightVisitorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotlightVisitorQuery, GetSpotlightVisitorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotlightVisitorQuery, GetSpotlightVisitorQueryVariables>(GetSpotlightVisitorDocument, baseOptions);
        }
export type GetSpotlightVisitorQueryHookResult = ReturnType<typeof useGetSpotlightVisitorQuery>;
export type GetSpotlightVisitorLazyQueryHookResult = ReturnType<typeof useGetSpotlightVisitorLazyQuery>;
export type GetSpotlightVisitorQueryResult = ApolloReactCommon.QueryResult<GetSpotlightVisitorQuery, GetSpotlightVisitorQueryVariables>;
export const GetSpotlightMagazineDocument = gql`
    query getSpotlightMagazine($id: String!) {
  spotlightMagazine(id: $id) {
    ...MagazineFragment
  }
}
    ${MagazineFragmentFragmentDoc}`;

/**
 * __useGetSpotlightMagazineQuery__
 *
 * To run a query within a React component, call `useGetSpotlightMagazineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightMagazineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightMagazineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSpotlightMagazineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotlightMagazineQuery, GetSpotlightMagazineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotlightMagazineQuery, GetSpotlightMagazineQueryVariables>(GetSpotlightMagazineDocument, baseOptions);
      }
export function useGetSpotlightMagazineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotlightMagazineQuery, GetSpotlightMagazineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotlightMagazineQuery, GetSpotlightMagazineQueryVariables>(GetSpotlightMagazineDocument, baseOptions);
        }
export type GetSpotlightMagazineQueryHookResult = ReturnType<typeof useGetSpotlightMagazineQuery>;
export type GetSpotlightMagazineLazyQueryHookResult = ReturnType<typeof useGetSpotlightMagazineLazyQuery>;
export type GetSpotlightMagazineQueryResult = ApolloReactCommon.QueryResult<GetSpotlightMagazineQuery, GetSpotlightMagazineQueryVariables>;
export const GetCollectionMagazineEntityVisualContentDocument = gql`
    query getCollectionMagazineEntityVisualContent($collectionId: String!, $params: VisualContentPerformanceParameters!, $limit: Int) {
  collectionEntityVisualContent(collectionId: $collectionId, params: $params, limit: $limit) {
    visualContent {
      media {
        id
        name
        cloudinaryId
        cloudinaryVersion
        mediaType
      }
    }
    metrics {
      totalDownloads
      totalViews
    }
  }
}
    `;

/**
 * __useGetCollectionMagazineEntityVisualContentQuery__
 *
 * To run a query within a React component, call `useGetCollectionMagazineEntityVisualContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionMagazineEntityVisualContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionMagazineEntityVisualContentQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      params: // value for 'params'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionMagazineEntityVisualContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionMagazineEntityVisualContentQuery, GetCollectionMagazineEntityVisualContentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionMagazineEntityVisualContentQuery, GetCollectionMagazineEntityVisualContentQueryVariables>(GetCollectionMagazineEntityVisualContentDocument, baseOptions);
      }
export function useGetCollectionMagazineEntityVisualContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionMagazineEntityVisualContentQuery, GetCollectionMagazineEntityVisualContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionMagazineEntityVisualContentQuery, GetCollectionMagazineEntityVisualContentQueryVariables>(GetCollectionMagazineEntityVisualContentDocument, baseOptions);
        }
export type GetCollectionMagazineEntityVisualContentQueryHookResult = ReturnType<typeof useGetCollectionMagazineEntityVisualContentQuery>;
export type GetCollectionMagazineEntityVisualContentLazyQueryHookResult = ReturnType<typeof useGetCollectionMagazineEntityVisualContentLazyQuery>;
export type GetCollectionMagazineEntityVisualContentQueryResult = ApolloReactCommon.QueryResult<GetCollectionMagazineEntityVisualContentQuery, GetCollectionMagazineEntityVisualContentQueryVariables>;
export const GetCollectionVoiceVisualContentDocument = gql`
    query getCollectionVoiceVisualContent($collectionId: String!, $voiceId: String!, $dates: TimeRange!, $limit: Int) {
  collectionVoiceVisualContent(collectionId: $collectionId, voiceId: $voiceId, dates: $dates, limit: $limit) {
    visualContent {
      media {
        id
        name
        cloudinaryId
        cloudinaryVersion
        mediaType
      }
    }
    metrics {
      totalDownloads
      totalViews
      totalMiv
      totalPlacements
    }
  }
}
    `;

/**
 * __useGetCollectionVoiceVisualContentQuery__
 *
 * To run a query within a React component, call `useGetCollectionVoiceVisualContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionVoiceVisualContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionVoiceVisualContentQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      voiceId: // value for 'voiceId'
 *      dates: // value for 'dates'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionVoiceVisualContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionVoiceVisualContentQuery, GetCollectionVoiceVisualContentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionVoiceVisualContentQuery, GetCollectionVoiceVisualContentQueryVariables>(GetCollectionVoiceVisualContentDocument, baseOptions);
      }
export function useGetCollectionVoiceVisualContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionVoiceVisualContentQuery, GetCollectionVoiceVisualContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionVoiceVisualContentQuery, GetCollectionVoiceVisualContentQueryVariables>(GetCollectionVoiceVisualContentDocument, baseOptions);
        }
export type GetCollectionVoiceVisualContentQueryHookResult = ReturnType<typeof useGetCollectionVoiceVisualContentQuery>;
export type GetCollectionVoiceVisualContentLazyQueryHookResult = ReturnType<typeof useGetCollectionVoiceVisualContentLazyQuery>;
export type GetCollectionVoiceVisualContentQueryResult = ApolloReactCommon.QueryResult<GetCollectionVoiceVisualContentQuery, GetCollectionVoiceVisualContentQueryVariables>;
export const GetCollectionEntityPerformanceDocument = gql`
    query getCollectionEntityPerformance($collectionId: String!, $params: VisualContentPerformanceParameters!) {
  collectionEntityPerformance(collectionId: $collectionId, params: $params) {
    metrics {
      totalMiv
      totalPlacements
      totalViews
      totalDownloads
    }
    funnel {
      published
      viewed
      downloaded
      placed
    }
  }
}
    `;

/**
 * __useGetCollectionEntityPerformanceQuery__
 *
 * To run a query within a React component, call `useGetCollectionEntityPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionEntityPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionEntityPerformanceQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCollectionEntityPerformanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionEntityPerformanceQuery, GetCollectionEntityPerformanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionEntityPerformanceQuery, GetCollectionEntityPerformanceQueryVariables>(GetCollectionEntityPerformanceDocument, baseOptions);
      }
export function useGetCollectionEntityPerformanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionEntityPerformanceQuery, GetCollectionEntityPerformanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionEntityPerformanceQuery, GetCollectionEntityPerformanceQueryVariables>(GetCollectionEntityPerformanceDocument, baseOptions);
        }
export type GetCollectionEntityPerformanceQueryHookResult = ReturnType<typeof useGetCollectionEntityPerformanceQuery>;
export type GetCollectionEntityPerformanceLazyQueryHookResult = ReturnType<typeof useGetCollectionEntityPerformanceLazyQuery>;
export type GetCollectionEntityPerformanceQueryResult = ApolloReactCommon.QueryResult<GetCollectionEntityPerformanceQuery, GetCollectionEntityPerformanceQueryVariables>;
export const GetCollectionVoicePerformanceDocument = gql`
    query getCollectionVoicePerformance($collectionId: String!, $voiceId: String!, $dates: TimeRange!) {
  collectionVoicePerformance(collectionId: $collectionId, voiceId: $voiceId, dates: $dates) {
    metrics {
      totalMiv
      totalPlacements
      totalViews
      totalDownloads
    }
    funnel {
      published
      viewed
      downloaded
      placed
    }
  }
}
    `;

/**
 * __useGetCollectionVoicePerformanceQuery__
 *
 * To run a query within a React component, call `useGetCollectionVoicePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionVoicePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionVoicePerformanceQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      voiceId: // value for 'voiceId'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useGetCollectionVoicePerformanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionVoicePerformanceQuery, GetCollectionVoicePerformanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionVoicePerformanceQuery, GetCollectionVoicePerformanceQueryVariables>(GetCollectionVoicePerformanceDocument, baseOptions);
      }
export function useGetCollectionVoicePerformanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionVoicePerformanceQuery, GetCollectionVoicePerformanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionVoicePerformanceQuery, GetCollectionVoicePerformanceQueryVariables>(GetCollectionVoicePerformanceDocument, baseOptions);
        }
export type GetCollectionVoicePerformanceQueryHookResult = ReturnType<typeof useGetCollectionVoicePerformanceQuery>;
export type GetCollectionVoicePerformanceLazyQueryHookResult = ReturnType<typeof useGetCollectionVoicePerformanceLazyQuery>;
export type GetCollectionVoicePerformanceQueryResult = ApolloReactCommon.QueryResult<GetCollectionVoicePerformanceQuery, GetCollectionVoicePerformanceQueryVariables>;
export const GetCollectionVoicePlacementsDocument = gql`
    query getCollectionVoicePlacements($collectionId: String!, $voiceId: String!, $dates: TimeRange!, $limit: Int) {
  collectionVoicePlacements(collectionId: $collectionId, voiceId: $voiceId, dates: $dates, limit: $limit) {
    databackendId
    date
    articleLink
    source
    imageLink
    miv
    reach
    title
    countryCode
    numberOfMedias
    voiceId
    placementType
    engagement
    socialName
    socialAccountLink
    socialViews
  }
}
    `;

/**
 * __useGetCollectionVoicePlacementsQuery__
 *
 * To run a query within a React component, call `useGetCollectionVoicePlacementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionVoicePlacementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionVoicePlacementsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      voiceId: // value for 'voiceId'
 *      dates: // value for 'dates'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionVoicePlacementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionVoicePlacementsQuery, GetCollectionVoicePlacementsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionVoicePlacementsQuery, GetCollectionVoicePlacementsQueryVariables>(GetCollectionVoicePlacementsDocument, baseOptions);
      }
export function useGetCollectionVoicePlacementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionVoicePlacementsQuery, GetCollectionVoicePlacementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionVoicePlacementsQuery, GetCollectionVoicePlacementsQueryVariables>(GetCollectionVoicePlacementsDocument, baseOptions);
        }
export type GetCollectionVoicePlacementsQueryHookResult = ReturnType<typeof useGetCollectionVoicePlacementsQuery>;
export type GetCollectionVoicePlacementsLazyQueryHookResult = ReturnType<typeof useGetCollectionVoicePlacementsLazyQuery>;
export type GetCollectionVoicePlacementsQueryResult = ApolloReactCommon.QueryResult<GetCollectionVoicePlacementsQuery, GetCollectionVoicePlacementsQueryVariables>;
export const VisualContentModalStateDocument = gql`
    query visualContentModalState {
  spotlight @client {
    visualContentModal {
      open
      visualContent {
        ...VisualContentData
      }
    }
  }
}
    ${VisualContentDataFragmentDoc}`;

/**
 * __useVisualContentModalStateQuery__
 *
 * To run a query within a React component, call `useVisualContentModalStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualContentModalStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualContentModalStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisualContentModalStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VisualContentModalStateQuery, VisualContentModalStateQueryVariables>) {
        return ApolloReactHooks.useQuery<VisualContentModalStateQuery, VisualContentModalStateQueryVariables>(VisualContentModalStateDocument, baseOptions);
      }
export function useVisualContentModalStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VisualContentModalStateQuery, VisualContentModalStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VisualContentModalStateQuery, VisualContentModalStateQueryVariables>(VisualContentModalStateDocument, baseOptions);
        }
export type VisualContentModalStateQueryHookResult = ReturnType<typeof useVisualContentModalStateQuery>;
export type VisualContentModalStateLazyQueryHookResult = ReturnType<typeof useVisualContentModalStateLazyQuery>;
export type VisualContentModalStateQueryResult = ApolloReactCommon.QueryResult<VisualContentModalStateQuery, VisualContentModalStateQueryVariables>;
export const GetMediaPlacementsByDateDocument = gql`
    query getMediaPlacementsByDate($id: String!, $startDate: Date, $endDate: Date) {
  mediaPlacementsByDate(id: $id, startDate: $startDate, endDate: $endDate) {
    ...MediaPlacementFragment
  }
}
    ${MediaPlacementFragmentFragmentDoc}`;

/**
 * __useGetMediaPlacementsByDateQuery__
 *
 * To run a query within a React component, call `useGetMediaPlacementsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaPlacementsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaPlacementsByDateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetMediaPlacementsByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMediaPlacementsByDateQuery, GetMediaPlacementsByDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMediaPlacementsByDateQuery, GetMediaPlacementsByDateQueryVariables>(GetMediaPlacementsByDateDocument, baseOptions);
      }
export function useGetMediaPlacementsByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaPlacementsByDateQuery, GetMediaPlacementsByDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMediaPlacementsByDateQuery, GetMediaPlacementsByDateQueryVariables>(GetMediaPlacementsByDateDocument, baseOptions);
        }
export type GetMediaPlacementsByDateQueryHookResult = ReturnType<typeof useGetMediaPlacementsByDateQuery>;
export type GetMediaPlacementsByDateLazyQueryHookResult = ReturnType<typeof useGetMediaPlacementsByDateLazyQuery>;
export type GetMediaPlacementsByDateQueryResult = ApolloReactCommon.QueryResult<GetMediaPlacementsByDateQuery, GetMediaPlacementsByDateQueryVariables>;
export const CollectionInfoDocument = gql`
    query collectionInfo($brand: String, $season: String, $collection: String) {
  collectionInfo(brand: $brand, season: $season, collection: $collection) {
    id
    brandName
    seasonName
    collectionName
    publishedAt
    firstMediaPublicId
  }
}
    `;

/**
 * __useCollectionInfoQuery__
 *
 * To run a query within a React component, call `useCollectionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionInfoQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      season: // value for 'season'
 *      collection: // value for 'collection'
 *   },
 * });
 */
export function useCollectionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionInfoQuery, CollectionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionInfoQuery, CollectionInfoQueryVariables>(CollectionInfoDocument, baseOptions);
      }
export function useCollectionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionInfoQuery, CollectionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionInfoQuery, CollectionInfoQueryVariables>(CollectionInfoDocument, baseOptions);
        }
export type CollectionInfoQueryHookResult = ReturnType<typeof useCollectionInfoQuery>;
export type CollectionInfoLazyQueryHookResult = ReturnType<typeof useCollectionInfoLazyQuery>;
export type CollectionInfoQueryResult = ApolloReactCommon.QueryResult<CollectionInfoQuery, CollectionInfoQueryVariables>;
export const GetCurrentCollectionAndFiltersDocument = gql`
    query getCurrentCollectionAndFilters {
  spotlight @client {
    collection {
      currentCollectionId
      filters {
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCollectionAndFiltersQuery__
 *
 * To run a query within a React component, call `useGetCurrentCollectionAndFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCollectionAndFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCollectionAndFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCollectionAndFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentCollectionAndFiltersQuery, GetCurrentCollectionAndFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentCollectionAndFiltersQuery, GetCurrentCollectionAndFiltersQueryVariables>(GetCurrentCollectionAndFiltersDocument, baseOptions);
      }
export function useGetCurrentCollectionAndFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentCollectionAndFiltersQuery, GetCurrentCollectionAndFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentCollectionAndFiltersQuery, GetCurrentCollectionAndFiltersQueryVariables>(GetCurrentCollectionAndFiltersDocument, baseOptions);
        }
export type GetCurrentCollectionAndFiltersQueryHookResult = ReturnType<typeof useGetCurrentCollectionAndFiltersQuery>;
export type GetCurrentCollectionAndFiltersLazyQueryHookResult = ReturnType<typeof useGetCurrentCollectionAndFiltersLazyQuery>;
export type GetCurrentCollectionAndFiltersQueryResult = ApolloReactCommon.QueryResult<GetCurrentCollectionAndFiltersQuery, GetCurrentCollectionAndFiltersQueryVariables>;
export const GetCurrentCollectiondIdDocument = gql`
    query getCurrentCollectiondId {
  spotlight @client {
    collection {
      currentCollectionId
    }
  }
}
    `;

/**
 * __useGetCurrentCollectiondIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentCollectiondIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCollectiondIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCollectiondIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCollectiondIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentCollectiondIdQuery, GetCurrentCollectiondIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentCollectiondIdQuery, GetCurrentCollectiondIdQueryVariables>(GetCurrentCollectiondIdDocument, baseOptions);
      }
export function useGetCurrentCollectiondIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentCollectiondIdQuery, GetCurrentCollectiondIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentCollectiondIdQuery, GetCurrentCollectiondIdQueryVariables>(GetCurrentCollectiondIdDocument, baseOptions);
        }
export type GetCurrentCollectiondIdQueryHookResult = ReturnType<typeof useGetCurrentCollectiondIdQuery>;
export type GetCurrentCollectiondIdLazyQueryHookResult = ReturnType<typeof useGetCurrentCollectiondIdLazyQuery>;
export type GetCurrentCollectiondIdQueryResult = ApolloReactCommon.QueryResult<GetCurrentCollectiondIdQuery, GetCurrentCollectiondIdQueryVariables>;
export const GetCollectionLocalDataDocument = gql`
    query getCollectionLocalData($id: String!) {
  collectionById(id: $id) @client {
    id
    brandName
    seasonName
    collectionName
    publishedAt
  }
}
    `;

/**
 * __useGetCollectionLocalDataQuery__
 *
 * To run a query within a React component, call `useGetCollectionLocalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionLocalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionLocalDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionLocalDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionLocalDataQuery, GetCollectionLocalDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionLocalDataQuery, GetCollectionLocalDataQueryVariables>(GetCollectionLocalDataDocument, baseOptions);
      }
export function useGetCollectionLocalDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionLocalDataQuery, GetCollectionLocalDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionLocalDataQuery, GetCollectionLocalDataQueryVariables>(GetCollectionLocalDataDocument, baseOptions);
        }
export type GetCollectionLocalDataQueryHookResult = ReturnType<typeof useGetCollectionLocalDataQuery>;
export type GetCollectionLocalDataLazyQueryHookResult = ReturnType<typeof useGetCollectionLocalDataLazyQuery>;
export type GetCollectionLocalDataQueryResult = ApolloReactCommon.QueryResult<GetCollectionLocalDataQuery, GetCollectionLocalDataQueryVariables>;
export const CollectionFunnelDataDocument = gql`
    query collectionFunnelData($filters: SpotlightCollectionFilters) {
  collectionFunnelData(filters: $filters) {
    published
    viewed
    downloaded
    placed
  }
}
    `;

/**
 * __useCollectionFunnelDataQuery__
 *
 * To run a query within a React component, call `useCollectionFunnelDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionFunnelDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionFunnelDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCollectionFunnelDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionFunnelDataQuery, CollectionFunnelDataQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionFunnelDataQuery, CollectionFunnelDataQueryVariables>(CollectionFunnelDataDocument, baseOptions);
      }
export function useCollectionFunnelDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionFunnelDataQuery, CollectionFunnelDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionFunnelDataQuery, CollectionFunnelDataQueryVariables>(CollectionFunnelDataDocument, baseOptions);
        }
export type CollectionFunnelDataQueryHookResult = ReturnType<typeof useCollectionFunnelDataQuery>;
export type CollectionFunnelDataLazyQueryHookResult = ReturnType<typeof useCollectionFunnelDataLazyQuery>;
export type CollectionFunnelDataQueryResult = ApolloReactCommon.QueryResult<CollectionFunnelDataQuery, CollectionFunnelDataQueryVariables>;
export const CollectionMapDataDocument = gql`
    query collectionMapData($filters: SpotlightCollectionFilters) {
  collectionMapData(filters: $filters) {
    countries {
      countryCode
      metrics {
        totalMiv
        totalViews
        totalDownloads
        totalPlacements
      }
    }
    worldSource
  }
}
    `;

/**
 * __useCollectionMapDataQuery__
 *
 * To run a query within a React component, call `useCollectionMapDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionMapDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionMapDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCollectionMapDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionMapDataQuery, CollectionMapDataQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionMapDataQuery, CollectionMapDataQueryVariables>(CollectionMapDataDocument, baseOptions);
      }
export function useCollectionMapDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionMapDataQuery, CollectionMapDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionMapDataQuery, CollectionMapDataQueryVariables>(CollectionMapDataDocument, baseOptions);
        }
export type CollectionMapDataQueryHookResult = ReturnType<typeof useCollectionMapDataQuery>;
export type CollectionMapDataLazyQueryHookResult = ReturnType<typeof useCollectionMapDataLazyQuery>;
export type CollectionMapDataQueryResult = ApolloReactCommon.QueryResult<CollectionMapDataQuery, CollectionMapDataQueryVariables>;
export const CollectionSummaryCountersDocument = gql`
    query collectionSummaryCounters($filters: SpotlightCollectionFilters) {
  collectionSummaryCounters(filters: $filters) {
    totalMiv
    totalDownloads
    totalViews
    totalPlacements
  }
}
    `;

/**
 * __useCollectionSummaryCountersQuery__
 *
 * To run a query within a React component, call `useCollectionSummaryCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionSummaryCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionSummaryCountersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCollectionSummaryCountersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionSummaryCountersQuery, CollectionSummaryCountersQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionSummaryCountersQuery, CollectionSummaryCountersQueryVariables>(CollectionSummaryCountersDocument, baseOptions);
      }
export function useCollectionSummaryCountersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionSummaryCountersQuery, CollectionSummaryCountersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionSummaryCountersQuery, CollectionSummaryCountersQueryVariables>(CollectionSummaryCountersDocument, baseOptions);
        }
export type CollectionSummaryCountersQueryHookResult = ReturnType<typeof useCollectionSummaryCountersQuery>;
export type CollectionSummaryCountersLazyQueryHookResult = ReturnType<typeof useCollectionSummaryCountersLazyQuery>;
export type CollectionSummaryCountersQueryResult = ApolloReactCommon.QueryResult<CollectionSummaryCountersQuery, CollectionSummaryCountersQueryVariables>;
export const GetSpotlightCollectionVisitorSelectedTabDocument = gql`
    query getSpotlightCollectionVisitorSelectedTab {
  spotlight @client {
    collection {
      visitorSelectTab
    }
  }
}
    `;

/**
 * __useGetSpotlightCollectionVisitorSelectedTabQuery__
 *
 * To run a query within a React component, call `useGetSpotlightCollectionVisitorSelectedTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightCollectionVisitorSelectedTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightCollectionVisitorSelectedTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotlightCollectionVisitorSelectedTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotlightCollectionVisitorSelectedTabQuery, GetSpotlightCollectionVisitorSelectedTabQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotlightCollectionVisitorSelectedTabQuery, GetSpotlightCollectionVisitorSelectedTabQueryVariables>(GetSpotlightCollectionVisitorSelectedTabDocument, baseOptions);
      }
export function useGetSpotlightCollectionVisitorSelectedTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotlightCollectionVisitorSelectedTabQuery, GetSpotlightCollectionVisitorSelectedTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotlightCollectionVisitorSelectedTabQuery, GetSpotlightCollectionVisitorSelectedTabQueryVariables>(GetSpotlightCollectionVisitorSelectedTabDocument, baseOptions);
        }
export type GetSpotlightCollectionVisitorSelectedTabQueryHookResult = ReturnType<typeof useGetSpotlightCollectionVisitorSelectedTabQuery>;
export type GetSpotlightCollectionVisitorSelectedTabLazyQueryHookResult = ReturnType<typeof useGetSpotlightCollectionVisitorSelectedTabLazyQuery>;
export type GetSpotlightCollectionVisitorSelectedTabQueryResult = ApolloReactCommon.QueryResult<GetSpotlightCollectionVisitorSelectedTabQuery, GetSpotlightCollectionVisitorSelectedTabQueryVariables>;
export const CollectionVisitorsDataDocument = gql`
    query collectionVisitorsData($tableFilters: SpotlightCollectionVisitorsTableFilters) {
  collectionVisitorsList(tableFilters: $tableFilters) {
    visitors {
      ...VisitorByDatesFragment
    }
    totalCount
  }
}
    ${VisitorByDatesFragmentFragmentDoc}`;

/**
 * __useCollectionVisitorsDataQuery__
 *
 * To run a query within a React component, call `useCollectionVisitorsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionVisitorsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionVisitorsDataQuery({
 *   variables: {
 *      tableFilters: // value for 'tableFilters'
 *   },
 * });
 */
export function useCollectionVisitorsDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionVisitorsDataQuery, CollectionVisitorsDataQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionVisitorsDataQuery, CollectionVisitorsDataQueryVariables>(CollectionVisitorsDataDocument, baseOptions);
      }
export function useCollectionVisitorsDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionVisitorsDataQuery, CollectionVisitorsDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionVisitorsDataQuery, CollectionVisitorsDataQueryVariables>(CollectionVisitorsDataDocument, baseOptions);
        }
export type CollectionVisitorsDataQueryHookResult = ReturnType<typeof useCollectionVisitorsDataQuery>;
export type CollectionVisitorsDataLazyQueryHookResult = ReturnType<typeof useCollectionVisitorsDataLazyQuery>;
export type CollectionVisitorsDataQueryResult = ApolloReactCommon.QueryResult<CollectionVisitorsDataQuery, CollectionVisitorsDataQueryVariables>;
export const GetCollectionTopMagazinesDocument = gql`
    query getCollectionTopMagazines($tableFilters: SpotlightCollectionMagazinesTableFilters) {
  collectionMagazinesList(tableFilters: $tableFilters) {
    magazines {
      ...MagazineByDatesFragment
    }
  }
}
    ${MagazineByDatesFragmentFragmentDoc}`;

/**
 * __useGetCollectionTopMagazinesQuery__
 *
 * To run a query within a React component, call `useGetCollectionTopMagazinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionTopMagazinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionTopMagazinesQuery({
 *   variables: {
 *      tableFilters: // value for 'tableFilters'
 *   },
 * });
 */
export function useGetCollectionTopMagazinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionTopMagazinesQuery, GetCollectionTopMagazinesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionTopMagazinesQuery, GetCollectionTopMagazinesQueryVariables>(GetCollectionTopMagazinesDocument, baseOptions);
      }
export function useGetCollectionTopMagazinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionTopMagazinesQuery, GetCollectionTopMagazinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionTopMagazinesQuery, GetCollectionTopMagazinesQueryVariables>(GetCollectionTopMagazinesDocument, baseOptions);
        }
export type GetCollectionTopMagazinesQueryHookResult = ReturnType<typeof useGetCollectionTopMagazinesQuery>;
export type GetCollectionTopMagazinesLazyQueryHookResult = ReturnType<typeof useGetCollectionTopMagazinesLazyQuery>;
export type GetCollectionTopMagazinesQueryResult = ApolloReactCommon.QueryResult<GetCollectionTopMagazinesQuery, GetCollectionTopMagazinesQueryVariables>;
export const GetCollectionMagazinesDataDocument = gql`
    query getCollectionMagazinesData($tableFilters: SpotlightCollectionMagazinesTableFilters) {
  collectionMagazinesList(tableFilters: $tableFilters) {
    magazines {
      ...MagazineByDatesFragment
    }
    totalCount
  }
}
    ${MagazineByDatesFragmentFragmentDoc}`;

/**
 * __useGetCollectionMagazinesDataQuery__
 *
 * To run a query within a React component, call `useGetCollectionMagazinesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionMagazinesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionMagazinesDataQuery({
 *   variables: {
 *      tableFilters: // value for 'tableFilters'
 *   },
 * });
 */
export function useGetCollectionMagazinesDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionMagazinesDataQuery, GetCollectionMagazinesDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionMagazinesDataQuery, GetCollectionMagazinesDataQueryVariables>(GetCollectionMagazinesDataDocument, baseOptions);
      }
export function useGetCollectionMagazinesDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionMagazinesDataQuery, GetCollectionMagazinesDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionMagazinesDataQuery, GetCollectionMagazinesDataQueryVariables>(GetCollectionMagazinesDataDocument, baseOptions);
        }
export type GetCollectionMagazinesDataQueryHookResult = ReturnType<typeof useGetCollectionMagazinesDataQuery>;
export type GetCollectionMagazinesDataLazyQueryHookResult = ReturnType<typeof useGetCollectionMagazinesDataLazyQuery>;
export type GetCollectionMagazinesDataQueryResult = ApolloReactCommon.QueryResult<GetCollectionMagazinesDataQuery, GetCollectionMagazinesDataQueryVariables>;
export const GetCollectionEvolutionDataDocument = gql`
    query getCollectionEvolutionData($filters: SpotlightCollectionFilters) {
  collectionEvolutionData(filters: $filters) {
    values {
      label
      miv
      downloads
    }
  }
}
    `;

/**
 * __useGetCollectionEvolutionDataQuery__
 *
 * To run a query within a React component, call `useGetCollectionEvolutionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionEvolutionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionEvolutionDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCollectionEvolutionDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionEvolutionDataQuery, GetCollectionEvolutionDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionEvolutionDataQuery, GetCollectionEvolutionDataQueryVariables>(GetCollectionEvolutionDataDocument, baseOptions);
      }
export function useGetCollectionEvolutionDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionEvolutionDataQuery, GetCollectionEvolutionDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionEvolutionDataQuery, GetCollectionEvolutionDataQueryVariables>(GetCollectionEvolutionDataDocument, baseOptions);
        }
export type GetCollectionEvolutionDataQueryHookResult = ReturnType<typeof useGetCollectionEvolutionDataQuery>;
export type GetCollectionEvolutionDataLazyQueryHookResult = ReturnType<typeof useGetCollectionEvolutionDataLazyQuery>;
export type GetCollectionEvolutionDataQueryResult = ApolloReactCommon.QueryResult<GetCollectionEvolutionDataQuery, GetCollectionEvolutionDataQueryVariables>;
export const CollectionVisualContentDocument = gql`
    query collectionVisualContent($filters: SpotlightCollectionFilters, $limit: Int!, $sortBy: VisualContentSortingValue, $order: OrderOption, $page: Int) {
  collectionVisualContent(filters: $filters, limit: $limit, sortBy: $sortBy, order: $order, page: $page) {
    ...VisualContentData
  }
}
    ${VisualContentDataFragmentDoc}`;

/**
 * __useCollectionVisualContentQuery__
 *
 * To run a query within a React component, call `useCollectionVisualContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionVisualContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionVisualContentQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCollectionVisualContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionVisualContentQuery, CollectionVisualContentQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionVisualContentQuery, CollectionVisualContentQueryVariables>(CollectionVisualContentDocument, baseOptions);
      }
export function useCollectionVisualContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionVisualContentQuery, CollectionVisualContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionVisualContentQuery, CollectionVisualContentQueryVariables>(CollectionVisualContentDocument, baseOptions);
        }
export type CollectionVisualContentQueryHookResult = ReturnType<typeof useCollectionVisualContentQuery>;
export type CollectionVisualContentLazyQueryHookResult = ReturnType<typeof useCollectionVisualContentLazyQuery>;
export type CollectionVisualContentQueryResult = ApolloReactCommon.QueryResult<CollectionVisualContentQuery, CollectionVisualContentQueryVariables>;
export const GetCollectionVisualContentListWithTotalDocument = gql`
    query getCollectionVisualContentListWithTotal($filters: SpotlightCollectionFilters, $limit: Int!, $sortBy: VisualContentSortingValue, $order: OrderOption, $page: Int, $collectionId: String!, $fullList: Boolean) {
  collectionVisualContent(filters: $filters, limit: $limit, sortBy: $sortBy, order: $order, page: $page, fullList: $fullList) {
    ...VisualContentData
  }
  collectionTotalMedias(collectionId: $collectionId)
}
    ${VisualContentDataFragmentDoc}`;

/**
 * __useGetCollectionVisualContentListWithTotalQuery__
 *
 * To run a query within a React component, call `useGetCollectionVisualContentListWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionVisualContentListWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionVisualContentListWithTotalQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      collectionId: // value for 'collectionId'
 *      fullList: // value for 'fullList'
 *   },
 * });
 */
export function useGetCollectionVisualContentListWithTotalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionVisualContentListWithTotalQuery, GetCollectionVisualContentListWithTotalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionVisualContentListWithTotalQuery, GetCollectionVisualContentListWithTotalQueryVariables>(GetCollectionVisualContentListWithTotalDocument, baseOptions);
      }
export function useGetCollectionVisualContentListWithTotalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionVisualContentListWithTotalQuery, GetCollectionVisualContentListWithTotalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionVisualContentListWithTotalQuery, GetCollectionVisualContentListWithTotalQueryVariables>(GetCollectionVisualContentListWithTotalDocument, baseOptions);
        }
export type GetCollectionVisualContentListWithTotalQueryHookResult = ReturnType<typeof useGetCollectionVisualContentListWithTotalQuery>;
export type GetCollectionVisualContentListWithTotalLazyQueryHookResult = ReturnType<typeof useGetCollectionVisualContentListWithTotalLazyQuery>;
export type GetCollectionVisualContentListWithTotalQueryResult = ApolloReactCommon.QueryResult<GetCollectionVisualContentListWithTotalQuery, GetCollectionVisualContentListWithTotalQueryVariables>;
export const GetCollectionVisualContentListDocument = gql`
    query getCollectionVisualContentList($filters: SpotlightCollectionFilters, $limit: Int!, $sortBy: VisualContentSortingValue, $order: OrderOption, $page: Int, $fullList: Boolean) {
  collectionVisualContent(filters: $filters, limit: $limit, sortBy: $sortBy, order: $order, page: $page, fullList: $fullList) {
    ...VisualContentData
  }
}
    ${VisualContentDataFragmentDoc}`;

/**
 * __useGetCollectionVisualContentListQuery__
 *
 * To run a query within a React component, call `useGetCollectionVisualContentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionVisualContentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionVisualContentListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      fullList: // value for 'fullList'
 *   },
 * });
 */
export function useGetCollectionVisualContentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionVisualContentListQuery, GetCollectionVisualContentListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionVisualContentListQuery, GetCollectionVisualContentListQueryVariables>(GetCollectionVisualContentListDocument, baseOptions);
      }
export function useGetCollectionVisualContentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionVisualContentListQuery, GetCollectionVisualContentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionVisualContentListQuery, GetCollectionVisualContentListQueryVariables>(GetCollectionVisualContentListDocument, baseOptions);
        }
export type GetCollectionVisualContentListQueryHookResult = ReturnType<typeof useGetCollectionVisualContentListQuery>;
export type GetCollectionVisualContentListLazyQueryHookResult = ReturnType<typeof useGetCollectionVisualContentListLazyQuery>;
export type GetCollectionVisualContentListQueryResult = ApolloReactCommon.QueryResult<GetCollectionVisualContentListQuery, GetCollectionVisualContentListQueryVariables>;
export const GetCollectionPlacementListDocument = gql`
    query getCollectionPlacementList($filters: SpotlightCollectionFilters, $limit: Int!, $sortBy: PlacementSortingValue, $order: OrderOption, $page: Int) {
  collectionPlacements(filters: $filters, limit: $limit, sortBy: $sortBy, order: $order, page: $page) {
    ...PlacementData
  }
}
    ${PlacementDataFragmentDoc}`;

/**
 * __useGetCollectionPlacementListQuery__
 *
 * To run a query within a React component, call `useGetCollectionPlacementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionPlacementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionPlacementListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCollectionPlacementListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionPlacementListQuery, GetCollectionPlacementListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionPlacementListQuery, GetCollectionPlacementListQueryVariables>(GetCollectionPlacementListDocument, baseOptions);
      }
export function useGetCollectionPlacementListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionPlacementListQuery, GetCollectionPlacementListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionPlacementListQuery, GetCollectionPlacementListQueryVariables>(GetCollectionPlacementListDocument, baseOptions);
        }
export type GetCollectionPlacementListQueryHookResult = ReturnType<typeof useGetCollectionPlacementListQuery>;
export type GetCollectionPlacementListLazyQueryHookResult = ReturnType<typeof useGetCollectionPlacementListLazyQuery>;
export type GetCollectionPlacementListQueryResult = ApolloReactCommon.QueryResult<GetCollectionPlacementListQuery, GetCollectionPlacementListQueryVariables>;
export const GetCollectionPlacementListWithTotalDocument = gql`
    query getCollectionPlacementListWithTotal($filters: SpotlightCollectionFilters, $limit: Int!, $sortBy: PlacementSortingValue, $order: OrderOption, $page: Int, $collectionId: String, $dateRange: TimeRange) {
  collectionPlacements(filters: $filters, limit: $limit, sortBy: $sortBy, order: $order, page: $page) {
    ...PlacementData
  }
  collectionPlacementsCount(collectionId: $collectionId, dateRange: $dateRange)
}
    ${PlacementDataFragmentDoc}`;

/**
 * __useGetCollectionPlacementListWithTotalQuery__
 *
 * To run a query within a React component, call `useGetCollectionPlacementListWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionPlacementListWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionPlacementListWithTotalQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      collectionId: // value for 'collectionId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetCollectionPlacementListWithTotalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionPlacementListWithTotalQuery, GetCollectionPlacementListWithTotalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionPlacementListWithTotalQuery, GetCollectionPlacementListWithTotalQueryVariables>(GetCollectionPlacementListWithTotalDocument, baseOptions);
      }
export function useGetCollectionPlacementListWithTotalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionPlacementListWithTotalQuery, GetCollectionPlacementListWithTotalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionPlacementListWithTotalQuery, GetCollectionPlacementListWithTotalQueryVariables>(GetCollectionPlacementListWithTotalDocument, baseOptions);
        }
export type GetCollectionPlacementListWithTotalQueryHookResult = ReturnType<typeof useGetCollectionPlacementListWithTotalQuery>;
export type GetCollectionPlacementListWithTotalLazyQueryHookResult = ReturnType<typeof useGetCollectionPlacementListWithTotalLazyQuery>;
export type GetCollectionPlacementListWithTotalQueryResult = ApolloReactCommon.QueryResult<GetCollectionPlacementListWithTotalQuery, GetCollectionPlacementListWithTotalQueryVariables>;
export const GetCollectionListDocument = gql`
    query getCollectionList($page: Int!, $limit: Int!, $sortBy: CollectionListSortOption, $order: OrderOption) {
  collectionList(page: $page, limit: $limit, sortBy: $sortBy, order: $order) {
    collections {
      id
      brandName
      seasonName
      collectionName
      publishedAt
      metrics {
        totalMiv
        totalPlacements
        totalViews
        totalDownloads
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetCollectionListQuery__
 *
 * To run a query within a React component, call `useGetCollectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCollectionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionListQuery, GetCollectionListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionListQuery, GetCollectionListQueryVariables>(GetCollectionListDocument, baseOptions);
      }
export function useGetCollectionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionListQuery, GetCollectionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionListQuery, GetCollectionListQueryVariables>(GetCollectionListDocument, baseOptions);
        }
export type GetCollectionListQueryHookResult = ReturnType<typeof useGetCollectionListQuery>;
export type GetCollectionListLazyQueryHookResult = ReturnType<typeof useGetCollectionListLazyQuery>;
export type GetCollectionListQueryResult = ApolloReactCommon.QueryResult<GetCollectionListQuery, GetCollectionListQueryVariables>;
export const GetSpotlightUiFiltersDocument = gql`
    query getSpotlightUIFilters {
  spotlight @client {
    collection {
      filters {
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useGetSpotlightUiFiltersQuery__
 *
 * To run a query within a React component, call `useGetSpotlightUiFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightUiFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightUiFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotlightUiFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotlightUiFiltersQuery, GetSpotlightUiFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotlightUiFiltersQuery, GetSpotlightUiFiltersQueryVariables>(GetSpotlightUiFiltersDocument, baseOptions);
      }
export function useGetSpotlightUiFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotlightUiFiltersQuery, GetSpotlightUiFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotlightUiFiltersQuery, GetSpotlightUiFiltersQueryVariables>(GetSpotlightUiFiltersDocument, baseOptions);
        }
export type GetSpotlightUiFiltersQueryHookResult = ReturnType<typeof useGetSpotlightUiFiltersQuery>;
export type GetSpotlightUiFiltersLazyQueryHookResult = ReturnType<typeof useGetSpotlightUiFiltersLazyQuery>;
export type GetSpotlightUiFiltersQueryResult = ApolloReactCommon.QueryResult<GetSpotlightUiFiltersQuery, GetSpotlightUiFiltersQueryVariables>;
export const GetSpotlightUrlDocument = gql`
    query getSpotlightUrl($collectionParams: SpotlightSignParameters) {
  spotlightUrl(collectionParams: $collectionParams)
}
    `;

/**
 * __useGetSpotlightUrlQuery__
 *
 * To run a query within a React component, call `useGetSpotlightUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightUrlQuery({
 *   variables: {
 *      collectionParams: // value for 'collectionParams'
 *   },
 * });
 */
export function useGetSpotlightUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotlightUrlQuery, GetSpotlightUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotlightUrlQuery, GetSpotlightUrlQueryVariables>(GetSpotlightUrlDocument, baseOptions);
      }
export function useGetSpotlightUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotlightUrlQuery, GetSpotlightUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotlightUrlQuery, GetSpotlightUrlQueryVariables>(GetSpotlightUrlDocument, baseOptions);
        }
export type GetSpotlightUrlQueryHookResult = ReturnType<typeof useGetSpotlightUrlQuery>;
export type GetSpotlightUrlLazyQueryHookResult = ReturnType<typeof useGetSpotlightUrlLazyQuery>;
export type GetSpotlightUrlQueryResult = ApolloReactCommon.QueryResult<GetSpotlightUrlQuery, GetSpotlightUrlQueryVariables>;
export const SendSpotlightRequestDocument = gql`
    mutation sendSpotlightRequest {
  sendSpotlightRequest
}
    `;
export type SendSpotlightRequestMutationFn = ApolloReactCommon.MutationFunction<SendSpotlightRequestMutation, SendSpotlightRequestMutationVariables>;

/**
 * __useSendSpotlightRequestMutation__
 *
 * To run a mutation, you first call `useSendSpotlightRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSpotlightRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSpotlightRequestMutation, { data, loading, error }] = useSendSpotlightRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendSpotlightRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSpotlightRequestMutation, SendSpotlightRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSpotlightRequestMutation, SendSpotlightRequestMutationVariables>(SendSpotlightRequestDocument, baseOptions);
      }
export type SendSpotlightRequestMutationHookResult = ReturnType<typeof useSendSpotlightRequestMutation>;
export type SendSpotlightRequestMutationResult = ApolloReactCommon.MutationResult<SendSpotlightRequestMutation>;
export type SendSpotlightRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSpotlightRequestMutation, SendSpotlightRequestMutationVariables>;
export const GetRootTagTreesDocument = gql`
    query getRootTagTrees($types: [String]!) {
  rootTagTrees(types: $types) {
    ...TagTreeFragment
    childs {
      ...TagTreeFragment
      childs {
        ...TagTreeFragment
        childs {
          ...TagTreeFragment
        }
      }
    }
  }
}
    ${TagTreeFragmentFragmentDoc}`;

/**
 * __useGetRootTagTreesQuery__
 *
 * To run a query within a React component, call `useGetRootTagTreesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootTagTreesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootTagTreesQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetRootTagTreesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRootTagTreesQuery, GetRootTagTreesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRootTagTreesQuery, GetRootTagTreesQueryVariables>(GetRootTagTreesDocument, baseOptions);
      }
export function useGetRootTagTreesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRootTagTreesQuery, GetRootTagTreesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRootTagTreesQuery, GetRootTagTreesQueryVariables>(GetRootTagTreesDocument, baseOptions);
        }
export type GetRootTagTreesQueryHookResult = ReturnType<typeof useGetRootTagTreesQuery>;
export type GetRootTagTreesLazyQueryHookResult = ReturnType<typeof useGetRootTagTreesLazyQuery>;
export type GetRootTagTreesQueryResult = ApolloReactCommon.QueryResult<GetRootTagTreesQuery, GetRootTagTreesQueryVariables>;
export const GetDescriptionTagsDocument = gql`
    query getDescriptionTags {
  tagsByTypes(types: ["description"]) {
    tags {
      id
      name
      type
      key
      parent {
        id
        name
        key
        type
      }
    }
  }
}
    `;

/**
 * __useGetDescriptionTagsQuery__
 *
 * To run a query within a React component, call `useGetDescriptionTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDescriptionTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDescriptionTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDescriptionTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDescriptionTagsQuery, GetDescriptionTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDescriptionTagsQuery, GetDescriptionTagsQueryVariables>(GetDescriptionTagsDocument, baseOptions);
      }
export function useGetDescriptionTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDescriptionTagsQuery, GetDescriptionTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDescriptionTagsQuery, GetDescriptionTagsQueryVariables>(GetDescriptionTagsDocument, baseOptions);
        }
export type GetDescriptionTagsQueryHookResult = ReturnType<typeof useGetDescriptionTagsQuery>;
export type GetDescriptionTagsLazyQueryHookResult = ReturnType<typeof useGetDescriptionTagsLazyQuery>;
export type GetDescriptionTagsQueryResult = ApolloReactCommon.QueryResult<GetDescriptionTagsQuery, GetDescriptionTagsQueryVariables>;
export const GetTagsByTypesWithColorDocument = gql`
    query getTagsByTypesWithColor($types: [String!]!) {
  tagsByTypes(types: $types) {
    type
    tags {
      ...MediaTagFragment
      parent {
        id
        type
      }
      associatedTags {
        id
      }
      childs {
        ...MediaTagFragment
        colorCode
        parent {
          id
          type
        }
        associatedTags {
          id
        }
      }
    }
  }
}
    ${MediaTagFragmentFragmentDoc}`;

/**
 * __useGetTagsByTypesWithColorQuery__
 *
 * To run a query within a React component, call `useGetTagsByTypesWithColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByTypesWithColorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByTypesWithColorQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetTagsByTypesWithColorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagsByTypesWithColorQuery, GetTagsByTypesWithColorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTagsByTypesWithColorQuery, GetTagsByTypesWithColorQueryVariables>(GetTagsByTypesWithColorDocument, baseOptions);
      }
export function useGetTagsByTypesWithColorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagsByTypesWithColorQuery, GetTagsByTypesWithColorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTagsByTypesWithColorQuery, GetTagsByTypesWithColorQueryVariables>(GetTagsByTypesWithColorDocument, baseOptions);
        }
export type GetTagsByTypesWithColorQueryHookResult = ReturnType<typeof useGetTagsByTypesWithColorQuery>;
export type GetTagsByTypesWithColorLazyQueryHookResult = ReturnType<typeof useGetTagsByTypesWithColorLazyQuery>;
export type GetTagsByTypesWithColorQueryResult = ApolloReactCommon.QueryResult<GetTagsByTypesWithColorQuery, GetTagsByTypesWithColorQueryVariables>;
export const GetAllowedRelationshipsDocument = gql`
    query getAllowedRelationships {
  allowedRelationships {
    tagId
    allowedRelationshipTypes
  }
}
    `;

/**
 * __useGetAllowedRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetAllowedRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllowedRelationshipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllowedRelationshipsQuery, GetAllowedRelationshipsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllowedRelationshipsQuery, GetAllowedRelationshipsQueryVariables>(GetAllowedRelationshipsDocument, baseOptions);
      }
export function useGetAllowedRelationshipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllowedRelationshipsQuery, GetAllowedRelationshipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllowedRelationshipsQuery, GetAllowedRelationshipsQueryVariables>(GetAllowedRelationshipsDocument, baseOptions);
        }
export type GetAllowedRelationshipsQueryHookResult = ReturnType<typeof useGetAllowedRelationshipsQuery>;
export type GetAllowedRelationshipsLazyQueryHookResult = ReturnType<typeof useGetAllowedRelationshipsLazyQuery>;
export type GetAllowedRelationshipsQueryResult = ApolloReactCommon.QueryResult<GetAllowedRelationshipsQuery, GetAllowedRelationshipsQueryVariables>;
export const GetCategoryTagTreesDocument = gql`
    query getCategoryTagTrees {
  tagsByTypes(types: ["nature", "description"]) {
    type
    tags {
      id
      name
      key
    }
  }
  categoryTagTree {
    tag {
      id
      name
      type
      key
    }
    childs {
      tag {
        id
        name
        type
        key
      }
      childs {
        tag {
          id
          name
          type
          key
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryTagTreesQuery__
 *
 * To run a query within a React component, call `useGetCategoryTagTreesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryTagTreesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryTagTreesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryTagTreesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoryTagTreesQuery, GetCategoryTagTreesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoryTagTreesQuery, GetCategoryTagTreesQueryVariables>(GetCategoryTagTreesDocument, baseOptions);
      }
export function useGetCategoryTagTreesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoryTagTreesQuery, GetCategoryTagTreesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoryTagTreesQuery, GetCategoryTagTreesQueryVariables>(GetCategoryTagTreesDocument, baseOptions);
        }
export type GetCategoryTagTreesQueryHookResult = ReturnType<typeof useGetCategoryTagTreesQuery>;
export type GetCategoryTagTreesLazyQueryHookResult = ReturnType<typeof useGetCategoryTagTreesLazyQuery>;
export type GetCategoryTagTreesQueryResult = ApolloReactCommon.QueryResult<GetCategoryTagTreesQuery, GetCategoryTagTreesQueryVariables>;
export const GetTagsListDocument = gql`
    query getTagsList {
  tagList {
    name
    isCustom
    id
    type
    key
    translationsAndAliases {
      key
      translation
      aliases
    }
  }
}
    `;

/**
 * __useGetTagsListQuery__
 *
 * To run a query within a React component, call `useGetTagsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagsListQuery, GetTagsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTagsListQuery, GetTagsListQueryVariables>(GetTagsListDocument, baseOptions);
      }
export function useGetTagsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagsListQuery, GetTagsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTagsListQuery, GetTagsListQueryVariables>(GetTagsListDocument, baseOptions);
        }
export type GetTagsListQueryHookResult = ReturnType<typeof useGetTagsListQuery>;
export type GetTagsListLazyQueryHookResult = ReturnType<typeof useGetTagsListLazyQuery>;
export type GetTagsListQueryResult = ApolloReactCommon.QueryResult<GetTagsListQuery, GetTagsListQueryVariables>;
export const GetUserSessionDocument = gql`
    query getUserSession {
  getSession {
    isAuthenticated
    sessionData {
      tenant
      tenantName
      tenantEcosystemId
      EcosystemAccountID
      EcosystemAccountName
      salesforceAccountId
      salesforceAccountParentId
    }
    usePortal
    tenant {
      id
      logo {
        publicId
        publicUrl
      }
      isSamplesEnabled
      isTagsEnabled
      dev
      name
      featureFlags {
        ...FeatureFlagsInfo
      }
      designers
    }
    user {
      id
      uuid
      email
      firstName
      lastName
      role
    }
  }
}
    ${FeatureFlagsInfoFragmentDoc}`;

/**
 * __useGetUserSessionQuery__
 *
 * To run a query within a React component, call `useGetUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSessionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSessionQuery, GetUserSessionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSessionQuery, GetUserSessionQueryVariables>(GetUserSessionDocument, baseOptions);
      }
export function useGetUserSessionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSessionQuery, GetUserSessionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSessionQuery, GetUserSessionQueryVariables>(GetUserSessionDocument, baseOptions);
        }
export type GetUserSessionQueryHookResult = ReturnType<typeof useGetUserSessionQuery>;
export type GetUserSessionLazyQueryHookResult = ReturnType<typeof useGetUserSessionLazyQuery>;
export type GetUserSessionQueryResult = ApolloReactCommon.QueryResult<GetUserSessionQuery, GetUserSessionQueryVariables>;
export const GetFeatureFlagsFromSessionDocument = gql`
    query getFeatureFlagsFromSession {
  getSession {
    tenant {
      featureFlags {
        ...FeatureFlagsInfo
      }
    }
  }
}
    ${FeatureFlagsInfoFragmentDoc}`;

/**
 * __useGetFeatureFlagsFromSessionQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsFromSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsFromSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsFromSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFlagsFromSessionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeatureFlagsFromSessionQuery, GetFeatureFlagsFromSessionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeatureFlagsFromSessionQuery, GetFeatureFlagsFromSessionQueryVariables>(GetFeatureFlagsFromSessionDocument, baseOptions);
      }
export function useGetFeatureFlagsFromSessionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeatureFlagsFromSessionQuery, GetFeatureFlagsFromSessionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeatureFlagsFromSessionQuery, GetFeatureFlagsFromSessionQueryVariables>(GetFeatureFlagsFromSessionDocument, baseOptions);
        }
export type GetFeatureFlagsFromSessionQueryHookResult = ReturnType<typeof useGetFeatureFlagsFromSessionQuery>;
export type GetFeatureFlagsFromSessionLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsFromSessionLazyQuery>;
export type GetFeatureFlagsFromSessionQueryResult = ApolloReactCommon.QueryResult<GetFeatureFlagsFromSessionQuery, GetFeatureFlagsFromSessionQueryVariables>;
export const GetTagsEnabledDocument = gql`
    query getTagsEnabled {
  getSession @client {
    tenant {
      isTagsEnabled
    }
  }
}
    `;

/**
 * __useGetTagsEnabledQuery__
 *
 * To run a query within a React component, call `useGetTagsEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsEnabledQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagsEnabledQuery, GetTagsEnabledQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTagsEnabledQuery, GetTagsEnabledQueryVariables>(GetTagsEnabledDocument, baseOptions);
      }
export function useGetTagsEnabledLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagsEnabledQuery, GetTagsEnabledQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTagsEnabledQuery, GetTagsEnabledQueryVariables>(GetTagsEnabledDocument, baseOptions);
        }
export type GetTagsEnabledQueryHookResult = ReturnType<typeof useGetTagsEnabledQuery>;
export type GetTagsEnabledLazyQueryHookResult = ReturnType<typeof useGetTagsEnabledLazyQuery>;
export type GetTagsEnabledQueryResult = ApolloReactCommon.QueryResult<GetTagsEnabledQuery, GetTagsEnabledQueryVariables>;
export const GetSessionTenantDocument = gql`
    query getSessionTenant {
  getSession @client {
    sessionData {
      tenant
    }
  }
}
    `;

/**
 * __useGetSessionTenantQuery__
 *
 * To run a query within a React component, call `useGetSessionTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionTenantQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSessionTenantQuery, GetSessionTenantQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSessionTenantQuery, GetSessionTenantQueryVariables>(GetSessionTenantDocument, baseOptions);
      }
export function useGetSessionTenantLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSessionTenantQuery, GetSessionTenantQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSessionTenantQuery, GetSessionTenantQueryVariables>(GetSessionTenantDocument, baseOptions);
        }
export type GetSessionTenantQueryHookResult = ReturnType<typeof useGetSessionTenantQuery>;
export type GetSessionTenantLazyQueryHookResult = ReturnType<typeof useGetSessionTenantLazyQuery>;
export type GetSessionTenantQueryResult = ApolloReactCommon.QueryResult<GetSessionTenantQuery, GetSessionTenantQueryVariables>;
export const UpdateTenantDocument = gql`
    mutation updateTenant($logo: LogoParams) {
  updateTenant(logo: $logo) {
    id
    logo {
      publicId
      publicUrl
    }
  }
}
    `;
export type UpdateTenantMutationFn = ApolloReactCommon.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, baseOptions);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = ApolloReactCommon.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const UploadTenantLogoDocument = gql`
    mutation uploadTenantLogo {
  uploadTenantLogo
}
    `;
export type UploadTenantLogoMutationFn = ApolloReactCommon.MutationFunction<UploadTenantLogoMutation, UploadTenantLogoMutationVariables>;

/**
 * __useUploadTenantLogoMutation__
 *
 * To run a mutation, you first call `useUploadTenantLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTenantLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTenantLogoMutation, { data, loading, error }] = useUploadTenantLogoMutation({
 *   variables: {
 *   },
 * });
 */
export function useUploadTenantLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadTenantLogoMutation, UploadTenantLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadTenantLogoMutation, UploadTenantLogoMutationVariables>(UploadTenantLogoDocument, baseOptions);
      }
export type UploadTenantLogoMutationHookResult = ReturnType<typeof useUploadTenantLogoMutation>;
export type UploadTenantLogoMutationResult = ApolloReactCommon.MutationResult<UploadTenantLogoMutation>;
export type UploadTenantLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadTenantLogoMutation, UploadTenantLogoMutationVariables>;
export const GetSamplesCollectionsDocument = gql`
    query getSamplesCollections {
  tenant {
    isSamplesEnabled
    samplesCollections {
      name
      season
      designer
    }
  }
}
    `;

/**
 * __useGetSamplesCollectionsQuery__
 *
 * To run a query within a React component, call `useGetSamplesCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSamplesCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSamplesCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSamplesCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSamplesCollectionsQuery, GetSamplesCollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSamplesCollectionsQuery, GetSamplesCollectionsQueryVariables>(GetSamplesCollectionsDocument, baseOptions);
      }
export function useGetSamplesCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSamplesCollectionsQuery, GetSamplesCollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSamplesCollectionsQuery, GetSamplesCollectionsQueryVariables>(GetSamplesCollectionsDocument, baseOptions);
        }
export type GetSamplesCollectionsQueryHookResult = ReturnType<typeof useGetSamplesCollectionsQuery>;
export type GetSamplesCollectionsLazyQueryHookResult = ReturnType<typeof useGetSamplesCollectionsLazyQuery>;
export type GetSamplesCollectionsQueryResult = ApolloReactCommon.QueryResult<GetSamplesCollectionsQuery, GetSamplesCollectionsQueryVariables>;
export const AcceptPrivacyTermsDocument = gql`
    mutation acceptPrivacyTerms($privacy: PrivacyAcceptance) {
  acceptPrivacyTerms(privacy: $privacy) {
    email
  }
}
    `;
export type AcceptPrivacyTermsMutationFn = ApolloReactCommon.MutationFunction<AcceptPrivacyTermsMutation, AcceptPrivacyTermsMutationVariables>;

/**
 * __useAcceptPrivacyTermsMutation__
 *
 * To run a mutation, you first call `useAcceptPrivacyTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPrivacyTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPrivacyTermsMutation, { data, loading, error }] = useAcceptPrivacyTermsMutation({
 *   variables: {
 *      privacy: // value for 'privacy'
 *   },
 * });
 */
export function useAcceptPrivacyTermsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptPrivacyTermsMutation, AcceptPrivacyTermsMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptPrivacyTermsMutation, AcceptPrivacyTermsMutationVariables>(AcceptPrivacyTermsDocument, baseOptions);
      }
export type AcceptPrivacyTermsMutationHookResult = ReturnType<typeof useAcceptPrivacyTermsMutation>;
export type AcceptPrivacyTermsMutationResult = ApolloReactCommon.MutationResult<AcceptPrivacyTermsMutation>;
export type AcceptPrivacyTermsMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptPrivacyTermsMutation, AcceptPrivacyTermsMutationVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Media",
        "possibleTypes": [
          {
            "name": "Video"
          },
          {
            "name": "Image"
          },
          {
            "name": "Pdf"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ShowroomFile",
        "possibleTypes": [
          {
            "name": "ShowroomImage"
          },
          {
            "name": "ShowroomVideo"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ShowroomAnalyticsGuestMediaStats",
        "possibleTypes": [
          {
            "name": "ShowroomAnalyticsGuestDownloadedMediaStats"
          },
          {
            "name": "ShowroomAnalyticsGuestViewedMediaStats"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ShowroomAnalyticsGuestMediaStatsMedia",
        "possibleTypes": [
          {
            "name": "ShowroomAnalyticsGuestDownloadedMediaStatsMedia"
          },
          {
            "name": "ShowroomAnalyticsGuestViewedMediaStatsMedia"
          }
        ]
      }
    ]
  }
};
      export default result;
    