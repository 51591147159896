import { SpotlightCollectionUi, VisitorTab } from 'generated/graphql';

export const initialState: SpotlightCollectionUi = {
	filters: {
		startDate: new Date(),
		endDate: new Date()
	},
	currentCollectionId: null,
	visitorSelectTab: VisitorTab.Visitors
};
