import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthenticatedRoute from 'components/AuthenticatedRoute';
import NotFoundError from 'components/Common/Error/NotFoundError';
import UnauthenticatedRoute from 'components/UnauthenticatedRoute';

const Library = React.lazy(() => import('containers/Library'));
const Showroom = React.lazy(() => import('containers/Showroom'));
const PublicShowroom = React.lazy(() => import('containers/PublicShowroom'));
const Login = React.lazy(() => import('containers/Login'));
const Download = React.lazy(() => import('containers/Download'));
const Spotlight = React.lazy(() => import('containers/Spotlight'));
const TagsManagement = React.lazy(() => import('containers/TagsManagement'));

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/login" element={<UnauthenticatedRoute component={Login} />}></Route>
			<Route path="/public/download/:id" element={<UnauthenticatedRoute component={Download} />}></Route>
			<Route path="/p/:tenant/showrooms/:id" element={<UnauthenticatedRoute component={PublicShowroom} />}></Route>
			<Route path="/showrooms/*" element={<AuthenticatedRoute component={Showroom} />}></Route>
			<Route path="/spotlight/*" element={<AuthenticatedRoute component={Spotlight} />}></Route>
			<Route path="/library" element={<AuthenticatedRoute component={Library} />}></Route>
			<Route path="/library/:brand" element={<AuthenticatedRoute component={Library} />}></Route>
			<Route path="/library/:brand/:season" element={<AuthenticatedRoute component={Library} />}></Route>
			<Route path="/library/:brand/:season/:collection" element={<AuthenticatedRoute component={Library} />}></Route>
			<Route path="/library/:brand/:season/:collection/:album" element={<AuthenticatedRoute component={Library} />}></Route>
			<Route path="/tags-management/*" element={<AuthenticatedRoute component={TagsManagement} />} />
			<Route path="/" element={<AuthenticatedRoute component={Library} />}></Route>
			{/* Finally, catch all unmatched routes */}
			<Route path="/*" element={<UnauthenticatedRoute component={NotFoundError} />}></Route>
		</Routes>
	);
};

export default React.memo(AppRoutes);
