import { FieldMergeFunction, FieldReadFunction, Reference } from '@apollo/client';
import flatten from 'lodash/flatten';
import isUndefined from 'lodash/isUndefined';

import StateManager from 'Apollo/StateManager';
import { GetMediasQueryVariables, MediaSearchResponse, LibrarySearchUi } from 'generated/graphql';

export type MediaSearchResponseCache = Pick<MediaSearchResponse, 'cursor' | 'searchId' | 'totalCount'> & {
	medias: { [key: string]: MediaSearchResponse['medias'][0] };
};

export const mergeMedias: FieldMergeFunction<MediaSearchResponseCache, MediaSearchResponse> = (existing, incoming, { readField, args }) => {
	const { cursor } = args as GetMediasQueryVariables;
	const existingSearchId = existing?.searchId;
	const incomingSearchId = incoming?.searchId;

	const existingMedias = existing ? { ...existing.medias } : {};

	const newMedias = incoming.medias.reduce((medias, media) => {
		const id: string = readField('id', media);
		medias[id] = media;
		return medias;
	}, {});

	const medias =
		existingSearchId === incomingSearchId
			? cursor
				? { ...existingMedias, ...newMedias }
				: { ...newMedias, ...existingMedias }
			: { ...newMedias };

	// When fetching new uploaded medias, we don't request the cursor
	const existingCursor = existing?.cursor;
	const nextCursor = isUndefined(incoming.cursor) ? existingCursor : incoming.cursor;

	return {
		medias,
		cursor: nextCursor,
		totalCount: incoming.totalCount,
		searchId: incoming.searchId
	};
};

export const readMedias: FieldReadFunction<MediaSearchResponseCache, MediaSearchResponse> = existing => {
	if (existing) {
		return {
			medias: Object.values(existing.medias),
			cursor: existing.cursor,
			totalCount: existing.totalCount,
			searchId: existing.searchId
		};
	}
};

export const readSelectedFilterValues: FieldReadFunction<Reference[]> = (_, { args, toReference }) => {
	const stateManager = new StateManager<LibrarySearchUi>('library.search');
	const mediaBuckets =
		stateManager
			.get()
			.selectedFilters.find(f => f.type === args.filterType)
			?.byValue.map(id => toReference({ id, type: args.filterType, __typename: 'MediaBucket' })) || [];
	return mediaBuckets;
};

export const readAllSelectedFilterValues: FieldReadFunction<string[]> = () => {
	const stateManager = new StateManager<LibrarySearchUi>('library.search');
	return flatten(stateManager.get().selectedFilters.map(filter => filter.byValue)) || [];
};

export const readSelectedBoxFilterValues: FieldReadFunction<Reference[]> = (_, { args, toReference }) => {
	const stateManager = new StateManager<LibrarySearchUi>('library.search');
	const mediaBuckets = stateManager.get().selectedFilters.filter(f => f.box === args.filterBox);
	return mediaBuckets.flatMap(bucket => bucket.byValue.map(id => toReference({ id, type: bucket.type, __typename: 'MediaBucket' })));
};
