import { ShowroomUserViewSamplesUi } from 'generated/graphql';
import form from './Form/state';

const initialState: ShowroomUserViewSamplesUi = {
	isRequestSamplesFormOpen: false,
	isRequestSamplesFormValid: false,
	form
};

export default initialState; 
