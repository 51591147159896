import * as Sentry from '@sentry/browser';
import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useGetUserSessionQuery, IsAuthenticated } from 'generated/graphql';

import { useIdentifyUser } from 'lib/identifyUser';

import Layout from 'components/Common/Layout';
import LoadingAnimation from 'components/Common/LoadingAnimation';

export default function AuthenticatedRoute({ component: C, ...props }) {
	const { error, data: session, loading } = useGetUserSessionQuery();
	const identify = useIdentifyUser();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();

	React.useEffect(() => {
		if (session) {
			const { isAuthenticated, sessionData, user, usePortal, tenant } = session.getSession;

			if (isAuthenticated) {
				identify(
					{
						id: user.uuid,
						email: user.email,
						role: user.role,
						firstName: user.firstName,
						lastName: user.lastName,
						externalGuest: false,
						migratedToPortal: usePortal,
						sampleRequestingOn: tenant.isSamplesEnabled
					},
					{
						Site: tenant.name,
						siteId: sessionData?.tenant,
						ecoAccountId: sessionData?.EcosystemAccountID,
						EcoAccountName: sessionData?.EcosystemAccountName,
						SalesforceId: sessionData?.salesforceAccountId,
						sfparentid: sessionData?.salesforceAccountParentId,
						ecosystemId: user?.uuid
					}
				);
			}
		}
	}, [session, identify]);

	const isAuthenticated = React.useMemo(() => session?.getSession.isAuthenticated, [session]);

	if (error) {
		Sentry.captureException(new Error(`Error getting session: ${error.message}`));
	}

	// TODO: change this to a main loader
	if (loading) return <LoadingAnimation />;

	return isAuthenticated ? (
		<Layout session={session.getSession as IsAuthenticated}>
			<React.Suspense fallback={<LoadingAnimation />}>
				<C {...props} />
			</React.Suspense>
		</Layout>
	) : (
		<Navigate to={`/login?redirect=${pathname}${searchParams}`} replace />
	);
}
