import * as Sentry from '@sentry/browser';
import React from 'react';

type ErrorBoundaryState = {
	hasError: boolean;
	errorMessage: string;
};

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorMessage: '' };
	}

	componentDidCatch(error: Error) {
		if (error.name === 'ChunkLoadError') {
			window.location.reload();
		} else Sentry.captureException(error);
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			errorMessage: error.message
		};
	}

	render() {
		// We only display the error in staging / dev environment
		if (this.state.hasError && process.env.REACT_APP_STAGE !== 'production') {
			return <div>Something went wrong: {this.state.errorMessage}</div>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
