import { LibraryMediaDetailModalUi, MediaDetailDisplay } from 'generated/graphql';

const initialState: LibraryMediaDetailModalUi = {
	open: false,
	display: MediaDetailDisplay.Bulk,
	mediaIds: null,
	tagPanel: {
		display: null,
		initialCategory: '',
		isDescriptionTag: false,
		relationshipId: null
	},
	mediaDetailsContextIds: []
};

export default initialState;
