import { ShowroomEditor, ShowroomSectionUi } from 'generated/graphql';

export const initialSectionState: ShowroomSectionUi = {
	id: '',
	name: 'Section#1',
	rows: [],
	rowMediaIds: [],
	options: {
		spacing: 0,
		fullWidth: false,
		displayLabel: false,
		zoomLayer: true,
		productLayer: false,
		productLayerSettings: {
			galleriesFields: [],
			samplesFields: []
		},
		globalSelectedFilters: {
			galleriesFields: [],
			samplesFields: []
		}
	},
	loading: false,
	loaded: false
};

const initialState: ShowroomEditor = {
	isOpen: false,
	showroomId: null,
	step: 0,
	currentSection: -1,
	displaySectionOptions: null,
	showroomParams: {
		name: '',
		downloadEnabled: false,
		downloadPdfEnabled: false,
		requestSamplesEnabled: false,
		samplesDepartmentId: null,
		language: 'en',
		library: null,
		librarySeason: null,
		libraryCollection: null,
		heroTitle: '',
		heroContent: '',
		heroFontColor: '#000',
		heroBackgroundColor: '#FFF',
		heroMedia: {
			mediaType: '',
			publicId: '',
			publicUrl: ''
		},
		displayHeroMedia: false,
		displayHeroContent: false,
		pdfs: [],
		sections: [initialSectionState],
		prContacts: [
			{
				name: '',
				jobTitle: '',
				email: '',
				phone: ''
			}
		]
	}
};

export default initialState;
