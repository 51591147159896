import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface CustomThemeFields {
	custom: {
		mediaDetailModalZIndex?: number;
		mediaDetailComponentsZIndex?: number;
		alertZIndex?: number;
		tooltipZIndex?: number;
		lightGreenBackgroundColor?: string;
		defaultBoxShadow?: string;
		transparentBlackBackroundColor?: string;
	};
}
declare module '@material-ui/core/styles/createTheme' {
	interface Theme extends CustomThemeFields {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends Partial<CustomThemeFields> {}
}

export const primaryColor = '#00C795';
export const secondaryColor = '#4df7b9';
export const launchmetricsGrey = '#2F2F2F';
export const launchmetricsLightGrey = '#F8F6F6';
export const lightGreenBackgroundColor = '#E7FFF6';
export const launchmetricsFontGrey = '#9B9B9B';
export const borderGreyColor = '#E6E7E8';
export const borderGreyColorStronger = '#DEDEDE';
export const secondaryMono1 = '#939597';
export const defaultFontSize = 16;
export const backgroundBlack = '#212121';
export const backgroundSpotlight = '#F5F3F3';
export const drawerBackgroundBlack = 'rgba(0,0,0, 0.65)';
export const backgroundGrey = '#CCCCCC';
export const bottomButtonsBackground = grey[100];
export const collectionEvolutionGraphScaleTickColor = grey[500];
export const collectionEvolutionGraphDownloadsColor = '#E25BAF';
export const collectionEvolutionGraphMivColor = '#5EC498';
export const lightestMapGreen = '#9FECD4';
export const lightMapGreen = '#5FE0B7';
export const darkMapGreen = '#4CB392';
export const darkestMapGreen = '#39866E';
// This type should be changed if we decide to override default breakpoints at some point
export type ThemeBreakpoint = Breakpoint;

export const theme = createTheme({
	spacing: 1,
	palette: {
		primary: {
			main: primaryColor,
			light: '#5FE0B7'
		},
		secondary: {
			main: secondaryColor
		}
	},
	overrides: {
		MuiCard: {
			root: {
				borderRadius: 10
			}
		},
		MuiButton: {
			label: {
				textTransform: 'none',
				fontSize: defaultFontSize
			},
			containedPrimary: {
				backgroundColor: secondaryColor,
				'&:hover': {
					backgroundColor: secondaryColor
				}
			},
			root: {
				borderRadius: 3
			}
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderWidth: 1
				},
				borderRadius: 3
			}
		},
		MuiDialogTitle: {
			root: {
				padding: '70px 100px 30px 100px'
			}
		},
		MuiDialogContent: {
			root: {
				padding: '0 0 30px 100px'
			}
		},
		MuiDialogActions: {
			root: {
				padding: '0 0 70px'
			}
		},
		MuiLinearProgress: {
			root: {
				borderRadius: 10
			},
			colorPrimary: {
				backgroundColor: launchmetricsLightGrey
			}
		}
	},
	typography: {
		fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
		h1: {
			fontSize: 38,
			fontWeight: 'bold'
		},
		h2: {
			fontSize: '2em',
			fontWeight: 'bold'
		},
		h4: {
			fontWeight: 400,
			fontSize: 18,
			lineHeight: '24px'
		},
		h3: {
			fontWeight: 400,
			fontSize: 22,
			lineHeight: '32px'
		}
	},
	shape: {
		borderRadius: 0
	},
	custom: {
		mediaDetailModalZIndex: 2000,
		mediaDetailComponentsZIndex: 2001,
		alertZIndex: 3000,
		tooltipZIndex: 4000,
		lightGreenBackgroundColor,
		defaultBoxShadow: '0px 4px 14px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.05)',
		transparentBlackBackroundColor: 'rgba(0, 0, 0, 0.2)'
	}
});
