import * as FullStory from '@fullstory/browser';
import React from 'react';

type UserData = {
	id: string;
	email: string;
	externalGuest: boolean;
	role?: string;
	firstName?: string;
	lastName?: string;
	migratedToPortal?: boolean;
	sampleRequestingOn?: boolean;
};

type TenantData = {
	ecosystemId?: string;
	Site: string;
	siteId: string;
	ecoAccountId: string;
	EcoAccountName: string;
	SalesforceId: string;
	sfparentid: string;
};

export const useIdentifyUser = () => {
	const { aptrinsic } = window as any;

	const identify = React.useCallback(
		(userData: UserData, tenantData: TenantData, uuid?: string) => {
			aptrinsic(
				'identify',
				{
					...userData
				},
				{
					...tenantData
				}
			);

			let userVars: FullStory.UserVars = {
				...(userData.firstName && { displayName: `${userData.firstName} ${userData.lastName}` }),
				email: userData.email,
				...tenantData
			};
			FullStory.identify(userData.email, userVars);
		},
		[aptrinsic]
	);

	return identify;
};
