import axios, { AxiosInstance } from 'axios';

export const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;

const cloudinaryConfig = {
	baseURL: `${CLOUDINARY_URL}`
};

export default class CloudinaryApi {
	private _ajax: AxiosInstance;

	constructor(extraConfig?) {
		this._ajax = axios.create({ ...cloudinaryConfig, ...extraConfig });
	}

	public async post(url: string, data: FormData | any, config: any = {}) {
		return await this._ajax.post(url, data, config);
	}
}
