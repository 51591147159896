import React from 'react';

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { launchmetricsGrey } from 'Styles/theme';

const BlackTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: launchmetricsGrey,
		fontSize: 12,
		lineHeight: '18px',
		maxWidth: 200
	},
	arrow: {
		color: launchmetricsGrey
	},
	popper: {
		zIndex: theme.custom.tooltipZIndex
	}
}))(Tooltip);

export default React.memo(BlackTooltip);
