export default class LocalStorageManager {

	private static storage: LocalStorageManager = new LocalStorageManager();

	private constructor() { }

	public static getInstance() {
		return this.storage;
	}

	public getItem(key: string) {
		const item = localStorage.getItem(key);
		return item ? JSON.parse(item) : undefined;
	}

	public setItem(key: string, item: unknown) {
		localStorage.setItem(key, JSON.stringify(item));
	}
}