import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export type AlertButton = Omit<ButtonProps, 'children' | 'color'> & {
	text: string;
	buttonType: 'cancel' | 'confirm';
};

export type ComponentProps = {
	classes?: {
		modalContainer: string;
	};
	onAccept?(): void;
	onCancel?(): void;
	onClose(): void;
	titleText: string;
	messageText: string;
	onAcceptText?: string;
	onCancelText?: string;
	icon?: JSX.Element;
	displayAsModal?: boolean;
	buttons?: AlertButton[];
};

const Alert = (props: ComponentProps) => {
	const { onClose, titleText, messageText, icon, displayAsModal, buttons, onCancel, onCancelText, onAcceptText, onAccept } = props;

	const classes = useStyles();
	const { t } = useTranslation();

	const buttonsConfig = React.useMemo(() => {
		let buttonsConfig: AlertButton[];
		if (buttons?.length > 0) buttonsConfig = [...buttons];
		else
			buttonsConfig = [
				{
					id: 'alertCancelButton',
					buttonType: 'cancel',
					onClick: onCancel,
					text: onCancelText || t('alert.defaultCancel')
				},
				{
					id: 'alertAcceptButton',
					buttonType: 'confirm',
					onClick: onAccept,
					text: onAcceptText || t('alert.defaultAccept')
				}
			];
		return buttonsConfig;
	}, [buttons, onAccept, onAcceptText, onCancel, onCancelText, t]);

	const alertBox = React.useMemo(() => {
		return (
			<Box id={'alertContainer'} className={classes.confirmRemoveContainer} display="flex" flexDirection="row" alignItems="center">
				{!!icon && (
					<Box display="flex" className={classes.icon}>
						{icon}
					</Box>
				)}
				<Box id={'alertTextContainer'} display="flex" flexDirection="column" className={classes.textContainer}>
					<Typography className={classes.confirmRemoveTitle} id={'alertTitle'} variant="h4">
						{titleText}
					</Typography>
					<Typography className={classes.confirmRemoveText} id={'alertText'}>
						{messageText}
					</Typography>
					<Box id={'alertButtons'} display="flex" alignItems="center" justifyContent="flex-start">
						{buttonsConfig.map(({ text, buttonType, ...buttonProps }) => (
							<Button
								key={buttonProps.id}
								disableElevation
								variant="contained"
								{...buttonProps}
								color={buttonType === 'confirm' ? 'secondary' : 'primary'}
								className={buttonType === 'confirm' ? classes.confirmRemoveButtonKeep : classes.confirmRemoveButtonRemove}
								onClick={ev => {
									if (buttonProps.onClick) buttonProps.onClick(ev);
									onClose();
								}}
							>
								<span className={classes.confirmRemoveButtonText}>{text}</span>
							</Button>
						))}
					</Box>
				</Box>
			</Box>
		);
	}, [classes, icon, titleText, messageText, buttonsConfig, onClose]);

	return displayAsModal ? (
		<Modal open={true} className={clsx(classes.windowContainer, props?.classes?.modalContainer)}>
			{alertBox}
		</Modal>
	) : (
		alertBox
	);
};

export default React.memo(Alert);
