import { GetCurrentFolderDataQuery, useGetCurrentFolderDataQuery, useGetCurrentFolderIdQuery } from 'generated/graphql';

type GetCurrenFolderHook = () => [string, GetCurrentFolderDataQuery];

export const useGetCurrentFolder: GetCurrenFolderHook = () => {
	const { data: folderIdData } = useGetCurrentFolderIdQuery();
	const currentFolderId = folderIdData.library.currentFolderId;
	const { data: currentFolderData } = useGetCurrentFolderDataQuery({ variables: { id: currentFolderId }, skip: !currentFolderId });
	return [currentFolderId, currentFolderData];
};
