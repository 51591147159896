import LocalStorageManager from "lib/LocalStorageManager";

type ShowroomBasketItem = string[];

type StorageType = 'download' |'samples';

export default class ShowroomBasket {

	private itemKey: string
	private storageManager = LocalStorageManager.getInstance()

	constructor(type: StorageType, tenantId: string, showroomId: string) {
		switch (type) {
			case 'samples':
				this.itemKey = `samplesBasket-${tenantId}-${showroomId}`;
				break;
			case 'download':
				this.itemKey = `downloadBasket-${tenantId}-${showroomId}`;
				break;
		}
	}

	public getBasket(): ShowroomBasketItem {
		const basket = this.storageManager.getItem(this.itemKey);
		return basket || [];
	}

	public setBasket(basket: ShowroomBasketItem): void {
		this.storageManager.setItem(this.itemKey, basket);
	}
}