import { makeStyles } from '@material-ui/core/styles';

type StyleProps = {
	showSwitch: boolean;
};

export const useStyles = makeStyles(theme => ({
	toggleButtonContainer: {
		position: 'fixed',
		bottom: 60,
		left: 20,
		zIndex: 9999,
		display: 'flex',
		alignItems: 'center'
	},
	switchContainer: {
		overflow: 'hidden',
		width: (props: StyleProps) => (props.showSwitch ? 145 : 0),
		transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
	},
	switchLabel: {
		marginLeft: 0
	}
}));
