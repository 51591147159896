import React from 'react';
import { useNavigate } from 'react-router-dom';

import { changeCurrentFolderId, changeCurrentLibrary } from 'Apollo/Store/Library/resolvers';
import { Folder, useIsEditorModeQuery } from 'generated/graphql';

import { useGetCurrentFolder } from 'lib/currentFolder';
import { useGetCurrentLibrary } from 'lib/currentLibrary';

const defaultPath = '/library';

export const usePathManager = () => {
	const navigate = useNavigate();
	const {
		data: {
			showroom: {
				editor: { isOpen: isShowroomEditor }
			}
		}
	} = useIsEditorModeQuery();
	const [libraryId, { data: libraryData }] = useGetCurrentLibrary();
	const library = libraryData?.getLibrary;

	const [, currentFolderData] = useGetCurrentFolder();
	const currentFolder = currentFolderData?.folderById;

	const updateUrl = React.useCallback(() => {
		if (library) {
			currentFolder
				? navigate(`${defaultPath}/${library.id}${currentFolder.pathIds || ''}`, { replace: true })
				: navigate(`${defaultPath}/${library.id}`, { replace: true });
		} else {
			navigate(`${defaultPath}`, { replace: true });
		}
	}, [library, currentFolder, navigate]);

	const changeLibrary = React.useCallback(
		(libraryId: string) => {
			if (!isShowroomEditor) {
				navigate(`${defaultPath}/${libraryId || ''}`, { replace: true });
				changeCurrentLibrary(libraryId);
			} else {
				changeCurrentLibrary(libraryId);
			}
		},
		[navigate, isShowroomEditor]
	);

	const changeFolder = React.useCallback(
		(folder: Folder) => {
			if (isShowroomEditor) {
				changeCurrentFolderId(folder?.id || null, true);
			} else {
				navigate(`${defaultPath}/${libraryId}${folder?.pathIds || ''}`, { replace: true });
				changeCurrentFolderId(folder?.id || null, true);
			}
		},
		[navigate, libraryId, isShowroomEditor]
	);

	return {
		changeLibrary,
		changeFolder,
		updateUrl
	};
};
