import dayjs from 'dayjs';

import { OccasionType, ShowroomUserViewSamplesFormUi } from 'generated/graphql';

const initialDate = dayjs();

export const initialState: ShowroomUserViewSamplesFormUi = {
	deliveryDate: initialDate,
	returnDate: dayjs(initialDate).add(1, 'day'),
	streetAdress: '',
	city: '',
	zipCode: '',
	country: '',
	state: '',
	firstName: '',
	lastName: '',
	jobTitle: '',
	company: '',
	email: '',
	prefixNumber: '',
	phoneNumber: '',
	occasionType: OccasionType.None,
	runThroughDate: null,
	publication: '',
	storyTheme: '',
	eventName: '',
	celebrity: '',
	additionalComments: '',
	shootDateStart: null,
	shootDateEnd: null,
	shootLocation: '',
	photographer: '',
	modelName: ''
};

export default initialState;
