/* istanbul ignore file */
export const loadImage = async (url): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = async () => {
			resolve(img);
		};
		img.onerror = err => {
			reject(err);
		};
		img.src = url;
	});
};
