import React from 'react';

import { useTimeout } from 'lib/Hooks';

import Alert, { ComponentProps as AlertComponentProps } from 'components/Common/Alert/Alert';

export type ComponentProps = {
	children: JSX.Element;
};

export type AlertProps = {
	duration?: number;
};

export type AlertState = {
	open: boolean;
	openAlert(props: Omit<AlertComponentProps, 'onClose'> & { onClose?(): void }, alertProps?: AlertProps): void;
};

export const AlertContext = React.createContext<AlertState>({
	open: false,
	openAlert: null
});

const AlertProvider = (props: ComponentProps) => {
	const { children } = props;

	const { run } = useTimeout();

	const [open, setOpen] = React.useState<boolean>(false);
	const [alertComponentProps, setAlertComponentProps] = React.useState<AlertComponentProps>({
		onClose: () => setOpen(false),
		titleText: 'Alert',
		messageText: 'this is the default message'
	});

	const openAlert = React.useCallback(
		(props: Omit<AlertComponentProps, 'onClose'> & { onClose?(): void }, alertProps?: AlertProps) => {
			setAlertComponentProps({ onClose: () => setOpen(false), ...props });
			setOpen(true);
			if (alertProps?.duration) {
				run(() => {
					setOpen(false);
				}, alertProps.duration);
			}
		},
		[run]
	);

	const state: AlertState = {
		open,
		openAlert
	};

	return (
		<AlertContext.Provider value={state}>
			{children}
			{open && <Alert {...alertComponentProps} />}
		</AlertContext.Provider>
	);
};

export default React.memo(AlertProvider);
